import React from 'react'
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { useLoader } from '../Layout/Loader';
import { Autocomplete } from '@material-ui/lab';
import { Grid, TextField } from '@material-ui/core';
import * as APIs from '../../Data/APIs';
import { Button } from "@material-ui/core";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import exportIconSVG from "../../Icons/exportArrow.svg";

function UnitMeasurementModal() {
  const dialog = useModalDialog(0);
  const { openLoader, closeLoader } = useLoader();
  const [isExportClicked, setIsExportClicked] = React.useState(false);
  const [selectedInstance, setSelectedInstance] = React.useState();
  const [instancesAutoCompleteInput, setInstanceAutoCompleteInput] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState();
  const [instances, setInstances] = React.useState([]);
  // static data for the begin of unit measurement file 
  const minDate = dayjs("2025/02");
  const minDateMillieSeconds = minDate.valueOf();
  const maxDate = dayjs().subtract(1, "month").endOf("month");
  const maxDateMillieSeconds = maxDate.valueOf();
  // validation 
  const [selectedDateValidation, setSelectedDateValidation] = React.useState("");
  const [selectedInstanceValidation, setSelectedInstanceValidation] = React.useState("");

  // Handle Export Click with Validation
  const handleDownloadFile = (e) => {
    e.preventDefault();
    if (!isExportClicked || selectedDateValidation || selectedInstanceValidation)
      return;
    else {
      // Proceed with API call
      const payload = {
        InstanceId: `${selectedInstance?.InstanceID}`,
        Month: `${selectedDate?.$M + 1}`, // Fix month extraction
        Year: `${selectedDate?.$y}`,
      };

      openLoader();
      APIs.downloadUnitMeasurementFile(payload).then((r) => {
        window.open(r?.url);
        dialog.closeDialog();
        closeLoader();
        setIsExportClicked(false);
      });
    }
  };

  React.useEffect(() => {
    if (!selectedDate) {
      setSelectedDateValidation("Date is required");
    } else if (selectedDate.valueOf() < minDateMillieSeconds) {
      setSelectedDateValidation("Date must be after Feb 2025");
    } else if (selectedDate.valueOf() > maxDateMillieSeconds) {
      setSelectedDateValidation("Date must be before the actual month");
    } else {
      setSelectedDateValidation("");
    }
  }, [selectedDate, selectedDateValidation])

  React.useEffect(() => {
    if (!selectedInstance) {
      setSelectedInstanceValidation("Instance is required");
    } else {
      setSelectedInstanceValidation("");
    }
  }, [selectedInstance, selectedInstanceValidation])

  React.useEffect(() => {
    APIs.getGroupsInstances().then((r) => {
      setInstances(r?.data);
    }
    );
  }, [])

  return (
    <form onSubmit={handleDownloadFile}>
      <div style={{ minWidth: "600px" }} className="d-flex flex-column">
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={5}>
            <h6 className="form-title">
              Select Instance:
              <span className="text-danger mb-2 ml-2">*</span>
            </h6>
            <Autocomplete
              options={instances}
              autoHighlight
              className="search-dropdown w-100"
              style={{ maxWidth: "100%" }}
              inputValue={instancesAutoCompleteInput ?? ""}
              onChange={(e, v) => {
                setSelectedInstance(v);
                setInstanceAutoCompleteInput(v?.InstanceURL);
              }}
              getOptionLabel={(option) => option?.InstanceURL}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setInstanceAutoCompleteInput(e.target.value)}
                  inputProps={{ ...params.inputProps, autoComplete: "off" }}
                />
              )}
            />
            <div className="col-xl-2 alignvh-center" hidden={!isExportClicked}></div>
            <div className="col-xl-10 text-danger" hidden={!isExportClicked}>{selectedInstanceValidation}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} style={{ marginLeft: "10px" }}>
            <h6 className="form-title">
              Select Month and Year:
              <span className="text-danger mb-2 ml-2">*</span>
            </h6>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="col-12"
                disableToolbar
                variant="outline"
                views={["year", "month"]}
                sx={{
                  "& .MuiOutlinedInput-root ": {
                    border: "1px solid var(--grey6) !important", // Ensure border stays visible with the portal color
                    transition: "none", // Disable hover transition effect
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    border: "1px solid var(--grey6) !important", // Force border to stay the same
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Disable the notched outline
                  },
                }}
                format="MM/YYYY"
                id="date-picker-inline"
                style={{ width: "100%" }}
                maxDate={maxDate}
                minDate={minDate}
                onChange={date => {
                  setSelectedDate(date)
                }}
              />
            </LocalizationProvider>
            <div className="col-xl-2 alignvh-center" hidden={!isExportClicked}></div>
            <div className="col-xl-10 text-danger" hidden={!isExportClicked}>{selectedDateValidation}</div>
          </Grid>
        </Grid>
        <div className="row d-flex justify-content-end mt-3" style={{ gap: "5px" }}>
          <Button variant="contained" className="blue-outlined-btn" color="primary" onClick={() => dialog.closeDialog()}>CANCEL</Button>
          <Button type="submit" variant="contained" className="blue-btn" onClick={() => setIsExportClicked(true)}>
            <svg width="22" height="23" viewBox="0 0 22 23" stroke="white" strokeWidth="1.5" fill="none" >
              <use xlinkHref={`${exportIconSVG}#exportIcon`} style={{ fill: "white" }}></use>
            </svg>
            <span className="ml-2">Export</span>
          </Button>
        </div>
      </div>
    </form>
  )
} export default UnitMeasurementModal