import React, { useState, useRef, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { format, parse, isValid } from "date-fns";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import calendarIconSVG from "../../Icons/fal-calendar-alt.svg";

const EmptyToolbar = () => null;

const displayedPositionToNumericIndex = (displayedPos, displayedValue) => {
    let numericIndex = 0;
    for (let i = 0; i < displayedPos; i++) {
        if (displayedValue[i] !== '/') {
            numericIndex++;
        }
    }
    return numericIndex;
};

const numericIndexToDisplayedPosition = (numericIndex, displayedValue) => {
    let count = 0;
    for (let i = 0; i < displayedValue.length; i++) {
        if (count === numericIndex) return i;
        if (displayedValue[i] !== '/') count++;
    }
    return displayedValue.length;
};

const GenericDatePicker = ({ value, onChange, classNames, placeholder, minDate }) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(() => {
        if (!value) return "";
        if (typeof value === "string") return value;
        if (value instanceof Date && isValid(value)) return format(value, "MM/dd/yyyy");
        return "";
    });
    const [open, setOpen] = useState(false);
    const [dateFinalized, setDateFinalized] = useState(true);
    const [isInvalid, setIsInvalid] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [lastSelection, setLastSelection] = useState({ start: 0, end: 0 });

    useEffect(() => {
        if (value instanceof Date && isValid(value)) {
            const formatted = format(value, "MM/dd/yyyy");
            if (inputValue !== formatted) {
                setInputValue(formatted);
            }
            setIsInvalid(false);
        } else if (typeof value === "string") {
            setInputValue(value);
            setIsInvalid(!normalizeDate(value));
        } else if (value === null && !dateFinalized) {
            // Don’t override if it’s during internal user edit
        } else if (value === null || value === "") {
            setInputValue("");
            setIsInvalid(false);
        }
    }, [value]);

    const normalizeDate = (dateStr) => {
        if (!dateStr || typeof dateStr !== "string") return null;
        let match = dateStr.match(/^(\d{2})\/(\d{2})(?:\/(\d{2,4}))?$/);
        if (match) {
            let [, month, day, year] = match;
            if (!year) year = new Date().getFullYear();
            if (year.length === 2) year = `20${year}`;
            const parsedDate = parse(`${month}/${day}/${year}`, "MM/dd/yyyy", new Date());
            return isValid(parsedDate) ? parsedDate : null;
        }
        return null;
    };

    const handleSelect = (event) => {
        setLastSelection({
            start: event.target.selectionStart,
            end: event.target.selectionEnd,
        });
    };

    const handleInputChange = (event) => {
        const newDisplayedValue = event.target.value;
        const newNumericValue = newDisplayedValue.replace(/\D/g, '');
        const prevNumericValue = inputValue.replace(/\D/g, '');

        const numericStart = displayedPositionToNumericIndex(lastSelection.start, inputValue);
        const numericEnd = displayedPositionToNumericIndex(lastSelection.end, inputValue);

        const insertedLength = newNumericValue.length - (prevNumericValue.length - (numericEnd - numericStart));
        const newInputDigits = newNumericValue.substring(numericStart, numericStart + insertedLength);

        let updatedNumeric = prevNumericValue.slice(0, numericStart) + newInputDigits + prevNumericValue.slice(numericEnd);
        updatedNumeric = updatedNumeric.slice(0, 8); // Limit to 8 digits

        let formattedValue = '';
        for (let i = 0; i < updatedNumeric.length; i++) {
            if (i === 2 || i === 4) {
                formattedValue += '/';
            }
            formattedValue += updatedNumeric[i];
        }

        setInputValue(formattedValue);
        setDateFinalized(false);

        // Calculate new cursor position
        const newCursorNumericPosition = numericStart + insertedLength;
        let displayedCursorPos = numericIndexToDisplayedPosition(newCursorNumericPosition, formattedValue);

        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(displayedCursorPos, displayedCursorPos);
            }
        }, 0);

        if (!formattedValue.trim()) {
            onChange(null);
            setIsInvalid(false);
            setDateFinalized(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Backspace") {
            event.preventDefault();

            const numericStart = displayedPositionToNumericIndex(lastSelection.start, inputValue);
            const numericEnd = displayedPositionToNumericIndex(lastSelection.end, inputValue);

            let prevNumericValue = inputValue.replace(/\D/g, '');

            if (numericStart === 0 && numericEnd === prevNumericValue.length) {
                // If everything is selected, clear the input
                setInputValue("");
                setIsInvalid(false);
                setDateFinalized(false);
                onChange(null);
                return;
            }

            let updatedNumeric = "";

            if (numericStart < numericEnd) {
                // If a range is selected, remove the selected range
                updatedNumeric = prevNumericValue.slice(0, numericStart) + prevNumericValue.slice(numericEnd);
            } else if (numericStart > 0) {
                // Delete a single digit before the cursor
                updatedNumeric = prevNumericValue.slice(0, numericStart - 1) + prevNumericValue.slice(numericStart);
            }

            updatedNumeric = updatedNumeric.slice(0, 8); // Ensure max 8 digits

            let formattedValue = "";
            for (let i = 0; i < updatedNumeric.length; i++) {
                if (i === 2 || i === 4) {
                    formattedValue += "/";
                }
                formattedValue += updatedNumeric[i];
            }

            setInputValue(formattedValue);
            setDateFinalized(false);

            // Adjust cursor position after deletion
            const newCursorNumericPosition = Math.max(0, numericStart - 1);
            let displayedCursorPos = numericIndexToDisplayedPosition(newCursorNumericPosition, formattedValue);

            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                    inputRef.current.setSelectionRange(displayedCursorPos, displayedCursorPos);
                }
            }, 0);
        } else if (event.key === "Enter") {
            event.preventDefault();
            finalizeDate();
        }
    };


    const finalizeDate = () => {
        if (dateFinalized) return;

        if (!inputValue.trim()) {
            setIsInvalid(false);
            onChange(null);
            setDateFinalized(true);
            return;
        }

        const date = normalizeDate(inputValue);
        if (date) {
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            setInputValue(format(utcDate, "MM/dd/yyyy"));
            onChange(utcDate); // valid date
            setIsInvalid(false);
        } else {
            onChange(inputValue); // pass raw invalid input
            setIsInvalid(true);
        }

        setDateFinalized(true);
    };


    const handleOpenCalendar = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleCloseCalendar = () => {
        setOpen(false);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="date-div alignvh-center w-100">
                <TextField
                    inputRef={inputRef}
                    value={inputValue}
                    onChange={handleInputChange}
                    onSelect={handleSelect}
                    onBlur={finalizeDate}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    error={isInvalid}
                    helperText={isInvalid ? "Invalid Date" : ""}
                    placeholder={placeholder}
                    InputProps={{
                        endAdornment: (
                            <img
                                src={calendarIconSVG}
                                alt="calendar"
                                onClick={handleOpenCalendar}
                                style={{ cursor: "pointer" }}
                            />
                        ),
                    }}
                />
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseCalendar}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <DatePicker
                        autoOk
                        variant="static"
                        openTo="date"
                        value={normalizeDate(inputValue) || null}
                        onChange={(date) => {
                            if (date && isValid(date)) {
                                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                                setInputValue(format(utcDate, "MM/dd/yyyy"));
                                onChange(utcDate);
                                setIsInvalid(false);
                                handleCloseCalendar();
                            }
                        }}
                        ToolbarComponent={EmptyToolbar}
                        minDate={minDate} // Pass the minDate prop to the DatePicker
                    />
                </Popover>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default GenericDatePicker;