import React from 'react';
import * as APIs from '../../Data/APIs';
import { useLoader } from '../Layout/Loader';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from '@mui/material';
import { FailureModal } from '../Modals/FailureModal';


function RejectAndReplaceCarrier({carrier,...props}) {
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [availableCarriers, setAvailableCarriers] = React.useState([]);
    const [selectedCarrier, setSelectedCarrier] = React.useState("");
    const [carrierAutoCompleteInput, setCarrierAutoCompleteInput] = React.useState("");
    // create body for reject carrier
    // API reject carrier
    const handleRejectCarrierSubmit = () => {
        const body= {
            RejectedCarrierID: carrier?.CarrierID,
            RejectedCarrierName: carrier?.CarrierName,
            NewCarrierID: selectedCarrier?.CarrierID,
            NewCarrierName: selectedCarrier?.CarrierName
        }        
        openLoader();
        APIs.rejectCarrier(body).then((r) => {
            if(r?.success === 0){
                FailureModal(dialog,{
                    title: "Failure",
                    content: r?.msg
                });
            }
            else{
                closeLoader();
                window.location.reload();
            }
    });
    }
    // get approved and pending carriers except the selected carrier 
    React.useEffect(() => {
        openLoader();
        APIs.getCarriers({ StatusTypeId: [1,2] }).then((r) => (
            setAvailableCarriers(r?.data.filter(c => c?.CarrierID !== carrier?.CarrierID)),
            closeLoader()
        ));
    }, [])
  return (
    <div id="reject-carrier" style={{minWidth: "600px"}} className="d-flex flex-column">
        <span>{`If you want to reject "${carrier?.CarrierName}" please select another for its linked Connectivity Requests`}</span>
            <br />
            <Autocomplete
                options={availableCarriers}
                autoHighlight
                className="search-dropdown w-100"
                style={{maxWidth: "100%"}}
                inputValue={carrierAutoCompleteInput??""}
                onChange={(e, v) => 
                {
                    setSelectedCarrier(v);
                    setCarrierAutoCompleteInput(v?.CarrierName);
                }}
                getOptionLabel={(option)=>option?.CarrierName}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        onChange={(e) => setCarrierAutoCompleteInput(e.target.value)}
                        inputProps={{...params.inputProps, autoComplete: "off"}}
                        />}
            />
        <div className="d-flex justify-content-end mt-3" style={{gap: "10px"}}>
            <Button variant="contained" className="blue-btn" color="primary" onClick={()=>handleRejectCarrierSubmit()}>SUBMIT</Button>
            <Button variant="contained" className="blue-outlined-btn" color="primary" onClick={()=>dialog.closeDialog()}>CANCEL</Button>
        </div>
    </div>
  )
}

export default RejectAndReplaceCarrier