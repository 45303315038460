import React, { useRef } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import * as APIs from "../../../Data/APIs";
import MapInternalCarrier from "./MapInternalCarrier";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { Button } from "@material-ui/core";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';


function SelectVerifiedCarriers({ parentForceUpdate, onCarrierNotListedChecked, carriers, onSelectedOtherCarriersChecked, setSelectedCarriers, selectedCarriers, selectedOtherCarriers, setSelectedOtherCarriers, carrierSearchValue, onIndexCallback, onInternalCarrier, ...props }) {

    const dialog = useModalDialog(0);
    const [checkedCarrier, setCheckedCarrier] = React.useState(false);
    const [index, setIndex] = React.useState(-1);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [carrierOtherChecked, setCarrierOtherChecked] = React.useState(false);

    const handleCarrierChange = (value) => (e) => {
        setCheckedCarrier(e.target.checked);
    }

    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarriers;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            // value.CarrierID = null;
            // value.CarrierInternalName = "";
            selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
        }
        else {
            value.SelectedCRTypes = ["EDI"];
            value.ConnectivityRequestEDISubTypeID = 1;
            selectedCarriersArray.push(value);
            forceUpdate();
            if (value.InternalCarrierID === null)
                dialog.openDialog("Map Carriers", <MapInternalCarrier iSolvedCarrier={value} forceCarrierListUpdate={parentForceUpdate} selectedCarriers={selectedCarriers} />, []);
        }
        setSelectedCarriers([...selectedCarriersArray]);
        setIndex(selectedCarriers.indexOf(value));
        onIndexCallback(selectedCarriers.indexOf(value));
    }

    const handleCarrierOtherChange = (value) => (e) => {
        onSelectedOtherCarriersChecked(e.target.checked);
        setCarrierOtherChecked(e.target.checked);
        onCarrierNotListedChecked(e.target.checked);
        let selectedOtherCarriersArray = selectedOtherCarriers;
        if (!value || value === "") selectedOtherCarriersArray = [];
        else if (selectedOtherCarriersArray.indexOf(value) >= 0) {
            // value.CarrierID = null;
            // value.CarrierInternalName = "";
            selectedOtherCarriersArray.splice(selectedOtherCarriersArray.indexOf(value), 1);
        }
        else {
            selectedOtherCarriersArray.push(value);
            forceUpdate();
        }
        // value.SelectedCRTypes = ["EDI"];
        // value.ConnectivityRequestEDISubType = 1;
        setSelectedOtherCarriers([...selectedOtherCarriersArray]);
        setIndex(selectedCarriers.indexOf(value));
        onIndexCallback(selectedCarriers.indexOf(value));
    }

    React.useEffect(() => {
        if (!selectedCarriers) return;
        const selectedCarrier = selectedCarriers[index];
        if (!selectedCarrier) return;
        selectedCarrier.CarrierID = onInternalCarrier;
    }, [onInternalCarrier]);

    return (
        <div id="select-carriers-plans">
            <Accordion expanded={false}>
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="carriers-select"
                    className="accordion-checkboxes select-carrier-accordion"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                id={`carrier-option-other`}
                                value={carrierOtherChecked}
                                checked={carrierOtherChecked === true}
                                onClick={handleCarrierOtherChange()}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<DoneOutlineIcon />}
                            />}
                        label="Not Listed"
                    />
                </AccordionSummary>
            </Accordion>
            {carriers?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                <Accordion key={`carriers-${ci}`} expanded={false}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="carriers-select"
                        className="accordion-checkboxes select-carrier-accordion"
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <Checkbox
                                    id={`carrier-option-${ci}`}
                                    value={c}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checked={(selectedCarriers && selectedCarriers.indexOf(c) >= 0)}
                                    onChange={handleCarrierChange(c)}
                                    onClick={handleCarrierClick(c)}
                                    checkedIcon={<DoneOutlineIcon />}
                                    color="default"
                                />
                            }
                            label={c?.CarrierName}
                        />
                    </AccordionSummary>
                </Accordion>
            ))}
        </div>
    )
}

export default SelectVerifiedCarriers;