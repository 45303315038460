import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { useAuthentication } from "../../Data/Authentication";
import GenericDatePicker from "../Layout/GenericDatePicker";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


function FormsTransmissionsFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onPageSizeAdv,
    onSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    onCloseClearReset,
    onFeedIDAdvFilter,
    onSelectedFeedNameAdvFilter,
    onSelectedCarrierIDAdvFilter,
    onSelectedFeedStatusAdvFilter,
    onSelectedFeedPhaseAdvFilter,
    onExecutionIDAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    onSelectedProcessingStepAdvFilter,
    onRequestFromDateAdvFilter,
    onRequestToDateAdvFilter,
    onSentFromDateAdvFilter,
    onSentToDateAdvFilter,
    mode,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);

    const [feedIDAdv, setFeedIDAdv] = React.useState("");
    const [selectedFeedNameAdv, setSelectedFeedNameAdv] = React.useState([]);
    const [feedsList, setFeedsList] = React.useState([]);
    const [feedName, setFeedName] = React.useState();
    const [feedSearchValue, setFeedSearchValue] = React.useState("");
    const [selectedCarrierIDAdv, setSelectedCarrierIDAdv] = React.useState([]);
    const [carName, setCarName] = React.useState();
    const [carriersList, setCarriersList] = React.useState([]);
    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [selectedFeedStatusAdv, setSelectedFeedStatusAdv] = React.useState([]);
    const [feedsStatusesList, setFeedsStatusesList] = React.useState([]);
    const [selectedFeedPhaseAdv, setSelectedFeedPhaseAdv] = React.useState([]);
    const [feedsPhasesList, setFeedsPhasesList] = React.useState([]);
    const [executionIDAdv, setExecutionIDAdv] = React.useState("");
    const [selectedExecutionStatusAdv, setSelectedExecutionStatusAdv] = React.useState([]);
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);
    const [selectedProcessingStepAdv, setSelectedProcessingStepAdv] = React.useState([]);
    const [processingStepsList, setProcessingStepsList] = React.useState([]);
    const [requestFromDateAdv, setRequestFromDateAdv] = React.useState(todayDate);
    const [requestToDateAdv, setRequestToDateAdv] = React.useState(todayDate);
    const [sentFromDateAdv, setSentFromDateAdv] = React.useState(null);
    const [sentToDateAdv, setSentToDateAdv] = React.useState(null);

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
    }, [feedName])

    React.useEffect(() => {
        APIs.getCarriersListByName({ CarrierName: carName ?? "" , StatusTypeId: [1,2]}).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [carName])

    React.useEffect(() => {
        APIs.getFeedStatuses().then((r) => (!(r?.length) ? setFeedsStatusesList([]) : setFeedsStatusesList(r)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedPhases().then((r) => (!(r?.data?.length) ? setFeedsPhasesList([]) : setFeedsPhasesList(r.data)));
    }, [])

    React.useEffect(() => {
        APIs.getDFExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getDFProcessingStepsList().then((r) => setProcessingStepsList(r?.data));
    }, [])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleClearSearch = () => {
        if (mode !== "singleFormFeed") {
            setFeedIDAdv("");
            onFeedIDAdvFilter("");
            setSelectedFeedNameAdv([]);
            onSelectedFeedNameAdvFilter([]);
            setSelectedCarrierIDAdv([]);
            onSelectedCarrierIDAdvFilter([]);
        }
        
        setSelectedFeedStatusAdv([]);
        onSelectedFeedStatusAdvFilter([]);
        setSelectedFeedPhaseAdv([]);
        onSelectedFeedPhaseAdvFilter([]);
        setExecutionIDAdv("");
        onExecutionIDAdvFilter("");
        setSelectedExecutionStatusAdv([]);
        onSelectedExecutionStatusAdvFilter([]);
        setSelectedProcessingStepAdv([]);
        onSelectedProcessingStepAdvFilter([]);
        setRequestFromDateAdv(monthDate);
        setRequestToDateAdv(todayDate);
        onRequestFromDateAdvFilter(monthDate);
        onRequestToDateAdvFilter(todayDate);
        setSentFromDateAdv(null);
        setSentToDateAdv(null);
        onSentFromDateAdvFilter(null);
        onSentToDateAdvFilter(null);
        onSelectedPageAdv(1);
    }

    React.useEffect(() => {
        if (mode !== "singleFormFeed") {
            setFeedIDAdv("");
            onFeedIDAdvFilter("");
            setSelectedFeedNameAdv([]);
            onSelectedFeedNameAdvFilter([]);
            setSelectedCarrierIDAdv([]);
            onSelectedCarrierIDAdvFilter([]);
        }

        setSelectedFeedStatusAdv([]);
        onSelectedFeedStatusAdvFilter([]);
        setSelectedFeedPhaseAdv([]);
        onSelectedFeedPhaseAdvFilter([]);
        setExecutionIDAdv("");
        onExecutionIDAdvFilter("");
        setSelectedExecutionStatusAdv([]);
        onSelectedExecutionStatusAdvFilter([]);
        setSelectedProcessingStepAdv([]);
        onSelectedProcessingStepAdvFilter([]);
        setRequestFromDateAdv(todayDate);
        setRequestToDateAdv(todayDate);
        onRequestFromDateAdvFilter(todayDate);
        onRequestToDateAdvFilter(todayDate);
        setSentFromDateAdv(null);
        setSentToDateAdv(null);
        onSentFromDateAdvFilter(null);
        onSentToDateAdvFilter(null);
        onSelectedPageAdv(1);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handleFeedIDFilter = (e) => {
        setFeedIDAdv(e.target.value);
        onFeedIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleFeedNameClick = (value) => (event) => {
        let selectedFeedsArray = selectedFeedNameAdv;
        if (!value || value === "") selectedFeedsArray = [];
        else if (selectedFeedsArray.indexOf(value) >= 0) {
            selectedFeedsArray.splice(selectedFeedsArray.indexOf(value), 1);
        }
        else {
            selectedFeedsArray.push(value);
        }
        setSelectedFeedNameAdv([...selectedFeedsArray]);
        onSelectedFeedNameAdvFilter([...selectedFeedsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarrierIDAdv;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
        }
        else {
            selectedCarriersArray.push(value);
        }
        setSelectedCarrierIDAdv([...selectedCarriersArray]);
        onSelectedCarrierIDAdvFilter([...selectedCarriersArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleFeedsStatusesClick = (value) => (event) => {
        let selectedFeedsStatusesArray = selectedFeedStatusAdv;
        if (!value || value === "") selectedFeedsStatusesArray = [];
        else if (selectedFeedsStatusesArray.indexOf(value) >= 0) {
            selectedFeedsStatusesArray.splice(selectedFeedsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedFeedsStatusesArray.push(value);
        }
        setSelectedFeedStatusAdv([...selectedFeedsStatusesArray]);
        onSelectedFeedStatusAdvFilter([...selectedFeedsStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleFeedsPhasesClick = (value) => (event) => {
        let selectedFeedsPhasesArray = selectedFeedPhaseAdv;
        if (!value || value === "") selectedFeedsPhasesArray = [];
        else if (selectedFeedsPhasesArray.indexOf(value) >= 0) {
            selectedFeedsPhasesArray.splice(selectedFeedsPhasesArray.indexOf(value), 1);
        }
        else {
            selectedFeedsPhasesArray.push(value);
        }
        setSelectedFeedPhaseAdv([...selectedFeedsPhasesArray]);
        onSelectedFeedPhaseAdvFilter([...selectedFeedsPhasesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleExecutionIDFilter = (e) => {
        setExecutionIDAdv(e.target.value);
        onExecutionIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleExecutionStatusesClick = (value) => (event) => {
        let selectedExecutionStatusesArray = selectedExecutionStatusAdv;
        if (!value || value === "") selectedExecutionStatusesArray = [];
        else if (selectedExecutionStatusesArray.indexOf(value) >= 0) {
            selectedExecutionStatusesArray.splice(selectedExecutionStatusesArray.indexOf(value), 1);
        }
        else {
            selectedExecutionStatusesArray.push(value);
        }
        setSelectedExecutionStatusAdv([...selectedExecutionStatusesArray]);
        onSelectedExecutionStatusAdvFilter([...selectedExecutionStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleProcessingStepsClick = (value) => (event) => {
        let selectedProcessingStepsArray = selectedProcessingStepAdv;
        if (!value || value === "") selectedProcessingStepsArray = [];
        else if (selectedProcessingStepsArray.indexOf(value) >= 0) {
            selectedProcessingStepsArray.splice(selectedProcessingStepsArray.indexOf(value), 1);
        }
        else {
            selectedProcessingStepsArray.push(value);
        }
        setSelectedProcessingStepAdv([...selectedProcessingStepsArray]);
        onSelectedProcessingStepAdvFilter([...selectedProcessingStepsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleRequestDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setRequestFromDateAdv(date);
            onRequestFromDateAdvFilter(date);
        }
        else {
            setRequestToDateAdv(date);
            onRequestToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };

    const handleSentDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSentFromDateAdv(date);
            onSentFromDateAdvFilter(date);
        }
        else {
            setSentToDateAdv(date);
            onSentToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };
    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
    }
    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    React.useEffect(() => {
        if (requestFromDateAdv === null || requestToDateAdv === null) {
            setRequestFromDateAdv(monthDate);
            setRequestToDateAdv(todayDate);
            onRequestFromDateAdvFilter(monthDate);
            onRequestToDateAdvFilter(todayDate);
        }
    }, [requestFromDateAdv, requestToDateAdv])

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Transmissions Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            {(userActions?.includes("43") ||
                userActions?.includes("20")) ? (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                // variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                {mode !== "singleFormFeed"
                                    ? [
                                        <Tab key="feed-id" className="filter-tab-btn" label="Feed ID" {...a11yProps(0)} />,
                                        <Tab key="feed-name" className="filter-tab-btn" label="Feed Name" {...a11yProps(1)} />,
                                        <Tab key="carrier-name" className="filter-tab-btn" label="Carrier Name" {...a11yProps(2)} />,
                                    ]
                                    : null}
                                <Tab className="filter-tab-btn" label="Feed Status" {...a11yProps(mode !== "singleFormFeed" ? 3 : 0)} />
                                <Tab className="filter-tab-btn" label="Feed Phase" {...a11yProps(mode !== "singleFormFeed" ? 4 : 1)} />
                                <Tab className="filter-tab-btn" label="Execution ID" {...a11yProps(mode !== "singleFormFeed" ? 5 : 2)} />
                                <Tab className="filter-tab-btn" label="Transmission Status" {...a11yProps(mode !== "singleFormFeed" ? 6 : 3)} />
                                <Tab className="filter-tab-btn" label="Processing Step" {...a11yProps(mode !== "singleFormFeed" ? 7 : 4)} />
                                <Tab className="filter-tab-btn" label="Request Date" {...a11yProps(mode !== "singleFormFeed" ? 8 : 5)} />
                                <Tab className="filter-tab-btn" label="Sent Date" {...a11yProps(mode !== "singleFormFeed" ? 9 : 6)} />
                            </Tabs>
                            
                            {mode !== "singleFormFeed" && (
                                <>
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one feed ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Feed ID" value={feedIDAdv} onChange={handleFeedIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search feeds here..." value={feedSearchValue} onChange={(e) => setFeedSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`feeds-select-all`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedNameAdv && selectedFeedNameAdv.length === feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedFeedNameAdv, feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s), setSelectedFeedNameAdv, onSelectedFeedNameAdvFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }

                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`feeds-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedNameAdv && selectedFeedNameAdv.indexOf(c) >= 0}
                                                                onClick={handleFeedNameClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.FeedName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`carriers-select-all`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarrierIDAdv && selectedCarrierIDAdv.length === carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedCarrierIDAdv, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarrierIDAdv, onSelectedCarrierIDAdvFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }

                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`carriers-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carrier-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarrierIDAdv && selectedCarrierIDAdv.indexOf(c) >= 0}
                                                                onClick={handleCarrierClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.CarrierName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                                </>
                            )}
                            
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 3 : 0}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`feeds-statuse-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-statuse-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-statuse-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedStatusAdv && selectedFeedStatusAdv.length === feedsStatusesList.length}
                                                            onClick={handelSelectAll(selectedFeedStatusAdv, feedsStatusesList, setSelectedFeedStatusAdv, onSelectedFeedStatusAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {feedsStatusesList.map((f, fi) => (
                                        <Accordion key={`feeds-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedStatusAdv && selectedFeedStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 4 : 1}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`feeds-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-phases-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedPhaseAdv && selectedFeedPhaseAdv.length === feedsPhasesList.length}
                                                            onClick={handelSelectAll(selectedFeedPhaseAdv, feedsPhasesList, setSelectedFeedPhaseAdv, onSelectedFeedPhaseAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {feedsPhasesList.map((f, fi) => (
                                        <Accordion key={`feeds-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedPhaseAdv && selectedFeedPhaseAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedPhaseName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 5 : 2}>
                                <span>Please filter by only one execution ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Execution ID" value={executionIDAdv} onChange={handleExecutionIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 6 : 3}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`execution-status-all`}>
                                        <AccordionSummary
                                            aria-controls="execution-status-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`execution-status-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.length === executionStatusesList.length}
                                                            onClick={handelSelectAll(selectedExecutionStatusAdv, executionStatusesList, setSelectedExecutionStatusAdv, onSelectedExecutionStatusAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {executionStatusesList.map((f, fi) => (
                                        <Accordion key={`execution-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="execution-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`execution-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleExecutionStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 7 : 4}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`processing-step-all`}>
                                        <AccordionSummary
                                            aria-controls="processing-step-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`processing-step-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedProcessingStepAdv && selectedProcessingStepAdv.length === processingStepsList.length}
                                                            onClick={handelSelectAll(selectedProcessingStepAdv, processingStepsList, setSelectedProcessingStepAdv, onSelectedProcessingStepAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {processingStepsList.map((f, fi) => (
                                        <Accordion key={`processing-step-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="processing-step-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`processing-step-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedProcessingStepAdv && selectedProcessingStepAdv.indexOf(f) >= 0}
                                                                onClick={handleProcessingStepsClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 8 : 5}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestFromDateAdv}
                                    onChange={(date) => {
                                        setRequestFromDateAdv(date);
                                        onRequestFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestToDateAdv}
                                    onChange={(date) => {
                                        setRequestToDateAdv(date);
                                        onRequestToDateAdvFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 9 : 6}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentFromDateAdv}
                                    onChange={(date) => {
                                        setSentFromDateAdv(date);
                                        onSentFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentToDateAdv}
                                    onChange={(date) => {
                                        setSentToDateAdv(date);
                                        onSentToDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            ) : (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                // variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                            
                                {mode !== "singleFormFeed"
                                    ? [
                                        <Tab className="filter-tab-btn" label="Feed Name" {...a11yProps(0)} />,
                                        <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(1)} />,
                                    ]
                                    : null}
                                <Tab className="filter-tab-btn" label="Feed Status" {...a11yProps(mode !== "singleFormFeed" ? 2 : 0)} />
                                <Tab className="filter-tab-btn" label="Feed Phase" {...a11yProps(mode !== "singleFormFeed" ? 3 : 1)} />
                                <Tab className="filter-tab-btn" label="Transmission Status" {...a11yProps(mode !== "singleFormFeed" ? 4 : 2)} />
                                <Tab className="filter-tab-btn" label="Processing Step" {...a11yProps(mode !== "singleFormFeed" ? 5 : 3)} />
                                <Tab className="filter-tab-btn" label="Request Date" {...a11yProps(mode !== "singleFormFeed" ? 6 : 4)} />
                                <Tab className="filter-tab-btn" label="Sent Date" {...a11yProps(mode !== "singleFormFeed" ? 7 : 5)} />
                            </Tabs>
                            {mode !== "singleFormFeed" && (
                                <>
                            <TabPanel value={tabValue} index={0}>
                                <input type="text" className="text-input w-100" placeholder="Search feeds here..." value={feedSearchValue} onChange={(e) => setFeedSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`feeds-select-all`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedNameAdv && selectedFeedNameAdv.length === feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedFeedNameAdv, feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s), setSelectedFeedNameAdv, onSelectedFeedNameAdvFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }



                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`feeds-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedNameAdv && selectedFeedNameAdv.indexOf(c) >= 0}
                                                                onClick={handleFeedNameClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.FeedName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`carriers-select-all`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarrierIDAdv && selectedCarrierIDAdv.length === carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedCarrierIDAdv, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarrierIDAdv, onSelectedCarrierIDAdvFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }


                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`carriers-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carrier-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarrierIDAdv && selectedCarrierIDAdv.indexOf(c) >= 0}
                                                                onClick={handleCarrierClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.CarrierName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            </>
                            )}
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 2 : 0}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`feeds-statuse-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-statuse-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-statuse-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedStatusAdv && selectedFeedStatusAdv.length === feedsStatusesList.length}
                                                            onClick={handelSelectAll(selectedFeedStatusAdv, feedsStatusesList, setSelectedFeedStatusAdv, onSelectedFeedStatusAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {feedsStatusesList.map((f, fi) => (
                                        <Accordion key={`feeds-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedStatusAdv && selectedFeedStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 3 : 1}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`feeds-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-phases-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedPhaseAdv && selectedFeedPhaseAdv.length === feedsPhasesList.length}
                                                            onClick={handelSelectAll(selectedFeedPhaseAdv, feedsPhasesList, setSelectedFeedPhaseAdv, onSelectedFeedPhaseAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {feedsPhasesList.map((f, fi) => (
                                        <Accordion key={`feeds-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedPhaseAdv && selectedFeedPhaseAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedPhaseName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 4 : 2}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`execution-status-all`}>
                                        <AccordionSummary
                                            aria-controls="execution-status-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`execution-status-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.length === executionStatusesList.length}
                                                            onClick={handelSelectAll(selectedExecutionStatusAdv, executionStatusesList, setSelectedExecutionStatusAdv, onSelectedExecutionStatusAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {executionStatusesList.map((f, fi) => (
                                        <Accordion key={`execution-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="execution-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`execution-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleExecutionStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 5 : 3}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`processing-step-all`}>
                                        <AccordionSummary
                                            aria-controls="processing-step-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`processing-step-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedProcessingStepAdv && selectedProcessingStepAdv.length === processingStepsList.length}
                                                            onClick={handelSelectAll(selectedProcessingStepAdv, processingStepsList, setSelectedProcessingStepAdv, onSelectedProcessingStepAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>


                                    {processingStepsList.map((f, fi) => (
                                        <Accordion key={`processing-step-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="processing-step-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`processing-step-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedProcessingStepAdv && selectedProcessingStepAdv.indexOf(f) >= 0}
                                                                onClick={handleProcessingStepsClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 6 : 4}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestFromDateAdv}
                                    onChange={(date) => {
                                        setRequestFromDateAdv(date);
                                        onRequestFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestToDateAdv}
                                    onChange={(date) => {
                                        setRequestToDateAdv(date);
                                        onRequestToDateAdvFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={mode !== "singleFormFeed" ? 7 : 5}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentFromDateAdv}
                                    onChange={(date) => {
                                        setSentFromDateAdv(date);
                                        onSentFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentToDateAdv}
                                    onChange={(date) => {
                                        setSentToDateAdv(date);
                                        onSentToDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            )}


            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )

}

export default FormsTransmissionsFilterModalBody;