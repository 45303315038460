import React from "react";
import { Divider, Paper } from "@material-ui/core";
import BorderCircularProgress from "../Charts/BorderCircularProgress";
import Chart from "react-google-charts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dateTimeSVG from "../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'moment';
import PieChartIcon from '@mui/icons-material/PieChart';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import LinearProgressBar from "../Layout/LinearProgressBar";
import GenericDatePicker from "../Layout/GenericDatePicker";

function OERequestsStatistics({
    dashboardOEStatus,
    dashboardOEPhases,
    onPhaseFilter,
    onStatusFilter,
    tableRef,
    onSetOnPhaseDashboardChange,
    onSetOnStatusDashboardChange,
    onSetCreatedFromDateFilter,
    onSetCreatedToDateFilter,
    onSetStatusesFilterCard,
    setQuickFiltersClicked,
    onSetFilterCard,
    planYearStartFromDateFilter,
    setPlanYearStartFromDateFilter,
    planYearStartToDateFilter,
    setPlanYearStartToDateFilter,
    ...props
}) {
    const _statusColors = [
        "#CCCCCC",
        "#0e4c8a",
        "#EBA400",
        "#F22525",
        "#00C305",



    ];


    const _phaseColors = [
        "#CCCCCC",
        "#7cd992",
        "#000000",
        "#EBA400",
        "#5382a1",
        "#0e4c8a",
        "#00C305",

    ];
    const [expandedAccordion, setExpandedAccordion] = React.useState(false);
    const phaseSizes = [300, 275, 250, 225, 200, 175, 150, 125];
    function handlePhase(phase) {
        onSetFilterCard(true);
        onSetOnPhaseDashboardChange(true);
        onSetOnStatusDashboardChange(false);
        onSetCreatedFromDateFilter(null);
        onSetCreatedToDateFilter(null);
        onStatusFilter("");
        onSetStatusesFilterCard([1, 2, 4, 5]);

        onPhaseFilter(phase);
        tableRef.current.scrollIntoView({ behavior: 'smooth' });
        setQuickFiltersClicked(true);
    }

    function handleStatus(status) {
        onSetFilterCard(true);
        onSetOnStatusDashboardChange(true);
        onSetOnPhaseDashboardChange(false);
        onSetCreatedFromDateFilter(null);
        onSetCreatedToDateFilter(null);
        onSetStatusesFilterCard([]);
        onPhaseFilter("");
        onStatusFilter(status);
        tableRef.current.scrollIntoView({ behavior: 'smooth' });
        setQuickFiltersClicked(true);

    }
    const handlePlanYearStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setPlanYearStartFromDateFilter(date);
        }
        else {
            setPlanYearStartToDateFilter(date);
        }
    };
    const getCountsForElementStatus = (id) => {
        const elementIndex = dashboardOEStatus.findIndex((element) => {
            return element.id === id;
        })
        return dashboardOEStatus[elementIndex].count;
    }
    const getCountsForElementPhase = (id) => {
        const elementIndex = dashboardOEPhases.findIndex((element) => {
            return element.id === id;
        })
        return dashboardOEPhases[elementIndex].count;
    }
    return (
        <div id="connectivity-requests-statistics">
            <div className="row w-100 alignvh-center mb-4">
                <h5 className="black1 fw-400 ml-3">OE Requests Dashboard</h5>
            </div>
            <Accordion className="exchangeAccordion mb-4">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                    style={{ minHeight: "65px" }}
                >
                    <div style={{ fontWeight: "500" }}>
                        <PieChartIcon className="color pink-primary-color mr-3" />
                        <span>OE Requests Statistics</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ borderTop: "1px solid gainsboro" }}>
                    <div className="row mb-5">
                        <div className="col-xl-3">
                            <span>Plan Year Start Date:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={planYearStartFromDateFilter}
                                    onChange={(date) => setPlanYearStartFromDateFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                        </div>
                        <div className="col-xl-3">
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={planYearStartToDateFilter}
                                    onChange={(date) => setPlanYearStartToDateFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                        </div>
                    </div>
                    <div className="mb-5">
                        {dashboardOEStatus && dashboardOEStatus.length > 0 && dashboardOEStatus.filter(s => s.count === 0).length !== dashboardOEStatus.length && (
                            <>
                                <h6 className="fw-500">Statuses</h6>
                                <div className="row mt-3">
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardOEStatus.find(el => el.id == 1)?.name} percentage={Math.round(dashboardOEStatus.find(el => el.id == 1)?.percentage * 10) / 10} count={dashboardOEStatus.find(el => el.id == 1)?.count} level="blue" onClickHandler={() => handleStatus(1)} />
                                        <LinearProgressBar title={dashboardOEStatus.find(el => el.id == 2)?.name} percentage={Math.round(dashboardOEStatus.find(el => el.id == 2)?.percentage * 10) / 10} count={dashboardOEStatus.find(el => el.id == 2)?.count} level="green" onClickHandler={() => handleStatus(2)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardOEStatus.find(el => el.id == 4)?.name} percentage={Math.round(dashboardOEStatus.find(el => el.id == 4)?.percentage * 10) / 10} count={dashboardOEStatus.find(el => el.id == 4)?.count} level="red" onClickHandler={() => handleStatus(4)} />
                                        <LinearProgressBar title={dashboardOEStatus.find(el => el.id == 5)?.name} percentage={Math.round(dashboardOEStatus.find(el => el.id == 5)?.percentage * 10) / 10} count={dashboardOEStatus.find(el => el.id == 5)?.count} level="red" onClickHandler={() => handleStatus(5)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardOEStatus.find(el => el.id == 3)?.name} percentage={Math.round(dashboardOEStatus.find(el => el.id == 3)?.percentage * 10) / 10} count={dashboardOEStatus.find(el => el.id == 3)?.count} level="red" onClickHandler={() => handleStatus(3)} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {dashboardOEPhases && dashboardOEPhases.length > 0 && dashboardOEPhases.filter(s => s.count === 0).length !== dashboardOEPhases.length && (
                            <>
                                <h6 className="fw-500">Phases</h6>
                                <div className="row mt-3">
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 2)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 2)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 2)?.count} level="blue" onClickHandler={() => handlePhase(2)} />
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 1)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 1)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 1)?.count} level="blue" onClickHandler={() => handlePhase(1)} />
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 3)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 3)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 3)?.count} level="blue" onClickHandler={() => handlePhase(3)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 4)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 4)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 4)?.count} level="blue" onClickHandler={() => handlePhase(4)} />
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 5)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 5)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 5)?.count} level="green" onClickHandler={() => handlePhase(5)} />
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 6)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 6)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 6)?.count} level="green" onClickHandler={() => handlePhase(6)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardOEPhases.find(el => el.id == 7)?.name} percentage={Math.round(dashboardOEPhases.find(el => el.id == 7)?.percentage * 10) / 10} count={dashboardOEPhases.find(el => el.id == 7)?.count} level="red" onClickHandler={() => handlePhase(7)} />
                                    </div>
                                </div>
                            </>
                        )}

                        {dashboardOEStatus.filter(s => s.count === 0).length === dashboardOEStatus.length && dashboardOEPhases.filter(s => s.count === 0).length === dashboardOEPhases.length && (
                            <div className="no-data-alert mt-5">
                                <h5>No Data Available!</h5>
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default OERequestsStatistics;
