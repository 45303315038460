import React from "react";
import { Paper, TextField, Button, Select, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useAuthentication } from "../../Data/Authentication";
import FormsTransmissionsFilterModalBody from "./FormsTransmissionsFilterModalBody";
import Moment from 'moment';
import TruncatedChip from "../Layout/TruncatedChip";
import refreshSVG from "../../Icons/refresh.svg";
import cancelIconSVG from "../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../Icons/advancedFilter.svg";
import GenericDatePicker from "../Layout/GenericDatePicker";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const yearDate = new Date();
yearDate.setHours(0, 0, 0, 0)
yearDate.setFullYear(todayDate.getFullYear() - 1);

function FormsTransmissionDashboardFilter({
    onFeedIDFilter,
    onCarrierIDFilter,
    onExecutionStatusFilter,
    onProcessingStepFilter,
    onFeedStatusFilter,
    onFeedPhaseFilter,
    onExecutionIDFilter,
    onFromDateFilter,
    fromDateFilter,
    onToDateFilter,
    toDateFilter,
    onSentFromDateFilter,
    sentFromDateFilter,
    onSentToDateFilter,
    sentToDateFilter,
    setRefreshClicked,
    selectedPage,
    onSelectedPage,
    pageSize,
    onPageSize,
    totalCount,
    setTotalCount,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    feedIDAdvFilter,
    onFeedIDAdvFilter,
    selectedFeedNameAdvFilter,
    onSelectedFeedNameAdvFilter,
    selectedCarrierIDAdvFilter,
    onSelectedCarrierIDAdvFilter,
    selectedFeedStatusAdvFilter,
    onSelectedFeedStatusAdvFilter,
    selectedFeedPhaseAdvFilter,
    onSelectedFeedPhaseAdvFilter,
    executionIDAdvFilter,
    onExecutionIDAdvFilter,
    selectedExecutionStatusAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    selectedProcessingStepAdvFilter,
    onSelectedProcessingStepAdvFilter,
    requestFromDateAdvFilter,
    onRequestFromDateAdvFilter,
    requestToDateAdvFilter,
    onRequestToDateAdvFilter,
    sentFromDateAdvFilter,
    onSentFromDateAdvFilter,
    sentToDateAdvFilter,
    onSentToDateAdvFilter,
    mode,
    ...props
}) {


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [feedsList, setFeedsList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);
    const [processingStepsList, setProcessingStepsList] = React.useState([]);
    const [feedsStatusesList, setFeedsStatusesList] = React.useState([]);
    const [feedsPhasesList, setFeedsPhasesList] = React.useState([]);

    const [selectedFromDate, setSelectedFromDate] = React.useState(todayDate);
    const [selectedToDate, setSelectedToDate] = React.useState(todayDate);
    const [selectedSentFromDate, setSelectedSentFromDate] = React.useState(null);
    const [selectedSentToDate, setSelectedSentToDate] = React.useState(null);
    const [feedName, setFeedName] = React.useState("");
    const [feedNameAutoValue, setFeedNameAutoValue] = React.useState("");
    const [feedID, setFeedID] = React.useState("");
    const [carrierName, setCarrierName] = React.useState("");
    const [carrierNameAutoValue, setCarrierNameAutoValue] = React.useState("");
    const [carrierID, setCarrierID] = React.useState("");
    const [executionStatus, setExecutionStatus] = React.useState("");
    const [processingStep, setProcessingStep] = React.useState("");
    const [feedStatus, setFeedStatus] = React.useState("");
    const [feedPhase, setFeedPhase] = React.useState("");
    const [executionID, setExecutionID] = React.useState("");

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        if (mode !== "singleFormFeed") {
            onFeedIDFilter("");
            setFeedID("");
            setFeedName("");
            onCarrierIDFilter("");
            setCarrierName("");
        }
        
        onExecutionStatusFilter("");
        setExecutionStatus("");
        onProcessingStepFilter("");
        setProcessingStep("");
        onFeedStatusFilter("");
        setFeedStatus("");
        onFeedPhaseFilter("");
        setFeedPhase("");
        onExecutionIDFilter("");
        setExecutionID("");
        onFromDateFilter(todayDate);
        setSelectedFromDate(todayDate);
        onToDateFilter(todayDate);
        setSelectedToDate(todayDate);
        onSentFromDateFilter(null);
        setSelectedSentFromDate(null);
        onSentToDateFilter(null);
        setSelectedSentToDate(null);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
        setFeedNameAutoValue(feedName);
    }, [feedName])

    React.useEffect(() => {
        openLoader();
        APIs.getCarriersListByName({ CarrierName: carrierName ?? "" , StatusTypeId: [1,2]}).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
        setCarrierNameAutoValue(carrierName);
    }, [carrierName])

    React.useEffect(() => {
        APIs.getDFExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getDFProcessingStepsList().then((r) => setProcessingStepsList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getFeedStatuses().then((r) => (!(r?.length) ? setFeedsStatusesList([]) : setFeedsStatusesList(r)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedPhases().then((r) => (!(r?.data?.length) ? setFeedsPhasesList([]) : setFeedsPhasesList(r.data)));
    }, [])

    React.useEffect(() => {
        if (feedsList)
            closeLoader();
        else openLoader()
    }, [feedsList])

    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedFromDate(date);
            onFromDateFilter(date);
        }
        else {
            setSelectedToDate(date);
            onToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleSentDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedSentFromDate(date);
            onSentFromDateFilter(date);
        }
        else {
            setSelectedSentToDate(date);
            onSentToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleExecutionStatusChange = (e) => {
        setExecutionStatus(e.target.value);
        onExecutionStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleProcessingStepChange = (e) => {
        setProcessingStep(e.target.value);
        onProcessingStepFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedStatusChange = (e) => {
        setFeedStatus(e.target.value);
        onFeedStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedPhaseChange = (e) => {
        setFeedPhase(e.target.value);
        onFeedPhaseFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleExecutionIDFilter = (e) => {
        setExecutionID(e.target.value);
        onExecutionIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedIDFilter = (e) => {
        setFeedID(e.target.value);
        onFeedIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClearSearch = () => {
        setFeedName("");
        setFeedID("");
        onFeedIDFilter("");
        setCarrierName("");
        setCarrierID("");
        onCarrierIDFilter("");
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setFeedStatus("");
        onFeedStatusFilter("");
        setFeedPhase("");
        onFeedPhaseFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setSelectedFromDate(monthDate);
        onFromDateFilter(monthDate);
        setSelectedToDate(todayDate);
        onToDateFilter(todayDate);
        setSelectedSentFromDate(null);
        onSentFromDateFilter(null);
        setSelectedSentToDate(null);
        onSentToDateFilter(null);
        onSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        if (mode !== "singleFormFeed") {
            setFeedName("");
            setFeedID("");
            onFeedIDFilter("");
            setCarrierName("");
            setCarrierID("");
            onCarrierIDFilter("");
            onFeedIDAdvFilter("");
            onSelectedFeedNameAdvFilter([]);
            onSelectedCarrierIDAdvFilter([]);
        }
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setFeedStatus("");
        onFeedStatusFilter("");
        setFeedPhase("");
        onFeedPhaseFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setSelectedFromDate(todayDate);
        onFromDateFilter(todayDate);
        setSelectedToDate(todayDate);
        onToDateFilter(todayDate);
        setSelectedSentFromDate(null);
        onSentFromDateFilter(null);
        setSelectedSentToDate(null);
        onSentToDateFilter(null);
        onSelectedPage(1);
        onSelectedFeedStatusAdvFilter([]);
        onSelectedFeedPhaseAdvFilter([]);
        onExecutionIDAdvFilter("");
        onSelectedExecutionStatusAdvFilter([]);
        onSelectedProcessingStepAdvFilter([]);
        onRequestFromDateAdvFilter(todayDate);
        onRequestToDateAdvFilter(todayDate);
        onSentFromDateFilter(null);
        onSentToDateFilter(null);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    React.useEffect(() => {
        if (selectedFromDate === null || selectedToDate === null) {
            setSelectedFromDate(monthDate);
            onFromDateFilter(monthDate);
            setSelectedToDate(todayDate);
            onToDateFilter(todayDate);
        }
    }, [selectedFromDate, selectedToDate])

    return (
        <div>
            <div className="search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <Button
                        className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                        onClick={handleClickOpenDialog}
                        style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1">
                            <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                        </svg>
                        Advanced Filters
                    </Button>
                    <React.Fragment>
                        {((userActions?.includes("43") || userActions?.includes("20")) && mode != "singleFormFeed") && feedID && <TruncatedChip body={`Feed ID: ${feedID}`} />}
                        {((userActions?.includes("43") || userActions?.includes("20")) && mode != "singleFormFeed") && feedIDAdvFilter && <TruncatedChip body={`Feed ID: ${feedIDAdvFilter}`} />}
                        {(feedName && mode != "singleFormFeed") && <TruncatedChip body={`Feed: ${feedName}`} />}
                        {mode != "singleFormFeed" && selectedFeedNameAdvFilter && selectedFeedNameAdvFilter?.length > 0 && <TruncatedChip body={`Feeds: ${selectedFeedNameAdvFilter?.map(s => s.FeedName).join(" - ")}`} />}
                        {carrierName && mode != "singleFormFeed" && <TruncatedChip body={`Carrier: ${carrierName}`} />}
                        {selectedCarrierIDAdvFilter && selectedCarrierIDAdvFilter?.length > 0 && mode != "singleFormFeed" && <TruncatedChip body={`Carriers: ${selectedCarrierIDAdvFilter?.map(s => s.CarrierName).join(" - ")}`} />}
                        {feedStatus && <TruncatedChip body={`Feed Status: ${feedsStatusesList?.filter(s => s.FeedStatusID == feedStatus)[0].FeedStatusName}`} />}
                        {selectedFeedStatusAdvFilter && selectedFeedStatusAdvFilter?.length > 0 && <TruncatedChip body={`Feed Statuses: ${selectedFeedStatusAdvFilter?.map(s => s.FeedStatusName).join(" - ")}`} />}
                        {feedPhase && <TruncatedChip body={`Feed Phase: ${feedsPhasesList?.filter(r => r.FeedPhaseID == feedPhase)[0].FeedPhaseName}`} />}
                        {selectedFeedPhaseAdvFilter && selectedFeedPhaseAdvFilter?.length > 0 && <TruncatedChip body={`Feed Phases: ${selectedFeedPhaseAdvFilter?.map(s => s.FeedPhaseName).join(" - ")}`} />}
                        {executionID && <TruncatedChip body={`Execution ID: ${executionID}`} />}
                        {executionIDAdvFilter && <TruncatedChip body={`Execution ID: ${executionIDAdvFilter}`} />}
                        {executionStatus && <TruncatedChip body={`Transmission Status: ${executionStatusesList?.filter(s => s.Id == executionStatus)[0].Name}`} />}
                        {selectedExecutionStatusAdvFilter && selectedExecutionStatusAdvFilter?.length > 0 && <TruncatedChip body={`Transmission Statuses: ${selectedExecutionStatusAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {processingStep && <TruncatedChip body={`Processing Step: ${processingStepsList?.filter(s => s.Id == processingStep)[0].Name}`} />}
                        {selectedProcessingStepAdvFilter && selectedProcessingStepAdvFilter?.length > 0 && <TruncatedChip body={`Processing Steps: ${selectedProcessingStepAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {quickFiltersClicked && fromDateFilter && toDateFilter && <TruncatedChip body={`Request Date: From: ${Moment(fromDateFilter).format("MM/DD/YYYY")} To: ${Moment(toDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && fromDateFilter && !toDateFilter && <TruncatedChip body={`Request Date: From: ${Moment(fromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !fromDateFilter && toDateFilter && <TruncatedChip body={`Request Date: To: ${Moment(toDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && requestFromDateAdvFilter && requestToDateAdvFilter && <TruncatedChip body={`Request Date: From: ${Moment(requestFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(requestToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && requestFromDateAdvFilter && !requestToDateAdvFilter && <TruncatedChip body={`Request Date: From: ${Moment(requestFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !requestFromDateAdvFilter && requestToDateAdvFilter && <TruncatedChip body={`Request Date: To: ${Moment(requestToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && sentFromDateFilter && sentToDateFilter && <TruncatedChip body={`Sent Date: From: ${Moment(sentFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(sentToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && sentFromDateFilter && !sentToDateFilter && <TruncatedChip body={`Sent Date: From: ${Moment(sentFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !sentFromDateFilter && sentToDateFilter && <TruncatedChip body={`Sent Date: To: ${Moment(sentToDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && sentFromDateAdvFilter && sentToDateAdvFilter && <TruncatedChip body={`Sent Date: From: ${Moment(sentFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(sentToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && sentFromDateAdvFilter && !sentToDateAdvFilter && <TruncatedChip body={`Sent Date: From: ${Moment(sentFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !sentFromDateAdvFilter && sentToDateAdvFilter && <TruncatedChip body={`Sent Date: To: ${Moment(sentToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                    </React.Fragment>
                </div>
                {quickFiltersClicked && (
                    (userActions?.includes("43") || userActions?.includes("20")) ? (
                        <div className="row">
                         {mode !== "singleFormFeed" ? 
                         <>
                            <div className="col-xl-3 mt-3">
                                <span>Feed ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={feedID} onChange={handleFeedIDFilter} />
                            </div> 
                            <div className="col-xl-3 mt-3">
                            <span>Feed Name:</span>
                            <br />
                            <Autocomplete
                                options={feedsList}
                                autoHighlight
                                className="search-dropdown w-100"
                                inputValue={feedNameAutoValue ?? ""}
                                getOptionLabel={(option) => option?.FeedName}
                                onChange={(e, value) => {
                                    setFeedName(value?.FeedName ?? "");
                                    onFeedIDFilter(value?.FeedID ?? "");
                                    setFeedID(value?.FeedID ?? "");
                                    onSelectedPage(1);
                                    onPageSize(30);
                                }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option?.FeedName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={e => setFeedNameAutoValue(e.target.value)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Carrier Name:</span>
                            <br />
                            <Autocomplete
                                options={carriersList}
                                autoHighlight
                                className="search-dropdown w-100"
                                inputValue={carrierNameAutoValue ?? ""}
                                getOptionLabel={(option) => option?.CarrierName}
                                onChange={(e, value) => {
                                    setCarrierName(value?.CarrierName ?? "");
                                    setCarrierID(value?.CarrierID ?? "");
                                    onCarrierIDFilter(value?.CarrierID ?? "");
                                    onSelectedPage(1);
                                    onPageSize(30);
                                }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option?.CarrierName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={e => setCarrierNameAutoValue(e.target.value)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        </>
                            :
                            <></>
                        }
                            
                            <div className="col-xl-3 mt-3">
                                <span>Feed Status:</span>
                                <br />
                                <FormControl label="Select status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedStatus}
                                        onChange={handleFeedStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsStatusesList?.map((c, ci) => (
                                            <MenuItem key={`feed-status-${ci}`} value={c?.FeedStatusID}>{c?.FeedStatusName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Phase:</span>
                                <br />
                                <FormControl label="Select phase" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedPhase}
                                        onChange={handleFeedPhaseChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsPhasesList?.map((c, ci) => (
                                            <MenuItem key={`feed-phase-${ci}`} value={c?.FeedPhaseID}>{c?.FeedPhaseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Execution ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={executionID} onChange={handleExecutionIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Transmission Status:</span>
                                <br />
                                <FormControl label="Select execution status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={executionStatus}
                                        onChange={handleExecutionStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {executionStatusesList?.map((c, ci) => (
                                            <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Processing Step:</span>
                                <br />
                                <FormControl label="Select processing step" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={processingStep}
                                        onChange={handleProcessingStepChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {processingStepsList?.map((c, ci) => (
                                            <MenuItem key={`processing-step-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Request Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedFromDate}
                                    onChange={(date) => {
                                        setSelectedFromDate(date);
                                        onFromDateFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedToDate}
                                    onChange={(date) => {
                                        setSelectedToDate(date);
                                        onToDateFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Sent Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedSentFromDate}
                                    onChange={(date) => {
                                        setSelectedSentFromDate(date);
                                        onSentFromDateFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedSentToDate}
                                    onChange={(date) => {
                                        setSelectedSentToDate(date);
                                        onSentToDateFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Feed Name:</span>
                                <br />
                                <Autocomplete
                                    options={feedsList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={feedNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.FeedName}
                                    onChange={(e, value) => {
                                        setFeedName(value?.FeedName ?? "");
                                        onFeedIDFilter(value?.FeedID ?? "");
                                        setFeedID(value?.FeedID ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.FeedName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setFeedNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Carrier Name:</span>
                                <br />
                                <Autocomplete
                                    options={carriersList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={carrierNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarrierName(value?.CarrierName ?? "");
                                        setCarrierID(value?.CarrierID ?? "");
                                        onCarrierIDFilter(value?.CarrierID ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarrierNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Status:</span>
                                <br />
                                <FormControl label="Select status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedStatus}
                                        onChange={handleFeedStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsStatusesList?.map((c, ci) => (
                                            <MenuItem key={`feed-status-${ci}`} value={c?.FeedStatusID}>{c?.FeedStatusName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Phase:</span>
                                <br />
                                <FormControl label="Select phase" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedPhase}
                                        onChange={handleFeedPhaseChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsPhasesList?.map((c, ci) => (
                                            <MenuItem key={`feed-phase-${ci}`} value={c?.FeedPhaseID}>{c?.FeedPhaseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Transmission Status:</span>
                                <br />
                                <FormControl label="Select execution status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={executionStatus}
                                        onChange={handleExecutionStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {executionStatusesList?.map((c, ci) => (
                                            <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Processing Step:</span>
                                <br />
                                <FormControl label="Select processing step" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={processingStep}
                                        onChange={handleProcessingStepChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {processingStepsList?.map((c, ci) => (
                                            <MenuItem key={`processing-step-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Request Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedFromDate}
                                    onChange={(date) => {
                                        setSelectedFromDate(date);
                                        onFromDateFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedToDate}
                                    onChange={(date) => {
                                        setSelectedToDate(date);
                                        onToDateFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Sent Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedSentFromDate}
                                    onChange={(date) => {
                                        setSelectedSentFromDate(date);
                                        onSentFromDateFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedSentToDate}
                                    onChange={(date) => {
                                        setSelectedSentToDate(date);
                                        onSentToDateFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                        </div>
                    ))
                }
                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>
            </div >

            <FormsTransmissionsFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onPageSizeAdv={onPageSizeAdv}
                onSelectedPageAdv={onSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onCloseClearReset={handleClearSearchReset}
                onFeedIDAdvFilter={onFeedIDAdvFilter}
                onSelectedFeedNameAdvFilter={onSelectedFeedNameAdvFilter}
                onSelectedCarrierIDAdvFilter={onSelectedCarrierIDAdvFilter}
                onSelectedFeedStatusAdvFilter={onSelectedFeedStatusAdvFilter}
                onSelectedFeedPhaseAdvFilter={onSelectedFeedPhaseAdvFilter}
                onExecutionIDAdvFilter={onExecutionIDAdvFilter}
                onSelectedExecutionStatusAdvFilter={onSelectedExecutionStatusAdvFilter}
                onSelectedProcessingStepAdvFilter={onSelectedProcessingStepAdvFilter}
                onRequestFromDateAdvFilter={onRequestFromDateAdvFilter}
                onRequestToDateAdvFilter={onRequestToDateAdvFilter}
                onSentFromDateAdvFilter={onSentFromDateAdvFilter}
                onSentToDateAdvFilter={onSentToDateAdvFilter}
                mode={mode}
            />
        </div >
    )
}

export default FormsTransmissionDashboardFilter;