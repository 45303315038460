import React from "react";
import { Paper, TextField, Button, Select, Chip } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import { useAuthentication } from "../../../Data/Authentication";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TransmissionsProductionFilterModalBody from "./TransmissionsProductionFilterModalBody";
import Moment from 'moment';
import TruncatedChip from "../../Layout/TruncatedChip";
import { changeTimeZoneToEST } from "../../Layout/DateConverted";
import refreshSVG from "../../../Icons/refresh.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";


const todayDate = changeTimeZoneToEST(new Date());
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const yearDate = new Date();
yearDate.setHours(0, 0, 0, 0)
yearDate.setFullYear(todayDate.getFullYear() - 1);

function TransmissionDashboardProductionFilter({
    onFeedIDFilter,
    onCarrierIDFilter,
    executionStatusFilter,
    onExecutionStatusFilter,
    onProcessingStepFilter,
    onFeedTypeFilter,
    onExecutionIDFilter,
    onFromDateFilter,
    fromDateFilter,
    onToDateFilter,
    toDateFilter,
    setRefreshClicked,
    selectedPage,
    onSelectedPage,
    pageSize,
    onPageSize,
    totalCount,
    setTotalCount,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    feedIDAdvFilter,
    onFeedIDAdvFilter,
    selectedFeedNameAdvFilter,
    onSelectedFeedNameAdvFilter,
    selectedCarrierIDAdvFilter,
    onSelectedCarrierIDAdvFilter,
    selectedFeedTypeAdvFilter,
    onSelectedFeedTypeAdvFilter,
    executionIDAdvFilter,
    onExecutionIDAdvFilter,
    selectedExecutionStatusAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    selectedProcessingStepAdvFilter,
    onSelectedProcessingStepAdvFilter,
    requestFromDateAdvFilter,
    onRequestFromDateAdvFilter,
    requestToDateAdvFilter,
    onRequestToDateAdvFilter,
    statusTabValue,
    feedPhaseFilter,
    onFeedPhaseFilter,
    selectedFeedPhaseAdvFilter,
    onSelectedFeedPhaseAdvFilter,
    onTransmissionStatusDashboardChange,
    onTransmissionPhaseDashboardChange,
    onSetTransmissionStatusDashboardChange,
    onSetTransmissionPhaseDashboardChange,
    onSetFilterCard,
    ...props
}) {


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [feedsList, setFeedsList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);
    const [processingStepsList, setProcessingStepsList] = React.useState([]);
    const [feedsTypesList, setFeedsTypesList] = React.useState([]);
    const [feedsPhasesList, setFeedsPhasesList] = React.useState([]);

    const [selectedFromDate, setSelectedFromDate] = React.useState(null);
    const [selectedToDate, setSelectedToDate] = React.useState(null);
    const [feedName, setFeedName] = React.useState("");
    const [feedNameAutoValue, setFeedNameAutoValue] = React.useState("");
    const [feedID, setFeedID] = React.useState("");
    const [carrierName, setCarrierName] = React.useState("");
    const [carrierNameAutoValue, setCarrierNameAutoValue] = React.useState("");
    const [feedPhase, setFeedPhase] = React.useState("");
    const [carrierID, setCarrierID] = React.useState("");
    const [executionStatus, setExecutionStatus] = React.useState("");
    const [processingStep, setProcessingStep] = React.useState("");
    const [feedType, setFeedType] = React.useState("");
    const [executionID, setExecutionID] = React.useState("");

    const [openDialog, setOpenDialog] = React.useState(false);



    React.useEffect(() => {
        if (onTransmissionPhaseDashboardChange) {
            setExecutionStatus('');
            onExecutionStatusFilter('');
            handleClearDashBordReset();
            onSetFilterCard(false);
            setFeedPhase(feedPhaseFilter);
        }
    }, [feedPhase, feedPhaseFilter, onTransmissionPhaseDashboardChange])

    React.useEffect(() => {
        if (onTransmissionStatusDashboardChange) {
            setFeedPhase('');
            onFeedPhaseFilter('');
            handleClearDashBordReset();
            onSetFilterCard(false);
            setExecutionStatus(executionStatusFilter);
        }
    }, [executionStatus, executionStatusFilter, onTransmissionStatusDashboardChange])

    const handleClearDashBordReset = () => {
        setFeedName("");
        setFeedID("");
        onFeedIDFilter("");
        setCarrierName("");
        setCarrierID("");
        onCarrierIDFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setFeedType("");
        onFeedTypeFilter("");
        // setSelectedFromDate(todayDate);
        // onFromDateFilter(todayDate);
        // setSelectedToDate(todayDate);
        // onToDateFilter(todayDate);
        onSelectedPage(1);
        onFeedIDAdvFilter("");
        onSelectedFeedNameAdvFilter([]);
        onSelectedCarrierIDAdvFilter([]);
        onSelectedFeedTypeAdvFilter([]);
        onExecutionIDAdvFilter("");
        onSelectedExecutionStatusAdvFilter([]);
        onSelectedProcessingStepAdvFilter([]);
        onSelectedFeedPhaseAdvFilter([]);
        // onRequestFromDateAdvFilter(todayDate);
        // onRequestToDateAdvFilter(todayDate);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
        onSetTransmissionPhaseDashboardChange(false);
        onSetTransmissionStatusDashboardChange(false);
    }



    const handleClickOpenDialog = () => {
        onFeedIDFilter("");
        setFeedID("");
        setFeedName("");
        onCarrierIDFilter("");
        setCarrierName("");
        onExecutionStatusFilter("");
        setExecutionStatus("");
        onProcessingStepFilter("");
        setProcessingStep("");
        onFeedTypeFilter("");
        setFeedType("");
        onFeedPhaseFilter("");
        setFeedPhase("");
        onExecutionIDFilter("");
        setExecutionID("");
        onFromDateFilter(todayDate);
        setSelectedFromDate(todayDate);
        onToDateFilter(todayDate);
        setSelectedToDate(todayDate);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
        setFeedNameAutoValue(feedName);
    }, [feedName])

    React.useEffect(() => {
        openLoader();
        APIs.getCarriersListByName({ CarrierName: carrierName ?? "" , StatusTypeId: [1,2]}).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
        setCarrierNameAutoValue(carrierName);
    }, [carrierName])

    React.useEffect(() => {
        APIs.getProductionExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getProcessingStepsList().then((r) => setProcessingStepsList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getFeedFileTypes().then((r) => (!(r?.data?.length) ? setFeedsTypesList([]) : setFeedsTypesList(r?.data)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedPhases().then((r) => (!(r?.data?.length) ? setFeedsPhasesList([]) : setFeedsPhasesList(r.data)));
    }, [])

    React.useEffect(() => {
        if (feedsList)
            closeLoader();
        else openLoader()
    }, [feedsList])

    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedFromDate(date);
            onFromDateFilter(date);
        }
        else {
            setSelectedToDate(date);
            onToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleExecutionStatusChange = (e) => {
        setExecutionStatus(e.target.value);
        onExecutionStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleProcessingStepChange = (e) => {
        setProcessingStep(e.target.value);
        onProcessingStepFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedTypeChange = (e) => {
        setFeedType(e.target.value);
        onFeedTypeFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleExecutionIDFilter = (e) => {
        setExecutionID(e.target.value);
        onExecutionIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedPhaseChange = (e) => {
        setFeedPhase(e.target.value);
        onFeedPhaseFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedIDFilter = (e) => {
        setFeedID(e.target.value);
        onFeedIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClearSearch = () => {
        setFeedName("");
        setFeedID("");
        onFeedIDFilter("");
        setCarrierName("");
        setCarrierID("");
        onCarrierIDFilter("");
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setFeedType("");
        onFeedTypeFilter("");
        setFeedPhase("");
        onFeedPhaseFilter("");
        setSelectedFromDate(monthDate);
        onFromDateFilter(monthDate);
        setSelectedToDate(todayDate);
        onToDateFilter(todayDate);
        onSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setFeedName("");
        setFeedID("");
        onFeedIDFilter("");
        setCarrierName("");
        setCarrierID("");
        onCarrierIDFilter("");
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setFeedType("");
        onFeedTypeFilter("");
        setFeedPhase("");
        onFeedPhaseFilter("");
        setSelectedFromDate(todayDate);
        onFromDateFilter(todayDate);
        setSelectedToDate(todayDate);
        onToDateFilter(todayDate);
        onSelectedPage(1);
        onFeedIDAdvFilter("");
        onSelectedFeedNameAdvFilter([]);
        onSelectedCarrierIDAdvFilter([]);
        onSelectedFeedTypeAdvFilter([]);
        onExecutionIDAdvFilter("");
        onSelectedExecutionStatusAdvFilter([]);
        onSelectedProcessingStepAdvFilter([]);
        onSelectedFeedPhaseAdvFilter([]);
        onRequestFromDateAdvFilter(todayDate);
        onRequestToDateAdvFilter(todayDate);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    return (
        <div>
            <div className="search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <React.Fragment>
                        {(userActions?.includes("43") || userActions?.includes("20")) && feedID && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed ID: ${feedID}`} />}
                        {(userActions?.includes("43") || userActions?.includes("20")) && feedIDAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed ID: ${feedIDAdvFilter}`} />}
                        {feedName && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed: ${feedName}`} />}
                        {selectedFeedNameAdvFilter && selectedFeedNameAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feeds: ${selectedFeedNameAdvFilter?.map(s => s.FeedName).join(" - ")}`} />}
                        {carrierName && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Carrier: ${carrierName}`} />}
                        {selectedCarrierIDAdvFilter && selectedCarrierIDAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Carriers: ${selectedCarrierIDAdvFilter?.map(s => s.CarrierName).join(" - ")}`} />}
                        {feedType && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed Type: ${feedsTypesList?.filter(s => s.FeedTypeID == feedType)[0].FeedTypeName}`} />}
                        {selectedFeedTypeAdvFilter && selectedFeedTypeAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed Types: ${selectedFeedTypeAdvFilter?.map(s => s.FeedTypeName).join(" - ")}`} />}
                        {feedPhase && <TruncatedChip body={`Feed Phase: ${feedsPhasesList?.filter(r => r.FeedPhaseID == feedPhase)[0].FeedPhaseName}`} />}
                        {selectedFeedPhaseAdvFilter && selectedFeedPhaseAdvFilter?.length > 0 && <TruncatedChip body={`Feed Phases: ${selectedFeedPhaseAdvFilter?.map(s => s.FeedPhaseName).join(" - ")}`} />}
                        {executionID && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Execution ID: ${executionID}`} />}
                        {executionIDAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Execution ID: ${executionIDAdvFilter}`} />}
                        {executionStatus && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Transmission Status: ${executionStatusesList?.filter(s => s.Id == executionStatus)[0].Name}`} />}
                        {selectedExecutionStatusAdvFilter && selectedExecutionStatusAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Transmission Statuses: ${selectedExecutionStatusAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {processingStep && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Processing Step: ${processingStepsList?.filter(s => s.Id == processingStep)[0].Name}`} />}
                        {selectedProcessingStepAdvFilter && selectedProcessingStepAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Processing Steps: ${selectedProcessingStepAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                    </React.Fragment>
                </div>
                {quickFiltersClicked && (
                    (userActions?.includes("43") || userActions?.includes("20")) ? (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Feed ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={feedID} onChange={handleFeedIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Name:</span>
                                <br />
                                <Autocomplete
                                    options={feedsList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={feedNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.FeedName}
                                    onChange={(e, value) => {
                                        setFeedName(value?.FeedName ?? "");
                                        onFeedIDFilter(value?.FeedID ?? "");
                                        setFeedID(value?.FeedID ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.FeedName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setFeedNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Carrier Name:</span>
                                <br />
                                <Autocomplete
                                    options={carriersList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={carrierNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarrierName(value?.CarrierName ?? "");
                                        setCarrierID(value?.CarrierID ?? "");
                                        onCarrierIDFilter(value?.CarrierID ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarrierNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Transmission Status:</span>
                                <br />
                                <FormControl label="Select execution status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={executionStatus}
                                        onChange={handleExecutionStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>

                                        {executionStatusesList?.map((c, ci) => (
                                            <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </div>
                            <div hidden className="col-xl-3 mt-3">
                                <span>Feed Phase:</span>
                                <br />
                                <FormControl label="Select phase" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedPhase}
                                        onChange={handleFeedPhaseChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsPhasesList?.map((c, ci) => (
                                            <MenuItem key={`feed-phase-${ci}`} value={c?.FeedPhaseID}>{c?.FeedPhaseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Feed Name:</span>
                                <br />
                                <Autocomplete
                                    options={feedsList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={feedNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.FeedName}
                                    onChange={(e, value) => {
                                        setFeedName(value?.FeedName ?? "");
                                        onFeedIDFilter(value?.FeedID ?? "");
                                        setFeedID(value?.FeedID ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.FeedName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setFeedNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Carrier Name:</span>
                                <br />
                                <Autocomplete
                                    options={carriersList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={carrierNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarrierName(value?.CarrierName ?? "");
                                        setCarrierID(value?.CarrierID ?? "");
                                        onCarrierIDFilter(value?.CarrierID ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarrierNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Transmission Status:</span>
                                <br />
                                <FormControl label="Select execution status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={executionStatus}
                                        onChange={handleExecutionStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>

                                        {executionStatusesList?.map((c, ci) => (
                                            <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </div>
                            <div hidden className="col-xl-3 mt-3">
                                <span>Feed Phase:</span>
                                <br />
                                <FormControl label="Select phase" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedPhase}
                                        onChange={handleFeedPhaseChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsPhasesList?.map((c, ci) => (
                                            <MenuItem key={`feed-phase-${ci}`} value={c?.FeedPhaseID}>{c?.FeedPhaseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    ))
                }

                <div className="col-xl-12 mt-4">
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2">
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>

            </div>

            <TransmissionsProductionFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onPageSizeAdv={onPageSizeAdv}
                onSelectedPageAdv={onSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onCloseClearReset={handleClearSearchReset}
                onFeedIDAdvFilter={onFeedIDAdvFilter}
                onSelectedFeedNameAdvFilter={onSelectedFeedNameAdvFilter}
                onSelectedCarrierIDAdvFilter={onSelectedCarrierIDAdvFilter}
                onSelectedFeedTypeAdvFilter={onSelectedFeedTypeAdvFilter}
                onSelectedFeedPhaseAdvFilter={onSelectedFeedPhaseAdvFilter}
                onExecutionIDAdvFilter={onExecutionIDAdvFilter}
                onSelectedExecutionStatusAdvFilter={onSelectedExecutionStatusAdvFilter}
                onSelectedProcessingStepAdvFilter={onSelectedProcessingStepAdvFilter}
                onRequestFromDateAdvFilter={onRequestFromDateAdvFilter}
                onRequestToDateAdvFilter={onRequestToDateAdvFilter}
            />
        </div>
    )
}

export default TransmissionDashboardProductionFilter;