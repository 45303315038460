import React from 'react'
import { useLoader } from "../../../Layout/Loader";
import { useHistory } from "react-router-dom";
import { Redirect, useParams } from "react-router-dom";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import TabsTitles from "../../../Layout/TabsTitles";
import { Paper, Switch, Button, FormControlLabel, Zoom, MenuItem, FormControl, Select } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import { useAuthentication } from "../../../../Data/Authentication";
import BackupIcon from '@material-ui/icons/Backup';
import Moment from 'moment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { FailureModal } from "../../../Modals/FailureModal";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { CustomTooltip } from "../../../Layout/Tooltip";
import { DropzoneArea } from 'material-ui-dropzone';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { parseISO } from 'date-fns';
import { AlertModal } from "../../../Modals/AlertModal";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import infoCircleSVG from "../../../../Icons/info-circle.svg";
import { Grid } from '@mui/material';
import GenericDatePicker from '../../../Layout/GenericDatePicker';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const AnalystConnectivityRequestAddOERequest = () => {
    const dialog = useModalDialog(0);
    const { authUser } = useAuthentication();

    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const { connectivityRequestIDURL } = useParams();
    const [planYearStartDay, setPlanYearStartDay] = React.useState(null);
    const [expectedDay, setExpectedDay] = React.useState(null);
    const [oEType, setOEType] = React.useState("");
    const [oERequestDescription, setOERequestDescription] = React.useState("");
    const [fileAttached, setFileAttached] = React.useState();
    const [fileAttachedBase64, setFileAttachedBase64] = React.useState();
    const fileInputRef = React.useRef();
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [updatedGSChecked, setUpdatedGSChecked] = React.useState(false);
    const [isolvedDataChangesChecked, setIsolvedDataChangesChecked] = React.useState(false);
    const [draftChecked, setDraftChecked] = React.useState(false);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [OEContact, setOEContact] = React.useState("");

    // validation var 
    const [planYearStartDayValidation, setPlanYearStartDayValidation] = React.useState("");
    const [expectedDayValidation, setExpectedDayValidation] = React.useState("");
    // const [oETypeValidation, setOETypeValidation] = React.useState("");
    // const [oERequestDescriptionValidation, setOERequestDescriptionValidation] = React.useState("");
    // const [fileAttachedValidation, setFileAttachedValidation] = React.useState("");
    const [connectivityReq, setConnectivityReq] = React.useState();

    React.useEffect(() => {
        setOEContact(authUser?.attributes?.sub);
    }, [authUser])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); }).finally(() => closeLoader());
    }, [])
    // validation func 
    // planYearStartDay validation
    React.useEffect(() => {
        if (!planYearStartDay || planYearStartDay === "Invalid date") setPlanYearStartDayValidation("Plan year start day is missing"); else setPlanYearStartDayValidation("");
    }, [planYearStartDay, planYearStartDayValidation]);
    //  expectedDay validation
    React.useEffect(() => {
        if (!expectedDay || expectedDay === "Invalid date") setExpectedDayValidation("Client's expected data ready date is missing");
        else if (expectedDay >= planYearStartDay) setExpectedDayValidation("Client's expected data ready date should be smaller than plan year start date");
        else setExpectedDayValidation("");
    }, [expectedDay, planYearStartDay, expectedDayValidation])
    // React.useEffect(() => {
    //     if (!oEType) setOETypeValidation("OE type is missing"); else setOETypeValidation("");
    // }, [oEType, oETypeValidation])
    // React.useEffect(() => {
    //     if (!oERequestDescription) setOERequestDescriptionValidation("oE Type Description is missing"); else setOERequestDescriptionValidation("");
    // }, [oERequestDescription, oERequestDescriptionValidation])
    // React.useEffect(() => {
    //   if (!fileAttached && updatedGSChecked) setFileAttachedValidation("Group structure is missing"); else setFileAttachedValidation("");
    //  }, [fileAttached, fileAttachedValidation, updatedGSChecked])

    const submitOERequest = (e) => {
        e.preventDefault();
        if (planYearStartDayValidation || expectedDayValidation) return;
        openLoader();
        let oERequestBody = {};
        oERequestBody = {
            ConnectivityRequestID: connectivityRequestIDURL,
            PlanYearStartDate: planYearStartDay,
            ClientDataExpectedDate: expectedDay,
            OEType: oEType,
            ISolvedDataChanges: isolvedDataChangesChecked ? 1 : 0,
            UpdatedGroupStructure: updatedGSChecked ? 1 : 0,
            IsDraftOERequest: draftChecked ? 1 : 0,
            OEChanges: oERequestDescription,
            IsolvedContact: OEContact,
            Attachment: {
                FileName: fileAttached?.name?.substring(0, fileAttached?.name.lastIndexOf('.')) ?? "",
                Extension: fileAttached?.name?.substring(fileAttached?.name.lastIndexOf('.') + 1, fileAttached?.name.length) ?? "",
                Value: fileAttachedBase64 ?? ""
            }
        }

        let checkDuplicatedBody = {
            ConnectivityRequestID: [`${connectivityReq?.ConnectivityRequestID}`],
            PlanYearStartDate: [planYearStartDay],
            ConnectivityRequestFeedID: [`${connectivityReq?.ConnectivityRequestFeedID}`],
        }

        // check if OE Request is Duplicated for same start Plan Year
        APIs.checkDuplicateOERequest(checkDuplicatedBody).then((r) => {
            closeLoader();
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            } else {
                if (r?.Duplicate === true) {
                    AlertModal(dialog, () => {
                        openLoader();
                        // Api for add OE Request 
                        APIs.createOERequest(oERequestBody).then((r) => {
                            if (r?.success === "0") {
                                FailureModal(dialog, {
                                    title: 'Failure',
                                    body: r?.msg,
                                });
                            } else {
                                routeHistory.push(`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`)
                            }
                        }).catch((s) => FailureModal(dialog, {
                            title: 'Failure',
                            body: 'Error processing request',
                        })).finally((t) => closeLoader());
                    },
                        {
                            title: 'Alert! This OE request is duplicated!',
                            info: <div className="text-left fs-12">
                                Duplicated OE Requests:
                                {r?.DuplicateList?.map(s =>
                                    s?.OERequestIDs?.map(d =>
                                        <a className='warningLink' key={`oeReques-${s?.OERequestIDs}-${d}`} href={`/OERequests/ViewConnectivityRequest/${s?.ConnectivityRequestID}/ViewOERequest/${d}`} target="_blank" rel="noopener noreferrer">- {d}</a>
                                    )
                                )}
                            </div>,
                            question: `Are you sure you want to proceed?`,
                        });
                } else {

                    // Api for add OE Request 
                    APIs.createOERequest(oERequestBody).then((r) => {
                        if (r?.success === "0") {
                            FailureModal(dialog, {
                                title: 'Failure',
                                body: r?.msg,
                            });
                        } else {
                            routeHistory.push(`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`)
                        }
                    }).catch((s) => FailureModal(dialog, {
                        title: 'Failure',
                        body: 'Error processing request',
                    })).finally((t) => closeLoader());
                }
            }
        }).catch((s) => FailureModal(dialog, {
            title: 'Failure',
            body: 'Error processing request',
        })).finally((t) => closeLoader());


    }
    const handleFileAttachedChange = (e) => {
        setFileAttached(e.target.files[0]);
        encodeFileAttachedBase64(e.target.files[0]);
    }
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleFileAttachedChangeDrag = (files) => {
        if (files.length === 0) return;
        if (files[0]?.size === 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'File is empty!'
            });
        }
        else {
            encodeFileAttachedBase64(files[0]);
            setFileAttached(files[0]);
            setOpenSnackBar(true);
        }
    }
    const encodeFileAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFileAttachedBase64(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };
    const handlePlanYearStartDateChange = (date) => {
        setPlanYearStartDay(Moment(date).format('YYYY-MM-DD'));
    };

    const handleExpectedDateChange = (date) => {
        setExpectedDay(Moment(date).format('YYYY-MM-DD'));
    };

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    return (

        <div id="create-action-item">
            {connectivityReq && <BreadcrumbSteps
                breadcrumbTitle={`Connectivity Request "${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName}"`}
                isCurrent={false}
                breadcrumbTitleCurrent="Add OE Request"
                breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
            />}
            <TabsTitles tabTitle={["Add OE Request"]} />
            <Paper elevation={0} className="p-5">

                <form onSubmit={submitOERequest}>


                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">OE Request Type</h6>
                            <div className="textfield-div">
                                {connectivityReq?.ConnectivityRequestStatusName !== "Production" ? "One Time" : "Regular"}
                            </div>
                        </Grid>
                        {/* Plan Year Start Date */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Plan Year Start Date
                                    <span className="text-danger mb-2 ml-2">*</span></h6>
                                <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={"The client’s renewal date with the carrier"}>
                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                    <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                    </svg>
                                </CustomTooltip>
                            </div>
                            <div className="mt-md-n2">
                                <GenericDatePicker
                                        classNames="w-100"
                                        id="frequency-date"
                                        value={planYearStartDay ? parseISO(planYearStartDay) : null}
                                        onChange={(date) => {
                                            if (!date) {
                                                setPlanYearStartDay(null);
                                            } else {
                                                const formattedDate = Moment(date).format("YYYY-MM-DD");
                                                setPlanYearStartDay(formattedDate);
                                            }
                                        }}
                                        placeholder="Plan Year Start Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                            </div>
                            <div className="text-danger mt-3" hidden={!requestSubmitted}>{planYearStartDayValidation}</div>
                        </Grid>
                        {/* Expected Date  */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Client's Expected Data Ready Date</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                                <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={"Client’s expected date of when OE updates/entries will be finalized to send the OE file"}>
                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                    <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                    </svg>
                                </CustomTooltip>
                            </div>
                            <div className="mt-md-n2">
                                <GenericDatePicker
                                        classNames="w-100"
                                        id="date"
                                        value={expectedDay ? parseISO(expectedDay) : null}
                                        onChange={(date) => {
                                            if (!date) {
                                                setExpectedDay(null);
                                            } else {
                                                const formattedDate = Moment(date).format("YYYY-MM-DD");
                                                setExpectedDay(formattedDate);
                                            }
                                        }}
                                        placeholder="Client's Expected Data Ready Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                            </div>

                            <div className="mt-3 text-danger" hidden={!requestSubmitted}>{expectedDayValidation}</div>
                        </Grid>
                        {/* isolved data changes */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">isolved (Data) Changes</h6>
                                <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please check it if there are changes in the data like plan names or coverage level codes, it does not necessary require an updated structure</div>}>
                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                    <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                    </svg>
                                </CustomTooltip>
                            </div>
                            <FormControlLabel
                                className="dependents-switch"
                                control={
                                    <Switch
                                        checked={isolvedDataChangesChecked}
                                        onChange={() => setIsolvedDataChangesChecked(!isolvedDataChangesChecked)}
                                    />
                                }
                                label=""
                            />
                        </Grid>
                        {/* updated GS */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Updated Group Structure</h6>
                                <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={"Please check it if there is a new group structure for the current open enrollment"}>
                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                    <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                    </svg>
                                </CustomTooltip>
                            </div>
                            <FormControlLabel
                                className="dependents-switch"
                                control={
                                    <Switch
                                        checked={updatedGSChecked}
                                        onChange={() => setUpdatedGSChecked(!updatedGSChecked)}
                                    />
                                }
                                label=""
                            />
                        </Grid>

                        {/* attach file  */}
                        {updatedGSChecked && (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Group Structure</h6>

                                    {fileAttached ? (
                                        <div className="px-0">
                                            <input ref={fileInputRef} hidden type="file" className="text-input w-100" style={{ paddingRight: "50px" }} value={fileAttached ? fileAttached?.File?.name : ""} />

                                            <div className="textfield-div">
                                                <span>{fileAttached?.name}</span>
                                                <span>
                                                    <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} style={{ cursor: "pointer" }}>
                                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                    </svg>
                                                    {/* <DeleteForeverIcon className="ml-3 color grey2" style={{ cursor: "pointer" }} onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} /> */}
                                                </span>
                                                <span className="ml-auto color blue-main-color"><BackupIcon /></span>
                                            </div>

                                            <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                                                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                                    File {fileAttached?.name} successfully added.
                                                </Alert>
                                            </Snackbar>
                                        </div>
                                    ) : (
                                        <div className="mt-3 pr-0 pl-0">
                                            <DropzoneArea
                                                maxFileSize={20 * 1024 * 1024}
                                                // add max size 20mb               
                                                dropzoneParagraphClass={"MuiDropzoneArea-text-updated-gray"}
                                                filesLimit={1}
                                                dropzoneClass={"containerClassDrag"}
                                                onChange={(files) => handleFileAttachedChangeDrag(files)}
                                                showPreviewsInDropzone={false}
                                                showAlerts={false}
                                                dropzoneText={
                                                    <div className="dz-message fs-14" data-dz-message style={{ display: "grid" }}>
                                                        <b>
                                                            Click here to add Group Structure
                                                        </b>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    )
                                    }
                                </Grid>
                                {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                                <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{fileAttachedValidation}</div> */}
                            </>
                        )}

                        {/* Description */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Expected Changes (if any)</h6>
                                <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={"Please list benefit changes that should be considered for the current open enrollment"}>
                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                    <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                    </svg>
                                </CustomTooltip>
                            </div>
                            <textarea type="text" className="action-item-textarea w-100" value={oERequestDescription} onChange={(e) => { setOERequestDescription(e.target.value) }} rows={4} ></textarea>
                            {/* oERequestDescriptionValidation >> if Error */}
                        </Grid>

                        {/* isolved Contact */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">isolved Contact</h6>
                                <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={"Assigned OE Contact"}>
                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                    <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                    </svg>
                                </CustomTooltip>
                            </div>
                            <FormControl label="Select OE contact" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={OEContact ?? ""}
                                    onChange={e => setOEContact(e.target.value)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="d-flex flex-column mt-3">
                                <div className="d-flex">
                                    <h6 className="form-title"> Draft</h6>
                                    <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>The new plan year data will pause until the Plan Year Start Date is reached, and the OE process will not start until the Draft mode is off</div>}>
                                        {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                        <svg width="15" height="15" viewBox="0 0 25 24" className='ml-1'>
                                            <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                        </svg>
                                    </CustomTooltip>
                                </div>
                                <FormControlLabel
                                    className="dependents-switch"
                                    control={
                                        <Switch
                                            checked={draftChecked}
                                            onChange={() => setDraftChecked(!draftChecked)}
                                        />
                                    }
                                    label=""
                                />
                            </div>
                        </Grid>
                        {/* Draft OE */}
                        <Grid item xs={12} sm={12} md={6}>

                        </Grid>
                        <div className="w-100 text-right">
                            <Button type="submit" variant="contained" className="blue-btn" onClick={() => { setRequestSubmitted(true); }}>Submit OE Request</Button>
                        </div>

                    </Grid>

                    <div className="row m-5 mt-0 d-none">
                        {/* OE Type  */}

                        {/* <div className="col-xl-2 mt-3 alignvh-center">
                            <h6 className="form-title">OE Type</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>

                        <div className="col-xl-10 mt-3 pr-0 pl-0 ">
                            <FormControl label="OE Type" focused={false} variant="outlined" className="w-100">

                                <Select
                                    className="text-dropdown"

                                    value={oEType}

                                    onChange={e => setOEType(e.target.value)}
                                >
                                    <MenuItem value="Passive">Passive</MenuItem>
                                    <MenuItem value="Active">Active</MenuItem>

                                </Select>
                            </FormControl>
                        </div> */}
                        {/* error start day  */}
                        {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{oETypeValidation}</div> */}
                    </div>


                </form>

            </Paper>
        </div >
    )
}

export default AnalystConnectivityRequestAddOERequest
