import React from "react";
import { Divider, Paper, Select, TextField, Switch } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import EmptyActivityLogsSVG from "../../../../Images/emptyActivityLogs.svg"
import Button from "@material-ui/core/Button";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import Moment from 'moment';
import AnalystFeedTransmissionProtocol from "./AnalystFeedTransmissionProtocol";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import AnalystCreateTransmissionProtocol from "../CreateFeed/AnalystCreateTransmissionProtocol";
import MultipleTabsTitles from "../../../Layout/MultipleTabsTitles";
import AnalystSingleFeedTransmission from "./AnalystSingleFeedTransmission";
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import FeedConnectivitiesList from "./FeedConnectivitiesList";
import SetLastSentFileModal from "./SetLastSentFileModal";
import { useAuthentication } from "../../../../Data/Authentication";
import AnalystFeedForms from "./AnalystFeedForms";
import AnalystFeedFormDictionary from "./AnalystFeedFormDictionary";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AnalystFeedFormAdd from "./AnalystFeedFormAdd";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Set401kProcessFeedDate from "./Set401kProcessFeedDate";
import SchedulerObject from "../SharedComponents/SchedulerObject";
import AnalystCreateSchedulers from "../SharedComponents/AnalystCreateShedulers";
import AnalystViewScheduler from "../SharedComponents/AnalystViewScheduler";
import SetLastSentFileModalForms from "./SetLastSentFileModalForms";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import editRequestIcon from "../../../../Icons/editIcon.svg";
import plusIconSVG from "../../../../Icons/plusIcon.svg";
import addSchedulerIcon from "../../../../Icons/addScheduler.svg";
import minusIconSVG from "../../../../Icons/minus.svg";
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AnalystSingleFeedFormsTransmission from "./AnalystSingleFeedFormsTransmission";

let currentTime = new Date().getTime();
currentTime = Moment(currentTime).format('YYYY-MM-DDTHH:mm:ss');

let currentDate = new Date();
currentDate = Moment(currentDate).format('YYYY-MM-DD');

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function AnalystFeedConfig() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const routeHistory = useHistory();
    const { feedIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const [onEdit, setOnEdit] = React.useState(false);
    const [value, setValue] = React.useState(0);

    //Lookup Lists
    const [feedsStatus, setFeedsStatus] = React.useState([]);
    const [feedsPhase, setFeedsPhase] = React.useState([]);
    const [structureTypes, setStructureTypes] = React.useState([]);
    const [feedsTransmissionTypes, setFeedsTransmissionTypes] = React.useState([]);
    const [carrierMaps, setCarrierMaps] = React.useState([]);
    const [feedsProtocolTypes, setFeedsProtocolTypes] = React.useState([]);
    const [feedSSHKeys, setFeedSSHKeys] = React.useState([])
    const [componentsList, setComponentsList] = React.useState([]);
    const [componentsListIDs, setComponentsListIDs] = React.useState([]);
    const [componentsDFList, setComponentsDFList] = React.useState([]);
    const [componentsDFListIDs, setComponentsDFListIDs] = React.useState([]);
    const [carrierKeys, setCarrierKeys] = React.useState([]);


    //View - Edit Feed Info
    const [feed, setFeed] = React.useState();
    const [fileType, setFileType] = React.useState("");
    const [status, setStatus] = React.useState();
    const [phase, setPhase] = React.useState();
    const [mapID, setMapID] = React.useState();
    const [dataType, setDataType] = React.useState("");
    const [feedStructureType, setFeedStructureType] = React.useState("");
    const [feedEncryptionKey, setFeedEncryptionKey] = React.useState("");
    const [testOutputFileName, setTestOutputFileName] = React.useState();
    const [prodOutputFileName, setProdOutputFileName] = React.useState();
    const [feedDuplicateCode, setFeedDuplicateCode] = React.useState("");
    const [groupNumber, setGroupNumber] = React.useState("")
    const [selectedComponent, setSelectedComponent] = React.useState([]);
    const [checkedComponent, setCheckedComponent] = React.useState(false);
    const [selectedDFComponent, setSelectedDFComponent] = React.useState([]);
    const [checkedDFComponent, setCheckedDFComponent] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [clientCopyContact, setClientCopyContact] = React.useState("");
    const [feedConnectivities, setFeedConnectivities] = React.useState([]);

    const [outputTestFileNameMsg, setOutputTestFileNameMsg] = React.useState("");
    const [outputProdFileNameMsg, setOutputProdFileNameMsg] = React.useState("");

    const [toEmail, setToEmail] = React.useState("");
    const [ccEmail, setCcEmail] = React.useState("");
    const [bccEmail, setBccEmail] = React.useState("");
    const [emailSubject, setEmailSubject] = React.useState("")
    const [emailBody, setEmailBody] = React.useState("")

    // Transmission email
    const [toEmailTransmission, setToEmailTransmission] = React.useState("");
    const [ccEmailTransmission, setCcEmailTransmission] = React.useState("");
    const [bccEmailTransmission, setBccEmailTransmission] = React.useState("");
    const [emailSubjectTransmission, setEmailSubjectTransmission] = React.useState("");
    const [emailBodyTransmission, setEmailBodyTransmission] = React.useState("");
    const [carrierEmailSubjectValidationForm, setCarrierEmailSubjectValidationForm] = React.useState("");
    const [carrierEmailBodyValidationForm, setCarrierEmailBodyValidationForm] = React.useState("");
    const [carrierEmailToEmailValidationForm, setCarrierEmailToEmailValidationForm] = React.useState("");
    const [selectedForms, setSelectedForms] = React.useState([]);
    const [DFsList, setDFsList] = React.useState([]);

    //Forms Workflow
    const [DFWorkflowTypeForm, setDFWorkflowTypeForm] = React.useState("");
    // const [HRSignatureForm, setHRSignatureForm] = React.useState("");
    const [HRFirstNameForm, setHRFirstNameForm] = React.useState("");
    const [HRLastNameForm, setHRLastNameForm] = React.useState("");
    // const [employeeSignatureForm, setEmployeeSignatureForm] = React.useState("");
    const [employeeSignatureNeeded, setemployeeSignatureNeeded] = React.useState(false)
    const [hrSignatureNeeded, setHrSignatureNeeded] = React.useState(false)


    //Forms Dictionary
    const [dictionaryList, setDictionaryList] = React.useState([]);

    const [planYearStartDate, setPlanYearStartDate] = React.useState(null);
    const [enablePlanYearStartDate, setEnablePlanYearStartDate] = React.useState(false);

    //View Transmission - Scheduler Info
    const [transmissionProtocolObject, setTransmissionProtocolObject] = React.useState(null);
    const [transmissionEmailObject, setTransmissionEmailObject] = React.useState(null);
    const [schedulerObject, setSchedulerObject] = React.useState();
    const [selectedComponentsView, setSelectedComponentsView] = React.useState([]);

    //Create - Edit Transmission
    const [addTransmissionProtocolClicked, setAddTransmissionProtocolClicked] = React.useState(false);
    const [feedTransmissionType, setFeedTransmissionType] = React.useState();
    const [feedProtocolType, setFeedProtocolType] = React.useState();
    const [feedSSHKey, setFeedSSHKey] = React.useState()
    const [feedSFTPSite, setFeedSFTPSite] = React.useState();
    const [feedSFTPPort, setFeedSFTPPort] = React.useState();
    const [feedSFTPUsername, setFeedSFTPUsername] = React.useState();
    const [feedSFTPPassword, setFeedSFTPPassword] = React.useState();
    const [feedSFTPDirectory, setFeedSFTPDirectory] = React.useState();

    //Create - Edit Scheduler
    const [schedulersValidationForm, setSchedulersValidationForm] = React.useState("");
    const [feedSchedulers, setFeedSchedulers] = React.useState([new SchedulerObject({})]);
    const [addSchedulerClicked, setAddSchedulerClicked] = React.useState(false);


    // OE FILE 
    const [feedOEFileName, setFeedOEFileName] = React.useState("");
    const [outputOEFileNameMsg, setOutputOEFileNameMsg] = React.useState("");

    //Validations
    const [HrSignatureRequiredValidation, sethrSignatureRequiredValidation] = React.useState("");

    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [transmissionTypeValidationForm, setTransmissionTypeValidationForm] = React.useState("");
    const [sftpProtocolTypeValidationForm, setSFTPProtocolTypeValidationForm] = React.useState("");
    const [sftpSSHKeyValidationForm, setSFTPSSHKeyValidationForm] = React.useState("")
    const [sftpSiteValidationForm, setSFTPSiteValidationForm] = React.useState("");
    const [sftpPortValidationForm, setSFTPPortValidationForm] = React.useState("");
    const [sftpUsernameValidationForm, setSFTPUsernameValidationForm] = React.useState("");
    const [sftpPasswordValidationForm, setSFTPPasswordValidationForm] = React.useState("");
    const [sftpDirectoryValidationForm, setSFTPDirectoryValidationForm] = React.useState("");

    const [selectFormMainTypesValidationForm, setSelectFormMainTypesValidationForm] = React.useState("");
    const [selectFormSubTypesValidationForm, setSelectFormSubTypesValidationForm] = React.useState("");
    const [reasonValidationForm, setReasonValidationForm] = React.useState("");
    const [DFTransmitterEmailValidationForm, setDFTransmitterEmailValidationForm] = React.useState("");
    const [productionCarrierEmailSubjectValidationForm, setProductionCarrierEmailSubjectValidationForm] = React.useState("")
    const [productionCarrierEmailBodyValidationForm, setProductionCarrierEmailBodyValidationForm] = React.useState("")

    const [matchingAllPlansValidation, setMatchingAllPlansValidation] = React.useState(false);
    const [disableSheduler, setDisableSheduler] = React.useState(false);

    const [timeoutSeconds, settimeoutSeconds] = React.useState(-1);
    const [testConnectionClicked, setTestConnectionClicked] = React.useState(false);

    const [tabValue, setTabValue] = React.useState(0);
    const [tabDFElementValue, setTabDFElementValue] = React.useState(0);
    const feedTabs = ["Feed Configuration", "Transmission History"];

    const [feedsDfEmployeesElements, setFeedsDfEmployeesElements] = React.useState([]);
    const [feedsDfDependentsElements, setFeedsDfDependentsElements] = React.useState([]);
    const [feedsDfEmployeeBenefitsElements, setFeedsDfEmployeeBenefitsElements] = React.useState([]);
    const [feedsDfDependentBenefitsElements, setFeedsDfDependentBenefitsElements] = React.useState([]);
    const [feedsDfWaivedCoveragesElements, setFeedsDfWaivedCoveragesElements] = React.useState([]);
    const [feedsDfBeneficiaryElements, setFeedsDfBeneficiaryElements] = React.useState([]);

    const [selectedEmployeesDFElements, setSelectedEmployeesDFElements] = React.useState([]);
    const [selectedDependentsDFElements, setSelectedDependentsDFElements] = React.useState([]);
    const [selectedEmployeeBenefitsDFElements, setSelectedEmployeeBenefitsDFElements] = React.useState([]);
    const [selectedDependentBenefitsDFElements, setSelectedDependentBenefitsDFElements] = React.useState([]);
    const [selectedWaivedCoveragesDFElements, setSelectedWaivedCoveragesDFElements] = React.useState([]);
    const [selectedBeneficiaryDFElements, setSelectedBeneficiaryDFElements] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [businessRuleErrorList, setBusinessRuleErrorList] = React.useState([]);
    const [checkedBusinessRuleError, setCheckedBusinessRuleError] = React.useState(false);
    const [selectedBusinessRuleError, setSelectedBusinessRuleError] = React.useState([]);
    const [businessRuleErrorIDs, setBusinessRuleErrorIDs] = React.useState([]);

    const [emailSubjectInit, setEmailSubjectInit] = React.useState("");

    React.useEffect(() => {
        setEmailSubjectInit(
            feedConnectivities && feedConnectivities.length === 1
                ?
                (feedConnectivities && feedConnectivities[0].CarrierName === "Third Party"
                    ?
                    `${feedConnectivities[0]?.PeoName ? feedConnectivities[0]?.PeoName : feedConnectivities[0]?.GroupName}-${feedConnectivities[0]?.CarrierName} (${feedConnectivities[0]?.ThirdPartyName})`
                    :
                    `${feedConnectivities[0]?.PeoName ? feedConnectivities[0]?.PeoName : feedConnectivities[0]?.GroupName}-${feedConnectivities[0]?.CarrierName}`)
                :
                feedConnectivities && feedConnectivities.length > 1
                    ?
                    (feedConnectivities && feedConnectivities[0].CarrierName === "Third Party"
                        ?
                        `${feedConnectivities[0]?.CarrierName} (${feedConnectivities[0]?.ThirdPartyName})`
                        :
                        `${feedConnectivities[0]?.CarrierName}`)
                    :
                    ``
        )
    }, [feedConnectivities, toEmail, ccEmail, bccEmail])

    React.useEffect(() => {

        if (feedConnectivities?.filter(f => f?.ConnectivityRequestStatusName !== "Cancelled").length > 0) {
            setDisableSheduler(false);
        } else {
            setDisableSheduler(true);
        }


    }, [feedConnectivities,])


    const emailBodyInit = "Hi,\n\nKindly note that the scheduled production file for the referenced feed is now submitted. For any weekly feedback or data discrepancy reports, please reach out directly to your client contact.\nFor technical/file transmission issues, please reach out to editeam@isolvedhcm.com \n\nThanks!\nisolved Integration Team";
    const FormsFeedEmailBodyInit = `Hi,\n\nKindly note that the attached Form for the referenced Carrier has been transmitted. Please process accordingly. For any feedback or data discrepancy reports, please reach out directly to the client using the contact information from this email.\n\nWe recommend reviewing your bill or carrier portal to ensure accurate and timely processing by the Carrier.\n\nThanks,\nisolved Integration Team`


    // for transsimion
    React.useEffect(() => {
        if ((toEmailTransmission !== "" || ccEmailTransmission !== "" || bccEmailTransmission !== "") && !emailSubjectTransmission?.replaceAll(" ", ""))
            setCarrierEmailSubjectValidationForm("Please add a subject to this email notification"); else setCarrierEmailSubjectValidationForm("");
    }, [toEmailTransmission, ccEmailTransmission, bccEmailTransmission, emailSubjectTransmission, carrierEmailSubjectValidationForm])

    React.useEffect(() => {
        if ((toEmailTransmission !== "" || ccEmailTransmission !== "" || bccEmailTransmission !== "") && !emailBodyTransmission?.replaceAll(" ", "").replaceAll("\n", ""))
            setCarrierEmailBodyValidationForm("Please add a body to this email notification"); else setCarrierEmailBodyValidationForm("");
    }, [toEmailTransmission, ccEmailTransmission, bccEmailTransmission, emailBodyTransmission, carrierEmailBodyValidationForm])

    React.useEffect(() => {
        if (feedTransmissionType && feedTransmissionType == 1 && (toEmailTransmission === "" || toEmailTransmission === null))
            setCarrierEmailToEmailValidationForm("Please add a valid email address"); else setCarrierEmailToEmailValidationForm("");
    }, [toEmailTransmission, carrierEmailToEmailValidationForm, feedTransmissionType,])


    const handleTabChange = (e, newValue) => {
        if (!feed || feed === undefined) return;
        else {
            setTabValue(newValue);
            if (newValue === 1)
                if (feed?.FeedMapTypeID === 2) routeHistory.push(`/FormsTransmissions/ViewFeed/${feed?.FeedID}/Dashboard`);
                else routeHistory.push(`/EDITransmissions/ViewFeed/${feed?.FeedID}/Dashboard`);
            else routeHistory.push(`/Feeds/ViewFeed/${feed?.FeedID}`);
        }
    };

    const handleDFElementTabChange = (e, newValue) => {
        if (!feed || feed === undefined) return;
        else {
            setTabDFElementValue(newValue);
        }
    };


    const handleEmployeesDFElementClick = (value) => (event) => {
        event.stopPropagation();
        setSelectedEmployeesDFElements((prevSelected) => {

            // Find the index of the clicked element in the previous state array
            const index = prevSelected.findIndex(x => x.Id === value.Id);

            // Create a new array based on whether the element is already selected
            if (index >= 0) {
                // If the element is already selected, remove it
                return prevSelected.filter(x => x.Id !== value.Id);
            } else {
                // If the element is not selected, add it
                return [...prevSelected, value];
            }
        });
    }

    const handleDependentsDFElementClick = (value) => (event) => {
        event.stopPropagation();
        setSelectedDependentsDFElements((prevSelected) => {
            // Find the index of the clicked element in the previous state array
            const index = prevSelected.findIndex(x => x.Id === value.Id);

            // Create a new array based on whether the element is already selected
            if (index >= 0) {
                // If the element is already selected, remove it
                return prevSelected.filter(x => x.Id !== value.Id);
            } else {
                // If the element is not selected, add it
                return [...prevSelected, value];
            }
        });
    }

    const handleEmployeeBenefitsDFElementClick = (value) => (event) => {
        setSelectedEmployeeBenefitsDFElements((prevSelected) => {
            // Find the index of the clicked element in the previous state array
            const index = prevSelected.findIndex(x => x.Id === value.Id);

            // Create a new array based on whether the element is already selected
            if (index >= 0) {
                // If the element is already selected, remove it
                return prevSelected.filter(x => x.Id !== value.Id);
            } else {
                // If the element is not selected, add it
                return [...prevSelected, value];
            }
        });
    }

    const handleDependentBenefitsDFElementClick = (value) => (event) => {
        setSelectedDependentBenefitsDFElements((prevSelected) => {
            // Find the index of the clicked element in the previous state array
            const index = prevSelected.findIndex(x => x.Id === value.Id);

            // Create a new array based on whether the element is already selected
            if (index >= 0) {
                // If the element is already selected, remove it
                return prevSelected.filter(x => x.Id !== value.Id);
            } else {
                // If the element is not selected, add it
                return [...prevSelected, value];
            }
        });
    }

    const handleWaivedCoveragesDFElementClick = (value) => (event) => {
        setSelectedWaivedCoveragesDFElements((prevSelected) => {
            // Find the index of the clicked element in the previous state array
            const index = prevSelected.findIndex(x => x.Id === value.Id);

            // Create a new array based on whether the element is already selected
            if (index >= 0) {
                // If the element is already selected, remove it
                return prevSelected.filter(x => x.Id !== value.Id);
            } else {
                // If the element is not selected, add it
                return [...prevSelected, value];
            }
        });
    }

    const handleBeneficiaryDFElementClick = (value) => (event) => {
        setSelectedBeneficiaryDFElements((prevSelected) => {
            // Find the index of the clicked element in the previous state array
            const index = prevSelected.findIndex(x => x.Id === value.Id);

            // Create a new array based on whether the element is already selected
            if (index >= 0) {
                // If the element is already selected, remove it
                return prevSelected.filter(x => x.Id !== value.Id);
            } else {
                // If the element is not selected, add it
                return [...prevSelected, value];
            }
        });
    }

    React.useEffect(() => {
        openLoader();
        APIs.getFeed(feedIDURL).then((r) => {
            setFeed(r?.data);
            setSelectedComponentsView(r?.data?.EngineComponents);
            setSelectedEmployeesDFElements(r?.data?.DfElements?.AllElements?.filter(x => x.TableID === 1));
            setSelectedDependentsDFElements(r?.data?.DfElements?.AllElements?.filter(x => x.TableID === 2));
            setSelectedEmployeeBenefitsDFElements(r?.data?.DfElements?.AllElements?.filter(x => x.TableID === 3));
            setSelectedDependentBenefitsDFElements(r?.data?.DfElements?.AllElements?.filter(x => x.TableID === 4));
            setSelectedWaivedCoveragesDFElements(r?.data?.DfElements?.AllElements?.filter(x => x.TableID === 5));
            setSelectedBeneficiaryDFElements(r?.data?.DfElements?.AllElements?.filter(x => x.TableID === 6));
            setBusinessRuleErrorIDs(r?.data?.BusinessRules?.map((c) => c.Id));
            setSchedulerObject(r?.data?.ScheduleJobObject);
            setTransmissionProtocolObject(r?.data?.TransmissionSftpObject?.length !== 0 ? r?.data?.TransmissionSftpObject : null);
            setTransmissionEmailObject(r?.data?.TransmissionEmailObject?.length !== 0 ? r?.data?.TransmissionEmailObject : null);
            closeLoader();
        });
    }, [])

    React.useEffect(() => {
        if (feed === undefined) openLoader(); else closeLoader();
    }, [feed])

    React.useEffect(() => {
        APIs.getFeedsConnectivities(feedIDURL).then((r) => (
            !(r?.data?.length) ? setFeedConnectivities([]) : setFeedConnectivities(r?.data)
        ))
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getStructureTypes().then((r) => !(r?.data?.length) ? setStructureTypes([]) : setStructureTypes(r?.data?.map((c) => (c))));
    }, []);

    React.useEffect(() => {
        openLoader()
        APIs.getSSHKeys().then(r => (setFeedSSHKeys(r?.data), closeLoader()))
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedStatuses().then((r) => (
            (!(r?.length) ? setFeedsStatus([]) : setFeedsStatus(r))

            // closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedDfElements().then((r) => {
            if (r?.AllElements) {
                setFeedsDfEmployeesElements(r?.AllElements.filter(x => x.TableID === 1));
                setFeedsDfDependentsElements(r?.AllElements.filter(x => x.TableID === 2));
                setFeedsDfEmployeeBenefitsElements(r?.AllElements.filter(x => x.TableID === 3));
                setFeedsDfDependentBenefitsElements(r?.AllElements.filter(x => x.TableID === 4));
                setFeedsDfWaivedCoveragesElements(r?.AllElements.filter(x => x.TableID === 5));
                setFeedsDfBeneficiaryElements(r?.AllElements.filter(x => x.TableID === 6));
            } else {
                setFeedsDfEmployeesElements([]);
                setFeedsDfDependentsElements([]);
                setFeedsDfEmployeeBenefitsElements([]);
                setFeedsDfDependentBenefitsElements([]);
                setFeedsDfWaivedCoveragesElements([]);
                setFeedsDfBeneficiaryElements([]);
            }
        });
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedPhases().then((r) => (
            (!(r?.data?.length) ? setFeedsPhase([]) : setFeedsPhase(r?.data))

            // closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedTransmissionTypes().then((r) => (
            setFeedsTransmissionTypes(r?.data)
            // closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedProtocolTypes().then((r) => (
            setFeedsProtocolTypes(r?.data)
            // closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllEngineComponents().then((r) => (
            setComponentsList(r?.data),
            setComponentsListIDs(r?.data?.map(c => c?.ComponentID))
            // closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllEngineDFComponents().then((r) => (
            setComponentsDFList(r?.data),
            setComponentsDFListIDs(r?.data?.map(c => c?.ComponentID))
            // closeLoader()
        ));
    }, [])


    React.useEffect(() => {
        openLoader();
        const getMapsBody = { CarrierID: `${feed?.CarrierID}` }
        APIs.getCarrierMaps(getMapsBody).then((r) => !(r?.data?.length) ? setCarrierMaps([]) : setCarrierMaps(r?.data?.map(c => c))).finally(() => closeLoader());;
    }, [feed?.CarrierID, onEdit])

    React.useEffect(() => {
        openLoader();
        if (!feed?.CarrierID) return;
        else APIs.getCarrierKeys(feed?.CarrierID).then((r) => setCarrierKeys(r?.data)).finally(() => closeLoader());;
    }, [feed?.CarrierID, onEdit])

    React.useEffect(() => {
        openLoader();
        APIs.getDigitalFormsList(feed?.CarrierID).then((r) => (
            setDFsList(r?.data),
            closeLoader()
        ));
    }, [feed?.CarrierID])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    // Form Validations
    React.useEffect(() => {
        if (!feedTransmissionType) setTransmissionTypeValidationForm("Transmission type is missing, please select one from the list."); else setTransmissionTypeValidationForm("");
    }, [feedTransmissionType, transmissionTypeValidationForm])

    React.useEffect(() => {
        if (!feedProtocolType) setSFTPProtocolTypeValidationForm("Protocol type is missing, please select one from the list."); else setSFTPProtocolTypeValidationForm("");
    }, [feedProtocolType, sftpProtocolTypeValidationForm])

    React.useEffect(() => {
        if (!feedSSHKey) setSFTPSSHKeyValidationForm("SSH key is missing, please select one from the list."); else setSFTPSSHKeyValidationForm("")
    }, [feedSSHKey, sftpSSHKeyValidationForm])



    React.useEffect(() => {
        if (!feedSFTPSite?.replaceAll(" ", "")) setSFTPSiteValidationForm("SFTP site is missing, please fill it before submitting."); else setSFTPSiteValidationForm("");
    }, [feedSFTPSite, sftpSiteValidationForm])

    React.useEffect(() => {
        if (!feedSFTPPort) setSFTPPortValidationForm("SFTP port is missing, please fill it before submitting."); else setSFTPPortValidationForm("");
    }, [feedSFTPPort, sftpPortValidationForm])

    React.useEffect(() => {
        if (!feedSFTPUsername?.replaceAll(" ", "")) setSFTPUsernameValidationForm("SFTP username is missing, please fill it before submitting."); else setSFTPUsernameValidationForm("");
    }, [feedSFTPUsername, sftpUsernameValidationForm])

    React.useEffect(() => {
        if (!feedSFTPPassword?.replaceAll(" ", "")) setSFTPPasswordValidationForm("SFTP password is missing, please fill it before submitting."); else setSFTPPasswordValidationForm("");
    }, [feedSFTPPassword, sftpPasswordValidationForm])

    React.useEffect(() => {
        if (!feedSFTPDirectory?.replaceAll(" ", "")) setSFTPDirectoryValidationForm("SFTP remote directory is missing, please fill it before submitting."); else setSFTPDirectoryValidationForm("");
    }, [feedSFTPDirectory, sftpDirectoryValidationForm])


    React.useEffect(() => {
        if (selectedForms?.length > 0 && selectedForms?.any(s => !s.MainTypes || s.MainTypes?.length <= 0)) setSelectFormMainTypesValidationForm("Missing form main type, please select at least one"); else setSelectFormMainTypesValidationForm("");
    }, [JSON.stringify(selectedForms)], selectFormMainTypesValidationForm)

    React.useEffect(() => {
        if (selectedForms?.length > 0 && selectedForms?.any(s => !s.SubTypes || s.SubTypes?.length <= 0)) setSelectFormSubTypesValidationForm("Missing form sub type, please select at least one"); else setSelectFormSubTypesValidationForm("");
    }, [JSON.stringify(selectedForms)], selectFormSubTypesValidationForm)

    React.useEffect(() => {
        if (feed?.FeedMapTypeID === 2 && selectedDFComponent?.filter(f => f.ComponentID === 5).length > 0 && (toEmail === "" && ccEmail === "" && bccEmail === ""))
            setDFTransmitterEmailValidationForm("Please add at least one Carrier contact because Transmitter component is checked"); else setDFTransmitterEmailValidationForm("");
    }, [selectedDFComponent, toEmail, ccEmail, bccEmail, DFTransmitterEmailValidationForm])

    React.useEffect(() => {
        if ((toEmail !== "" || ccEmail !== "" || bccEmail !== "") && !emailSubject?.replaceAll(" ", ""))
            setProductionCarrierEmailSubjectValidationForm("Please add a subject to this email notification"); else setProductionCarrierEmailSubjectValidationForm("");
    }, [toEmail, ccEmail, bccEmail, emailSubject, productionCarrierEmailSubjectValidationForm])

    React.useEffect(() => {
        if ((toEmail !== "" || ccEmail !== "" || bccEmail !== "") && !emailBody?.replaceAll(" ", "").replaceAll("\n", ""))
            setProductionCarrierEmailBodyValidationForm("Please add a body to this email notification"); else setProductionCarrierEmailBodyValidationForm("");
    }, [toEmail, ccEmail, bccEmail, emailBody, productionCarrierEmailBodyValidationForm])


    React.useEffect(() => {
        if (hrSignatureNeeded && ((feedConnectivities?.length > 0))) {
            let flag = false;
            feedConnectivities.forEach(fc => {

                if (fc.ConnectivityRequestFormsSubTypeName !== "Require Admin Approval") {
                    flag = true;
                    // sethrSignatureRequiredValidation("CR Submission Type should be Batch Submission");
                }
            }
            )
            if (flag) sethrSignatureRequiredValidation("CR Transmission Type should be Require Admin Approval"); else sethrSignatureRequiredValidation("");
        }
        else {
            sethrSignatureRequiredValidation("");
        }
    }, [hrSignatureNeeded, feedConnectivities, HrSignatureRequiredValidation])


    React.useEffect(() => {
        if (onEdit && !reason?.replaceAll(" ", "").replaceAll("\n", "") &&
            (selectedBusinessRuleError?.length > 0)
            // ignore validation in Case New plan year data is received (only)
            && !(selectedBusinessRuleError?.length == 1 && selectedBusinessRuleError[0].Id == 7)
        ) {
            setReasonValidationForm("Ignore business rules reason is missing,  please fill it before submitting.");
        }
        else setReasonValidationForm("");
    }, [reason, requestSubmitted, reasonValidationForm, selectedBusinessRuleError])



    //Edit Feed Info

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handlePhaseChange = (e) => {
        setPhase(e.target.value);
    };

    const handleFileTypeChange = (e) => {
        setFileType(e.target.value);
    }

    const handleDataTypeChange = (e) => {
        setDataType(e.target.value);
    }

    const handleStructureTypeChange = (e) => {
        setFeedStructureType(e.target.value);
    }

    const handleTestOutputFileNameChange = (e) => {
        setTestOutputFileName(e.target.value);
    }

    const handleProdOutputFileNameChange = (e) => {
        setProdOutputFileName(e.target.value);
    }

    const handleToEmailChange = (e) => {
        setToEmail(e.target.value);
    }

    const handleCcEmailChange = (e) => {
        setCcEmail(e.target.value);
    }

    const handleBccEmailChange = (e) => {
        setBccEmail(e.target.value);
    }

    const handleEmailSubjectChange = e => {
        setEmailSubject(e.target.value)
    }

    const handleMapChange = (e) => {
        setMapID(e.target.value);
    };

    const handleDuplicateCodeChange = (e) => {
        setFeedDuplicateCode(e.target.value);
    }

    const handleEncryptionKeyChange = (e) => {
        setFeedEncryptionKey(e.target.value);
    }
    const handleClientCopyContact = e => {
        setClientCopyContact(e.target.value)
    }

    const handleComponentChange = (value) => (e) => {
        setCheckedComponent(e.target.checked);
    }
    // OE update 
    const handleOEFileNameChange = (e) => {
        setFeedOEFileName(e.target.value);
    }
    const handleComponentClick = (value) => (event) => {
        let componentsListArray = componentsList;
        let selectedComponentArray = selectedComponent;
        let selectedIndex = componentsListArray.indexOf(value);
        if (selectedIndex > -1) {
            if (!value || value === "") selectedComponentArray = [];
            else if (selectedComponentArray.indexOf(value) >= 0)
                selectedComponentArray.splice(selectedComponentArray.indexOf(value), selectedIndex + 6);
            else selectedComponentArray = componentsListArray.slice(0, selectedIndex + 1);
        }
        setSelectedComponent([...selectedComponentArray]);
    }

    React.useEffect(() => {
        if (componentsList[0] !== undefined) selectedComponent?.push(componentsList[0]);
    }, [componentsList, onEdit === true])


    const handleComponentDFChange = (value) => (e) => {
        setCheckedDFComponent(e.target.checked);
    }

    const handleComponentDFClick = (value) => (event) => {
        let componentsListDFArray = componentsDFList;
        let selectedComponentDFArray = selectedDFComponent;
        let selectedIndex = componentsListDFArray.indexOf(value);
        if (selectedIndex > -1) {
            if (!value || value === "") selectedComponentDFArray = [];
            else if (selectedComponentDFArray.indexOf(value) >= 0)
                selectedComponentDFArray.splice(selectedComponentDFArray.indexOf(value), selectedIndex + 6);
            else selectedComponentDFArray = componentsListDFArray.slice(0, selectedIndex + 1);
        }
        setSelectedDFComponent([...selectedComponentDFArray]);
    }

    React.useEffect(() => {
        if (componentsDFList[0] !== undefined) selectedDFComponent?.push(componentsDFList[0]);
    }, [componentsDFList, onEdit === true])

    const handlePlanYearStartDateChange = (date) => {
        setPlanYearStartDate(Moment(date).format('YYYY-MM-DD'));
    };

    const handleEnablePlanYearStartDateChange = (e) => {
        setEnablePlanYearStartDate(e.target.checked);
    }

    const handleGroupNumberChange = (e) => {
        setGroupNumber(e.target.value);
    }

    React.useEffect(() => {
        setStatus(feed?.FeedStatusID);
        setPhase(feed?.FeedPhaseID);
        setMapID(feed?.MapID);
        setDataType(feed?.DataType);
        setFeedEncryptionKey(feed?.EncryptionKey?.EncryptionKeyID);
        setSelectedComponent(componentsList?.slice(0, selectedComponentsView?.length));
        setSelectedDFComponent(componentsDFList?.slice(0, selectedComponentsView?.length));
        setTestOutputFileName(feed?.TestOutputFileName);
        setProdOutputFileName(feed?.ProdOutputFileName);
        setFeedOEFileName(feed?.OEOutputFileName);
        setFeedDuplicateCode(feed?.DuplicatePlanCode);
        setGroupNumber(feed?.CarrierClientNumber);
        setFeedTransmissionType(parseInt(feed?.TransmissionTypeID));
        setFeedProtocolType(transmissionProtocolObject?.Type);
        setFeedSSHKey(transmissionProtocolObject?.FeedKeyId);
        setFeedSFTPSite(transmissionProtocolObject?.Site);
        setFeedSFTPPort(transmissionProtocolObject?.Port);
        setFeedSFTPUsername(transmissionProtocolObject?.UserName);
        setFeedSFTPPassword(transmissionProtocolObject?.Password);
        setFeedSFTPDirectory(transmissionProtocolObject?.RemoteDirectory);
        setToEmail(feed?.EmailObject?.To?.length > 0 ? feed?.EmailObject?.To?.map(s => s).join(", ") : "");
        setCcEmail(feed?.EmailObject?.Cc?.length > 0 ? feed?.EmailObject?.Cc?.map(s => s).join(", ") : "");
        setBccEmail(feed?.EmailObject?.Bcc?.length > 0 ? feed?.EmailObject?.Bcc?.map(s => s).join(", ") : "");
        setClientCopyContact(feed?.ProductionClientCopyEmail?.To?.length > 0 ? feed?.ProductionClientCopyEmail?.To?.map(s => s).join(", ") : "");
        setEmailSubject((!feed?.EmailObject?.EmailSubject || feed?.EmailObject?.EmailSubject === "") ? emailSubjectInit : feed?.EmailObject?.EmailSubject);
        setEmailBody((!feed?.EmailObject?.EmailBody || feed?.EmailObject?.EmailBody === "") ? (feed?.FeedTypeID == 1 ? emailBodyInit : FormsFeedEmailBodyInit) : feed?.EmailObject?.EmailBody);
        setPlanYearStartDate(feed?.PlanYearStartDate && feed?.PlanYearStartDate !== "0000-00-00 00:00:00" ? feed?.PlanYearStartDate : null);
        setEnablePlanYearStartDate(feed?.EnablePlanYearStartDate === 0 ? false : feed?.EnablePlanYearStartDate === 1 ? true : false);
        setFileType(feed?.FeedTypeID);
        setFeedStructureType(feed?.StructureTypeID);
        setReason(feed?.DisableBusinessRulesReason);
        setFeedSchedulers((schedulerObject?.Schedules != null && schedulerObject?.Schedules?.length > 0) ? schedulerObject?.Schedules : []);
        feed?.WorkflowConfigurations && setemployeeSignatureNeeded(feed?.WorkflowConfigurations[0]?.WorkflowConfigurationObject?.TypeID == 3 ? true : false)
        setHrSignatureNeeded(feed?.HrSignatureRequired == 1 ? true : false)
        // Transmission
        setToEmailTransmission(transmissionEmailObject?.EmailObject?.To?.length > 0 ? transmissionEmailObject?.EmailObject?.To?.map(s => s).join(", ") : "");
        setCcEmailTransmission(transmissionEmailObject?.EmailObject?.Cc?.length > 0 ? transmissionEmailObject?.EmailObject?.Cc?.map(s => s).join(", ") : "");
        setBccEmailTransmission(transmissionEmailObject?.EmailObject?.Bcc?.length > 0 ? transmissionEmailObject?.EmailObject?.Bcc?.map(s => s).join(", ") : "");
        setEmailSubjectTransmission((!transmissionEmailObject?.EmailObject?.EmailSubject || transmissionEmailObject?.EmailObject?.EmailSubject === "") ? emailSubjectInit : transmissionEmailObject?.EmailObject?.EmailSubject);
        setEmailBodyTransmission((!transmissionEmailObject?.EmailObject?.EmailBody || transmissionEmailObject?.EmailObject?.EmailBody === "") ? emailBodyInit : transmissionEmailObject?.EmailObject?.EmailBody);
    }, [feed, onEdit === true])

    React.useEffect(() => {
        if (addSchedulerClicked) { setFeedSchedulers([new SchedulerObject({})]); }
    }, [onEdit, addSchedulerClicked])

    React.useEffect(() => {
        if (onEdit === true)
            setValue(1);
        else setValue(0);
    }, [onEdit])

    React.useEffect(() => {
        if (planYearStartDate === null || planYearStartDate === "Invalid date")
            setEnablePlanYearStartDate(false);
        else setEnablePlanYearStartDate(enablePlanYearStartDate);
    }, [planYearStartDate])

    //Get all All Business Rule  Error
    React.useEffect(() => {
        openLoader();
        APIs.getAllBusinessRuleError().then((r) => (setBusinessRuleErrorList(r?.data), closeLoader()));
    }, []);

    const handleBusinessRuleErrorClick = (value) => (event) => {
        let selectedBusinessRuleErrorArray = selectedBusinessRuleError;
        if (!value || value === "") { selectedBusinessRuleErrorArray = []; }
        else if (selectedBusinessRuleErrorArray.indexOf(value) >= 0) {
            selectedBusinessRuleErrorArray.splice(selectedBusinessRuleErrorArray.indexOf(value), 1);
        }
        else {
            selectedBusinessRuleErrorArray.push(value);
        }
        setSelectedBusinessRuleError([...selectedBusinessRuleErrorArray]);
    }

    const handleBusinessRuleErrorChange = (value) => (e) => {
        setCheckedBusinessRuleError(e.target.checked);
    }

    React.useEffect(() => {
        if (businessRuleErrorList?.length > 0 && businessRuleErrorIDs?.length > 0) {
            businessRuleErrorIDs?.map((c, ci) => {
                businessRuleErrorList.forEach((s, si) => {
                    if (s.Id === c) {
                        selectedBusinessRuleError?.push(s);
                    }
                });
            });
        }
    }, [businessRuleErrorList, businessRuleErrorIDs]);

    const submitUpdatedFeedConfig = (e) => {
        e.preventDefault();
        let updatedFeedConfig = {};
        let emptyEmailObject = {
            To: "",
            Cc: "",
            Bcc: "",
            EmailSubject: "",
            EmailBody: "",
        };
        let dataScheduler = feedSchedulers?.map((s, si) => {
            if (s.Type === "WeeklyScheduler") {
                return {
                    Type: s.Type,
                    Enabled: s.Enabled,
                    Recurrence: s.Recurrence,
                    StartDate: s.StartDate,
                    EndDate: null,
                    DayFrequency: 0,
                    DayFrequencyRecurrence: null,
                    StartTime: s.StartTime,
                    EndTime: null,
                    WeekDay: s.WeekDay,
                    StartDateTime: s.StartDateTime,
                }
            }
            if (s.Type === "MonthlyScheduler") {
                return {
                    Type: s.Type,
                    Enabled: s.Enabled,
                    Recurrence: s.Recurrence,
                    StartDate: s.StartDate,
                    EndDate: null,
                    DayFrequency: 0,
                    DayFrequencyRecurrence: null,
                    StartTime: s.StartTime,
                    EndTime: null,
                    WeekDay: s.WeekDay,
                    DayOfMonth: s.DayOfMonth,
                    MonthlyType: s.MonthlyType,
                    PartType: s.PartType,
                    StartDateTime: s.StartDateTime,
                }
            }

        });

        let dataTransmission = null;
        if (feedTransmissionType == 2) { //SFTP transmission protocol  
            dataTransmission = {
                Type: feedProtocolType,
                Site: feedSFTPSite,
                Port: feedSFTPPort,
                UserName: feedSFTPUsername,
                Password: feedSFTPPassword,
                RemoteDirectory: feedSFTPDirectory,
                FeedKeyId: feedSSHKey,
            }

        } else if (feedTransmissionType == 1) { // email
            dataTransmission = {
                EmailObject: {
                    To: toEmailTransmission && toEmailTransmission.replaceAll(";", ",").replaceAll(" ", "").split(","),
                    Cc: ccEmailTransmission && ccEmailTransmission.replaceAll(";", ",").replaceAll(" ", "").split(","),
                    Bcc: bccEmailTransmission && bccEmailTransmission.replaceAll(";", ",").replaceAll(" ", "").split(","),
                    EmailSubject: (toEmailTransmission || ccEmailTransmission || bccEmailTransmission) ? emailSubjectTransmission : "",
                    EmailBody: (toEmailTransmission || ccEmailTransmission || bccEmailTransmission) ? emailBodyTransmission.replaceAll("\n", "<br/>") : "",
                },
                Type: "Email"
            };
        }



        if (feed?.FeedMapTypeID !== 2) { //EDI
            setRequestSubmitted(true);
            // EDI Transmission validations 
            if ((feed?.TransmissionTypeID !== null && (!addSchedulerClicked && addTransmissionProtocolClicked)) || addTransmissionProtocolClicked) { //there exist transmission protocol or added
                // for all types 
                if (transmissionTypeValidationForm) return;
                if (feedTransmissionType == 2) { //SFTP transmission protocol
                    if (feedProtocolType === "SCP SFTP (SSH)") { //no pw needed
                        if (sftpProtocolTypeValidationForm || sftpSiteValidationForm || sftpPortValidationForm || sftpUsernameValidationForm || sftpDirectoryValidationForm) return;
                    } else { //pw needed
                        if (sftpProtocolTypeValidationForm || sftpSiteValidationForm || sftpPortValidationForm || sftpUsernameValidationForm || sftpPasswordValidationForm || sftpDirectoryValidationForm) return;
                    }
                } else if (feedTransmissionType == 1) { // email
                    if (carrierEmailSubjectValidationForm || carrierEmailBodyValidationForm || carrierEmailToEmailValidationForm) return;
                }
            }

            if (addSchedulerClicked && addTransmissionProtocolClicked) { //add scheduler and transmission protocol
                setRequestSubmitted(true);
                //validation for all schedulers 
                if (schedulersValidationForm) return;
                if (HrSignatureRequiredValidation) return;

                if (feedTransmissionType != 1 && (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm)) return;

                openLoader();
                updatedFeedConfig = {
                    FeedID: feed?.FeedID,
                    FeedName: feed?.FeedName,
                    CarrierID: feed?.CarrierID,
                    FeedTypeID: feed?.FeedTypeID,
                    FeedMapTypeID: feed?.FeedMapTypeID,
                    DataType: feed?.DataType,
                    FeedStatusID: feed?.FeedStatusID,
                    FeedPhaseID: feed?.FeedPhaseID,
                    TestOutputFileName: feed?.TestOutputFileName,
                    ProdOutputFileName: feed?.ProdOutputFileName,
                    OEOutputFileName: feed?.OEOutputFileName,
                    ProductionClientCopyEmail: {
                        To:
                            feed?.ProductionClientCopyEmail?.To
                    },
                    EmailObject: feedTransmissionType == 1 ? emptyEmailObject : {
                        To: feed?.EmailObject?.To,
                        Cc: feed?.EmailObject?.Cc,
                        Bcc: feed?.EmailObject?.Bcc,
                        EmailSubject: feed?.EmailObject?.EmailSubject,
                        EmailBody: feed?.EmailObject?.EmailBody,
                    },
                    DuplicatePlanCode: feed?.DuplicatePlanCode,
                    // CarrierClientNumber: feed?.CarrierClientNumber,
                    StructureTypeID: feed?.StructureTypeID,
                    MapID: feed?.MapID,
                    EncryptionKeyID: feed?.EncryptionKey?.EncryptionKeyID,
                    EngineComponents: selectedComponentsView,
                    BusinessRuleErrorIDs: selectedBusinessRuleError.map((c) => c.Id),
                    DisableBusinessRulesReason: feed?.DisableBusinessRulesReason,
                    PlanYearStartDate: feed?.PlanYearStartDate,
                    EnablePlanYearStartDate: feed?.EnablePlanYearStartDate,
                    TransmissionTypeID: parseInt(feedTransmissionType),
                    ConnectionObject: dataTransmission,
                    SchedulesObject: dataScheduler.length > 0 ? [...dataScheduler] : null
                }

            }
            else if (addTransmissionProtocolClicked && !addSchedulerClicked) { //add transmission protocol only
                setRequestSubmitted(true);
                if (HrSignatureRequiredValidation) return;
                if (feedTransmissionType != 1 && (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm)) return;

                openLoader();
                updatedFeedConfig = {
                    FeedID: feed?.FeedID,
                    FeedName: feed?.FeedName,
                    CarrierID: feed?.CarrierID,
                    FeedTypeID: feed?.FeedTypeID,
                    FeedMapTypeID: feed?.FeedMapTypeID,
                    DataType: feed?.DataType,
                    FeedStatusID: feed?.FeedStatusID,
                    FeedPhaseID: feed?.FeedPhaseID,
                    TestOutputFileName: feed?.TestOutputFileName,
                    ProdOutputFileName: feed?.ProdOutputFileName,
                    OEOutputFileName: feed?.OEOutputFileName,
                    ProductionClientCopyEmail: {
                        To:
                            feed?.ProductionClientCopyEmail?.To

                    },
                    EmailObject: feedTransmissionType == 1 ? emptyEmailObject : {
                        To: feed?.EmailObject?.To,
                        Cc: feed?.EmailObject?.Cc,
                        Bcc: feed?.EmailObject?.Bcc,
                        EmailSubject: feed?.EmailObject?.EmailSubject,
                        EmailBody: feed?.EmailObject?.EmailBody,
                    },
                    DuplicatePlanCode: feed?.DuplicatePlanCode,
                    // CarrierClientNumber: feed?.CarrierClientNumber,
                    StructureTypeID: feed?.StructureTypeID,
                    MapID: feed?.MapID,
                    EncryptionKeyID: feed?.EncryptionKey?.EncryptionKeyID,
                    EngineComponents: selectedComponentsView,
                    BusinessRuleErrorIDs: selectedBusinessRuleError.map((c) => c.Id),
                    DisableBusinessRulesReason: feed?.DisableBusinessRulesReason,
                    PlanYearStartDate: feed?.PlanYearStartDate,
                    EnablePlanYearStartDate: feed?.EnablePlanYearStartDate,
                    TransmissionTypeID: parseInt(feedTransmissionType),
                    ConnectionObject: dataTransmission,
                }
            } else if (addSchedulerClicked && !addTransmissionProtocolClicked) { //add scheduler only
                setRequestSubmitted(true);
                //validation for all schedulers 
                if (schedulersValidationForm) return;
                if (HrSignatureRequiredValidation) return;
                if (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm) return;

                openLoader();
                updatedFeedConfig = {
                    FeedID: feed?.FeedID,
                    FeedName: feed?.FeedName,
                    CarrierID: feed?.CarrierID,
                    FeedTypeID: feed?.FeedTypeID,
                    FeedMapTypeID: feed?.FeedMapTypeID,
                    DataType: feed?.DataType,
                    FeedStatusID: feed?.FeedStatusID,
                    FeedPhaseID: feed?.FeedPhaseID,
                    TestOutputFileName: feed?.TestOutputFileName,
                    ProdOutputFileName: feed?.ProdOutputFileName,
                    OEOutputFileName: feed?.OEOutputFileName,
                    ProductionClientCopyEmail: {
                        To:
                            feed?.ProductionClientCopyEmail?.To

                    },
                    EmailObject: feedTransmissionType == 1 ? emptyEmailObject : {
                        To: feed?.EmailObject?.To,
                        Cc: feed?.EmailObject?.Cc,
                        Bcc: feed?.EmailObject?.Bcc,
                        EmailSubject: feed?.EmailObject?.EmailSubject,
                        EmailBody: feed?.EmailObject?.EmailBody,
                    },
                    DuplicatePlanCode: feed?.DuplicatePlanCode,
                    // CarrierClientNumber: feed?.CarrierClientNumber,
                    StructureTypeID: feed?.StructureTypeID,
                    MapID: feed?.MapID,
                    EncryptionKeyID: feed?.EncryptionKey?.EncryptionKeyID,
                    EngineComponents: selectedComponentsView,
                    BusinessRuleErrorIDs: selectedBusinessRuleError.map((c) => c.Id),
                    DisableBusinessRulesReason: feed?.DisableBusinessRulesReason,
                    PlanYearStartDate: feed?.PlanYearStartDate,
                    EnablePlanYearStartDate: feed?.EnablePlanYearStartDate,
                    TransmissionTypeID: parseInt(feed?.TransmissionTypeID),
                    ConnectionObject: feedTransmissionType == 1 ? {
                        EmailObject: {
                            To: transmissionEmailObject?.EmailObject?.To,
                            Cc: transmissionEmailObject?.EmailObject?.Cc,
                            Bcc: transmissionEmailObject?.EmailObject?.Bcc,
                            EmailSubject: transmissionEmailObject?.EmailObject?.EmailSubject,
                            EmailBody: transmissionEmailObject?.EmailObject?.EmailBody,
                        },
                        Type: "Email"
                    } : {
                        Type: transmissionProtocolObject?.Type,
                        Site: transmissionProtocolObject?.Site,
                        Port: transmissionProtocolObject?.Port,
                        UserName: transmissionProtocolObject?.UserName,
                        Password: transmissionProtocolObject?.Password,
                        RemoteDirectory: transmissionProtocolObject?.RemoteDirectory,
                        FeedKeyId: transmissionProtocolObject?.FeedKeyId,
                    },
                    SchedulesObject: dataScheduler.length > 0 ? [...dataScheduler] : null
                }

            } else { //no add scheduler and no add transmission
                setRequestSubmitted(true);
                if (HrSignatureRequiredValidation) return;
                if (feedTransmissionType != 1 && (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm)) return;
                if ((transmissionProtocolObject || transmissionEmailObject || feed?.TransmissionTypeID !== null) && schedulerObject?.Schedules?.length > 0) { //there exist transmission protocol and scheduler
                    //validation for all schedulers 
                    if (schedulersValidationForm) return;

                    if (!reason?.replaceAll(" ", "").replaceAll("\n", "") &&
                        (selectedBusinessRuleError?.length > 0)
                        //  in Case New plan year data is received (only)
                        && !(selectedBusinessRuleError?.length == 1 && selectedBusinessRuleError[0].Id == 7)
                    )
                        return;
                    openLoader();
                    updatedFeedConfig = {
                        FeedID: feed?.FeedID,
                        FeedName: feed?.FeedName,
                        CarrierID: feed?.CarrierID,
                        FeedTypeID: fileType,
                        FeedMapTypeID: feed?.FeedMapTypeID,
                        DataType: dataType,
                        FeedStatusID: status,
                        FeedPhaseID: phase,
                        TestOutputFileName: testOutputFileName,
                        ProdOutputFileName: prodOutputFileName,
                        OEOutputFileName: feedOEFileName,
                        ProductionClientCopyEmail: {
                            To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
                        },
                        EmailObject: feedTransmissionType == 1 ? emptyEmailObject : {
                            To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
                            EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
                        },
                        DuplicatePlanCode: feedDuplicateCode,
                        // CarrierClientNumber: groupNumber,
                        StructureTypeID: feedStructureType,
                        MapID: mapID,
                        EncryptionKeyID: feedEncryptionKey,
                        EngineComponents: selectedComponent.map(c => c.ComponentID),
                        BusinessRuleErrorIDs: selectedBusinessRuleError.map((c) => c.Id),
                        DisableBusinessRulesReason: (selectedBusinessRuleError?.length > 0) ? reason : "",
                        PlanYearStartDate: planYearStartDate,
                        EnablePlanYearStartDate: enablePlanYearStartDate,
                        TransmissionTypeID: parseInt(feedTransmissionType),
                        ConnectionObject: dataTransmission,
                        SchedulesObject: dataScheduler.length > 0 ? [...dataScheduler] : null
                    };

                } else if ((transmissionProtocolObject || transmissionEmailObject || feed?.TransmissionTypeID !== null)) { //there exists transmission protocol only

                    if (!reason?.replaceAll(" ", "").replaceAll("\n", "")
                        && (selectedBusinessRuleError?.length > 0)
                        //  in Case New plan year data is received (only)
                        && !(selectedBusinessRuleError?.length == 1 && selectedBusinessRuleError[0].Id == 7)
                    ) return;
                    openLoader();
                    updatedFeedConfig = {
                        FeedID: feed?.FeedID,
                        FeedName: feed?.FeedName,
                        CarrierID: feed?.CarrierID,
                        FeedTypeID: fileType,
                        FeedMapTypeID: feed?.FeedMapTypeID,
                        DataType: dataType,
                        FeedStatusID: status,
                        FeedPhaseID: phase,
                        TestOutputFileName: testOutputFileName,
                        ProdOutputFileName: prodOutputFileName,
                        OEOutputFileName: feedOEFileName,
                        ProductionClientCopyEmail: {
                            To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
                        },
                        EmailObject: feedTransmissionType == 1 ? emptyEmailObject : {
                            To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
                            EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
                        },
                        DuplicatePlanCode: feedDuplicateCode,
                        // CarrierClientNumber: groupNumber,
                        StructureTypeID: feedStructureType,
                        MapID: mapID,
                        EncryptionKeyID: feedEncryptionKey,
                        EngineComponents: selectedComponent.map(c => c.ComponentID),
                        BusinessRuleErrorIDs: selectedBusinessRuleError.map((c) => c.Id),
                        DisableBusinessRulesReason: (selectedBusinessRuleError?.length > 0) ? reason : "",
                        PlanYearStartDate: planYearStartDate,
                        EnablePlanYearStartDate: enablePlanYearStartDate,
                        TransmissionTypeID: parseInt(feedTransmissionType),
                        ConnectionObject: dataTransmission,
                    };
                } else { //there is no transmission protocol or scheduler
                    if (!reason?.replaceAll(" ", "").replaceAll("\n", "")
                        && (selectedBusinessRuleError?.length > 0)
                        //  in Case New plan year data is received (only)
                        && !(selectedBusinessRuleError?.length == 1 && selectedBusinessRuleError[0].Id == 7)
                    ) return;
                    openLoader();
                    updatedFeedConfig = {
                        FeedID: feed?.FeedID,
                        FeedName: feed?.FeedName,
                        CarrierID: feed?.CarrierID,
                        FeedTypeID: fileType,
                        FeedMapTypeID: feed?.FeedMapTypeID,
                        DataType: dataType,
                        FeedStatusID: status,
                        FeedPhaseID: phase,
                        TestOutputFileName: testOutputFileName,
                        ProdOutputFileName: prodOutputFileName,
                        OEOutputFileName: feedOEFileName,
                        ProductionClientCopyEmail: {
                            To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
                        },
                        EmailObject: feedTransmissionType == 1 ? emptyEmailObject : {
                            To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
                            EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
                        },
                        DuplicatePlanCode: feedDuplicateCode,
                        // CarrierClientNumber: groupNumber,
                        StructureTypeID: feedStructureType,
                        MapID: mapID,
                        EncryptionKeyID: feedEncryptionKey,
                        EngineComponents: selectedComponent.map(c => c.ComponentID),
                        BusinessRuleErrorIDs: selectedBusinessRuleError.map((c) => c.Id),
                        DisableBusinessRulesReason: (selectedBusinessRuleError?.length > 0) ? reason : "",
                        PlanYearStartDate: planYearStartDate,
                        EnablePlanYearStartDate: enablePlanYearStartDate,
                    };
                }
            }
        }
        // finish EDI Section
        else { //Forms
            setRequestSubmitted(true);
            if (HrSignatureRequiredValidation) return;
            if (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm || DFTransmitterEmailValidationForm) return;
            if (addSchedulerClicked) { //add scheduler only
                //validation for all schedulers 
                if (schedulersValidationForm) return;
                if (selectFormMainTypesValidationForm || selectFormSubTypesValidationForm) return;
                openLoader();
                updatedFeedConfig = {
                    FeedID: feed?.FeedID,
                    FeedName: feed?.FeedName,
                    CarrierID: feed?.CarrierID,
                    FeedTypeID: feed?.FeedTypeID,
                    FeedMapTypeID: feed?.FeedMapTypeID,
                    DataType: feed?.DataType,
                    FeedStatusID: feed?.FeedStatusID,
                    FeedPhaseID: feed?.FeedPhaseID,
                    TestOutputFileName: feed?.TestOutputFileName,
                    ProdOutputFileName: feed?.ProdOutputFileName,
                    OEOutputFileName: feed?.OEOutputFileName,
                    ProductionClientCopyEmail: {
                        To: feed?.ProductionClientCopyEmail?.To
                    },
                    EmailObject: {
                        To: feed?.EmailObject?.To,
                        Cc: feed?.EmailObject?.Cc,
                        Bcc: feed?.EmailObject?.Bcc,
                        EmailSubject: feed?.EmailObject?.EmailSubject,
                        EmailBody: feed?.EmailObject?.EmailBody,
                    },
                    WorkflowConfigurations: feed?.WorkflowConfigurations[0],
                    Dictionaries: feed?.Dictionaries?.map((s) => (
                        {
                            Key: s?.DictKey,
                            Value: s?.DictValue
                        }
                    )),
                    DuplicatePlanCode: feed?.DuplicatePlanCode,
                    // CarrierClientNumber: feed?.CarrierClientNumber,
                    StructureTypeID: feed?.StructureTypeID,
                    MapID: feed?.MapID,
                    EncryptionKeyID: feed?.EncryptionKey?.EncryptionKeyID,
                    EngineComponents: selectedComponentsView,
                    PlanYearStartDate: feed?.PlanYearStartDate,
                    EnablePlanYearStartDate: feed?.EnablePlanYearStartDate,
                    TransmissionTypeID: parseInt(feed?.TransmissionTypeID),
                    ConnectionObject: feedTransmissionType == 1 ? {
                        EmailObject: {
                            To: transmissionEmailObject?.EmailObject?.To,
                            Cc: transmissionEmailObject?.EmailObject?.Cc,
                            Bcc: transmissionEmailObject?.EmailObject?.Bcc,
                            EmailSubject: transmissionEmailObject?.EmailObject?.EmailSubject,
                            EmailBody: transmissionEmailObject?.EmailObject?.EmailBody,
                        },
                        Type: "Email"
                    } : {
                        Type: transmissionProtocolObject?.Type,
                        Site: transmissionProtocolObject?.Site,
                        Port: transmissionProtocolObject?.Port,
                        UserName: transmissionProtocolObject?.UserName,
                        Password: transmissionProtocolObject?.Password,
                        RemoteDirectory: transmissionProtocolObject?.RemoteDirectory,
                        FeedKeyId: transmissionProtocolObject?.FeedKeyId
                    },

                    SchedulesObject: dataScheduler.length > 0 ? [...dataScheduler] : null
                }

            } else { //no add scheduler
                setRequestSubmitted(true);
                if (HrSignatureRequiredValidation) return;
                if (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm) return;
                if (schedulerObject?.Schedules?.length > 0) { //there exist scheduler only
                    //validation for all schedulers 
                    if (schedulersValidationForm) return;
                    // if (feedSchedulerFrequency === "WeeklyScheduler") { //weekly scheduler
                    if (selectFormMainTypesValidationForm || selectFormSubTypesValidationForm) return;
                    openLoader();
                    updatedFeedConfig = {
                        FeedID: feed?.FeedID,
                        FeedName: feed?.FeedName,
                        CarrierID: feed?.CarrierID,
                        FeedTypeID: fileType,
                        FeedMapTypeID: feed?.FeedMapTypeID,
                        DataType: dataType,
                        FeedStatusID: status,
                        FeedPhaseID: phase,
                        TestOutputFileName: testOutputFileName,
                        ProdOutputFileName: prodOutputFileName,
                        OEOutputFileName: feedOEFileName,
                        ProductionClientCopyEmail: {
                            To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
                        },
                        EmailObject: {
                            To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
                            EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
                        },
                        DigitalForms: selectedForms?.map(form => ({
                            DigitalFormID: form?.Id,
                            MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        // WorkflowConfigurations: {
                        //     WorkflowConfigurationObject:
                        //         DFWorkflowTypeForm === "1" ? {
                        //             TypeID: DFWorkflowTypeForm
                        //         } : DFWorkflowTypeForm === "2" ? {
                        //             TypeID: DFWorkflowTypeForm,
                        //             HRFirstName: HRFirstNameForm,
                        //             HRLastName: HRLastNameForm
                        //         } : DFWorkflowTypeForm === "3" ? {
                        //             TypeID: DFWorkflowTypeForm
                        //         } : {}
                        // },
                        WorkflowConfigurations: {
                            WorkflowConfigurationObject:
                                employeeSignatureNeeded === true ? {
                                    TypeID: 3
                                } : employeeSignatureNeeded === false ? {
                                    TypeID: 1,
                                } : {}
                        },
                        HrSignatureRequired: hrSignatureNeeded === true ? 1 : 0,
                        DfElements: selectedEmployeesDFElements.concat(selectedDependentsDFElements, selectedEmployeeBenefitsDFElements, selectedDependentBenefitsDFElements, selectedWaivedCoveragesDFElements, selectedBeneficiaryDFElements).map(x => x.Id),
                        Dictionaries: dictionaryList,
                        DuplicatePlanCode: feedDuplicateCode,
                        // CarrierClientNumber: groupNumber,
                        StructureTypeID: feedStructureType,
                        MapID: mapID,
                        EncryptionKeyID: feedEncryptionKey,
                        EngineComponents: selectedDFComponent.map(c => c.ComponentID),
                        PlanYearStartDate: planYearStartDate,
                        EnablePlanYearStartDate: enablePlanYearStartDate,
                        TransmissionTypeID: parseInt(feedTransmissionType),
                        ConnectionObject: feedTransmissionType == 1 ? {
                            EmailObject: {
                                To: toEmailTransmission && toEmailTransmission.replaceAll(";", ",").replaceAll(" ", "").split(","),
                                Cc: ccEmailTransmission && ccEmailTransmission.replaceAll(";", ",").replaceAll(" ", "").split(","),
                                Bcc: bccEmailTransmission && bccEmailTransmission.replaceAll(";", ",").replaceAll(" ", "").split(","),
                                EmailSubject: (toEmailTransmission || ccEmailTransmission || bccEmailTransmission) ? emailSubjectTransmission : "",
                                EmailBody: (toEmailTransmission || ccEmailTransmission || bccEmailTransmission) ? emailBodyTransmission.replaceAll("\n", "<br/>") : "",
                            },
                            Type: "Email"
                        } : {
                            Type: feedProtocolType,
                            Site: feedSFTPSite,
                            Port: feedSFTPPort,
                            UserName: feedSFTPUsername,
                            Password: feedSFTPPassword,
                            RemoteDirectory: feedSFTPDirectory,
                            FeedKeyId: feedSSHKey,
                        },
                        SchedulesObject: dataScheduler.length > 0 ? [...dataScheduler] : null
                    };

                } else { //there is no scheduler
                    if (selectFormMainTypesValidationForm || selectFormSubTypesValidationForm) return;
                    openLoader();
                    updatedFeedConfig = {
                        FeedID: feed?.FeedID,
                        FeedName: feed?.FeedName,
                        CarrierID: feed?.CarrierID,
                        FeedTypeID: fileType,
                        FeedMapTypeID: feed?.FeedMapTypeID,
                        DataType: dataType,
                        FeedStatusID: status,
                        FeedPhaseID: phase,
                        TestOutputFileName: testOutputFileName,
                        ProdOutputFileName: prodOutputFileName,
                        OEOutputFileName: feedOEFileName,
                        ProductionClientCopyEmail: {
                            To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
                        },
                        EmailObject: {
                            To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                            EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
                            EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
                        },
                        DigitalForms: selectedForms?.map(form => ({
                            DigitalFormID: form?.Id,
                            MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        // WorkflowConfigurations: {
                        //     WorkflowConfigurationObject:
                        //         DFWorkflowTypeForm === "1" ? {
                        //             TypeID: DFWorkflowTypeForm
                        //         } : DFWorkflowTypeForm === "2" ? {
                        //             TypeID: DFWorkflowTypeForm,
                        //             HRFirstName: HRFirstNameForm,
                        //             HRLastName: HRLastNameForm
                        //         } : DFWorkflowTypeForm === "3" ? {
                        //             TypeID: DFWorkflowTypeForm
                        //         } : {}
                        // },
                        WorkflowConfigurations: {
                            WorkflowConfigurationObject:
                                { TypeID: employeeSignatureNeeded ? 3 : 1 }
                        },
                        HrSignatureRequired: hrSignatureNeeded === true ? 1 : 0,
                        DfElements: selectedEmployeesDFElements.concat(selectedDependentsDFElements, selectedEmployeeBenefitsDFElements, selectedDependentBenefitsDFElements, selectedWaivedCoveragesDFElements, selectedBeneficiaryDFElements).map(x => x.Id),
                        Dictionaries: dictionaryList,
                        DuplicatePlanCode: feedDuplicateCode,
                        // CarrierClientNumber: groupNumber,
                        StructureTypeID: feedStructureType,
                        MapID: mapID,
                        EncryptionKeyID: feedEncryptionKey,
                        EngineComponents: selectedDFComponent.map(c => c.ComponentID),
                        PlanYearStartDate: planYearStartDate,
                        EnablePlanYearStartDate: enablePlanYearStartDate,
                    };
                }
            }
        }

        APIs.editFeed(updatedFeedConfig).then((r) => {
            // debugger
            window.location.reload();
        });
    }


    const handleCancelButton = (e) => {
        if (addSchedulerClicked && addTransmissionProtocolClicked) { setAddTransmissionProtocolClicked(false); setAddSchedulerClicked(false); }
        else if (addSchedulerClicked)
            setAddSchedulerClicked(false);
        else if (addTransmissionProtocolClicked)
            setAddTransmissionProtocolClicked(false);
        else setOnEdit(false);
        window.location.reload();
    }

    // process Feed and get raw data 
    const getTestFile = (testOnly) => {
        // testOnly =>>will be 1 or 0 , 1 = stops on parser step only
        openLoader();
        APIs.getTestFile({ feedId: feedIDURL, testOnly }).then((r) => {
            if (r?.statusCode === 200) {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Your request has been processed successfully!`,
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: `The request has not been processed successfully. Please try again!`,
                });
            }
            closeLoader();
        })
    }

    const getTestFileConfirmation = () => {
        if (feed?.StructureTypeID === 3) {
            dialog.openDialog("Please select date to receive the data:",
                <Set401kProcessFeedDate feed={feed} feedIDURL={feedIDURL} />, []
            )
        }
        openModal(0, () => getTestFile(0), {
            title: 'Please Confirm',
            question: `Are you sure you want to get a new test file for feed ${feed?.FeedID}?`,
        })
    }

    const getRawDataConfirmation = () => {
        if (feed?.StructureTypeID === 3) {
            dialog.openDialog("Please select date to receive the data:",
                <Set401kProcessFeedDate feed={feed} feedIDURL={feedIDURL} />, []
            )
        }
        openModal(0, () => getTestFile(1), {
            title: 'Please Confirm',
            question: `Are you sure you want to get raw data for feed ${feed?.FeedID}?`,
        })
    }
    // // process Feed and get raw data 


    React.useEffect(() => {
        if (testConnectionClicked) {
            if (timeoutSeconds < 29) {
                setTimeout(() => settimeoutSeconds(timeoutSeconds + 1), 1000);
            } else {
                closeLoader();
                FailureModal(dialog, {
                    title: 'Failure',
                    body: "Request Timeout",
                });
                setTestConnectionClicked(false);
                settimeoutSeconds(-1);
                return;
            }
        }
    }, [testConnectionClicked, timeoutSeconds])

    const testTransmissionConnection = (e) => {
        e.stopPropagation();
        setTestConnectionClicked(true);
        openLoader();
        APIs.testFeedConnection({ feedID: feed?.FeedID }).then(r => {
            if (r?.success === 1) {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Connection Succeeded!`,
                });
                setTestConnectionClicked(false);
                settimeoutSeconds(-1);
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
                setTestConnectionClicked(false);
                settimeoutSeconds(-1);
            }
            closeLoader();
        })
    }

    const handleOutputTestFileNameCharRestriction = (e) => {
        let invalidChar = ["/", "\\", "}", "{", "^", "%", "'", "]", "[", "~", "#", "|", '"', "`"];
        if (invalidChar.any(s => s === e.key)) {
            e.preventDefault();
            setOutputTestFileNameMsg(`Invalid output file name, output file name should not contain ${e.key}`);
        } else {
            setOutputTestFileNameMsg("");
        }
    }

    const handleOutputProdFileNameCharRestriction = (e) => {
        let invalidChar = ["/", "\\", "}", "{", "^", "%", "'", "]", "[", "~", "#", "|", '"', "`"];
        if (invalidChar.any(s => s === e.key)) {
            e.preventDefault();
            setOutputProdFileNameMsg(`Invalid output file name, output file name should not contain ${e.key}`);
        } else {
            setOutputProdFileNameMsg("");
        }
    }

    // OE Validation
    const handleOutputOEFileNameCharRestriction = (e) => {
        let invalidChar = ["/", "\\", "}", "{", "^", "%", "'", "]", "[", "~", "#", "|", '"', "`"];
        if (invalidChar.any(s => s === e.key)) {
            e.preventDefault();
            setOutputOEFileNameMsg(`Invalid output file name, output file name should not contain ${e.key}`);
        } else {
            setOutputOEFileNameMsg("");
        }
    }

    const clearLastSentFileConfirmation = () => {
        openModal(0, clearLastSentFile, {
            title: 'Please Confirm',
            question: `Are you sure you want to clear the last sent file for feed ${feed?.FeedID}?`,
        })
    }

    const clearLastSentFile = () => {
        openLoader();
        APIs.clearLastSentFile(feedIDURL).then(r => window.location.reload());
    }

    const notisIsovedSuperAdminOriSolvedUser = () => {
        //attached user roles ID for analyst and super admin
        return (userRoles?.includes("1") ||
            userRoles?.includes("7"));
    }

    return (
        <div id="feed-config" className="newUi-collapse">
            <div className="d-flex flex-wrap">
                {feed && (
                    <BreadcrumbSteps
                        breadcrumbTitleCurrent={`${feed?.FeedName}`}
                        isCurrent={true}
                    />
                )}
                {feed?.CancellationDate && <> <div className="warmingOERequest  ml-auto">  <ErrorOutlineIcon className="mr-1" />Kindly note that this Feed is connected to a CR that has a future cancellation date. ({Moment(feed?.CancellationDate).format('MM/DD/YYYY')}) </div> <br /> </>}
            </div>
            <div className="d-flex">
                <MultipleTabsTitles disabled={!feedIDURL || feedIDURL === undefined} tabTitle={feedTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
                {((userActions?.includes("38") && feed?.FeedStatusID == 6) ||
                    userRoles?.includes("1") ||
                    userRoles?.includes("7")) && (
                        <Button variant="contained" className="blue-btn ml-auto" disabled={!feed} style={{ whiteSpace: "nowrap" }} onClick={getTestFileConfirmation}>
                            <label style={{ cursor: "pointer" }} className="mb-0">Process Feed</label>
                        </Button>
                    )
                }
                {(!(userRoles?.includes("1") ||
                    userRoles?.includes("6")) && feed?.StructureTypeName != "401K") && (
                        <Button variant="contained" className="blue-btn ml-1" disabled={!feed} style={{ whiteSpace: "nowrap" }} onClick={getRawDataConfirmation}>
                            <label style={{ cursor: "pointer" }} className="mb-0">Get Raw Data</label>
                        </Button>

                    )

                }

            </div>

            {tabValue === 0 && (
                <Paper elevation={0} className="p-5">
                    <div style={{ textAlignLast: "right", marginBottom: "15px" }}>
                        {(userActions?.includes("5") || userActions?.includes("20")) && (
                            <div className="row">
                                <div className="col-12 mb-1 text-right">
                                    <CustomTooltip TransitionComponent={Zoom} title={onEdit ? "Please submit your edit changes first before adding a transmission protocol" : (addSchedulerClicked ? "Please remove scheduler configuration first" : (feed?.FeedMapTypeID !== 2 && feed?.TransmissionTypeID !== null) ? "Transmission Protocol exists, click on 'Edit' to edit the configuration" : feed?.FeedMapTypeID === 2 ? "This option is not applicable with Forms feeds" : "")}>
                                        <span>
                                            <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => setAddTransmissionProtocolClicked(!addTransmissionProtocolClicked)} disabled={!feed || onEdit || feed?.TransmissionTypeID !== null || addSchedulerClicked || feed?.FeedMapTypeID === 2}>
                                                {addTransmissionProtocolClicked === false ?

                                                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                                                        <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                                                    </svg> :
                                                    <svg width="20" height="20" viewBox="0 0 20 20" className="mt--2">
                                                        <use xlinkHref={`${minusIconSVG}#minus`}></use>
                                                    </svg>
                                                }
                                                <label style={{ cursor: "pointer" }} className="ml-2 mb-0">{!addTransmissionProtocolClicked ? "Add" : "Remove"} Transmission Protocol</label>
                                            </Button>
                                        </span>
                                    </CustomTooltip >
                                    <CustomTooltip TransitionComponent={Zoom} title={onEdit ? "Please submit your edit changes first before adding a scheduler" : (addTransmissionProtocolClicked === false && transmissionProtocolObject === null && transmissionEmailObject === null && feed?.TransmissionTypeID === null && feed?.FeedMapTypeID !== 2) ? "Please add transmission protocol first" : schedulerObject?.Schedules !== null ? "Scheduler exists, click on 'Edit' to edit the configuration" : ((!onEdit && feed?.StructureTypeID === 3) || (onEdit && feedStructureType === 3)) ? "Scheduler is not applicable as it is a 401K feed" : disableSheduler ? "There are no active connectivity requests for this feed" : ""}>
                                        <span>

                                            <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => { setAddSchedulerClicked(!addSchedulerClicked); setFeedSchedulers([new SchedulerObject({})]); }} disabled={!feed || onEdit || disableSheduler || schedulerObject?.Schedules !== null || (addTransmissionProtocolClicked === false && transmissionProtocolObject === null && transmissionEmailObject === null && feed?.TransmissionTypeID === null && feed?.FeedMapTypeID !== 2) || (!onEdit && feed?.StructureTypeID === 3) || (onEdit && feedStructureType === 3)}>
                                                <svg width="20" height="24" viewBox="0 0 28 24" >
                                                    <use xlinkHref={`${addSchedulerIcon}#addSchedulerIcon`}></use>
                                                </svg>
                                                <label style={{ cursor: "pointer" }} className="ml-2 mb-0">{!addSchedulerClicked ? "Add" : "Remove"} Scheduler Configuration</label>
                                            </Button>
                                        </span>
                                    </CustomTooltip>
                                    <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => setOnEdit(!onEdit)} disabled={!feed || addSchedulerClicked}>
                                        <svg width="20" height="24" viewBox="0 0 25 24">
                                            <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                        </svg>
                                        <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                                    </Button>
                                </div>
                                <div className="col-12 mt-1 text-right">
                                    <CustomTooltip TransitionComponent={Zoom} title={feed?.SelectedDate?.fileName === "" ? "There is no last sent file set for this feed" : feed?.AvailableDates?.length === 0 ? "There are no available dates for this feed" : ""}>
                                        <span>
                                            <Button disabled={!feed || feed?.AvailableDates?.length === 0 || feed?.SelectedDate?.fileName === ""} variant="contained" className="blue-outlined-btn mr-2" onClick={clearLastSentFileConfirmation}>
                                                <label style={{ cursor: "pointer" }} className="mb-0">Clear Last Sent File</label>
                                            </Button>
                                        </span>
                                    </CustomTooltip>
                                    {feed?.FeedMapTypeName == "Forms" ?
                                        <>
                                            <CustomTooltip TransitionComponent={Zoom} title={"Set Last Sent File"}>
                                                <span>
                                                    <Button disabled={!feed} variant="contained" className="blue-outlined-btn mr-2" onClick={() => {
                                                        dialog.openDialog("Last Sent File Forms", <SetLastSentFileModalForms feed={feed} />, []);
                                                    }}>
                                                        <label style={{ cursor: "pointer" }} className="mb-0">Set Last Sent File</label>
                                                    </Button>
                                                </span>
                                            </CustomTooltip>
                                        </> :
                                        // EDI
                                        <CustomTooltip TransitionComponent={Zoom} title={feed?.AvailableDates?.length === 0 ? "There are no available dates for this feed" : ""}>
                                            <span>
                                                <Button disabled={!feed || feed?.AvailableDates?.length === 0} variant="contained" className="blue-outlined-btn mr-2" onClick={() => {
                                                    dialog.openDialog("Last Sent File", <SetLastSentFileModal feed={feed} />, []);
                                                }}>
                                                    <label style={{ cursor: "pointer" }} className="mb-0">Set Last Sent File</label>
                                                </Button>
                                            </span>
                                        </CustomTooltip>}
                                </div>
                            </div>
                        )}
                    </div>
                    <form onSubmit={submitUpdatedFeedConfig}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                aria-controls="panel2-content"
                                id="panel-header-cr-config"
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                style={{ pointerEvents: 'none' }}
                            >
                                <span>Feed Information</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Feed Name</h6>
                                        <div className="textfield-div">
                                            {feed?.FeedName}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Carrier Name</h6>
                                        <div className="textfield-div">
                                            {feed?.CarrierName}
                                        </div>
                                    </Grid>
                                    {feed?.ThirdPartyName && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Third Party Name</h6>
                                            <div className="textfield-div">
                                                {feed?.ThirdPartyName}
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Feed Type</h6>
                                        <div className="textfield-div">
                                            {feed?.FeedMapTypeName}
                                        </div>
                                    </Grid>
                                    {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) && (
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <h6 className="form-title">File Type</h6>
                                                {onEdit ? (
                                                    <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                                        <Select
                                                            className="text-dropdown"
                                                            value={fileType}
                                                            onChange={handleFileTypeChange}
                                                        >
                                                            <MenuItem value="1">Single Tenant</MenuItem>
                                                            <MenuItem value="2">Multi Tenant</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    <div className="textfield-div">
                                                        {feed?.FeedTypeName}
                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <h6 className="form-title">Data Type</h6>
                                                {onEdit ? (
                                                    <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                                        <Select
                                                            className="text-dropdown"
                                                            value={dataType}
                                                            onChange={handleDataTypeChange}
                                                        >
                                                            <MenuItem value="">None</MenuItem>
                                                            <MenuItem value="Changes Only File">Changes Only File</MenuItem>
                                                            <MenuItem value="Full File">Full File</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                ) : (
                                                    <div className="textfield-div">
                                                        {feed?.DataType}
                                                    </div>
                                                )}
                                            </Grid>
                                            {notisIsovedSuperAdminOriSolvedUser() && (
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <h6 className="form-title">Structure Type</h6>
                                                    {onEdit ? (
                                                        <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                                            <Select
                                                                className="text-dropdown"
                                                                value={feedStructureType}
                                                                onChange={handleStructureTypeChange}
                                                            >
                                                                {structureTypes?.map((c, ci) => (
                                                                    <MenuItem key={`structure-type-${ci}`} value={c?.StructureTypeID}>{c?.StructureTypeName}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    ) : (
                                                        <div className="textfield-div">
                                                            {feed?.StructureTypeName}
                                                        </div>
                                                    )}
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Status</h6>
                                        {onEdit ? (
                                            <FormControl label="Select Status" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={status ?? ""}
                                                    onChange={handleStatusChange}
                                                >
                                                    {feedsStatus?.map((s, si) => (
                                                        <MenuItem key={`connect-status-${si}`} value={s?.FeedStatusID}>{s?.FeedStatusName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {feed?.FeedStatusName}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Phase</h6>
                                        {onEdit ? (
                                            <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={phase ?? ""}
                                                    onChange={handlePhaseChange}
                                                >
                                                    {feedsPhase?.map((s, si) => (
                                                        <MenuItem key={`feed-phase-${si}`} value={s?.FeedPhaseID}>{s?.FeedPhaseName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {feed?.FeedPhaseName}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Output Test File Name</h6>
                                        {onEdit ? (
                                            <>
                                                <input onKeyDown={handleOutputTestFileNameCharRestriction} type="text" className="text-input w-100" value={testOutputFileName ?? ""} onChange={handleTestOutputFileNameChange} />
                                                <div className="mt-2 text-danger">{outputTestFileNameMsg}</div>
                                            </>
                                        ) : (
                                            <div className="textfield-div">
                                                {feed?.TestOutputFileName}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Output Production File Name</h6>
                                        {onEdit ? (
                                            <>
                                                <input onKeyDown={handleOutputProdFileNameCharRestriction} type="text" className="text-input w-100" value={prodOutputFileName ?? ""} onChange={handleProdOutputFileNameChange} />
                                                <div className="mt-2 text-danger">{outputProdFileNameMsg}</div>
                                            </>
                                        ) : (
                                            <div className="textfield-div">
                                                {feed?.ProdOutputFileName}
                                            </div>
                                        )}
                                    </Grid>
                                    {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Output OE File Name</h6>
                                            {onEdit ? (
                                                <>
                                                    <input onKeyDown={handleOutputOEFileNameCharRestriction} type="text" className="text-input w-100" value={feedOEFileName} onChange={handleOEFileNameChange} />
                                                    <div className="mt-2 text-danger">{outputOEFileNameMsg}</div>

                                                </>
                                            ) : (
                                                <div className="textfield-div">
                                                    {feed?.OEOutputFileName}
                                                </div>
                                            )}
                                        </Grid>
                                    )}
                                    {feedTransmissionType != 1 && (
                                        <React.Fragment>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <h6 className="form-title">Production Carrier Contact</h6>
                                                {onEdit ? (
                                                    <div className="components-div pb-3">
                                                        <div className="mt-3 mb-2">
                                                            <span>You can enter multiple emails here separated by commas</span>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 alignvh-center">
                                                                <h6 className="form-title">To:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                <input type="email" autoComplete='new-password' multiple className="text-input w-100" value={toEmail} onChange={handleToEmailChange} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 alignvh-center">
                                                                <h6 className="form-title">Cc:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                <input type="email" autoComplete='new-password' multiple className="text-input w-100" value={ccEmail} onChange={handleCcEmailChange} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 alignvh-center">
                                                                <h6 className="form-title">Bcc:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                <input type="email" autoComplete='new-password' multiple className="text-input w-100" value={bccEmail} onChange={handleBccEmailChange} />
                                                            </div>
                                                        </div>
                                                        {(toEmail !== "" || ccEmail !== "" || bccEmail !== "") && (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-xl-2 mt-3 alignvh-center">
                                                                        <h6 className="form-title">Subject:</h6>
                                                                    </div>
                                                                    <div className="col-xl-10 mt-3">
                                                                        <input autoComplete="new-password" className="text-input w-100" value={emailSubject} onChange={handleEmailSubjectChange} />
                                                                    </div>
                                                                    <div className="col-xl-2 mt-1 alignvh-center"></div>
                                                                    <div className="col-xl-10 mt-1">
                                                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{productionCarrierEmailSubjectValidationForm}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-2 mt-3 alignvh-center">
                                                                        <h6 className="form-title">Body:</h6>
                                                                    </div>
                                                                    <div className="col-xl-10 mt-3">
                                                                        <textarea type="text" className="action-item-textarea w-100" value={emailBody?.replaceAll("<br/>", "\n")} onChange={(e) => { setEmailBody(e.target.value) }} rows={4} />
                                                                    </div>
                                                                    <div className="col-xl-2 mt-1 alignvh-center"></div>
                                                                    <div className="col-xl-10 mt-1">
                                                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{productionCarrierEmailBodyValidationForm}</div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                                                            {DFTransmitterEmailValidationForm}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="textfield-div" style={{ display: "block" }}>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 alignvh-center">
                                                                <h6 className="form-title">To:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                {feed?.EmailObject?.To?.length > 0 && feed?.EmailObject?.To?.map(s => s).join(", ")}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 alignvh-center">
                                                                <h6 className="form-title">Cc:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                {feed?.EmailObject?.Cc?.length > 0 && feed?.EmailObject?.Cc?.map(s => s).join(", ")}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 alignvh-center">
                                                                <h6 className="form-title">Bcc:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                {feed?.EmailObject?.Bcc?.length > 0 && feed?.EmailObject?.Bcc?.map(s => s).join(", ")}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3">
                                                                <h6 className="form-title">Subject:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3">
                                                                {feed?.EmailObject?.EmailSubject}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-2 mt-3 mb-3">
                                                                <h6 className="form-title">Body:</h6>
                                                            </div>
                                                            <div className="col-xl-10 mt-3 mb-3">
                                                                <span style={{ whiteSpace: "break-spaces" }}>{feed?.EmailObject?.EmailBody?.replaceAll("<br/>", "\n")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Production Client Copy Contact</h6>
                                        {onEdit ? (
                                            <input type="email" placeholder="You can enter multiple emails here separated by commas" autoComplete="new-password" multiple className="text-input w-100" value={clientCopyContact} onChange={handleClientCopyContact} />
                                        ) : (
                                            <div className="textfield-div">
                                                {feed?.ProductionClientCopyEmail?.To?.length > 0 && feed?.ProductionClientCopyEmail?.To?.map(s => s).join(", ")}
                                            </div>
                                        )}
                                    </Grid>
                                    {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Map</h6>
                                            {onEdit ? (
                                                <FormControl label="Select Map" focused={false} variant="outlined" className="w-100">
                                                    <Select
                                                        className="text-dropdown"
                                                        value={mapID ?? ""}
                                                        onChange={handleMapChange}
                                                    >
                                                        {carrierMaps?.map((s, si) => (
                                                            <MenuItem key={`feed-map-${si}`} value={s?.Id}>{s?.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <div className="textfield-div">
                                                    {feed?.MapName}
                                                </div>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Duplicate Plan Code</h6>
                                        {onEdit ? (
                                            <textarea type="text" className="action-item-textarea w-100" value={feedDuplicateCode} onChange={handleDuplicateCodeChange} rows={4} />
                                        ) : (
                                            <div className="textfield-div">
                                                <span style={{ whiteSpace: "break-spaces" }}>{feed?.DuplicatePlanCode}</span>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Carrier Client Number</h6>
                                        <div className="textfield-div">
                                            {feed?.CarrierClientNumber}
                                        </div>
                                    </Grid>
                                    {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Encryption Key</h6>
                                            {onEdit ? (
                                                <FormControl label="Select key" focused={false} variant="outlined" className="w-100">
                                                    <Select
                                                        className="text-dropdown"
                                                        value={feedEncryptionKey ?? ""}
                                                        onChange={handleEncryptionKeyChange}
                                                    >
                                                        <MenuItem value="" className="color grey4">Reset...</MenuItem>
                                                        {carrierKeys?.map((c, ci) => (
                                                            <MenuItem key={`carrier-key-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <div className="textfield-div d-block">
                                                    {feed?.EncryptionKey?.ID !== null ? (
                                                        <>
                                                            <span><span>Name: </span>{feed?.EncryptionKey?.Name}</span>
                                                            <br />
                                                            <span><span>ID: </span>{feed?.EncryptionKey?.ID}</span>
                                                            <br />
                                                            <span><span>Fingerprint: </span>{feed?.EncryptionKey?.Fingerprint}</span>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Components</h6>
                                        {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) ? (
                                            onEdit ? (
                                                <div className="row container-div p-4 checkbox-list">
                                                    {componentsList?.map((c, ci) => (
                                                        <React.Fragment key={`components-list-${ci}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        id={`component-option-${ci}`}
                                                                        value={c}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checked={selectedComponent && selectedComponent.indexOf(c) >= 0}
                                                                        disabled={selectedComponent.indexOf(c) == 0}
                                                                        onChange={handleComponentChange(c)}
                                                                        onClick={handleComponentClick(c)}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                        color="default"
                                                                    />}
                                                                label={c?.ComponentName}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="textfield-div checkbox-list">
                                                    {componentsList?.map((c, ci) => (
                                                        <React.Fragment key={`components-list-${ci}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        id={`component-option-${ci}`}
                                                                        value={c}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checked={selectedComponentsView && selectedComponentsView.indexOf(c?.ComponentID) >= 0}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                        color="default"
                                                                    />}
                                                                label={c?.ComponentName}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )
                                        ) : (
                                            onEdit ? (
                                                <div className="row container-div p-4 checkbox-list">
                                                    {componentsDFList?.map((c, ci) => (
                                                        <React.Fragment key={`components-DF-list-${ci}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        id={`component-DF-option-${ci}`}
                                                                        value={c}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checked={selectedDFComponent && selectedDFComponent.indexOf(c) >= 0}
                                                                        disabled={selectedDFComponent.indexOf(c) == 0 || (!matchingAllPlansValidation && (ci == 3 || ci == 4))}
                                                                        onChange={handleComponentDFChange(c)}
                                                                        onClick={handleComponentDFClick(c)}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                        color="default"
                                                                    />}
                                                                label={c?.ComponentName}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="textfield-div checkbox-list">
                                                    {componentsDFList?.map((c, ci) => (
                                                        <React.Fragment key={`components-DF-list-${ci}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        id={`component-DF-option-${ci}`}
                                                                        value={c}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checked={selectedComponentsView && selectedComponentsView.indexOf(c?.ComponentID) >= 0}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                        color="default"
                                                                    />}
                                                                label={c?.ComponentName}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )
                                        )}
                                    </Grid>
                                    {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) && (
                                        <>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <h6 className="form-title">Ignore Business Rules</h6>
                                                {onEdit ? (
                                                    <div className="row container-div p-4 checkbox-list">
                                                        {businessRuleErrorList?.map((c, ci) => (
                                                            <React.Fragment key={`businessRuleError-list-${ci}`}>
                                                                <FormControlLabel
                                                                    aria-label="Acknowledge"
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    onFocus={(event) => event.stopPropagation()}
                                                                    control={
                                                                        <Checkbox
                                                                            id={`businessRuleError-option-${ci}`}
                                                                            value={c}
                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                            checked={(selectedBusinessRuleError && selectedBusinessRuleError.indexOf(c) >= 0)}
                                                                            onChange={handleBusinessRuleErrorChange(c)}
                                                                            onClick={handleBusinessRuleErrorClick(c)}
                                                                            checkedIcon={<DoneOutlineIcon />}
                                                                            color="default"
                                                                        />
                                                                    }
                                                                    label={c?.Name}
                                                                />
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="textfield-div checkbox-list">
                                                        {businessRuleErrorList?.map((c, ci) => (
                                                            <React.Fragment key={`businessRuleError-list-${ci}`}>
                                                                <FormControlLabel
                                                                    aria-label="Acknowledge"
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    onFocus={(event) => event.stopPropagation()}
                                                                    control={
                                                                        <Checkbox
                                                                            id={`businessRuleError-option-${ci}`}
                                                                            value={c}
                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                            checked={(selectedBusinessRuleError && selectedBusinessRuleError.indexOf(c) >= 0)}
                                                                            checkedIcon={<DoneOutlineIcon />}
                                                                            color="default"
                                                                        />
                                                                    }
                                                                    label={c?.Name}
                                                                />
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                )}
                                            </Grid>
                                            {selectedBusinessRuleError.length > 0 &&
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <h6 className="form-title">Ignore Business Rules Reason</h6>
                                                    {onEdit ? (
                                                        <>
                                                            <textarea
                                                                type="text"
                                                                className="action-item-textarea w-100"
                                                                value={reason}
                                                                onChange={(e) => {
                                                                    setReason(e.target.value);
                                                                }}
                                                                rows={4}
                                                            />
                                                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                                                                {reasonValidationForm}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="textfield-div">
                                                            <span style={{ whiteSpace: "break-spaces" }}>{feed?.DisableBusinessRulesReason ?? ""}</span>
                                                        </div>
                                                    )}
                                                </Grid>
                                            }
                                        </>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Last Sent File</h6>
                                        <div className="textfield-div">
                                            {feed?.SelectedDate?.date ? Moment(feed?.SelectedDate?.date).format('MM/DD/YYYY') : null}
                                            <br />
                                            {feed?.SelectedDate?.fileName}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Last Modified Date</h6>
                                        <div className="textfield-div">
                                            {feed?.lastModifiedDate ? Moment(feed?.lastModifiedDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Last Modified By</h6>
                                        <div className="textfield-div">
                                            {feed?.lastModifiedBy}
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        {feed?.FeedMapTypeID !== 2 && (
                            <div className="mt-5" id="feed-oe">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel-header-feed-oe"
                                    >
                                        <span>Open Enrollment</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <h6 className="form-title">Plan Year Start Date</h6>
                                                <div className="textfield-div">
                                                    {feed?.PlanYearStartDate && feed?.PlanYearStartDate !== "0000-00-00 00:00:00" ? Moment(feed?.PlanYearStartDate).format('MM/DD/YYYY') : null}
                                                    <div className="ml-auto">
                                                        <svg width="14" height="16" viewBox="0 0 14 16">
                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <h6 className="form-title">Hold Plan Year Start Date</h6>
                                                {onEdit ? (
                                                    <FormControlLabel
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <Checkbox
                                                                value={enablePlanYearStartDate}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={enablePlanYearStartDate}
                                                                disabled={!planYearStartDate || planYearStartDate === "Invalid date"}
                                                                onClick={handleEnablePlanYearStartDateChange}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        }
                                                    />
                                                ) : (
                                                    <div className="textfield-div">
                                                        {feed?.EnablePlanYearStartDate === 0 ? "No" : feed?.EnablePlanYearStartDate === 1 ? "Yes" : "No"}
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )}
                        {feed?.FeedMapTypeID === 2 && (
                            <>
                                <div className="mt-5" id="feeds-forms-section">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel-header-digitized-forms"
                                        >
                                            <span>Digitized Forms</span>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ flexWrap: "wrap" }}>
                                            <div className="border-bottom w-100 mb-3">
                                                <AppBar position="static" color="default">
                                                    <div className="row">
                                                        <Tabs
                                                            className="new-tabs border-bottom"
                                                            value={value}
                                                            onChange={handleChange}
                                                            scrollButtons="auto"
                                                            aria-label="scrollable auto tabs"
                                                        >
                                                            <Tab label="Attached Forms" />
                                                            {!onEdit ? (
                                                                <CustomTooltip TransitionComponent={Zoom} title="Please click on 'Edit' first to enable this button">
                                                                    <span>
                                                                        <Tab label="+ Attach New" disabled />
                                                                    </span>
                                                                </CustomTooltip>
                                                            ) : (
                                                                <Tab label="+ Attach New" />
                                                            )}
                                                        </Tabs>
                                                    </div>
                                                </AppBar>
                                            </div>
                                            {value === 0 && (
                                                <AnalystFeedForms
                                                    feed={feed}
                                                    onMatchingAllPlansValidation={(s) => setMatchingAllPlansValidation(s)}
                                                />
                                            )}
                                            {value === 1 && (
                                                <div className="row mt-3 w-100">
                                                    <div style={{ display: "inline-grid", width: "100%" }}>
                                                        <h6 className="form-title">Digitized Forms</h6>
                                                        <FormControl label="Select Forms" focused={false} variant="outlined" className="w-100">
                                                            <Select
                                                                className="text-dropdown"
                                                                value={selectedForms}
                                                                onChange={e => {
                                                                    setSelectedForms([...e.target.value]);
                                                                    forceUpdate();
                                                                }}
                                                                variant="outlined"
                                                                multiple
                                                                // input={
                                                                //     <Input />
                                                                // }
                                                                renderValue={(selected) => selected.map(s => s?.Name).join(', ')}
                                                            >
                                                                {DFsList?.length <= 0 &&
                                                                    <MenuItem disabled onClick={() => { }}>
                                                                        No available forms
                                                                    </MenuItem>
                                                                }
                                                                {DFsList?.map((c, ci) => (
                                                                    <MenuItem key={`DFs-${ci}`} value={c}>
                                                                        <Checkbox checked={selectedForms.indexOf(c) > -1} />
                                                                        <ListItemText primary={c.Name} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    {selectedForms?.length > 0 && (
                                                        <div className="w-100 mt-3">
                                                            <div className="container-div">
                                                                {selectedForms?.map((f, fi) => (
                                                                    <div key={`selected-forms-${fi}`} className="forms-list m-3">
                                                                        <AnalystFeedFormAdd
                                                                            selectedForm={f}
                                                                            index={fi}
                                                                            onSelectFormMainTypesValidationForm={(s) => setSelectFormMainTypesValidationForm(s)}
                                                                            onSelectFormSubTypesValidationForm={(s) => setSelectFormSubTypesValidationForm(s)}
                                                                            requestSubmitted={requestSubmitted}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="mt-5 w-100" id="workflow-config">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel-header-digitized-forms"
                                        >
                                            <span>Workflow Configuration</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {onEdit ? (
                                                <>
                                                    <FormControlLabel
                                                        className="dependents-switch"
                                                        control={
                                                            <Switch
                                                                checked={employeeSignatureNeeded}
                                                                onChange={() => setemployeeSignatureNeeded(!employeeSignatureNeeded)}
                                                            />
                                                        }
                                                        label="Employee Signature Required"
                                                    />
                                                    <FormControlLabel
                                                        className="dependents-switch"
                                                        control={
                                                            <Switch
                                                                checked={hrSignatureNeeded}
                                                                onChange={() => setHrSignatureNeeded(!hrSignatureNeeded)}
                                                            />
                                                        }
                                                        label="HR Signature Required"
                                                    />
                                                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{HrSignatureRequiredValidation}</div>
                                                </>
                                            ) : (
                                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <h6 className="form-title">Employee Signature Required</h6>
                                                        <div className="textfield-div">
                                                            {feed?.WorkflowConfigurations[0]?.WorkflowConfigurationObject.TypeID == 3 ? "Yes" : "No"}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <h6 className="form-title">HR Signature Required</h6>
                                                        <div className="textfield-div">
                                                            {feed?.HrSignatureRequired == 1 ? "Yes" : "No"}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="mt-5 w-100" id="captured-changes">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel-header-captured-changes"
                                        >
                                            <span>Captured Changes</span>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ flexWrap: "wrap" }}>
                                            <AppBar position="static" color="default">
                                                <div className="row border-bottom">
                                                    <Tabs
                                                        className="new-tabs w-100"
                                                        value={tabDFElementValue}
                                                        onChange={handleDFElementTabChange}
                                                    >
                                                        <Tab label='Employees' {...a11yProps(0)} />
                                                        <Tab label='Dependents' {...a11yProps(1)} />
                                                        <Tab label='Employee Benefits' {...a11yProps(2)} />
                                                        <Tab label='Dependent Benefits' {...a11yProps(3)} />
                                                        <Tab label='Waived Coverages' {...a11yProps(4)} />
                                                        <Tab label='Beneficiary' {...a11yProps(5)} />
                                                    </Tabs>
                                                </div>
                                            </AppBar>
                                            <TabPanel value={tabDFElementValue} index={0} className="w-100">
                                                <div className="row plans-list-div p-4">
                                                    {feedsDfEmployeesElements.map((p, pi) => (
                                                        <React.Fragment key={`employees-df-elements-${pi}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={!onEdit}
                                                                        id={`employee-df-element-option-${pi}`}
                                                                        value={p}
                                                                        checked={!!selectedEmployeesDFElements?.find(x => x.Id === p.Id)}
                                                                        onClick={handleEmployeesDFElementClick(p)}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                    />}
                                                                label={p.ElementTitle}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabDFElementValue} index={1} className="w-100">
                                                <div className="row plans-list-div p-4">
                                                    {feedsDfDependentsElements.map((p, pi) => (
                                                        <React.Fragment key={`dependents-df-elements-${pi}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={!onEdit}
                                                                        id={`dependent-df-element-option-${pi}`}
                                                                        value={p}
                                                                        checked={!!selectedDependentsDFElements?.find(x => x.Id === p.Id)}
                                                                        onClick={handleDependentsDFElementClick(p)}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                    />}
                                                                label={p.ElementTitle}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabDFElementValue} index={2} className="w-100">
                                                <div className="row plans-list-div p-4">
                                                    {feedsDfEmployeeBenefitsElements.map((p, pi) => (
                                                        <React.Fragment key={`employee-benefits-df-elements-${pi}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={!onEdit}
                                                                        id={`employee-benefit-df-element-option-${pi}`}
                                                                        value={p}
                                                                        checked={!!selectedEmployeeBenefitsDFElements?.find(x => x.Id === p.Id)}
                                                                        onClick={handleEmployeeBenefitsDFElementClick(p)}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                    />}
                                                                label={p.ElementTitle}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabDFElementValue} index={3} className="w-100">
                                                <div className="row plans-list-div p-4">
                                                    {feedsDfDependentBenefitsElements.map((p, pi) => (
                                                        <React.Fragment key={`dependent-benefits-df-elements-${pi}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={!onEdit}
                                                                        id={`dependent-benefit-df-element-option-${pi}`}
                                                                        value={p}
                                                                        checked={!!selectedDependentBenefitsDFElements?.find(x => x.Id === p.Id)}
                                                                        onClick={handleDependentBenefitsDFElementClick(p)}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                    />}
                                                                label={p.ElementTitle}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabDFElementValue} index={4} className="w-100">
                                                <div className="row plans-list-div p-4">
                                                    {feedsDfWaivedCoveragesElements.map((p, pi) => (
                                                        <React.Fragment key={`waived-coverages-df-elements-${pi}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={!onEdit}
                                                                        id={`waived-coverages-df-element-option-${pi}`}
                                                                        value={p}
                                                                        checked={!!selectedWaivedCoveragesDFElements?.find(x => x.Id === p.Id)}
                                                                        onClick={handleWaivedCoveragesDFElementClick(p)}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                    />}
                                                                label={p.ElementTitle}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </TabPanel>
                                            <TabPanel value={tabDFElementValue} index={5} className="w-100">
                                                <div className="row plans-list-div p-4">
                                                    {feedsDfBeneficiaryElements.map((p, pi) => (
                                                        <React.Fragment key={`beneficiary-df-elements-${pi}`}>
                                                            <FormControlLabel
                                                                aria-label="Acknowledge"
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={!onEdit}
                                                                        id={`beneficiary-df-element-option-${pi}`}
                                                                        value={p}
                                                                        checked={!!selectedBeneficiaryDFElements?.find(x => x.Id === p.Id)}
                                                                        onClick={handleBeneficiaryDFElementClick(p)}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                    />}
                                                                label={p.ElementTitle}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </TabPanel>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className="mt-5 w-100" id="dictionary">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel-header-dictionary"
                                        >
                                            <span>Dictionary</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AnalystFeedFormDictionary
                                                feed={feed}
                                                onEdit={onEdit}
                                                dictionaryList={dictionaryList}
                                                onDictionaryList={d => setDictionaryList(d)}
                                                parentForceUpdate={forceUpdate}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </>
                        )}

                        {/* Create Transmission Protocol */}
                        {addTransmissionProtocolClicked && (
                            <div className="mt-5" id="transmission-config">
                                <Accordion defaultExpanded={addTransmissionProtocolClicked}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel-header-dictionary"
                                    >
                                        <span>Transmission Protocol</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AnalystCreateTransmissionProtocol
                                            onTransmissionTypeChange={f => setFeedTransmissionType(f)}
                                            onProtocolTypeChange={f => setFeedProtocolType(f)}
                                            onSSHKeyChange={f => setFeedSSHKey(f)}
                                            onSFTPSiteChange={f => setFeedSFTPSite(f)}
                                            onSFTPPortChange={f => setFeedSFTPPort(f)}
                                            onSFTPUsernameChange={f => setFeedSFTPUsername(f)}
                                            onSFTPPasswordChange={f => setFeedSFTPPassword(f)}
                                            onSFTPDirectoryChange={f => setFeedSFTPDirectory(f)}
                                            transmissionTypeValidationForm={transmissionTypeValidationForm}
                                            sftpProtocolTypeValidationForm={sftpProtocolTypeValidationForm}
                                            sftpSSHKeyValidationForm={sftpSSHKeyValidationForm}
                                            sftpSiteValidationForm={sftpSiteValidationForm}
                                            sftpPortValidationForm={sftpPortValidationForm}
                                            sftpUsernameValidationForm={sftpUsernameValidationForm}
                                            sftpPasswordValidationForm={sftpPasswordValidationForm}
                                            sftpDirectoryValidationForm={sftpDirectoryValidationForm}
                                            requestSubmitted={requestSubmitted}
                                            feedsTransmissionTypes={feedsTransmissionTypes}
                                            feedsProtocolTypes={feedsProtocolTypes}
                                            feedSSHKeys={feedSSHKeys}
                                            toEmail={toEmailTransmission}
                                            setToEmail={setToEmailTransmission}
                                            ccEmail={ccEmailTransmission}
                                            setCcEmail={setCcEmailTransmission}
                                            bccEmail={bccEmailTransmission}
                                            setBccEmail={setBccEmailTransmission}
                                            emailSubject={emailSubjectTransmission}
                                            setEmailSubject={setEmailSubjectTransmission}
                                            carrierEmailSubjectValidationForm={carrierEmailSubjectValidationForm}
                                            emailBody={emailBodyTransmission}
                                            setEmailBody={setEmailBodyTransmission}
                                            carrierEmailBodyValidationForm={carrierEmailBodyValidationForm}
                                            carrierEmailToEmailValidationForm={carrierEmailToEmailValidationForm}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )}

                        {/* View - Edit Transmission Protocol */}
                        {(!feed?.FeedMapTypeID || feed?.FeedMapTypeID === 1) && (
                            (feed?.TransmissionTypeID !== null) && (
                                <div className="mt-5" id="transmission-config">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel-header-dictionary"
                                        >
                                            <span>Transmission Protocol</span>
                                            {!onEdit && feed?.TransmissionTypeID == 2 && (
                                                <Button variant="contained" className="ml-auto blue-btn" onClick={testTransmissionConnection}>
                                                    <label style={{ cursor: "pointer" }} className="mb-0">Test Connection</label>
                                                </Button>
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AnalystFeedTransmissionProtocol
                                                feed={feed}
                                                transmissionProtocolObject={transmissionProtocolObject}
                                                feedsTransmissionTypes={feedsTransmissionTypes}
                                                feedsProtocolTypes={feedsProtocolTypes}
                                                feedSSHKeys={feedSSHKeys}
                                                onEdit={onEdit}
                                                onTransmissionTypeChange={f => setFeedTransmissionType(f)}
                                                onProtocolTypeChange={f => setFeedProtocolType(f)}
                                                onSSHKeyChange={f => setFeedSSHKey(f)}
                                                onSFTPSiteChange={f => setFeedSFTPSite(f)}
                                                onSFTPPortChange={f => setFeedSFTPPort(f)}
                                                onSFTPUsernameChange={f => setFeedSFTPUsername(f)}
                                                onSFTPPasswordChange={f => setFeedSFTPPassword(f)}
                                                onSFTPDirectoryChange={f => setFeedSFTPDirectory(f)}
                                                transmissionTypeValidationForm={transmissionTypeValidationForm}
                                                sftpProtocolTypeValidationForm={sftpProtocolTypeValidationForm}
                                                sftpSiteValidationForm={sftpSiteValidationForm}
                                                sftpPortValidationForm={sftpPortValidationForm}
                                                sftpUsernameValidationForm={sftpUsernameValidationForm}
                                                sftpPasswordValidationForm={sftpPasswordValidationForm}
                                                sftpDirectoryValidationForm={sftpDirectoryValidationForm}
                                                sftpSSHKeyValidationForm={sftpSSHKeyValidationForm}
                                                requestSubmitted={requestSubmitted}
                                                toEmailTransmission={toEmailTransmission}
                                                setToEmailTransmission={setToEmailTransmission}
                                                ccEmailTransmission={ccEmailTransmission}
                                                setCcEmailTransmission={setCcEmailTransmission}
                                                bccEmailTransmission={bccEmailTransmission}
                                                setBccEmailTransmission={setBccEmailTransmission}
                                                emailSubjectTransmission={emailSubjectTransmission}
                                                setEmailSubjectTransmission={setEmailSubjectTransmission}
                                                carrierEmailSubjectValidationForm={carrierEmailSubjectValidationForm}
                                                emailBodyTransmission={emailBodyTransmission}
                                                setEmailBodyTransmission={setEmailBodyTransmission}
                                                carrierEmailBodyValidationForm={carrierEmailBodyValidationForm}
                                                carrierEmailToEmailValidationForm={carrierEmailToEmailValidationForm}
                                                transmissionEmailObject={transmissionEmailObject}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )
                        )}

                        {/* Create Scheduler */}
                        {addSchedulerClicked && (
                            <AnalystCreateSchedulers
                                feedSchedulers={feedSchedulers}
                                onSetFeedSchedulers={setFeedSchedulers}
                                schedulersValidationForm={schedulersValidationForm}
                                onSetSchedulersValidationForm={setSchedulersValidationForm}
                                requestSubmitted={requestSubmitted}
                                feedStatusID={feed.FeedStatusID}
                            />
                        )}

                        {/* View - Edit Scheduler */}
                        {onEdit && schedulerObject?.Schedules &&
                            <AnalystCreateSchedulers
                                feedSchedulers={feedSchedulers}
                                onSetFeedSchedulers={setFeedSchedulers}
                                schedulersValidationForm={schedulersValidationForm}
                                onSetSchedulersValidationForm={setSchedulersValidationForm}
                                requestSubmitted={requestSubmitted}
                                feedStatusID={feed.FeedStatusID}
                                onEdit={onEdit}
                            />
                        }
                        {(schedulerObject?.Schedules && schedulerObject?.Schedules?.length > 0 && !onEdit && (
                            <div id="scheduler-config" className="mt-5">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel-header-scheduler-view"
                                    >
                                        <span>Scheduler Configuration</span>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ flexWrap: "wrap" }}>
                                        {schedulerObject?.Schedules?.map((s, si) => (
                                            <AnalystViewScheduler key={`edit-scheduler-${si}`} index={si} scheduler={s} feedStatusID={feed.FeedStatusID} />
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))}

                        {(addTransmissionProtocolClicked || addSchedulerClicked || onEdit) && (
                            <div className="row w-100 mt-5" style={{ justifyContent: "flex-end" }}>
                                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={handleCancelButton}>Cancel</Button>
                                <Button variant="contained" className="blue-btn" type="submit">Submit</Button>
                            </div>
                        )}
                    </form >

                    {!onEdit && (
                        <div hidden>
                            {/* Activity Logs */}
                            <div className="row mt-5 w-100 alignvh-center">
                                <h5 className="black1 fw-700 mt-5">Activity Logs</h5>
                            </div>
                            <Divider className="mt-3 mb-5" />
                            {/* For Empty Activity Logs */}
                            <div style={{ background: "rgba(237, 234, 234, 0.4)", padding: "20px", textAlign: "center" }}>
                                <svg viewBox="0 0 190 183" width="250px" height="250px">
                                    <use xlinkHref={`${EmptyActivityLogsSVG}#emptyActivityLogs`}></use>
                                </svg>
                            </div>
                            {/* <ul className="activity-log-list" style={{ listStyleType: "none" }}>
                                <li>Map XYZ attached to the feed</li>
                                <li>Structure ABC attached to the feed</li>
                                <li>Scheduler updated to run every Monday at 10:00 AM</li>
                            </ul> */}
                        </div>
                    )
                    }

                    {!addTransmissionProtocolClicked && !addSchedulerClicked && feedConnectivities && feedConnectivities.length > 0 && (
                        <div id="scheduler-config" className="mt-5">
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel-header-crs"
                                >
                                    <span>Feed Connectivity Requests</span>
                                </AccordionSummary>
                                <AccordionDetails className="no-padding-accordion-details">
                                    <FeedConnectivitiesList feedConnectivities={feedConnectivities} />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )}
                </Paper>
            )}
            

            {tabValue === 1 && (
                feed?.FeedMapTypeID === 2 ? // FeedMapTypeID === 2 is for the Forms Feed type
                    <AnalystSingleFeedFormsTransmission feedID={feed?.FeedID} carrierID={feed?.CarrierID} feed={feed} />
                :
                    <AnalystSingleFeedTransmission feedID={feed?.FeedID} carrierID={feed?.CarrierID} feed={feed} />
            )}
        </div >
    )
}

export default AnalystFeedConfig;
