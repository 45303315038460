import React from "react";
import { useParams } from "react-router-dom";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import EditRequestSVG from "../../Images/editRequest.svg";
import { Paper, Button, Divider, FormControl, Select, MenuItem, Switch, FormControlLabel } from "@material-ui/core";
import TabsTitles from "../Layout/TabsTitles";
import BreadcrumbSteps from "../Layout/BreadcrumbSteps";
import { FailureModal } from "../Modals/FailureModal";
import { SuccessModal } from "../Modals/SuccessModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { useAuthentication } from "../../Data/Authentication";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { ConfirmModal } from "../Modals/ConfirmModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import plusIconSVG from "../../Icons/PlusIconWhite.svg";
import infoCircleSVG from "../../Icons/info-circle.svg";
import editRequestIcon from "../../Icons/editIcon.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';

function ViewUser() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const { userIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [onEdit, setOnEdit] = React.useState(false);
    const [user, setUser] = React.useState("");
    const [submitClicked, setSubmitClicked] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [validPhone, setValidPhone] = React.useState(false);
    const [rolesList, setRolesList] = React.useState([]);
    const [roleEntitiesList, setRoleEntitiesList] = React.useState([]);
    const [role, setRole] = React.useState("");
    const [entity, setEntity] = React.useState("");
    const [processFeedAccess, setProcessFeedAccess] = React.useState(false);
    const [connectivityRequestApprove, setConnectivityRequestApprove] = React.useState(false);

    // Validations
    const [firstNameValidation, setFirstNameValidation] = React.useState("");
    const [lastNameValidation, setLastNameValidation] = React.useState("");
    const [phoneNumberValidation, setPhoneNumberValidation] = React.useState("");
    const [roleValidation, setRoleValidation] = React.useState("");
    const [entityValidation, setEntityValidation] = React.useState("");

    React.useEffect(() => {
        if (!firstName) setFirstNameValidation("First name is missing, please write the User's first name."); else setFirstNameValidation("");
    }, [firstName, firstNameValidation])
    React.useEffect(() => {
        if (!lastName) setLastNameValidation("Last name is missing, please write the User's last name."); else setLastNameValidation("");
    }, [lastName, lastNameValidation])
    React.useEffect(() => {
        if (phone && !validPhone) setPhoneNumberValidation("Invalid phone number, please enter a valid phone number"); else setPhoneNumberValidation("");
    }, [phone, phoneNumberValidation])
    React.useEffect(() => {
        if (!role) setRoleValidation("User's role is missing, please select a role for the User."); else setRoleValidation("");
    }, [role, roleValidation])
    React.useEffect(() => {
        if (!entity && (role === 5 || role === 6)) setEntityValidation("User's role entity is missing, please select an entity for the User's role."); else setEntityValidation("");
    }, [entity, role, entityValidation])
    //  if (iSolvedSuperAmin or superAdmin) connectivityRequestApprove=true 
    React.useEffect(()=>{
        (role === 7 || role === 2) && setConnectivityRequestApprove(true);
    },[role])
    React.useEffect(() => {
        openLoader();
        APIs.getUserDetails(userIDURL).then((r) => setUser(r?.data));
    }, [])

    React.useEffect(() => {
        if (user) closeLoader();
        else openLoader();
    }, [user])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getAllRoles().then((r) => (
            setRolesList([...r?.data]),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getRoleEntities(role).then((r) => (
            setRoleEntitiesList(r?.data),
            setEntity(""),
            closeLoader()
        ));
    }, [role])

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }

    const enableDisableUser = () => {
        openLoader();
        let userRequestBody;
        if (user.IsEnabled === 1) {
            userRequestBody = {
                UserID: userIDURL,
                IsEnabled: 0
            }
        } else {
            userRequestBody = {
                UserID: userIDURL,
                IsEnabled: 1
            }
        }
        APIs.enableDisableUsers(userRequestBody).then(r => {
            window.location.reload();
        })
    }

    const resetMFA = () => {
        openLoader();
        APIs.resetUserMFA({ Email: user.Email }).then(r => {
            closeLoader();
        })
    }

    const resetUserPassword = () => {
        openLoader();
        APIs.resetUserPassword({ Email: user.Email }).then(r => {
            if (r?.success === "1") {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Your request has been processed successfully, the new password has been sent by email to the user.`,
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: `The request has not been processed successfully. Please try again!`,
                });
            }
            closeLoader();
        })
    }


    let returnUserRolesIDs = () => {
        let userRoleIDs = []
        user?.RolesEntities.map(userRoleEntity => userRoleIDs.push(userRoleEntity.Role.Id))
        return userRoleIDs
    }

    let setRoleHandler = () => {
        let userRoleIDs = returnUserRolesIDs();
        // compare two userRoleIDs with [3,4] to set Role (Admin Direct, Admin Network (99))

        if (userRoleIDs.includes(3) && userRoleIDs.includes(4)) {
            setRole(99);
        } else {
            setRole(user?.RolesEntities && user?.RolesEntities[0]?.Role?.Id);
        }
    }

    React.useEffect(() => {
        if (user) {
            setFirstName(user?.FirstName ?? "");
            setLastName(user?.LastName ?? "");
            setRoleHandler(); //decide if the user  role is (Admin Direct and Admin Network)  or single role
            setEntity(user?.RolesEntities && user?.RolesEntities[0]?.Role?.Entities[0]?.Id);
            setPhone(user?.PhoneNumber ?? "");
            let userRoleIDs = returnUserRolesIDs();
            if (userRoleIDs.includes(9)) setProcessFeedAccess(true)
            if (userRoleIDs.includes(11)) setConnectivityRequestApprove(true)
        }
    }, [onEdit === true, user])
    const handleRoleChange = (e) => {
        setRole(e.target.value)
        setConnectivityRequestApprove(false)
    }
    const submitUpdatedUser = (e) => {
        e.preventDefault();
        let updateUserObject = {};
        if (submitClicked) {
            if (firstNameValidation || lastNameValidation || roleValidation || phoneNumberValidation || entityValidation) return;
            if (entity) {
                updateUserObject = {
                    CognitoID: user?.CognitoID,
                    FirstName: firstName,
                    LastName: lastName,
                    PhoneNumber: phone?.replaceAll(" ", "").replaceAll("-", ""),
                    RolesEntities: [
                        {
                            RoleID: role,
                            EntityIDs: [entity]
                        }
                    ]
                }
            } else {
                updateUserObject = {
                    CognitoID: user?.CognitoID,
                    FirstName: firstName,
                    LastName: lastName,
                    PhoneNumber: phone?.replaceAll(" ", "").replaceAll("-", ""),
                    RolesEntities: []
                }
                // 99 >> Admin Direct , Admin Network (new Role)
                if (role !== 99) {
                    updateUserObject?.RolesEntities.push({ RoleID: role })
                } else {
                    updateUserObject?.RolesEntities.push({ RoleID: 3 }, { RoleID: 4 })
                }
            }
            {/* who can edit this column super admin (7) and isolved super admin (2) */ }
            if ((userRoles?.includes("2") || userRoles?.includes("7"))) {
                if (processFeedAccess) {
                    // Role 9 >> hidden Role for Process Feed
                    updateUserObject?.RolesEntities.push({ RoleID: 9 })
                }
                if (connectivityRequestApprove) {
                    updateUserObject?.RolesEntities.push({ RoleID: 11 })
                }
            } else {
                // handle if the user has already Process Feed but the current user doesn't see this field
                if (returnUserRolesIDs().includes(9)) updateUserObject?.RolesEntities.push({ RoleID: 9 })
            }
        }

        APIs.editUser(updateUserObject).then((r) => {
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            } else {
                window.location.reload();
            }
        }).catch((s) => FailureModal(dialog, {
            title: 'Failure',
            body: 'Error processing request',
        })).finally((t) => closeLoader());
    }

    return (
        <div id="connect-req-config">
            {user && (
                <BreadcrumbSteps
                    breadcrumbTitleCurrent={`User #${userIDURL}`}
                    isCurrent={true}
                />
            )}
            <div>
                <TabsTitles tabTitle={["View User"]} />
                <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                    <Button className="add-request blue-btn" style={{ marginTop: "-54px" }} href={`/Users/CreateUser`}>
                        <svg width="12" height="12" viewBox="0 0 12 12" className="mr-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg>
                        User
                    </Button>
                </div>
            </div>

            <Paper elevation={0} className="p-5">

                <form onSubmit={submitUpdatedUser}>
                    <div className="row w-100 alignvh-center">
                        {/* <h5 className="black1 fw-700">User information</h5> */}
                        <div className="ml-auto">
                            {/* Reset password button only shown for superadmins and iSolved superadmin */}
                            {(userRoles?.includes("7") ||
                                userRoles?.includes("2")) && (
                                    <>
                                        <Button variant="contained" className="blue-outlined-btn mr-2" onClick={(e) => {
                                            openModal(0, resetUserPassword, {
                                                title: 'Please Confirm',
                                                question: `Are you sure you want to reset the Password for this user?`,
                                            });
                                        }}>

                                            <label style={{ cursor: "pointer" }} className="mb-0">Reset Password</label>
                                        </Button>
                                    </>
                                )
                            }

                            {/* Edit User button only shown for superadmins in case of client user */}
                            {/* role 6 (client selected) 
                         userRoles?.includes("7") >> logged in user is Super Admin
                        */}
                            {(role !== 6 || (role === 6 && userRoles?.includes("7"))) && (
                                <>
                                    <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => { setOnEdit(!onEdit); }}>
                                        <svg width="20" height="24" viewBox="0 0 25 24">
                                            <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                        </svg>
                                        <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit User</label>
                                    </Button>
                                </>
                            )
                            }

                            {(userActions?.includes("56") ||
                                userActions?.includes("20")) && (
                                    <>
                                        <Button variant="contained" className="blue-outlined-btn mr-2" onClick={(e) => {
                                            openModal(0, resetMFA, {
                                                title: 'Please Confirm',
                                                question: `Are you sure you want to reset the MFA for this user?`,
                                            });
                                        }}>
                                            <label style={{ cursor: "pointer" }} className="mb-0">Reset MFA</label>
                                        </Button>
                                        <CustomTooltip TransitionComponent={Zoom} title={`${user.IsEnabled === 1 ? 'Disable' : 'Enable'} User`}>
                                            <Button variant="contained" className={user.IsEnabled === 1 ? 'toggle-on-btn' : 'toggle-off-btn'} onClick={enableDisableUser}>
                                                <label style={{ cursor: "pointer" }} className="mb-0">{user.IsEnabled === 1 ? `Enabled User` : `Disabled User`}</label>
                                            </Button>
                                        </CustomTooltip>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    {/* <Divider className="mt-3 mb-5" /> */}
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            aria-controls="panel2-content"
                            id="panel-header-cr-config"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            style={{ pointerEvents: 'none' }}
                        >
                            <span>User information</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {/* <div className="row"> */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">First Name</h6>


                                    {onEdit ? (
                                        <>
                                            <input type="text" className="text-input w-100" value={firstName} onChange={handleFirstNameChange} />
                                            <div className="mt-2 text-danger" hidden={!submitClicked}>{firstNameValidation}</div>
                                        </>
                                    ) : (
                                        <div className="textfield-div">
                                            {user?.FirstName}
                                        </div>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Last Name</h6>

                                    {onEdit ? (
                                        <>
                                            <input type="text" className="text-input w-100" value={lastName} onChange={handleLastNameChange} />
                                            <div className="mt-2 text-danger" hidden={!submitClicked}>{lastNameValidation}</div>
                                        </>
                                    ) : (
                                        <div className="textfield-div">
                                            {user?.LastName}
                                        </div>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Email</h6>

                                    <div className="textfield-div">
                                        {user?.Email}
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Phone Number</h6>

                                    {onEdit ? (
                                        <>
                                            <div className="text-input w-100">
                                                <IntlTelInput
                                                    defaultValue={phone ?? ""}
                                                    autoPlaceholder={false}
                                                    preferredCountries={["us", "eg"]}
                                                    onPhoneNumberChange={(checkValid, n, c, number) => {
                                                        setValidPhone(checkValid);
                                                        setPhone(number);
                                                    }}
                                                    format={false}
                                                />
                                            </div>
                                            <div className="mt-2 text-danger" hidden={!submitClicked}>{phoneNumberValidation}</div>
                                        </>
                                    ) : (
                                        <div className="textfield-div">
                                            {user?.PhoneNumber}
                                        </div>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Role</h6>

                                    {onEdit ? (
                                        <>
                                            <FormControl label="Select Role" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={role ?? ""}
                                                    onChange={(e) => handleRoleChange(e)}
                                                >
                                                    {rolesList?.map((s, si) => (
                                                        <MenuItem key={`roles-${si}`} value={s?.Id}>{s?.Name}</MenuItem>
                                                    ))}
                                                    {
                                                        // 61 for (iSolved Super Admin , Admin Direct and Admin Network)
                                                        (userActions?.includes("20") || userActions?.includes("61")) &&
                                                        (<MenuItem value={99}>Admin Direct , Admin Network</MenuItem>)
                                                    }

                                                </Select>
                                            </FormControl>
                                            <div className="mt-2 text-danger" hidden={!submitClicked}>{roleValidation}</div>
                                        </>
                                    ) : (
                                        <div className="textfield-div">
                                            {user?.RolesEntities && user?.RolesEntities.map((userRoleEntity, userRoleEntityIndex) => {
                                                // Role.Id is 9 (Process Feed Role) && Role.Id is 11 (connectivity request approval) (hidden Role)
                                                if (userRoleEntity.Role.Id !== 9 && userRoleEntity.Role.Id !== 11) {
                                                    return (userRoleEntityIndex !== 0 ? ', ' : '') + userRoleEntity.Role.Name
                                                }
                                            }).join('')}
                                        </div>
                                    )}
                                </Grid>

                                {(user?.RolesEntities && (user?.RolesEntities[0]?.Role?.Id === 5 || user?.RolesEntities[0]?.Role?.Id === 6)) && !onEdit && (
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">{user?.RolesEntities && user?.RolesEntities[0]?.Role?.Id === 5 ? "Partner" : "Client"}</h6>

                                            <div className="textfield-div">
                                                {user?.RolesEntities && user?.RolesEntities[0]?.Role?.Entities[0]?.Name}
                                            </div>
                                        </Grid>
                                    </>
                                )}

                                {(role === 5 || role === 6) && onEdit && (
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">{role === 5 ? "Partner" : "Client"}</h6>

                                            <FormControl label="Select Role" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={entity ?? ""}
                                                    onChange={(e) => setEntity(e.target.value)}
                                                >
                                                    {roleEntitiesList?.map((s, si) => (
                                                        <MenuItem key={`roles-${si}`} value={s?.EntityID}>{s?.EntityName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <div className="mt-2 text-danger" hidden={!submitClicked}>{entityValidation}</div>
                                        </Grid>
                                    </>
                                )}


                                {/* who can edit this column super admin (7) and isolved super admin (2) */}
                                {(userRoles?.includes("2") || userRoles?.includes("7")) &&
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Process Feed Access
                                                {onEdit && (
                                                    <CustomTooltip style={{ marginLeft: "10px" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please note by enabling this feature the user will be able to process production feeds and send production files to the carriers</div>}>
                                                        {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                                        <svg width="20" height="24" viewBox="0 0 25 24" >
                                                            <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                                        </svg>
                                                    </CustomTooltip>
                                                )}
                                            </h6>

                                            {onEdit ? (
                                                <>
                                                    <FormControlLabel
                                                        className="mt--2"
                                                        control={
                                                            <Switch
                                                                checked={processFeedAccess}
                                                                onChange={(e) => setProcessFeedAccess(e.target.checked)}
                                                            />
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <div className="textfield-div">
                                                    {user && returnUserRolesIDs().includes(9) == true ? 'Yes' : 'No'}
                                                </div>
                                            )}
                                        </Grid>
                                    </>}

                                {/* who can edit this column super admin (7) and isolved super admin (2) */}
                                {/* showing the connectivity request flag only for the roles only able to add (super admin ,isolved super admin and admin network) */}
                                {(userRoles?.includes("2") || userRoles?.includes("7"))
                                    && (role === 2 || role === 7 || role === 4 || role === 99)
                                    && <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Connectivity Request Approver
                                                {onEdit && (
                                                    <CustomTooltip style={{ marginLeft: "10px" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please note by enabling this feature the user will be able to process production feeds and send production files to the carriers</div>}>
                                                        {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                                        <svg width="20" height="24" viewBox="0 0 25 24" >
                                                            <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                                        </svg>
                                                    </CustomTooltip>
                                                )}
                                            </h6>

                                            {onEdit ? (
                                                <>
                                                    <FormControlLabel
                                                        className="mt--2"
                                                        control={
                                                            <Switch
                                                                disabled={(role===7 || role===2)?true:authUser?.attributes["email"]===user.Email?true:false}
                                                                checked={connectivityRequestApprove}
                                                                onChange={(e) => setConnectivityRequestApprove(e.target.checked)}
                                                            />
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <div className="textfield-div">
                                                    {user && returnUserRolesIDs().includes(11) == true ? 'Yes' : 'No'}
                                                </div>
                                            )}
                                        </Grid>
                                    </>}

                                {onEdit && (
                                    <div className="row w-100 mt-4" style={{ justifyContent: "flex-end" }}>
                                        <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => { setOnEdit(false); }}>Cancel</Button>
                                        <Button type="submit" variant="contained" className="blue-btn" onClick={() => setSubmitClicked(true)}>Submit</Button>
                                    </div>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </form >
            </Paper >
        </div >
    )
}

export default ViewUser;
