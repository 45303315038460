import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import SelectSingleConnectivityRequestSubType from "./SelectSingleConnectivityRequestSubType";
import FormsAttachments from "../../FormsAttachments/FormsAttachments";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SelectSingleConnectivityRequestType({
    carrier,
    index,
    selectedCRTypes,
    subTypeList,
    setSubTypeList,
    formsSubTypeList,
    setFormsSubTypeList,
    selectCRHREmailValidationForm,
    requestSubmitted,
    parentForceUpdate,
    isPEOClicked,
    attachFormsValidationForm,
    selectedCarriers,
    requestSaved,
    ...props
}) {

    // const [subTypeList, setSubTypeList] = React.useState([]);

    // React.useEffect(() => {
    //     APIs.getConnectivityRequestEDISubTypes().then((r) => {
    //         setSubTypeList(r?.data);
    //     });
    // }, [])



    const handleCRTypeClick = (value, f) => (event) => {
        let selectedCRTypes = f.SelectedCRTypes ?? []
        if (!value || value === "") selectedCRTypes = [];
        else if (selectedCRTypes?.indexOf(value) >= 0) {
            selectedCRTypes.splice(selectedCRTypes?.indexOf(value), 1);
        }
        else {
            selectedCRTypes.push(value);
        }
        decideCRSubType(selectedCRTypes, f)
        f.SelectedCRTypes = ([...selectedCRTypes]);
        parentForceUpdate();
    }

    const decideCRSubType = (selectedCRTypes, f) => {
        if (!selectedCRTypes.includes("EDI")) {
            f.ConnectivityRequestEDISubTypeID = ""
            if (f.ConnectivityRequestFormsSubTypeID === "" || f.ConnectivityRequestFormsSubTypeID == null) {
                f.ConnectivityRequestFormsSubTypeID = ""
            }
        }
        else if (!selectedCRTypes.includes("Forms")) {
            f.ConnectivityRequestFormsSubTypeID = ""
            f.HREmail = ""
            if (f.ConnectivityRequestEDISubTypeID === "" || f.ConnectivityRequestEDISubTypeID == null) {
                f.ConnectivityRequestEDISubTypeID = 1 //EDI Sub Type -> Regular
            }
        }
        else {
            if (f.ConnectivityRequestEDISubTypeID === "" || f.ConnectivityRequestEDISubTypeID == null) {
                f.ConnectivityRequestEDISubTypeID = 1 //EDI Sub Type -> Regular
            }
        }
    }

    return (
        <TabPanel value={index} index={index}  >
            <div className="row">
                <React.Fragment>
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                id={`edi-checkbox`}
                                value="EDI"
                                disabled={carrier.SelectedCRTypes && carrier.SelectedCRTypes.indexOf("No Preference") >= 0}
                                // checked={isPEOCliced || (carrier.SelectedCRTypes && carrier.SelectedCRTypes.indexOf("EDI") >= 0 ? true : false)} commented as it appears always clicked even if array is empty, remove isPEOclicked condition
                                checked={(carrier.SelectedCRTypes && carrier.SelectedCRTypes.indexOf("EDI") >= 0 ? true : false)}
                                onClick={handleCRTypeClick("EDI", carrier)}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<DoneOutlineIcon />}
                            />}
                        label="EDI"
                    />
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                id={`forms-checkbox`}
                                value="Forms"
                                disabled={isPEOClicked || (carrier.SelectedCRTypes && carrier.SelectedCRTypes.indexOf("No Preference") >= 0)}
                                checked={carrier.SelectedCRTypes && carrier.SelectedCRTypes.indexOf("Forms") >= 0 ? true : false}
                                onClick={handleCRTypeClick("Forms", carrier)}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<DoneOutlineIcon />}
                            />}
                        label="Forms"
                    />
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                id={`no-preference-checkbox`}
                                value="No Preference"
                                disabled={isPEOClicked || (carrier.SelectedCRTypes && (carrier.SelectedCRTypes.indexOf("EDI") >= 0 || carrier.SelectedCRTypes.indexOf("Forms") >= 0))}
                                checked={carrier.SelectedCRTypes && carrier.SelectedCRTypes.indexOf("No Preference") >= 0 ? true : false}
                                onClick={handleCRTypeClick("No Preference", carrier)}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<DoneOutlineIcon />}
                            />}
                        label="No Preference"
                    />
                </React.Fragment>
            </div>
            {carrier.SelectedCRTypes && (carrier?.SelectedCRTypes?.includes("EDI") || carrier?.SelectedCRTypes?.includes("Forms")) &&
                <SelectSingleConnectivityRequestSubType
                    carrier={carrier}
                    selectedCRTypes={carrier?.SelectedCRTypes}
                    subTypeList={subTypeList}
                    setSubTypeList={setSubTypeList}
                    formsSubTypeList={formsSubTypeList}
                    setFormsSubTypeList={setFormsSubTypeList}
                    selectCRHREmailValidationForm={selectCRHREmailValidationForm}
                    requestSubmitted={requestSubmitted}
                    parentForceUpdate={parentForceUpdate}
                    attachFormsValidationForm={attachFormsValidationForm}
                />}

            {(
                carrier.SelectedCRTypes && carrier?.SelectedCRTypes?.includes("Forms")) &&
                <FormsAttachments
                    selectedCarriers={selectedCarriers}
                    selectedCarrier={carrier}
                    requestSubmitted={requestSubmitted}
                    requestSaved={requestSaved}
                    // onSelectFormMainTypesValidationForm={(s) => setSelectFormMainTypesValidationForm(s)}
                    // selectFormMainTypesValidationForm={selectFormMainTypesValidationForm}
                    // onSelectFormSubTypesValidationForm={(s) => setSelectFormSubTypesValidationForm(s)}
                    // selectFormSubTypesValidationForm={selectFormSubTypesValidationForm}
                    attachFormsValidationForm={attachFormsValidationForm}
                    parentForceUpdate={parentForceUpdate}
                    index={index}
                />
            }
        </TabPanel>
    )
}

export default SelectSingleConnectivityRequestType;
