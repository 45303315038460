import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, FormControl } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { useAuthentication } from "../../../../Data/Authentication";
import GenericDatePicker from "../../../Layout/GenericDatePicker";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


function AnalystTransmissionsFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onPageSizeAdv,
    onSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    onCloseClearReset,
    onSelectedFeedStatusAdvFilter,
    onSelectedFeedPhaseAdvFilter,
    onExecutionIDAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    onSelectedProcessingStepAdvFilter,
    onRequestFromDateAdvFilter,
    onRequestToDateAdvFilter,
    onSentFromDateAdvFilter,
    onSentToDateAdvFilter,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);

    const [selectedFeedStatusAdv, setSelectedFeedStatusAdv] = React.useState([]);
    const [feedsStatusesList, setFeedsStatusesList] = React.useState([]);
    const [selectedFeedPhaseAdv, setSelectedFeedPhaseAdv] = React.useState([]);
    const [feedsPhasesList, setFeedsPhasesList] = React.useState([]);
    const [executionIDAdv, setExecutionIDAdv] = React.useState("");
    const [selectedExecutionStatusAdv, setSelectedExecutionStatusAdv] = React.useState([]);
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);
    const [selectedProcessingStepAdv, setSelectedProcessingStepAdv] = React.useState([]);
    const [processingStepsList, setProcessingStepsList] = React.useState([]);
    const [requestFromDateAdv, setRequestFromDateAdv] = React.useState(sixMonthDate);
    const [requestToDateAdv, setRequestToDateAdv] = React.useState(todayDate);
    const [sentFromDateAdv, setSentFromDateAdv] = React.useState(null);
    const [sentToDateAdv, setSentToDateAdv] = React.useState(null);

    React.useEffect(() => {
        APIs.getFeedStatuses().then((r) => (!(r?.length) ? setFeedsStatusesList([]) : setFeedsStatusesList(r)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedPhases().then((r) => (!(r?.data?.length) ? setFeedsPhasesList([]) : setFeedsPhasesList(r.data)));
    }, [])

    React.useEffect(() => {
        APIs.getExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getProcessingStepsList().then((r) => setProcessingStepsList(r?.data));
    }, [])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleClearSearch = () => {
        setSelectedFeedStatusAdv([]);
        onSelectedFeedStatusAdvFilter([]);
        setSelectedFeedPhaseAdv([]);
        onSelectedFeedPhaseAdvFilter([]);
        setExecutionIDAdv("");
        onExecutionIDAdvFilter("");
        setSelectedExecutionStatusAdv([]);
        onSelectedExecutionStatusAdvFilter([]);
        setSelectedProcessingStepAdv([]);
        onSelectedProcessingStepAdvFilter([]);
        setRequestFromDateAdv(null);
        setRequestToDateAdv(null);
        onRequestFromDateAdvFilter(null);
        onRequestToDateAdvFilter(null);
        setSentFromDateAdv(null);
        setSentToDateAdv(null);
        onSentFromDateAdvFilter(null);
        onSentToDateAdvFilter(null);
        onSelectedPageAdv(1);
    }

    React.useEffect(() => {
        setSelectedFeedStatusAdv([]);
        onSelectedFeedStatusAdvFilter([]);
        setSelectedFeedPhaseAdv([]);
        onSelectedFeedPhaseAdvFilter([]);
        setExecutionIDAdv("");
        onExecutionIDAdvFilter("");
        setSelectedExecutionStatusAdv([]);
        onSelectedExecutionStatusAdvFilter([]);
        setSelectedProcessingStepAdv([]);
        onSelectedProcessingStepAdvFilter([]);
        setRequestFromDateAdv(sixMonthDate);
        setRequestToDateAdv(todayDate);
        onRequestFromDateAdvFilter(sixMonthDate);
        onRequestToDateAdvFilter(todayDate);
        setSentFromDateAdv(null);
        setSentToDateAdv(null);
        onSentFromDateAdvFilter(null);
        onSentToDateAdvFilter(null);
        onSelectedPageAdv(1);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handleFeedsStatusesClick = (value) => (event) => {
        let selectedFeedsStatusesArray = selectedFeedStatusAdv;
        if (!value || value === "") selectedFeedsStatusesArray = [];
        else if (selectedFeedsStatusesArray.indexOf(value) >= 0) {
            selectedFeedsStatusesArray.splice(selectedFeedsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedFeedsStatusesArray.push(value);
        }
        setSelectedFeedStatusAdv([...selectedFeedsStatusesArray]);
        onSelectedFeedStatusAdvFilter([...selectedFeedsStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleFeedsPhasesClick = (value) => (event) => {
        let selectedFeedsPhasesArray = selectedFeedPhaseAdv;
        if (!value || value === "") selectedFeedsPhasesArray = [];
        else if (selectedFeedsPhasesArray.indexOf(value) >= 0) {
            selectedFeedsPhasesArray.splice(selectedFeedsPhasesArray.indexOf(value), 1);
        }
        else {
            selectedFeedsPhasesArray.push(value);
        }
        setSelectedFeedPhaseAdv([...selectedFeedsPhasesArray]);
        onSelectedFeedPhaseAdvFilter([...selectedFeedsPhasesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleExecutionIDFilter = (e) => {
        setExecutionIDAdv(e.target.value);
        onExecutionIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleExecutionStatusesClick = (value) => (event) => {
        let selectedExecutionStatusesArray = selectedExecutionStatusAdv;
        if (!value || value === "") selectedExecutionStatusesArray = [];
        else if (selectedExecutionStatusesArray.indexOf(value) >= 0) {
            selectedExecutionStatusesArray.splice(selectedExecutionStatusesArray.indexOf(value), 1);
        }
        else {
            selectedExecutionStatusesArray.push(value);
        }
        setSelectedExecutionStatusAdv([...selectedExecutionStatusesArray]);
        onSelectedExecutionStatusAdvFilter([...selectedExecutionStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleProcessingStepsClick = (value) => (event) => {
        let selectedProcessingStepsArray = selectedProcessingStepAdv;
        if (!value || value === "") selectedProcessingStepsArray = [];
        else if (selectedProcessingStepsArray.indexOf(value) >= 0) {
            selectedProcessingStepsArray.splice(selectedProcessingStepsArray.indexOf(value), 1);
        }
        else {
            selectedProcessingStepsArray.push(value);
        }
        setSelectedProcessingStepAdv([...selectedProcessingStepsArray]);
        onSelectedProcessingStepAdvFilter([...selectedProcessingStepsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleRequestDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setRequestFromDateAdv(date);
            onRequestFromDateAdvFilter(date);
        }
        else {
            setRequestToDateAdv(date);
            onRequestToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };

    const handleSentDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSentFromDateAdv(date);
            onSentFromDateAdvFilter(date);
        }
        else {
            setSentToDateAdv(date);
            onSentToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Transmissions Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            {(userActions?.includes("43") ||
                userActions?.includes("20")) ? (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                // variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Feed Status" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="Feed Phase" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Execution ID" {...a11yProps(2)} />
                                <Tab className="filter-tab-btn" label="Transmission Status" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Processing Step" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Request Date" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Sent Date" {...a11yProps(6)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsStatusesList.map((f, fi) => (
                                        <Accordion key={`feeds-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedStatusAdv && selectedFeedStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsPhasesList.map((f, fi) => (
                                        <Accordion key={`feeds-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedPhaseAdv && selectedFeedPhaseAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedPhaseName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <span>Please filter by only one execution ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Execution ID" value={executionIDAdv} onChange={handleExecutionIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {executionStatusesList.map((f, fi) => (
                                        <Accordion key={`execution-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="execution-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`execution-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleExecutionStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {processingStepsList.map((f, fi) => (
                                        <Accordion key={`processing-step-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="processing-step-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`processing-step-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedProcessingStepAdv && selectedProcessingStepAdv.indexOf(f) >= 0}
                                                                onClick={handleProcessingStepsClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestFromDateAdv}
                                    onChange={(date) => {
                                        setRequestFromDateAdv(date);
                                        onRequestFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestToDateAdv}
                                    onChange={(date) => {
                                        setRequestToDateAdv(date);
                                        onRequestToDateAdvFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentFromDateAdv}
                                    onChange={(date) => {
                                        setSentFromDateAdv(date);
                                        onSentFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentToDateAdv}
                                    onChange={(date) => {
                                        setSentToDateAdv(date);
                                        onSentToDateAdvFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            ) : (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                // variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Feed Status" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="Feed Phase" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Request Date" {...a11yProps(2)} />
                                <Tab className="filter-tab-btn" label="Sent Date" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Processing Step" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Transmission Status" {...a11yProps(5)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsStatusesList.map((f, fi) => (
                                        <Accordion key={`feeds-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedStatusAdv && selectedFeedStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsPhasesList.map((f, fi) => (
                                        <Accordion key={`feeds-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedPhaseAdv && selectedFeedPhaseAdv.indexOf(f) >= 0}
                                                                onClick={handleFeedsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedPhaseName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestFromDateAdv}
                                    onChange={(date) => {
                                        setRequestFromDateAdv(date);
                                        onRequestFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={requestToDateAdv}
                                    onChange={(date) => {
                                        setRequestToDateAdv(date);
                                        onRequestToDateAdvFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentFromDateAdv}
                                    onChange={(date) => {
                                        setSentFromDateAdv(date);
                                        onSentFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={sentToDateAdv}
                                    onChange={(date) => {
                                        setSentToDateAdv(date);
                                        onSentToDateAdvFilter(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {processingStepsList.map((f, fi) => (
                                        <Accordion key={`processing-step-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="processing-step-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`processing-step-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedProcessingStepAdv && selectedProcessingStepAdv.indexOf(f) >= 0}
                                                                onClick={handleProcessingStepsClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    {executionStatusesList.map((f, fi) => (
                                        <Accordion key={`execution-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="execution-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`execution-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.indexOf(f) >= 0}
                                                                onClick={handleExecutionStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            )}


            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )

}

export default AnalystTransmissionsFilterModalBody;