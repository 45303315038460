import React from "react";
import Pagination from "../Layout/Pagination";
import { Button } from "@material-ui/core";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import VerifiedIcon from '@mui/icons-material/Verified';
import GenericTable from "../Layout/GenericTable";
import { CustomTooltip } from "../Layout/Tooltip";
import verifyCarrierSVG from "../../Icons/shield-check.svg";
import cancelIconSVG from "../../Icons/CancelIcon.svg";
import RejectAndApproveCarrier from "../Carriers/RejectAndReplaceCarrier";
import { FailureModal } from "../Modals/FailureModal";
import { SuccessModal } from "../Modals/SuccessModal";
import { useAuthentication } from "../../Data/Authentication";

function CarriersTable({
    carriersList,
    setCarriersList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    quickFiltersClicked,
    carrierStatusFilter,
    ...props
}) {

    const [sortOrder, setSortOrder] = React.useState("ASC");

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        onSortFieldFilter(column?.sortingkey);
        onSortOrderFilter(sortDirection?.toUpperCase());

    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }
    const handleApproveCarrier = (c) => {
        const body = {
            "CarrierID": c?.CarrierID,
            "CarrierName":  c?.CarrierName,
        }
        
        openModal(0,() => approveCarrier(body),{
            title:"Approve Carrier",
            question: `Are you sure you want to approve the carrier "${c?.CarrierName}"?`,
        })
    }
    const approveCarrier = (body) => {
        
            openLoader();
            APIs.approveCarrier(body).then((r) => {
                if (r?.success === "0") {
                    FailureModal(dialog,{
                        title:"Failure",
                        body: r?.msg,
                    });
                }
                else {
                    closeLoader();
                    window.location.reload();
                }
            }).catch((error) => {
                closeLoader();
            });
        
    }
    const handleUnRejectCarrier = (c) => {
        const body = {
            CarrierID: c?.CarrierID,
            CarrierName:  c?.CarrierName,
        }
        
        openModal(0,() => unRejectCarrier(body),{
            title:"Un-Reject Carrier",
            question: `Are you sure you want to un-reject the carrier "${c?.CarrierName}"?`,
        })
    }
    const unRejectCarrier = (body) => {
        
            openLoader();
            APIs.unrejectCarrier(body).then((r) => {
                if (r?.success === "0") {
                    FailureModal(dialog,{
                        title:"Failure",
                        body: r?.msg,
                    });
                }
                else {
                    closeLoader();
                    window.location.reload();
                }
            }).catch((error) => {
                closeLoader();
            });
        
    }
    const baseColumnsArray = [
        {
            name: 'Carrier Code',
            sortable: true,
            sortingkey: 'CarrierNumber',
            id: 'CarrierNumber',
            selector: (c) => c?.CarrierNumber
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: (c) =>  <a href={`/Carriers/ViewCarrier/${c?.CarrierID}`}>{c?.CarrierName}</a>
        }, ];
    {/* adding actions column only for super admin and ISolved super admin roles and tab value*/}
    const pushToColumnsBasedOnUserRolesAndTabValue = () => {
            const columnsToAdd=[{
                name: 'Actions',
                sortable: false,
                id: 'Actions',
                width: '200px',
                selector: c =>  <div style={{display: "flex", gap: "10px"}}> 
                                       {(carrierStatusFilter === 2) && <CustomTooltip title={"Approve Carrier"} arrow>
                                            <span>
                                                <a onClick={()=>handleApproveCarrier(c)} >                               
                                                    <svg cursor="pointer" width="18" height="18" viewBox="0 0 24 24" className="mt--1" >
                                                        <use xlinkHref={`${verifyCarrierSVG}#shield-check`}></use>
                                                    </svg>
                                                </a>      
                                            </span>
                                        </CustomTooltip>  }
                                        {(carrierStatusFilter === 2) && <CustomTooltip title={"Reject Carrier"} arrow> 
                                            <span>
                                                <a onClick={()=>{dialog.openDialog("Please Confirm", <RejectAndApproveCarrier carrier={c}  />,[])}}>                               
                                                    <svg cursor="pointer" width="18" height="18" viewBox="0 0 18 18" style={{marginTop: "3px"}}>
                                                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>                                                   
                                                    </svg>
                                                </a>
                                            </span>
                                        </CustomTooltip>}
                                       {(carrierStatusFilter === 3) && <CustomTooltip title={"Un-Reject Carrier"} arrow>
                                            <span>
                                                <a onClick={()=>handleUnRejectCarrier(c)} >                               
                                                    <svg cursor="pointer" width="18" height="18" viewBox="0 0 24 24" className="mt--1" >
                                                        <use xlinkHref={`${verifyCarrierSVG}#shield-check`}></use>
                                                    </svg>
                                                </a>      
                                            </span>
                                        </CustomTooltip>  }
                                </div>
            }]
            // create a copy of the base columns array 
            let finalColumnsArray = [...baseColumnsArray];
            // check if the user is a super admin or ISolved super admin and the tab value is 1 for pending carriers
            if ((userRoles?.includes("7") || userRoles?.includes("2")) && ((carrierStatusFilter === 2) || (carrierStatusFilter === 3))) {
                finalColumnsArray.push(...columnsToAdd);
        }
        return finalColumnsArray;
    }

    return (
        <div className="mt-5" id="connectivity-group-requests-table">
            <GenericTable
                columns={[...pushToColumnsBasedOnUserRolesAndTabValue()]}
                data={carriersList}
                style={{ display: "flex"}}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection) : () => { }}
                />
            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={pageSize}
                    page={selectedPage}
                    onChange={(t, v) => {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    }}
                    count={totalCount}
                />
            </div>
        </div>
    )
}

export default CarriersTable;