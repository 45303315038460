import HomeDashboard from '../Components/Home/HomeDashboard';
import GroupRequestsDashboard from '../Components/GroupRequest/GroupRequestDashboard/GroupRequestsDashboard';
import CreateGroupRequest from '../Components/GroupRequest/CreateGroupRequest/CreateGroupRequest';
import ViewGroupRequest from '../Components/GroupRequest/ViewEditGroupRequest/ViewGroupRequest';
import TransmissionDashboard from '../Components/Transmissions/TransmissionDashboard';
import FormsTransmissionDashboard from '../Components/TransmissionsForms/FormsTransmissionDashboard';
import FormsTransmissionLogEmployeesDashboard from '../Components/TransmissionsForms/FormsTransmissionLogEmployeesDashboard';
import TransmissionDashboardProduction from '../Components/Transmissions/ProductionDashboard/TransmissionDashboardProduction';
import AnalystViewFeeds from '../Components/Feed/FeedsAnalyst/ViewFeeds/AnalystViewFeeds';
import ConnectivityRequestsDashboard from '../Components/ConnectivityRequest/ConnectivityRequestsDashboard/ConnectivityRequestsDashboard';
import ViewActionItemsList from '../Components/ActionItems/ViewActionItemsList';
import ViewMapsList from '../Components/Maps/ViewMapsList';
import ViewPartnersList from '../Components/Partners/ViewPartnersList';
import ViewFormsList from '../Components/Forms/ViewFormsList';
import ViewStructuresList from '../Components/Structures/ViewStructuresList';
import ViewCarriersList from '../Components/Carriers/ViewCarriersList';
import SearchCarriers from '../Components/Carriers/SearchCarriers';
import UsersListView from '../Components/Users/UsersDashboard/UsersListView';
import SupportView from '../Components/Support/SupportView';
import OERequestsDashboard from '../Components/OERequests/OERequestsDashboard';
import AnalystFeedConfigTransmissions from '../Components/Feed/FeedsAnalyst/ViewEditAnalystFeed/AnalystFeedConfigTransmissions';
import AnalystCreateFeed from '../Components/Feed/FeedsAnalyst/CreateFeed/AnalystCreateFeed';
import AnalystFeedConfig from '../Components/Feed/FeedsAnalyst/ViewEditAnalystFeed/AnalystFeedConfig';
import AddConnectivity from '../Components/GroupRequest/ViewEditGroupRequest/AddConnectivity';
import AnalystConnectivityRequestConfig from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/AnalystConnectivityRequestConfig';
import PreMappingFormAnalyst from '../Components/PreMappingForm/AnalystSide/CreatePreMappingForm/PreMappingFormAnalyst';
import PreMappingFormAnalystView from '../Components/PreMappingForm/AnalystSide/AnalystView/PreMappingFormAnalystView';
import PreMappingFormAdmin from '../Components/PreMappingForm/AdminSide/CreatePreMappingFormAdmin/PreMappingFormAdmin';
import PreMappingFormAdminView from '../Components/PreMappingForm/AdminSide/AdminView/PreMappingFormAdminView';
import AnalystConnectivityRequestActionItems from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/ActionItems/AnalystConnectivityRequestActionItems';
import AnalystConnectivityRequestAddActionItem from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/ActionItems/AnalystConnectivityRequestAddActionItem';
import AnalystConnectivityRequestViewActionItem from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/ActionItems/AnalystConnectivityRequestViewActionItem';
import AnalystConnectivityRequestOEList from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/OEList/AnalystConnectivityRequestOEList';
import AnalystConnectivityRequestAddOERequest from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/OEList/AnalystConnectivityRequestAddOERequest';
import AnalystConnectivityRequestViewOERequest from '../Components/ConnectivityRequest/ConnectivityRequestsAnalyst/OEList/AnalystConnectivityRequestViewOERequest';
import CreateOERequestsView from '../Components/GroupRequest/ViewEditGroupRequest/CreateOERequestsView';
import CarrierConfig from '../Components/Carriers/CarrierConfig';
import CreateUser from '../Components/Users/CreateUser';
import ViewUser from '../Components/Users/ViewUser';
import ProfilePage from '../Components/Profile/ProfilePage';
import SettingsPage from '../Components/Profile/SettingsPage';
import EmployeeDashboard from '../Components/EmployeeViews/EmployeeDashboard';
import CreateCarrier from '../Components/Carriers/CreateCarrier';
import AddIP from '../Components/Users/AddIP'

const componentsMap = {
    'HomeDashboard': HomeDashboard,
    'GroupRequestsDashboard': GroupRequestsDashboard,
    'CreateGroupRequest': CreateGroupRequest,
    'ViewGroupRequest': ViewGroupRequest,
    'TransmissionDashboard': TransmissionDashboard,
    'FormsTransmissionDashboard': FormsTransmissionDashboard,
    'FormsTransmissionLogEmployeesDashboard': FormsTransmissionLogEmployeesDashboard,
    'TransmissionDashboardProduction': TransmissionDashboardProduction,
    'AnalystViewFeeds': AnalystViewFeeds,
    'ConnectivityRequestsDashboard': ConnectivityRequestsDashboard,
    'ViewActionItemsList': ViewActionItemsList,
    'ViewMapsList': ViewMapsList,
    'ViewFormsList': ViewFormsList,
    'ViewStructuresList': ViewStructuresList,
    'ViewCarriersList': ViewCarriersList,
    'CreateCarrier':CreateCarrier,
    'SearchCarriers': SearchCarriers,
    'UsersListView': UsersListView,
    'SupportView': SupportView,
    'OERequestsDashboard': OERequestsDashboard,
    'AnalystFeedConfigTransmissions': AnalystFeedConfigTransmissions,
    'AnalystCreateFeed': AnalystCreateFeed,
    'AnalystFeedConfig': AnalystFeedConfig,
    'AddConnectivity': AddConnectivity,
    'AnalystConnectivityRequestConfig': AnalystConnectivityRequestConfig,
    'PreMappingFormAnalyst': PreMappingFormAnalyst,
    'PreMappingFormAnalystView': PreMappingFormAnalystView,
    'PreMappingFormAdmin': PreMappingFormAdmin,
    'PreMappingFormAdminView': PreMappingFormAdminView,
    'AnalystConnectivityRequestActionItems': AnalystConnectivityRequestActionItems,
    'AnalystConnectivityRequestAddActionItem': AnalystConnectivityRequestAddActionItem,
    'AnalystConnectivityRequestViewActionItem': AnalystConnectivityRequestViewActionItem,
    'AnalystConnectivityRequestOEList': AnalystConnectivityRequestOEList,
    'AnalystConnectivityRequestAddOERequest': AnalystConnectivityRequestAddOERequest,
    'AnalystConnectivityRequestViewOERequest': AnalystConnectivityRequestViewOERequest,
    'CreateOERequestsView': CreateOERequestsView,
    'CarrierConfig': CarrierConfig,
    'CreateUser': CreateUser,
    'ViewUser': ViewUser,
    'ProfilePage': ProfilePage,
    'SettingsPage': SettingsPage,
    'EmployeeDashboard': EmployeeDashboard,
    'ViewPartnersList': ViewPartnersList,
    'AddIP': AddIP
};

export default componentsMap;