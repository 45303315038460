import React from "react";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import Moment from 'moment';
import MultipleTabsTitles from "../../../Layout/MultipleTabsTitles";
import AnalystSingleFeedTransmission from "./AnalystSingleFeedTransmission";
import { useHistory } from "react-router-dom";
import AnalystSingleFeedFormsTransmission from "./AnalystSingleFeedFormsTransmission";


let currentTime = new Date().getTime();
currentTime = Moment(currentTime).format('YYYY-MM-DDTHH:mm:ss');

let currentDate = new Date();
currentDate = Moment(currentDate).format('YYYY-MM-DD');

function AnalystFeedConfig() {

    const routeHistory = useHistory();
    const { feedIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();

    //View - Edit Feed Info
    const [feed, setFeed] = React.useState();


    const [tabValue, setTabValue] = React.useState(1);
    const feedTabs = ["Feed Configuration", "Transmission History"];

    const handleTabChange = (e, newValue) => {
        if (!feed || feed === undefined) return;
        else {
            setTabValue(newValue);
            if (newValue === 1)
                routeHistory.push(`/EDITransmissions/ViewFeed/${feed?.FeedID}/Dashboard`);
            else routeHistory.push(`/Feeds/ViewFeed/${feed?.FeedID}`);
        }
    };

    React.useEffect(() => {
        if (!feed || feed === undefined) openLoader(); else closeLoader();
    }, [feed])


    React.useEffect(() => {
        openLoader();
        APIs.getFeed(feedIDURL).then((r) => { setFeed(r?.data); });
    }, [])


    return (
        <div id="feed-config">
            {feed && (
                <BreadcrumbSteps
                    breadcrumbTitleCurrent={`${feed?.FeedName}`}
                    isCurrent={true}
                />
            )}
            <MultipleTabsTitles disabled={!feedIDURL || feedIDURL === undefined} tabTitle={feedTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />



{tabValue === 1 && (
    feed?.FeedMapTypeID === 2 ? 
        <AnalystSingleFeedFormsTransmission feedID={feed?.FeedID} carrierID={feed?.CarrierID} feed={feed} />
    :
        <AnalystSingleFeedTransmission feedID={feed?.FeedID} carrierID={feed?.CarrierID} feed={feed} />
)}
        </div >
    )
}

export default AnalystFeedConfig;
