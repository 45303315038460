import React, {  useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Paper, Tabs, Box, Accordion, AccordionSummary, FormControlLabel, Checkbox, Tab, DialogActions, Button } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Draggable from "react-draggable";
import {  styled } from '@mui/system';
import { useAuthentication } from '../../Data/Authentication';
import { useLoader } from '../Layout/Loader';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { ConfirmModal } from '../Modals/ConfirmModal';
import * as APIs from "../../Data/APIs";
import exportIconSVG from "../../Icons/exportArrow.svg";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

function ActionItemsReportModal({
    openPartnersAIDialog,
    setOpenPartnersAIDialog,
    setSelectedPartnersAIReport,
    selectedPartnersAIReport,
    handleDownloadFile,
}){
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog();

    const [tabValue, setTabValue] = useState(0);

    const [partnerNames,setPartnerNames] = useState([]);
    const [partnersSearchValue,setPartnerSearchValue] = useState('');
    // remove duplicates from partners
    let filteredPartners = partnerNames?.filter((item, index, self) => self.findIndex(t => t.PartnerName === item.PartnerName) === index);

    function openModal(modalType, handleFunction, content){
        switch (modalType)  {
            case 0 : 
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    const handleCloseDialogResetConfirm = () => {
        if (selectedPartnersAIReport.length === 0) {
            setOpenPartnersAIDialog(false);
            return;
        }
        else{
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous partners selection.`,
        });}
        setPartnerSearchValue('');

    };

    const handleCloseDialogReset = () => {
        setOpenPartnersAIDialog(false);
        setSelectedPartnersAIReport([]);
    }



    const handleSelectAll = (updatedList, list, updatedFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            setSelectedPartnersAIReport([...list.map(s => s)]);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
        }
        }
    
    // handle partner click if already selected then remove it else add it
    const handlePartnerClick = (value) => (event) => {
        let selectedPartnersArray = selectedPartnersAIReport;
        if (!value || value === "") selectedPartnersArray = [];
        else if (selectedPartnersArray.indexOf(value) >= 0) {
            selectedPartnersArray.splice(selectedPartnersArray.indexOf(value), 1);
        }
        else {
            selectedPartnersArray.push(value);
        }
        setSelectedPartnersAIReport([...selectedPartnersArray]);
    }

    const handleSubmit = () => {
        handleDownloadFile();
        handleCloseDialogReset();
    }

        // get partners
    React.useEffect(() => {
        openLoader();
        APIs.getPartnerNames().then((r) => {
            setPartnerNames(r.data);
        }).finally(() => {
            closeLoader();});
    }, []);

    // handle close dialog without action buttons 
    React.useEffect(() => {
        if (!openPartnersAIDialog) {
            setSelectedPartnersAIReport([]);
            setPartnerSearchValue('');
        }
    },[openPartnersAIDialog]);
    return (
        <Dialog
        className="filter-full-div"
        open={openPartnersAIDialog}
        onClose={() => setOpenPartnersAIDialog(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>AIs Report</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="filter-body-div">
                    <Box style={{ display: "flex" }}>
                        <Tabs
                            style={{ maxHeight: "370px", overflow: "auto" }}
                            orientation="vertical"
                            aria-label="Vertical tabs example"
                            value={0}
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{ 
                                style: { 
                                    display: "none",
                                 } 
                            }}>
                            <Tab className="filter-tab-btn" style={{minWidth:"200px"}} label="Partner" {...a11yProps(0)}/>   
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <input type="text" className="text-input w-100" placeholder="Search partners here..." value={partnersSearchValue} onChange={(e) => setPartnerSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`partners-select-all`}>
                                    <AccordionSummary
                                        aria-controls="partners-select-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`partners-select-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedPartnersAIReport && selectedPartnersAIReport.length === filteredPartners.length}
                                                        onClick={handleSelectAll(selectedPartnersAIReport, [...filteredPartners.map(s => s.Id)], setSelectedPartnersAIReport)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>
                                    {/* filters according to the search value */}
                                { filteredPartners.filter( c => partnersSearchValue ? c?.PartnerName.toLowerCase().includes(partnersSearchValue.toLowerCase()) : c).map((c, ci) => (
                                    <Accordion key={`partners-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="partners-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`partners-select-option-${ci}`}
                                                            value={c?.Id}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPartnersAIReport && selectedPartnersAIReport.indexOf(c?.Id) >= 0}
                                                            onClick={handlePartnerClick(c?.Id)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.PartnerName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                    </Box>
                </div>    
            </DialogContent>  
            <DialogActions className="filter-footer-div"> 
                <Button className="blue-outlined-btn" onClick={handleCloseDialogResetConfirm}>
                    Cancel
                </Button>
                <Button  className="blue-btn" disabled={selectedPartnersAIReport.length === 0} onClick={()=>handleSubmit()}>
                    <svg width="22" height="23" viewBox="0 0 22 23" stroke="white" strokeWidth="1.5" fill="none">
                        <use xlinkHref={`${exportIconSVG}#exportIcon`}></use>
                    </svg>
                    Export
                </Button>
            </DialogActions>
        </Dialog>
    );


}
export default ActionItemsReportModal;