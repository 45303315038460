import React from 'react'
import * as APIs from "../../Data/APIs";
import { FormControl, Divider, Paper, Button, Select, MenuItem, TextField } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'moment';
import { useAuthentication } from "../../Data/Authentication";
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useParams } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import { useLoader } from '../Layout/Loader';
import TruncatedChip from "../Layout/TruncatedChip";
import MultipleTabsTitles from "../Layout/MultipleTabsTitles";
import OERequestsTable from './OERequestsTable';
import OERequestsModalBodyFilter from './OERequestsFilterModalBody';
import RefreshIcon from '@material-ui/icons/Refresh';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FailureModal } from "../Modals/FailureModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { styled } from '@mui/system';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import refreshSVG from "../../Icons/refresh.svg";
import cancelIconSVG from "../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../Icons/advancedFilter.svg";
import oeDataReadyEnabled from "../../Icons/oeDataReadyEnabled.svg";
import exportIconSVG from "../../Icons/exportArrow.svg";
import plusIconSVG from "../../Icons/plusIcon.svg";
import GenericDatePicker from '../Layout/GenericDatePicker';

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function ViewOERequestsList({
    oERequestsList,
    setOERequestsList,
    refreshClicked,
    setRefreshClicked,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    connectivityRequestIDFilter,
    setConnectivityRequestIDFilter,
    oERequestIDFilter,
    setOERequestIDFilter,
    selectedPlanYearStartFromDateFilter,
    setSelectedPlanYearStartFromDateFilter,
    selectedPlanYearStartToDateFilter,
    setSelectedPlanYearStartToDateFilter,
    selectedExpectedFromDateFilter,
    setSelectedExpectedFromDateFilter,
    selectedExpectedToDateFilter,
    setSelectedExpectedToDateFilter,
    selectedCreatedFromDateFilter,
    setSelectedCreatedFromDateFilter,
    selectedCreatedToDateFilter,
    setSelectedCreatedToDateFilter,
    statusFilter,
    setStatusFilter,
    phaseFilter,
    setPhaseFilter,
    oeTypeFilter,
    setOETypeFilter,
    assignedTo,
    setAssignedTo,
    assignedToAutoValue,
    setAssignedToAutoValue,
    iSolvedContact,
    setISolvedContact,
    iSolvedContactAutoValue,
    setISolvedContactAutoValue,
    updatedGS,
    setUpdatedGS,
    draft,
    setDraft,
    requestType,
    setRequestType,
    requestTypes,
    setRequestTypes,
    clientsList,
    setClientsList,
    peosList,
    setPEOsList,
    carriersList,
    setCarriersList,
    clientName,
    setClientName,
    clientNameAutoValue,
    setClientNameAutoValue,
    peoName,
    setPEOName,
    peoNameAutoValue,
    setPEONameAutoValue,
    carName,
    setCarName,
    carNameAutoValue,
    setCarNameAutoValue,
    clientSelectedFilter,
    setClientSelectedFilter,
    peoSelectedFilter,
    setPEOSelectedFilter,
    carrierSelectedFilter,
    setCarrierSelectedFilter,
    selectedOEFileSubmissionFromDateFilter,
    setSelectedOEFileSubmissionFromDateFilter,
    selectedOEFileSubmissionToDateFilter,
    setSelectedOEFileSubmissionToDateFilter,
    // adv
    connectivityRequestIDAdvFilter,
    setConnectivityRequestIDAdvFilter,
    oERequestIDAdvFilter,
    setOERequestIDAdvFilter,
    selectedPlanYearStartFromDateAdvFilter,
    setSelectedPlanYearStartFromDateAdvFilter,
    selectedPlanYearStartToDateAdvFilter,
    setSelectedPlanYearStartToDateAdvFilter,
    selectedExpectedFromDateAdvFilter,
    setSelectedExpectedFromDateAdvFilter,
    selectedExpectedToDateAdvFilter,
    setSelectedExpectedToDateAdvFilter,
    selectedCreatedFromDateAdvFilter,
    setSelectedCreatedFromDateAdvFilter,
    selectedCreatedToDateAdvFilter,
    setSelectedCreatedToDateAdvFilter,
    statusAdvFilter,
    setStatusAdvFilter,
    phaseAdvFilter,
    setPhaseAdvFilter,
    oeTypeAdvFilter,
    setOETypeAdvFilter,
    assignedToAdvFilter,
    setAssignedToAdvFilter,
    iSolvedContactAdvFilter,
    setISolvedContactAdvFilter,
    sortFieldAdvFilter,
    setSortFieldAdvFilter,
    sortOrderAdvFilter,
    setSortOrderAdvFilter,
    sortOrderFilter,
    setSortOrderFilter,
    sortFieldFilter,
    setSortFieldFilter,
    selectedAssignedToFilter,
    setSelectedAssignedToFilter,
    selectedISolvedContactFilter,
    setSelectedISolvedContactFilter,
    selectedStatusesFilter,
    setSelectedStatusesFilter,
    selectedPhasesFilter,
    setSelectedPhasesFilter,
    selectedOETypesFilter,
    setSelectedOETypesFilter,
    updatedGSAdvFilter,
    setUpdatedGSAdvFilter,
    draftAdvFilter,
    setDraftAdvFilter,
    requestTypeAdvFilter,
    setRequestTypeAdvFilter,
    selectedClientsFilter,
    setSelectedClientsFilter,
    selectedPEOsFilter,
    setSelectedPEOsFilter,
    selectedCarriersFilter,
    setSelectedCarriersFilter,
    selectedOEFileSubmissionFromDateAdvFilter,
    setSelectedOEFileSubmissionFromDateAdvFilter,
    selectedOEFileSubmissionToDateAdvFilter,
    setSelectedOEFileSubmissionToDateAdvFilter,
    selectedPage,
    setSelectedPage,
    pageSize,
    setPageSize,
    totalCount,
    setTotalCount,
    selectedPageAdv,
    setSelectedPageAdv,
    pageSizeAdv,
    setPageSizeAdv,
    statuses,
    setStatuses,
    statusesList,
    setStatusesList,
    phasesList,
    setPhasesList,
    usersList,
    setUsersList,
    groupsAndOEContactsList,
    setGroupsAndOEContactsList,
    contactsList,
    setContactsList,
    valueStatus,
    setValueStatus,
    tableRef,
    onPhaseDashboardChange,
    onStatusDashboardChange,
    onSetOnPhaseDashboardChange,
    onSetOnStatusDashboardChange,
    onSetStatusesFilterCard,
    statusesFilterCard,
    onsetFilterCard,
    planYearStartFromDateFilter,
    setPlanYearStartFromDateFilter,
    planYearStartToDateFilter,
    setPlanYearStartToDateFilter,
}) {

    // style
    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);
    const { authUser } = useAuthentication();
    const dialog = useModalDialog(0);
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedOEs, setSelectedOEs] = React.useState([]);
    const [oesClicked, setoesClicked] = React.useState(false);
    const [partnerStatus, setPartnerStatus] = React.useState("");

    React.useEffect(() => {
        const list = ["All"];
        if (statusesList?.length > 0) {
            list.push(...statusesList.map(s => s?.Name));
        }

        setStatuses(list);
    }, [statusesList])


    React.useEffect(() => {
        setSelectedPlanYearStartToDateFilter(planYearStartToDateFilter);
        setSelectedPlanYearStartFromDateFilter(planYearStartFromDateFilter);
    }, [planYearStartToDateFilter, planYearStartFromDateFilter])

    React.useEffect(() => {
        setAssignedToAutoValue(`${assignedTo?.FirstName ?? ""} ${assignedTo?.LastName ?? ""}`);
    }, [assignedTo])

    React.useEffect(() => {
        setISolvedContactAutoValue(`${iSolvedContact?.FirstName ?? ""} ${iSolvedContact?.LastName ?? ""}`);
    }, [iSolvedContact])

    const handleTabChange = (e, newValue) => {
        onSetStatusesFilterCard([]);
        setValueStatus(newValue);
        const selectedStatus = statusesList[newValue - 1]?.Id;
        setStatusFilter(selectedStatus);
        setSelectedPage(1);
        setPageSize(30);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getGroupsListByName({ GroupName: clientName ?? "" }).then((r) => !(r?.data?.length) ? setClientsList([]) : setClientsList(r?.data));
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
        APIs.getCarriersListByName({ CarrierName: carName ?? "", StatusTypeId: [1,2] }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [])

    React.useEffect(() => {
        setClientNameAutoValue(clientName);
    }, [clientName])

    React.useEffect(() => {
        setPEONameAutoValue(peoName);
    }, [peoName])

    React.useEffect(() => {
        setCarNameAutoValue(carName);
    }, [carName])


    React.useEffect(() => {
        APIs.getPartnerStatus().then((r) => setPartnerStatus(r));
    }, [])

    //    handle functions 
    const handleOERequestIDFilter = (e) => {
        setOERequestIDFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleConnectivityRequestIDFilter = (e) => {
        setConnectivityRequestIDFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handlePlanYearStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlanYearStartFromDateFilter(date);
        }
        else {
            setSelectedPlanYearStartToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };


    const handleOEFileSubmissionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedOEFileSubmissionFromDateFilter(date);
        }
        else {
            setSelectedOEFileSubmissionToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };
    const handleExpectedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedExpectedFromDateFilter(date);
        }
        else {
            setSelectedExpectedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };
    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCreatedFromDateFilter(date);
        }
        else {
            setSelectedCreatedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleStatusFilter = (e) => {
        setStatusFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handlePhaseFilter = (e) => {
        setPhaseFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }
    const handleOETypeFilter = (e) => {
        setOETypeFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleUpdatedGSFilter = (e, newValue) => {
        setUpdatedGS(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleDraftFilter = (e) => {
        setDraft(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleRequestTypeFilter = (e, newValue) => {
        setRequestType(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    React.useEffect(() => {
        if (onPhaseDashboardChange) {
            setStatusFilter("");
            setValueStatus(0);
            setSelectedPlanYearStartToDateFilter(planYearStartToDateFilter);
            setSelectedPlanYearStartFromDateFilter(planYearStartFromDateFilter);
            handleClearDashBordReset();
            onsetFilterCard(false);
        }
    }, [phaseFilter, onPhaseDashboardChange === true])

    React.useEffect(() => {
        if (onStatusDashboardChange) {
            setPhaseFilter("");
            setSelectedPlanYearStartToDateFilter(planYearStartToDateFilter);
            setSelectedPlanYearStartFromDateFilter(planYearStartFromDateFilter);
            handleClearDashBordReset();
            onsetFilterCard(false);
        }
    }, [statusFilter, onStatusDashboardChange === true])




    React.useEffect(() => {

        if (statusFilter === 1) { setValueStatus(1); setStatusFilter(1); } //In Progress
        else if (statusFilter === 2) { setValueStatus(2); setStatusFilter(2); }//"Completed"
        else if (statusFilter === 3) { setValueStatus(3); setStatusFilter(3); } //"Cancelled"
        else if (statusFilter === 4) { setValueStatus(4); setStatusFilter(4); } //"Blocked" 
        else if (statusFilter === 5) { setValueStatus(5); setStatusFilter(5); } //"Not Started"

    }, [statusFilter])



    const handleClearSearch = () => {
        setValueStatus(0);
        setConnectivityRequestIDFilter("");
        setOERequestIDFilter("");
        setPhaseFilter(undefined);
        setStatusFilter(undefined);
        setOETypeFilter("");
        setUpdatedGS("");
        setDraft("");
        setRequestType("");
        setAssignedTo("");
        setISolvedContact("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedPage(1);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
        onSetStatusesFilterCard([]);
        setPlanYearStartFromDateFilter(null);
        setPlanYearStartToDateFilter(null);
    }
    const handleClearSearchReset = () => {
        setValueStatus(0);
        setOERequestIDFilter("");
        setConnectivityRequestIDFilter("");
        setPhaseFilter(undefined);
        setStatusFilter(undefined);
        setOETypeFilter("");
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setDraft("");
        setDraftAdvFilter("");
        setDraftAdvFilter("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setAssignedTo("");
        setISolvedContact("");
        setConnectivityRequestIDAdvFilter("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedOEFileSubmissionFromDateAdvFilter(null);
        setSelectedOEFileSubmissionToDateAdvFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setOERequestIDAdvFilter("");
        setPhaseAdvFilter(undefined);
        setStatusAdvFilter(undefined);
        setOETypeAdvFilter("");
        setAssignedToAdvFilter("");
        setISolvedContactAdvFilter("");
        setSelectedPlanYearStartFromDateAdvFilter(null);
        setSelectedPlanYearStartToDateAdvFilter(null);
        setSelectedExpectedFromDateAdvFilter(null);
        setSelectedExpectedToDateAdvFilter(null);
        setSelectedCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDateAdvFilter(null);
        setSelectedPage(1);
        setSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setSelectedStatusesFilter([]);
        setSelectedPhasesFilter([]);
        setSelectedAssignedToFilter([]);
        setSelectedISolvedContactFilter([]);
        setSelectedOETypesFilter([]);
        setAdvancedFiltersClicked(false);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
        onSetStatusesFilterCard([]);
        setPlanYearStartFromDateFilter(null);
        setPlanYearStartToDateFilter(null);
    }
    const handleClearDashBordReset = () => {
        setOERequestIDFilter("");
        setConnectivityRequestIDFilter("");
        setOETypeFilter("");
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setDraft("");
        setDraftAdvFilter("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setAssignedTo("");
        setISolvedContact("");
        setConnectivityRequestIDAdvFilter("");
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedOEFileSubmissionFromDateAdvFilter(null);
        setSelectedOEFileSubmissionToDateAdvFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setOERequestIDAdvFilter("");
        setPhaseAdvFilter(undefined);
        setStatusAdvFilter(undefined);
        setOETypeAdvFilter("");
        setAssignedToAdvFilter("");
        setISolvedContactAdvFilter("");
        setSelectedPlanYearStartFromDateAdvFilter(null);
        setSelectedPlanYearStartToDateAdvFilter(null);
        setSelectedExpectedFromDateAdvFilter(null);
        setSelectedExpectedToDateAdvFilter(null);
        setSelectedCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDateAdvFilter(null);
        setSelectedPage(1);
        setSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setSelectedStatusesFilter([]);
        setSelectedPhasesFilter([]);
        setSelectedAssignedToFilter([]);
        setSelectedISolvedContactFilter([]);
        setSelectedOETypesFilter([]);
        setAdvancedFiltersClicked(false);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
        onSetOnStatusDashboardChange(false);
        onSetOnPhaseDashboardChange(false);
    }
    const handleClickOpenDialog = () => {
        setConnectivityRequestIDFilter("");
        setOERequestIDFilter("");
        setPhaseFilter(undefined);
        setStatusFilter(undefined);
        setOETypeFilter("");
        setUpdatedGS("");
        setDraft("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setAssignedTo("");
        setISolvedContact("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedCreatedFromDateFilter(null);
        setSelectedCreatedToDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
        onSetStatusesFilterCard([]);
        setPlanYearStartFromDateFilter(null);
        setPlanYearStartToDateFilter(null);
    };

    const handleDataIsReady = () => {
        openLoader();
        let transmitOes = {

            OERequestIDs: selectedOEs?.map(oe => (

                oe.Id

            ))
        }

        APIs.sendOEFile(transmitOes).then((r) => {
            // debugger
            openLoader();
            if (r.success === "1") {
                // SuccessModal(dialog, {
                //     title: 'Sucess',
                //     body: "Completed",
                // })
                closeLoader();
                window.location.reload()
            }

            else {
                closeLoader();
                setSelectedOEs([]);
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r.msg,
                }
                )


            }
        })

    };

    const isIsovedSuperAdminOrSuperAdmin = () => {
        //attached action ID to isolvedSuperAdmin
        return userActions?.includes("57") || userActions?.includes("20");
    }

    return (
        <div id="view-action-items" >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>

                <Button
                    className='blue-outlined-btn'
                    onClick={() => APIs.downloadOERequestsFullReport().then(r => r?.msg === "No data available" ? FailureModal(dialog, {
                        title: 'Failure',
                        body: 'No Data Available',
                    }) : window.open(r?.url)
                    )}
                >
                    <svg width="22" height="23" viewBox="0 0 22 23" >
                        <use xlinkHref={`${exportIconSVG}#exportIcon`}></use>
                    </svg>
                    <span className="ml-2">Export OE Full Report</span>
                </Button>

                {((userActions?.includes("6") || userActions?.includes("20")) || userRoles?.includes("5")) && (
                    <>
                        <div className="alignvh-center"
                        // style={advancedFiltersClicked ? { position: "relative", justifyContent: "flex-end", top: "-40px" } : { position: "relative", justifyContent: "flex-end" }}
                        >
                            <CustomTooltip TransitionComponent={Zoom} title={(userRoles?.includes("5") && !partnerStatus) ? <div>Requests have been suspended</div> : ""}>
                            <span>
                            <Button className="add-request blue-outlined-btn ml-2" disabled={userRoles?.includes("5") && !partnerStatus}
                                // style={advancedFiltersClicked ? { right: "65px", top: "0px" } : { right: "0px", top: "-40px" }}
                                href={`/GroupRequests/CreateGroupRequest`}>
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt--1">
                                    <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                                </svg> Group Request
                            </Button>
                            </span>
                            </CustomTooltip>
                        </div>

                    </>
                )
                }

            </div>

            {quickFiltersClicked && (
                <div>
                    <MultipleTabsTitles tabTitle={statuses} value={valueStatus} setValue={setValueStatus} onChange={handleTabChange} />
                </div>
            )}


            <Paper className="content-card-paper">
                <div className="search-container">
                    <div className="row alignvh-center">
                        <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                        <Button
                            className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                            onClick={handleClickOpenDialog}
                            style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                        >
                            <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1" >
                                <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                            </svg>
                            Advanced Filters
                        </Button>

                        <React.Fragment>
                            {oERequestIDFilter && <TruncatedChip body={`OE Request ID: ${oERequestIDFilter}`} />}
                            {oERequestIDAdvFilter && <TruncatedChip body={`OE Request ID: ${oERequestIDAdvFilter}`} />}

                            {clientSelectedFilter && <TruncatedChip body={`Client: ${clientSelectedFilter?.GroupName}`} />}
                            {selectedClientsFilter && selectedClientsFilter?.length > 0 && <TruncatedChip body={`Clients: ${selectedClientsFilter?.map(s => s.GroupName).join(" - ")}`} />}

                            {peoSelectedFilter && <TruncatedChip body={`PEO: ${peoSelectedFilter?.PeoName}`} />}
                            {selectedPEOsFilter && selectedPEOsFilter?.length > 0 && <TruncatedChip body={`PEOs: ${selectedPEOsFilter?.map(s => s.PeoName).join(" - ")}`} />}

                            {carrierSelectedFilter && <TruncatedChip body={`Carrier: ${carrierSelectedFilter?.CarrierName}`} />}
                            {selectedCarriersFilter && selectedCarriersFilter?.length > 0 && <TruncatedChip body={`Carriers: ${selectedCarriersFilter?.map(s => s.CarrierName).join(" - ")}`} />}


                            {connectivityRequestIDFilter && <TruncatedChip body={`Connectivity Request ID: ${connectivityRequestIDFilter}`} />}
                            {connectivityRequestIDAdvFilter && <TruncatedChip body={`Connectivity Request ID: ${connectivityRequestIDAdvFilter}`} />}

                            {statusFilter && <TruncatedChip body={`Status: ${statusesList?.filter(s => s.Id == statusFilter)[0].Name}`} />}
                            {statusAdvFilter && <TruncatedChip body={`Status: ${statusesList?.filter(s => s.Id == statusAdvFilter)[0].Name}`} />}
                            {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Status: ${selectedStatusesFilter?.map(s => s.Name).join(" - ")}`} />}
                            {/* statusesFilterCard */}
                            {statusesFilterCard && statusesFilterCard?.length > 0 && <TruncatedChip body={` Status: ${statusesFilterCard?.map((n, ni) => statusesList?.filter(s => s.Id == n)[0].Name).join(" - ")}`} />}
                            {phaseFilter && <TruncatedChip body={`Phase: ${phasesList?.filter(s => s.Id == phaseFilter)[0].Name}`} />}
                            {phaseAdvFilter && <TruncatedChip body={`Phase: ${phasesList?.filter(s => s.Id == phaseAdvFilter)[0].Name}`} />}
                            {selectedPhasesFilter && selectedPhasesFilter?.length > 0 && <TruncatedChip body={`Phase: ${selectedPhasesFilter?.map(s => s.Name).join(" - ")}`} />}

                            {oeTypeFilter && <TruncatedChip body={`OE Type: ${oeTypeFilter}`} />}
                            {oeTypeAdvFilter && <TruncatedChip body={`OE Type: ${oeTypeAdvFilter}`} />}
                            {selectedOETypesFilter && selectedOETypesFilter?.length > 0 && <TruncatedChip body={`OE Type: ${selectedOETypesFilter?.map(s => s).join(" - ")}`} />}

                            {assignedTo && <TruncatedChip body={`Assigned To: ${assignedTo.FirstName} ${assignedTo.LastName}`} />}
                            {assignedToAdvFilter && <TruncatedChip body={`Assigned To: ${assignedToAdvFilter.FirstName} ${assignedToAdvFilter.LastName}`} />}
                            {selectedAssignedToFilter && selectedAssignedToFilter?.length > 0 && <TruncatedChip body={`Assigned To: ${selectedAssignedToFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}

                            {iSolvedContact && <TruncatedChip body={`isolved Contact: ${iSolvedContact.FirstName} ${iSolvedContact.LastName}`} />}
                            {iSolvedContactAdvFilter && <TruncatedChip body={`isolved Contact: ${iSolvedContactAdvFilter.FirstName} ${iSolvedContactAdvFilter.LastName}`} />}
                            {selectedISolvedContactFilter && selectedISolvedContactFilter?.length > 0 && <TruncatedChip body={`isolved Contact: ${selectedISolvedContactFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}


                            {updatedGS && updatedGS !== "" && <TruncatedChip body={`Updated Group Structure: ${updatedGS === "1" ? "Yes" : updatedGS === "0" ? "No" : ""}`} />}
                            {updatedGSAdvFilter && updatedGSAdvFilter !== "" && <TruncatedChip body={`Updated Group Structure: ${updatedGSAdvFilter === "1" ? "Yes" : updatedGSAdvFilter === "0" ? "No" : ""}`} />}


                            {draft && draft !== "" && <TruncatedChip body={`Draft: ${draft === "1" ? "Yes" : draft === "0" ? "No" : ""}`} />}
                            {draftAdvFilter && draftAdvFilter !== "" && <TruncatedChip body={`Draft: ${draftAdvFilter === "1" ? "Yes" : draftAdvFilter === "0" ? "No" : ""}`} />}


                            {requestType && requestType !== "" && <TruncatedChip body={`Request Type: ${requestTypes?.filter(s => s.RequestTypeId == requestType)[0]?.RequestTypeName}`} />}
                            {requestTypeAdvFilter && requestTypeAdvFilter !== "" && <TruncatedChip body={`Request Type: ${requestTypes?.filter(s => s.RequestTypeId == requestTypeAdvFilter)[0]?.RequestTypeName}`} />}

                            {quickFiltersClicked && selectedPlanYearStartFromDateFilter && selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedPlanYearStartToDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && selectedPlanYearStartFromDateFilter && !selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateFilter).format("MM/DD/YYYY")} `} />}
                            {quickFiltersClicked && !selectedPlanYearStartFromDateFilter && selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Years Start Date: To: ${Moment(selectedPlanYearStartToDateFilter).format("MM/DD/YYYY")}`} />}

                            {advancedFiltersClicked && selectedPlanYearStartFromDateAdvFilter && selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedPlanYearStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && selectedPlanYearStartFromDateAdvFilter && !selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Years Start Date: From: ${Moment(selectedPlanYearStartFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                            {advancedFiltersClicked && !selectedPlanYearStartFromDateAdvFilter && selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Years Start Date: To: ${Moment(selectedPlanYearStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                            {quickFiltersClicked && selectedExpectedFromDateFilter && selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedExpectedToDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && selectedExpectedFromDateFilter && !selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateFilter).format("MM/DD/YYYY")} `} />}
                            {quickFiltersClicked && !selectedExpectedFromDateFilter && selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: To: ${Moment(selectedExpectedToDateFilter).format("MM/DD/YYYY")}`} />}

                            {advancedFiltersClicked && selectedExpectedFromDateAdvFilter && selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedExpectedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && selectedExpectedFromDateAdvFilter && !selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                            {advancedFiltersClicked && !selectedExpectedFromDateAdvFilter && selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: To: ${Moment(selectedExpectedToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                            {quickFiltersClicked && selectedCreatedFromDateFilter && selectedCreatedToDateFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedCreatedToDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && selectedCreatedFromDateFilter && !selectedCreatedToDateFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateFilter).format("MM/DD/YYYY")} `} />}
                            {quickFiltersClicked && !selectedCreatedFromDateFilter && selectedCreatedToDateFilter && <TruncatedChip body={`Created Date: To: ${Moment(selectedCreatedToDateFilter).format("MM/DD/YYYY")}`} />}

                            {advancedFiltersClicked && selectedCreatedFromDateAdvFilter && selectedCreatedToDateAdvFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedCreatedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && selectedCreatedFromDateAdvFilter && !selectedCreatedToDateAdvFilter && <TruncatedChip body={`Created Date: From: ${Moment(selectedCreatedFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                            {advancedFiltersClicked && !selectedCreatedFromDateAdvFilter && selectedCreatedToDateAdvFilter && <TruncatedChip body={`Created Date: To: ${Moment(selectedCreatedToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                            {quickFiltersClicked && selectedOEFileSubmissionFromDateFilter && selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedOEFileSubmissionToDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && selectedOEFileSubmissionFromDateFilter && !selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateFilter).format("MM/DD/YYYY")} `} />}
                            {quickFiltersClicked && !selectedOEFileSubmissionFromDateFilter && selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: To: ${Moment(selectedOEFileSubmissionToDateFilter).format("MM/DD/YYYY")}`} />}

                            {advancedFiltersClicked && selectedOEFileSubmissionFromDateAdvFilter && selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedOEFileSubmissionToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && selectedOEFileSubmissionFromDateAdvFilter && !selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                            {advancedFiltersClicked && !selectedOEFileSubmissionFromDateAdvFilter && selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: To: ${Moment(selectedOEFileSubmissionToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                        </React.Fragment>
                    </div>
                    {quickFiltersClicked && (
                        <div className="row mt-3">
                            <div className="col-xl-3 mt-3">
                                <span>OE Request  ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={oERequestIDFilter} onChange={handleOERequestIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Connectivity Request ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={connectivityRequestIDFilter} onChange={handleConnectivityRequestIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Client Name:</span>
                                <br />
                                <Autocomplete
                                    options={clientsList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={clientNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.GroupName}
                                    onChange={(e, value) => {
                                        setClientName(value?.GroupName ?? "");
                                        setClientSelectedFilter(value ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.GroupName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setClientNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>PEO Name:</span>
                                <br />
                                <Autocomplete
                                    options={peosList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={peoNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.PeoName}
                                    onChange={(e, value) => {
                                        setPEOName(value?.PeoName ?? "");
                                        setPEOSelectedFilter(value ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.PeoName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setPEONameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Carrier Name:</span>
                                <br />
                                <Autocomplete
                                    options={carriersList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={carNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarName(value?.CarrierName ?? "");
                                        setCarrierSelectedFilter(value ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Phase:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={phaseFilter}
                                        onChange={handlePhaseFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {phasesList?.map((p, pi) => (
                                            <option key={`phases-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Updated Group Structure:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={updatedGS ?? ""}
                                        onChange={handleUpdatedGSFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Assigned To:</span>
                                <br />
                                <Autocomplete
                                    options={contactsList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={assignedToAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setAssignedTo(newValue ?? "");
                                        // onAssignedToFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setAssignedToAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>isolved Contact:</span>
                                <br />
                                <Autocomplete
                                    options={groupsAndOEContactsList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={iSolvedContactAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setISolvedContact(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setISolvedContactAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Draft:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={draft ?? ""}
                                        onChange={handleDraftFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                    <span>Request Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={requestType ?? ""}
                                            onChange={handleRequestTypeFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                        {requestTypes?.map((p, pi) => (
                                            <option key={`oe-request-types-list-${pi}`} value={p.RequestTypeId}>{p.RequestTypeName}</option>
                                        ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3"></div>
                            <div className="col-xl-3 mt-3">
                                <span>Created Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedFromDateFilter}
                                    onChange={(date) => setSelectedCreatedFromDateFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedToDateFilter}
                                    onChange={(date) => setSelectedCreatedToDateFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Plan Year Start Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedPlanYearStartFromDateFilter}
                                    onChange={(date) => setSelectedPlanYearStartFromDateFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedPlanYearStartToDateFilter}
                                    onChange={(date) => setSelectedPlanYearStartToDateFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Client's Expected Data Ready Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedExpectedFromDateFilter}
                                    onChange={(date) => setSelectedExpectedFromDateFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedExpectedToDateFilter}
                                    onChange={(date) => setSelectedExpectedToDateFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>OE File Transmission Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedOEFileSubmissionFromDateFilter}
                                    onChange={(date) => setSelectedOEFileSubmissionFromDateFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedOEFileSubmissionToDateFilter}
                                    onChange={(date) => setSelectedOEFileSubmissionToDateFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                        </div>
                    )
                    }
                    <div className="col-xl-12 mt-4" >
                        <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                            <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                                <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                            </svg>
                            <span className="ml-1">Refresh List</span>
                        </Button>
                        <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                            <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2">
                                <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                            </svg> Clear Search
                        </Button>
                    </div>
                    {isIsovedSuperAdminOrSuperAdmin() &&
                        <div className="col-xl-12 mt-4" style={{ textAlign: "end" }}>
                            <Button
                                className=" blue-outlined-btn"
                                disabled={selectedOEs.length < 1}
                                onClick={(e) => {
                                    setoesClicked(true)
                                    handleDataIsReady()
                                }}
                            >
                                <svg width="16" height="13" viewBox="0 0 16 13" >
                                    <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                </svg>
                                <span className="ml-2">Data is ready</span>
                            </Button>
                        </div>
                    }

                    <OERequestsTable
                        tableRef={tableRef}
                        advancedFiltersClicked={advancedFiltersClicked}
                        quickFiltersClicked={quickFiltersClicked}
                        oERequestsList={oERequestsList}
                        setOERequestsList={setOERequestsList}
                        selectedPage={selectedPage}
                        pageSize={pageSize}
                        totalCount={totalCount}
                        setPageSize={setPageSize}
                        setSelectedPage={setSelectedPage}
                        onSortOrderFilter={(s) => setSortOrderFilter(s)}
                        onSortFieldFilter={(s) => setSortFieldFilter(s)}
                        selectedPageAdv={selectedPageAdv}
                        onSetSelectedPageAdv={setSelectedPageAdv}
                        pageSizeAdv={pageSizeAdv}
                        onSetPageSizeAdv={setPageSizeAdv}
                        onSortFieldAdvFilter={setSortFieldAdvFilter}
                        onSortOrderAdvFilter={setSortOrderAdvFilter}
                        selectedOEs={selectedOEs}
                        setSelectedOEs={(s) => setSelectedOEs(s)}
                    />
                </div >
            </Paper >

            <OERequestsModalBodyFilter
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onSetPageSizeAdv={(s) => setPageSizeAdv(s)}
                onSetSelectedPageAdv={setSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                setOERequestIDFilterAdvFilter={setOERequestIDAdvFilter}
                setSelectedPlanYearStartFromDateFilterAdvFilter={setSelectedPlanYearStartFromDateAdvFilter}
                setSelectedPlanYearStartToDateFilterAdvFilter={setSelectedPlanYearStartToDateAdvFilter}
                setSelectedExpectedFromDateFilterAdvFilter={setSelectedExpectedFromDateAdvFilter}
                setSelectedExpectedToDateFilterAdvFilter={setSelectedExpectedToDateAdvFilter}
                setSelectedCreatedFromDateFilterAdvFilter={setSelectedCreatedFromDateAdvFilter}
                setSelectedCreatedToDateFilterAdvFilter={setSelectedCreatedToDateAdvFilter}
                setStatusFilterAdvFilter={setStatusAdvFilter}
                setPhaseFilterAdvFilter={setPhaseAdvFilter}
                setOETypeFilterAdvFilter={setOETypeAdvFilter}
                setUpdatedGSAdvFilter={setUpdatedGSAdvFilter}
                setDraftAdvFilter={setDraftAdvFilter}
                setAssignedToAdvFilter={setAssignedToAdvFilter}
                setISolvedContactAdvFilter={setISolvedContactAdvFilter}
                setConnectivityRequestIDAdvFilter={setConnectivityRequestIDAdvFilter}
                onSelectedAssignedToFilter={setSelectedAssignedToFilter}
                selectedAssignedToFilter={selectedAssignedToFilter}
                onSelectedISolvedContactFilter={setSelectedISolvedContactFilter}
                selectedISolvedContactFilter={selectedISolvedContactFilter}
                setSelectedStatusesFilter={setSelectedStatusesFilter}
                setSelectedPhasesFilter={setSelectedPhasesFilter}
                setSelectedOETypesFilter={setSelectedOETypesFilter}
                onCloseClearReset={handleClearSearchReset}
                onSelectedClientsFilter={(s) => setSelectedClientsFilter(s)}
                selectedClientsFilter={selectedClientsFilter}
                onSelectedPEOsFilter={(s) => setSelectedPEOsFilter(s)}
                selectedPEOsFilter={selectedPEOsFilter}
                onSelectedCarriersFilter={(s) => setSelectedCarriersFilter(s)}
                selectedCarriersFilter={selectedCarriersFilter}
                setSelectedOEFileSubmissionFromDateFilterAdvFilter={setSelectedOEFileSubmissionFromDateAdvFilter}
                setSelectedOEFileSubmissionToDateFilterAdvFilter={setSelectedOEFileSubmissionToDateAdvFilter}
                setRequestTypeAdvFilter={setRequestTypeAdvFilter}
            />

        </div >
    )
}

export default ViewOERequestsList
