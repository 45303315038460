import React from "react";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import TabsTitles from "../../../Layout/TabsTitles";
import { createStyles, makeStyles, MenuItem, FormControl, Paper, Select, TextField, Button } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useParams } from "react-router-dom";
import Moment from 'moment';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { FailureModal } from "../../../Modals/FailureModal";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useLoader } from "../../../Layout/Loader";
import { useHistory } from "react-router-dom";
import { DropzoneArea } from 'material-ui-dropzone';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { parseISO } from 'date-fns';
import { useAuthentication } from "../../../../Data/Authentication";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import { Grid } from "@mui/material";
import GenericDatePicker from "../../../Layout/GenericDatePicker";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210,
        marginTop: 10,
        padding: 6,
        backgroundColor: "#E5E5E5",
        borderColor: "#E5E5E5",
    },
}));
const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

function AnalystConnectivityRequestAddActionItem() {


    const classes = useStyles();
    const dialog = useModalDialog(0);
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const { connectivityRequestIDURL } = useParams();
    const [responsiblePartiesList, setResponsiblePartiesList] = React.useState([]);
    const [responsibleParty, setResponsibleParty] = React.useState("");
    const [contactsList, setContactsList] = React.useState([]);
    const [contactsAnalystsList, setContactsAnalystsList] = React.useState([])
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [primaryAnalyst, setPrimaryAnalyst] = React.useState("");
    const [isolvedRepresentative, setIsolvedRepresentative] = React.useState("");
    const [contact, setContact] = React.useState("");
    const [actionItemText, setActionItemText] = React.useState("");
    const [actionItemClosureReason, setActionItemClosureReason] = React.useState("");
    const [actionItemDescription, setActionItemDescription] = React.useState("");
    const [dueDate, setDueDate] = React.useState(Moment(todayDate).format('YYYY-MM-DD'));
    const [completionDate, setCompletionDate] = React.useState(Moment(null).format('YYYY-MM-DD'));
    const [statusesList, setStatusesList] = React.useState([]);
    const [status, setStatus] = React.useState("");
    const [fileAttached, setFileAttached] = React.useState();
    const [fileAttachedList, setFileAttachedList] = React.useState([]);
    const [fileAttachedBase64, setFileAttachedBase64] = React.useState();
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    // Validations
    const [selectResponsiblePartyValidation, setSelectResponsiblePartyValidation] = React.useState("");
    const [selectContactValidation, setSelectContactValidation] = React.useState("");
    const [selectActionItemTextValidation, setSelectActionItemTextValidation] = React.useState("");
    const [selectActionItemDescriptionValidation, setSelectActionItemDescriptionValidation] = React.useState("");
    const [selectDueDateValidation, setSelectDueDateValidation] = React.useState("");
    const [selectStatusValidation, setSelectStatusValidation] = React.useState("");
    const [selectPrimaryAnalystValidation, setSelectPrimaryAnalystValidation] = React.useState("");
    const [selectIsolvedRepValidation, setSelectIsolvedRepValidation] = React.useState("");
    const [selectCompletionDateValidation, setSelectCompletionDateValidation] = React.useState("");
    const [selectClosureReasonValidation, setSelectClosureReasonValidation] = React.useState("");
    const [limitedTitleCharMsg, setLimitedTitleCharMsg] = React.useState("");
    const [tempGUID, setTempGUID] = React.useState("");
    const fileInputRef = React.useRef();
    const [connectivityReq, setConnectivityReq] = React.useState();

    //Role
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const isSuperAdminOrAnalyst = () => {
        return userRoles?.includes("1") || userRoles?.includes("7")
    }


    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); }).finally(() => closeLoader());
    }, [])
    React.useEffect(() => {
        if (!responsibleParty) setSelectResponsiblePartyValidation("Responsible party is missing, please select a responsible party from the list"); else setSelectResponsiblePartyValidation("");
    }, [responsibleParty, selectResponsiblePartyValidation])
    React.useEffect(() => {
        if (!contact) setSelectContactValidation("Contact name is missing, please select a contact from the list"); else setSelectContactValidation("");
    }, [contact, selectContactValidation])
    React.useEffect(() => {
        if (!actionItemText?.replaceAll(" ", "")) setSelectActionItemTextValidation("Action item title is missing, please describe briefly your case"); else setSelectActionItemTextValidation("");
    }, [actionItemText, selectActionItemTextValidation])
    React.useEffect(() => {
        if (!actionItemDescription?.replaceAll(" ", "").replaceAll("\n", "")) setSelectActionItemDescriptionValidation("Action item description is missing, please describe your case"); else setSelectActionItemDescriptionValidation("");
    }, [actionItemDescription, selectActionItemDescriptionValidation])
    React.useEffect(() => {
        if (!dueDate || dueDate === "Invalid date") setSelectDueDateValidation("Due date cannot be empty"); else setSelectDueDateValidation("");
    }, [dueDate, selectDueDateValidation])
    React.useEffect(() => {
        if (!status) setSelectStatusValidation("Action item status is not set, please select a status from the list"); else setSelectStatusValidation("");
    }, [status, selectStatusValidation])

    // responsibleParty = 1 (iSolved) , 3 (Analyst)
    React.useEffect(() => {
        if (!primaryAnalyst &&
            ((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                || (responsibleParty === 3 && !(isSuperAdminOrAnalyst())))
        )
            setSelectPrimaryAnalystValidation("Primary Analyst is not set , Please select Primary Analyst From the List");
        else setSelectPrimaryAnalystValidation("");
    }, [primaryAnalyst, responsibleParty, selectPrimaryAnalystValidation])

    React.useEffect(() => {
        if (!isolvedRepresentative &&
            ((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                || (responsibleParty === 3 && !(isSuperAdminOrAnalyst())))
        )
            setSelectIsolvedRepValidation("ISOLVED Representative is not set , Please select ISOLVED Representative From the List");
        else setSelectIsolvedRepValidation("");
    }, [isolvedRepresentative, responsibleParty, selectIsolvedRepValidation])

    React.useEffect(() => {
        if (status === 2 && (!completionDate || completionDate === "Invalid date")) setSelectCompletionDateValidation("Completion date cannot be empty if the action item is closed"); else setSelectCompletionDateValidation("");
    }, [status, completionDate, selectCompletionDateValidation])
    React.useEffect(() => {
        if (status === 2 && !actionItemClosureReason?.replaceAll(" ", "").replaceAll("\n", "")) setSelectClosureReasonValidation("Closure Reason cannot be empty if the action item is closed"); else setSelectClosureReasonValidation("");
    }, [status, actionItemClosureReason, selectClosureReasonValidation])

    React.useEffect(() => {
        APIs.getResponsiblePartiesList().then(r => !(r?.data?.length) ? setResponsiblePartiesList([]) : setResponsiblePartiesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getActionItemStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
            ConnectivityRequestID: connectivityRequestIDURL,
        }
        APIs.getContactsList(contactsBody).then(r => {
            !(r?.data?.length) ? setContactsAnalystsList([]) : setContactsAnalystsList(r?.data);
        })
    }, [])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: responsibleParty,
            ConnectivityRequestID: connectivityRequestIDURL
        }
        APIs.getContactsList(contactsBody).then((r) => {
            !(r?.data?.length) ? setContactsList([]) : setContactsList(r?.data);
        })
    }, [responsibleParty])

    const handleFileAttachedChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileAttachedBase64(files);
        }
    }

    const handelDeleteFile = (fileObj) => {
        let fileAttachedListArray = fileAttachedList;
        fileAttachedListArray.splice(fileAttachedListArray.findIndex((element) => {
            return element === fileObj;
        }), 1);
        setFileAttachedList([...fileAttachedListArray]);
    }


    const encodeFileAttachedBase64 = async (files) => {
        files.map((file, fi) => {
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result?.split(',')[1];
                    addCurrentFilesInArray(file, Base64,);

                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };

    const addCurrentFilesInArray = (file, fileBase64,) => {
        if (file === undefined) return;
        openLoader();
        let UploadedFilesArray = fileAttachedList;
        let newFile = {
            Type: "ActionItem",
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Name: file?.name,
        }
        let newFileUploadAPI = {
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Value: fileBase64,
        }
        APIs.addActionItemAttachment(newFileUploadAPI).then(r => {
            newFile.GUID = r.GUID;
            setTempGUID(r?.GUID);
        });
        UploadedFilesArray.push(newFile);
        setFileAttachedList([...UploadedFilesArray]);
    }
    const handleDueDateChange = (date) => {
        setDueDate(Moment(date).format('YYYY-MM-DD'));
    };

    const handleCompletionDateChange = (date) => {
        setCompletionDate(Moment(date).format('YYYY-MM-DD'));
    };



    React.useEffect(() => {
        if (status === 2) setCompletionDate(Moment(todayDate).format('YYYY-MM-DD')); else setCompletionDate(null);
    }, [status])

    const submitActionItem = (e) => {
        e.preventDefault();
        if (selectResponsiblePartyValidation || selectContactValidation || selectActionItemTextValidation || selectActionItemDescriptionValidation || selectDueDateValidation || selectStatusValidation || selectCompletionDateValidation || selectClosureReasonValidation || selectPrimaryAnalystValidation || selectIsolvedRepValidation) return;
        openLoader();
        let actionItemBody = {};
        if (responsibleParty === 2) {
            actionItemBody = {
                ConnectivityRequestID: connectivityRequestIDURL,
                ResponsiblePartyID: responsibleParty,
                CarrierContactID: contact?.Id,
                ActionItemText: actionItemText,
                Description: actionItemDescription.replaceAll("\n", "<br/>"),
                DueDate: dueDate,
                Status: status,
                CompletionDate: completionDate,
                NewAttachments: fileAttachedList,
                IsolvedRepresentative: isolvedRepresentative,
                PrimaryAnalyst: primaryAnalyst
            }
        } else {
            actionItemBody = {
                ConnectivityRequestID: connectivityRequestIDURL,
                ResponsiblePartyID: responsibleParty,
                ContactID: contact?.Id,
                ActionItemText: actionItemText,
                Description: actionItemDescription.replaceAll("\n", "<br/>"),
                DueDate: dueDate,
                Status: status,
                CompletionDate: completionDate,
                NewAttachments: fileAttachedList,
                IsolvedRepresentative: isolvedRepresentative,
                PrimaryAnalyst: primaryAnalyst
            }
        }

        APIs.createActionItem(actionItemBody).then((r) => {
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            } else {
                routeHistory.push(`/ActionItems/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`)
            }
        }).catch((s) => FailureModal(dialog, {
            title: 'Failure',
            body: 'Error processing request',
        })).finally((t) => closeLoader());
    }

    const handleLimitedTitleCharRestriction = (e) => {
        if (e.target.value.length >= 59 && e.key !== "Backspace") {
            e.preventDefault();
            setLimitedTitleCharMsg(`Action item should not be longer than 60 characters`);
        } else {
            setLimitedTitleCharMsg("");
        }
    }

    React.useEffect(() => {
        if (fileAttachedList?.any(s => !s.GUID)) {
            openLoader();
        }
        else closeLoader();
    }, [tempGUID])

    return (
        <div id="create-action-item">
            {connectivityReq && <BreadcrumbSteps
                breadcrumbTitle={`Connectivity Request "${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName} - ${connectivityReq?.CarrierName === "Third Party" ? connectivityReq?.CarrierName + " (" + connectivityReq?.ThirdPartyName + ") " : connectivityReq?.CarrierName}"`}
                isCurrent={false}
                breadcrumbTitleCurrent="Add Action Item"
                breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
            />}
            <TabsTitles tabTitle={["Add Action Item"]} />
            <Paper elevation={0} className="p-5">
                <form onSubmit={submitActionItem}>

                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* Responsible Party */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Responsible Party</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <FormControl label="Select responsible party" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={responsibleParty ?? ""}
                                    onChange={e => setResponsibleParty(e.target.value)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {responsiblePartiesList && responsiblePartiesList?.map((c, ci) => (
                                        <MenuItem key={`responsible-parties-list-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectResponsiblePartyValidation}</div>
                        </Grid>
                        {/* Contact Name */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Contact Name</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <Autocomplete
                                options={responsibleParty === 2 ? contactsList : contactsList.filter(s => s.IsEnabled === 1)}
                                autoHighlight
                                getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                onChange={(e, newValue) => {
                                    setContact(newValue);
                                }}
                                getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {`${option.FirstName} ${option.LastName}`}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectContactValidation}</div>
                        </Grid>
                        {/* contact */}
                        {contact && (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Contact Email</h6>
                                    <div className="textfield-div">
                                        {contact?.Email}
                                    </div>
                                </Grid>
                            </>
                        )}
                        {/* Action Item Title */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Action Item Title</h6>
                                <span className="text-danger ml-2">*</span>
                            </div>
                            <input type="text" className="text-input w-100" value={actionItemText?.substring(0, 60)} onKeyDown={handleLimitedTitleCharRestriction} onChange={(e) => { setActionItemText(e.target.value); }} />

                            <div className="mt-2 text-danger">{limitedTitleCharMsg}</div>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectActionItemTextValidation}</div>
                        </Grid>
                        {/* Description */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Description</h6>
                                <span className="text-danger ml-2">*</span>
                            </div>
                            <textarea type="text" className="action-item-textarea w-100" value={actionItemDescription} onChange={(e) => { setActionItemDescription(e.target.value) }} rows={4} />
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectActionItemDescriptionValidation}</div>
                        </Grid>
                        {/* Due Date */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Due Date</h6>
                                <span className="text-danger ml-2">*</span>
                            </div>

                            <div className="mt-md-n2">
                            <GenericDatePicker
                                classNames="w-100 m-auto"
                                id="frequency-date"
                                value={dueDate ? parseISO(dueDate) : null}
                                onChange={(date) => {
                                    if (!date) {
                                        setDueDate(null); // Handle clearing date properly
                                    } else {
                                        const formattedDate = Moment(date).format("YYYY-MM-DD"); // Keep consistent ISO format
                                        setDueDate(formattedDate);
                                    }
                                }}
                                placeholder="Due Day"
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                            </div>
                            <div className="mt-3 text-danger" hidden={!requestSubmitted}>{selectDueDateValidation}</div>
                        </Grid>
                        {/* Status */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Status</h6>
                                <span className="text-danger ml-2">*</span>
                            </div>

                            <FormControl label="Select status" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={status ?? ""}
                                    onChange={e => setStatus(e.target.value)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {statusesList && statusesList?.filter(s => s.Id !== 2).map((c, ci) => (
                                        <MenuItem key={`statuses-list-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectStatusValidation}</div>
                        </Grid>
                        {/* Primary Analyst */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Primary Analyst</h6>
                                {((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                                    || (responsibleParty === 3 && !(isSuperAdminOrAnalyst()))) && <span className="text-danger ml-2">*</span>}
                            </div>
                            <FormControl label="Select assigned to" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={primaryAnalyst ?? ""}
                                    onChange={e => setPrimaryAnalyst(e.target.value)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {contactsAnalystsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectPrimaryAnalystValidation}</div>
                        </Grid>
                        {/* isolved Representative */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">isolved Representative</h6>
                                {((responsibleParty === 1 && (isSuperAdminOrAnalyst()))
                                    || (responsibleParty === 3 && !(isSuperAdminOrAnalyst()))) && <span className="text-danger ml-2">*</span>}

                            </div>
                            <FormControl label="Select isolved representative" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={isolvedRepresentative ?? ""}
                                    onChange={e => setIsolvedRepresentative(e.target.value)}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectIsolvedRepValidation}</div>

                        </Grid>
                        {/* status == 2 >> Closure Reason */}
                        {status === 2 && (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Closure Reason</h6>
                                        <span className="text-danger ml-2">*</span>
                                    </div>
                                    <textarea type="text" className="action-item-textarea w-100" value={actionItemClosureReason} onChange={(e) => { setActionItemClosureReason(e.target.value) }} rows={4} />
                                </Grid>
                            </>
                        )}
                        {/* status == 2 >> Completion Date*/}
                        {status === 2 && (
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Completion Date</h6>
                                        <span className="text-danger ml-2">*</span>
                                    </div>

                                    <div className="mt-md-n2">
                                        <GenericDatePicker
                                                classNames="w-100 m-auto"
                                                id="frequency-date"
                                                value={completionDate ? parseISO(completionDate) : null}
                                                onChange={(date) => {
                                                    if (!date) {
                                                        setCompletionDate(null); // Handle clearing date properly
                                                    } else {
                                                        const formattedDate = Moment(date).format("YYYY-MM-DD"); // Keep consistent ISO format
                                                        setCompletionDate(formattedDate);
                                                    } 
                                                }}
                                                placeholder="Completion Day"
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                    </div>

                                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectResponsiblePartyValidation}</div>
                                </Grid>
                            </>
                        )}
                        {/* Attach File */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Attach File</h6>
                            <div className="container-div p-3">
                                <DropzoneArea
                                    maxFileSize={20 * 1024 * 1024}
                                    // add max size 20mb                                 dropzoneParagraphClass={"MuiDropzoneArea-text-updated-gray"}
                                    filesLimit={1000}
                                    dropzoneText={"Add Attachment"}
                                    dropzoneClass={"containerClassDrag"}
                                    onDrop={(files) => handleFileAttachedChangeDrag(files)}
                                    showPreviewsInDropzone={false}
                                    showAlerts={false}
                                />
                                {fileAttachedList.length <= 0 ? (
                                    <div className="forms-list text-center mt-3 w-100">
                                        <h6 className="color grey2">No attachment were added</h6>
                                    </div>
                                ) : (
                                    <div className="forms-list mt-3">
                                        <div className="row table-header">
                                            <div className="col-9 table-cell first-cell">Attachment</div>

                                            <div className="col-3 table-cell">Action</div>
                                        </div>
                                        {fileAttachedList && fileAttachedList?.map((f, fi) => (
                                            <>


                                                <div className="row table-row">
                                                    <div className="col-9 table-cell first-cell">
                                                        {f?.Name}
                                                    </div>
                                                    <div className="col-3 table-cell ">
                                                        <svg width="21" height="24" viewBox="0 0 21 24" className="color grey2 mx-auto" onClick={() => { handelDeleteFile(f) }} style={{ cursor: "pointer" }}>
                                                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                        </svg>
                                                    </div>
                                                </div>


                                            </>
                                        )
                                        )}
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>


                    <div className="w-100 text-right mt-3">
                        <Button type="submit" variant="contained" className="blue-btn" onClick={() => { setRequestSubmitted(true); }}>Submit Action Item</Button>
                    </div>
                </form>
            </Paper>
        </div >
    )
}

export default AnalystConnectivityRequestAddActionItem;
