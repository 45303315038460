import React from "react";
import { Chip } from "@material-ui/core";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import SelectCarriersAndPlans from "../CreateGroupRequest/SelectCarriersAndPlans";
import CarrierContacts from "../CreateGroupRequest/CarrierContacts";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import FilesAttachments from "../../FilesAttachments/FilesAttachments";
import UserAssignement from "../CreateGroupRequest/UserAssignment";
import SelectCarrierPlans from "../CreateGroupRequest/SelectCarrierPlans";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import FormsAttachments from "../../FormsAttachments/FormsAttachments";
import CreateThirdParty from "../CreateGroupRequest/CreateThirdParty";
import SelectConnectivityType from "../CreateGroupRequest/SelectConnectivityType";
import SelectClientInformation from "../CreateGroupRequest/SelectClientInformation";
import SelectVerifiedCarriers from "../CreateGroupRequest/SelectVerifiedCarriers";
import SelectCarrierDetails from "../CreateGroupRequest/SelectCarrierDetails";
import SelectConnectivityMigration from "../CreateGroupRequest/SelectConnectivityMigration";
import { emailPattern } from "./ViewGroupRequestClientContactsRow";
import Grid from '@mui/material/Grid';
import TruncatedChip from "../../Layout/TruncatedChip";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function CreateConnectivity({ groupReq, carrierContactTypes, ...props }) {

    const dialog = useModalDialog(0);
    const [selectedCarriers, setSelectedCarriers] = React.useState([]);
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [requestSaved, setRequestSaved] = React.useState(false);
    const [expanded, setExpanded] = React.useState("connectivityRequest");
    const [plans, setPlans] = React.useState();
    const [selectedThirdParties, setSelectedThirdParties] = React.useState([]);
    const [selectedPlans, setSelectedPlans] = React.useState([]);
    const [subTypeList, setSubTypeList] = React.useState([]);
    const [formsSubTypeList, setFormsSubTypeList] = React.useState([]);
    const [selectCarrierValidationForm, setSelectCarrierValidationForm] = React.useState("");
    const [selectPlanValidationForm, setSelectPlanValidationForm] = React.useState("");
    const [selectCRTypeValidationForm, setSelectCRTypeValidationForm] = React.useState("");
    const [selectCRHREmailValidationForm, setSelectCRHREmailValidationForm] = React.useState("");
    const [attachFormsValidationForm, setAttachFormsValidationForm] = React.useState("");
    const [contactValidationCallback, setContactValidationCallback] = React.useState("");

    const [inputsSavedValidation, setInputsSavedValidation] = React.useState(false);
    const [selectMigrationValidationForm, setSelectMigrationValidationForm] = React.useState("");
    const [selectMigrationStarDateValidationForm, setSelectMigrationStarDateValidationForm] = React.useState("");
    const [selectPlannedFirstProductionDateValidationForm, setSelectPlannedFirstProductionDateValidationForm] = React.useState("");
    const [selectMigrationPhaseValidationForm, setSelectMigrationPhaseValidationForm] = React.useState("");

    const [selectTPMigrationValidationForm, setSelectTPMigrationValidationForm] = React.useState("");
    const [selectTPMigrationStarDateValidationForm, setSelectTPMigrationStarDateValidationForm] = React.useState("");
    const [selectTPPlannedFirstProductionDateValidationForm, setSelectTPPlannedFirstProductionDateValidationForm] = React.useState("");
    const [selectTPMigrationPhaseValidationForm, setSelectTPMigrationPhaseValidationForm] = React.useState("");

    const [selectCRContactValidationForm, setSelectCRContactValidationForm] = React.useState("");
    const [selectCRContactFirstNameValidationForm, setSelectCRContactFirstNameValidationForm] = React.useState("");
    const [selectCRContactLastNameValidationForm, setSelectCRContactLastNameValidationForm] = React.useState("");
    const [selectCRContactEmailValidationForm, setSelectCRContactEmailValidationForm] = React.useState("");
    const [selectCRContactTypeValidationForm, setSelectCRContactTypeValidationForm] = React.useState("");



    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [, forceUpdate] = React.useReducer(x => !x, false);

    const [otherPlanChecked, setOtherPlanChecked] = React.useState(false);

    const [carrierNotListedChecked, setCarrierNotListedChecked] = React.useState(false);
    const [selectedOtherCarriers, setSelectedOtherCarriers] = React.useState([]);
    const [selectedOtherCarriersChecked, setSelectedOtherCarriersChecked] = React.useState(false);

    const [internalCarriers, setInternalCarriers] = React.useState([]);
    const [internalCarrierName, setInternalCarrierName] = React.useState("");

    const [selectedGroupCarriers, setSelectedGroupCarriers] = React.useState([]);
    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [indexCallback, setIndexCallback] = React.useState(-1);
    const [internalCarrier, setInternalCarrier] = React.useState();
    const [matchCarriersValidationForm, setMatchCarriersValidationForm] = React.useState("");
    const [carrierCodeValidationForm, setCarrierCodeValidationForm] = React.useState("");
    const [carrierNameValidationForm, setCarrierNameValidationForm] = React.useState("");
    const [thirdPartyNameValidationForm, setThirdPartyNameValidationForm] = React.useState("");
    const [thirdPartyPlansValidationForm, setThirdPartyPlansValidationForm] = React.useState("");

    const [thirdPartyContactsValidationForm, setThirdPartyContactsValidationForm] = React.useState("");
    const [thirdPartyContactsFirstNameValidationForm, setThirdPartyContactsFirstNameValidationForm] = React.useState("");
    const [thirdPartyContactsLastNameValidationForm, setThirdPartyContactsLastNameValidationForm] = React.useState("");
    const [thirdPartyContactsEmailValidationForm, setThirdPartyContactsEmailValidationForm] = React.useState("");
    const [thirdPartyContactsTypeValidationForm, setThirdPartyContactsTypeValidationForm] = React.useState("");


    const [selectThirdPartyTypeValidationForm, setSelectThirdPartyTypeValidationForm] = React.useState("");
    const [thirdPartyAttachFormsValidationForm, setThirdPartyAttachFormsValidationForm] = React.useState("");

    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    // const [selectFormMainTypesValidationForm, setSelectFormMainTypesValidationForm] = React.useState("");
    // const [selectFormSubTypesValidationForm, setSelectFormSubTypesValidationForm] = React.useState("");
    const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);
    const [selectClientContactValidationForm, setSelectClientContactValidationForm] = React.useState("");
    const [selectClientContactTPValidationForm, setSelectClientContactTPValidationForm] = React.useState("");
    const [SelectClientEffectiveDateTPValidationForm, setSelectClientEffectiveDateTPValidationForm] = React.useState("");

    const [selectClientEffectiveDateValidationForm, setSelectClientEffectiveDateValidationForm] = React.useState("");

    const [tabValueCallback, setTabValueCallback] = React.useState(0);
    const [duplicateCarriersList, setDuplicateCarriersList] = React.useState([]);
    const [similarCarriersList, setSimilarCarriersList] = React.useState([]);
    const [continueWithWarning, setContinueWithWarning] = React.useState({});
    const [duplicateValidation, setDuplicateValidation] = React.useState({});

    const checkAllThirdPartiesContactsValidation = () => {
        return thirdPartyContactsValidationForm ||
            thirdPartyContactsFirstNameValidationForm ||
            thirdPartyContactsLastNameValidationForm ||
            thirdPartyContactsEmailValidationForm ||
            thirdPartyContactsTypeValidationForm
    }

    //Get EDI Sub Types (on Page start)
    React.useEffect(() => {
        APIs.getConnectivityRequestEDISubTypes().then((r) => {
            setSubTypeList(r?.data);
        });
    }, [])

    //Get Forms Sub Types (on Page start)
    React.useEffect(() => {
        APIs.getConnectivityRequestFormsSubTypes().then((r) => {
            setFormsSubTypeList(r?.data);
        });
    }, [])

    React.useEffect(() => {
        APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));
    }, [])


    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        APIs.getCarriers({ StatusTypeId: [1, 2] }).then(r => setInternalCarriers(r?.data)); //1,2 to get approved and pending carriers only
    }, [])

    React.useEffect(() => {
        if (groupReq?.groupRequest?.IsPEO === 1) closeLoader();
        else {
            openLoader();
            const singleGroupBody = {
                InstanceID: groupReq?.groupRequest?.GroupInstanceID,
                ClientID: groupReq?.groupRequest?.GroupClientID,
                ClientCode: groupReq?.groupRequest?.GroupNumber,
                editMode: true // returns data without misc fields.
            }
            APIs.getSingleISolvedGroupDetails(singleGroupBody).then((r) => setSelectedGroupCarriers(r?.data?.Carriers));
        }
    }, [groupReq]);

    React.useEffect(() => {
        if (groupReq?.groupRequest?.IsPEO === 1) closeLoader();
        else if (selectedGroupCarriers) closeLoader(); else openLoader();
    }, [selectedGroupCarriers])

    React.useEffect(() => {
        if (!selectedCarriers) return;
        const selectedCarrier = selectedCarriers[indexCallback];
        if (!selectedCarrier) return;
        selectedCarrier.CarrierID = internalCarrier;
        setInternalCarrierName(internalCarriers?.filter(f => f.CarrierID === selectedCarrier.CarrierID)[0]?.CarrierName);
        selectedCarrier.CarrierInternalName = internalCarriers?.filter(f => f.CarrierID === selectedCarrier.CarrierID)[0]?.CarrierName ?? "Other";
    }, [internalCarrier]);


    const handleCarrierSearchValueChange = (e) => {
        setCarrierSearchValue(e.target.value);
    }


    React.useEffect(() => {
        if (selectedCarriers.length <= 0 && selectedThirdParties?.length <= 0 && (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length <= 0 || (!selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.length <= 0))) setSelectCarrierValidationForm("Please select at least one carrier"); else setSelectCarrierValidationForm("");
    }, [selectedCarriers, selectedThirdParties, selectedOtherCarriers, selectCarrierValidationForm])

    React.useEffect(() => {
        if (selectedCarriers.filter(s => s.InternalCarrierID === null && (!s.CarrierInternalName || s.CarrierInternalName === "")).length > 0)
            setMatchCarriersValidationForm("Please match all selected Carriers"); else setMatchCarriersValidationForm("");
    }, [JSON.stringify(selectedCarriers), matchCarriersValidationForm])

    React.useEffect(() => {
        if (groupReq?.groupRequest?.IsPEO === 1 && ((selectedCarriers && selectedCarriers?.filter(s => ((!s.Code || s.Code === ""))).length > 0) || (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.Code || s.Code === ""))).length > 0)))
            setCarrierCodeValidationForm("Please add carrier code for all selected Carriers"); else setCarrierCodeValidationForm("");
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), carrierCodeValidationForm])

    React.useEffect(() => {
        if (groupReq?.groupRequest?.IsPEO === 1 && selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.Name || s.Name === ""))).length > 0)
            setCarrierNameValidationForm("Please add carrier name for all selected Carriers");
        else if (hasDuplicateErrorsOrWarnings()) setCarrierNameValidationForm("One or more Carrier Name have duplicate or similar names. Please review.");
        else setCarrierNameValidationForm("");
    }, [JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), JSON.stringify(duplicateValidation), carrierNameValidationForm])

    React.useEffect(() => {
        if (groupReq?.groupRequest?.IsPEO !== 1 && selectedCarriers && selectedCarriers?.filter(s => (!s.SelectedCRTypes || s.SelectedCRTypes.length <= 0)).length > 0 ||
            (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.SelectedCRTypes || s.SelectedCRTypes.length <= 0))).length > 0)) {
            setSelectCRTypeValidationForm("Please select at least one connectivity request type for each Carrier selected");
        }
        else if (selectedCarriers && selectedCarriers.filter(s => s.SelectedCRTypes?.includes("Forms"))?.filter(d => d.ConnectivityRequestFormsSubTypeID?.length <= 0 || !d.ConnectivityRequestFormsSubTypeID).length > 0) {
            setSelectCRTypeValidationForm("Please select at least one sub type for each Carrier selected");
        }
        else {
            setSelectCRTypeValidationForm("");
        }
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectCRTypeValidationForm])

    React.useEffect(() => {

        let newselectedHREmailValidation = []
        let newselectedHREmailFormatValidation = []

        selectedCarriers?.map(c => {
            var emails = c.HREmail?.replace(/\s/g, '').split(",");
            var valid = true;

            for (var i = 0; i < emails?.length; i++) {
                if (emails[i] == "" || !emailPattern.test(emails[i])) {
                    valid = false;
                }
            }

            if (c.SelectedCRTypes?.includes("Forms") && c.HREmail?.split(" ").join("").length == 0) {
                newselectedHREmailValidation.push(c)
            }
            else if (c.SelectedCRTypes?.includes("Forms") && !valid) {
                newselectedHREmailFormatValidation.push(c)
            }

        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            var emails = c.HREmail?.replace(/\s/g, '').split(",");
            var valid = true;

            for (var i = 0; i < emails?.length; i++) {
                if (emails[i] == "" || !emailPattern.test(emails[i])) {
                    valid = false;
                }
            }

            if (c.SelectedCRTypes?.includes("Forms") && c.HREmail?.split(" ").join("").length == 0) {
                newselectedHREmailValidation.push(c)
            }
            else if (c.SelectedCRTypes?.includes("Forms") && !valid) {
                newselectedHREmailFormatValidation.push(c)
            }
        });


        if (newselectedHREmailValidation.length > 0) {
            setSelectCRHREmailValidationForm("Please add at least Email for each HR Email at each Carrier selected");
        }
        else if (newselectedHREmailFormatValidation.length > 0) {
            setSelectCRHREmailValidationForm("Please add valid Email for each HR Email at each Carrier selected");
        }
        else setSelectCRHREmailValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsEmailValidationForm])


    // =================================================[Client Contact Validations]
    React.useEffect(() => {
        if (
            (selectedCarriers?.length > 0 && selectedCarriers && selectedCarriers?.filter(c => (c.Contacts ?? []).length === 0).length > 0) ||
            (selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(c => (c.Contacts ?? []).length === 0).length > 0)) {
            setSelectCRContactValidationForm("Please add at least one Carrier contact for each Carrier selected");
        }
        else {
            setSelectCRContactValidationForm("");
        }
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectCRContactValidationForm])
    // Client Contact First Name Vaildation
    React.useEffect(() => {
        let newselectedCarriersContactsWithFirstNameValidation = []
        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithFirstNameValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {

            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithFirstNameValidation.push(contact)
                }
            })
        });

        if (newselectedCarriersContactsWithFirstNameValidation.length > 0) {
            setSelectCRContactFirstNameValidationForm("Please add at least First Name for each contact at each Carrier selected");
        }
        else setSelectCRContactFirstNameValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsFirstNameValidationForm])


    // Client Contact Last Name Vaildation
    React.useEffect(() => {
        let newselectedCarriersContactsWithLastNameValidation = []
        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithLastNameValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0) {
                    newselectedCarriersContactsWithLastNameValidation.push(contact)
                }
            })
        });

        if (newselectedCarriersContactsWithLastNameValidation.length > 0) {
            setSelectCRContactLastNameValidationForm("Please add at least Last Name for each contact at each Carrier selected");
        }
        else setSelectCRContactLastNameValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsLastNameValidationForm])

    // Client Contact type Vaildation
    React.useEffect(() => {
        let newselectedCarriersContactsTypeValidation = []
        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.CarrierContactType.split(" ").join("").length == 0) {
                    newselectedCarriersContactsTypeValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.CarrierContactType.split(" ").join("").length == 0) {
                    newselectedCarriersContactsTypeValidation.push(contact)
                }
            })
        });

        if (newselectedCarriersContactsTypeValidation.length > 0) {
            setSelectCRContactTypeValidationForm("Please add at least type for each contact at each Carrier selected");
        }
        else setSelectCRContactTypeValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsTypeValidationForm])

    // CLient Contact Email Validation
    React.useEffect(() => {

        let newselectedCarriersContactsEmailValidation = []
        let newselectedCarriersContactsEmailFormatValidation = []

        selectedCarriers?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
                    newselectedCarriersContactsEmailValidation.push(contact)
                } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
                    newselectedCarriersContactsEmailFormatValidation.push(contact)
                }
            })
        });
        selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => {
            return c.Contacts?.map(contact => {
                if (contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
                    newselectedCarriersContactsEmailValidation.push(contact)
                } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
                    newselectedCarriersContactsEmailFormatValidation.push(contact)
                }
            })
        });


        if (newselectedCarriersContactsEmailValidation.length > 0) {
            setSelectCRContactEmailValidationForm("Please add at least Email for each contact at each Carrier selected");
        }
        else if (newselectedCarriersContactsEmailFormatValidation.length > 0) {
            setSelectCRContactEmailValidationForm("Please add valid Email for each contact at each Carrier selected");
        }
        else setSelectCRContactEmailValidationForm("");
    }, [tabValueCallback, JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), thirdPartyContactsEmailValidationForm])

    // //===============================================[Client Contact Validations]


    React.useEffect(() => {
        if ((selectedCarriers && selectedCarriers?.filter(s => ((!s.SelectedPlans || s.SelectedPlans.length <= 0) && (!s.OtherPlanTypes || s.OtherPlanTypes === "")) || (s.SelectedPlans.filter(s => s !== "Other").length <= 0 && (!s.OtherPlanTypes || s.OtherPlanTypes === ""))).length > 0) ||
            (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => ((!s.SelectedPlans || s.SelectedPlans.length <= 0) && (!s.OtherPlanTypes || s.OtherPlanTypes === "")) || (s.SelectedPlans.filter(s => s !== "Other").length <= 0 && (!s.OtherPlanTypes || s.OtherPlanTypes === ""))).length > 0)) {
            setSelectPlanValidationForm("Please select at least one plan for each Carrier selected");
        } else {
            setSelectPlanValidationForm("");
        }
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectPlanValidationForm])


    React.useEffect(() => {
        if (selectedCarriers && selectedCarriers.filter(s => s.SelectedCRTypes?.includes("Forms"))?.filter(d => d.FormsAttachments?.length <= 0 || !d.FormsAttachments).length > 0) {
            setAttachFormsValidationForm("Please attach at least one form for each form Connectivity Request selected");
        } else {
            setAttachFormsValidationForm("");
        }
    }, [JSON.stringify(selectedCarriers), attachFormsValidationForm])

    React.useEffect(() => {
        if ((selectedCarriers && selectedCarriers?.filter(s => ((!s.Migration || s.Migration === ""))).length > 0))
            setSelectMigrationValidationForm("Migration type is missing, please select a type from the list for all selected Carriers"); else setSelectMigrationValidationForm("");
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectMigrationValidationForm])

    React.useEffect(() => {
        if ((selectedCarriers && selectedCarriers?.filter(s => (s.Migration !== "No" && (!s.MigrationStartDate || s.MigrationStartDate === "" || s.MigrationStartDate === "Invalid date"))).length > 0)
            || (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => (s.Migration !== "No" && (!s.MigrationStartDate || s.MigrationStartDate === "" || s.MigrationStartDate === "Invalid date"))).length > 0))
            setSelectMigrationStarDateValidationForm("Migration start date cannot be empty for all selected Carriers"); else setSelectMigrationStarDateValidationForm("");
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectMigrationStarDateValidationForm])

    React.useEffect(() => {
        if ((selectedCarriers && selectedCarriers?.filter(s => (s.Migration !== "No" && (!s.PlannedFirstProductionDate || s.PlannedFirstProductionDate === "" || s.PlannedFirstProductionDate === "Invalid date"))).length > 0) || (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => (s.Migration !== "No" && (!s.PlannedFirstProductionDate || s.PlannedFirstProductionDate === "" || s.PlannedFirstProductionDate === "Invalid date"))).length > 0))
            setSelectPlannedFirstProductionDateValidationForm("Planned first production date cannot be empty for all selected Carriers"); else setSelectPlannedFirstProductionDateValidationForm("");
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectPlannedFirstProductionDateValidationForm])

    React.useEffect(() => {
        if ((selectedCarriers && selectedCarriers?.filter(s => (s.Migration !== "No" && (!s.MigrationPhase || s.MigrationPhase === ""))).length > 0) || (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers?.selectedOtherCarriersFullArray?.filter(s => (s.Migration !== "No" && (!s.MigrationPhase || s.MigrationPhase === ""))).length > 0))
            setSelectMigrationPhaseValidationForm("Migration phase is missing, please select a phase from the list for all selected Carriers"); else setSelectMigrationPhaseValidationForm("");
    }, [JSON.stringify(selectedCarriers), JSON.stringify(selectedOtherCarriers?.selectedOtherCarriersFullArray), selectMigrationPhaseValidationForm])



    const checkAllContactsValidation = () => {
        return selectCRContactValidationForm || selectCRContactFirstNameValidationForm || selectCRContactLastNameValidationForm || selectCRContactEmailValidationForm || selectCRContactTypeValidationForm
    }

    const hasDuplicateErrorsOrWarnings = () => {
        return Object.entries(duplicateValidation).some(([index, validation]) =>
            validation.duplicateType === "error" || validation.duplicateType === "uniqueCarrierNameValidation" ||
            (validation.duplicateType === "warning" && !continueWithWarning[index])
        );
    };


    // Create connectivity requests added to a current group request
    const submitAddedConnectRequest = (e) => {
        e.preventDefault();
        let addConnectRequest = {};
        if (requestSubmitted) {
            if (groupReq?.groupRequest?.GroupRequestStatusID === 1) {
                if (groupReq?.groupRequest?.IsPEO === 1) {
                    if (selectClientContactValidationForm || selectClientEffectiveDateValidationForm || selectClientContactTPValidationForm || SelectClientEffectiveDateTPValidationForm || selectCRTypeValidationForm || carrierCodeValidationForm || carrierNameValidationForm || selectCarrierValidationForm || selectPlanValidationForm || checkAllContactsValidation() || selectCRHREmailValidationForm || thirdPartyNameValidationForm || selectMigrationValidationForm || selectMigrationPhaseValidationForm || selectMigrationStarDateValidationForm || selectPlannedFirstProductionDateValidationForm || selectTPMigrationValidationForm || selectTPMigrationPhaseValidationForm || selectTPMigrationStarDateValidationForm || selectTPPlannedFirstProductionDateValidationForm || thirdPartyPlansValidationForm || checkAllThirdPartiesContactsValidation() || selectThirdPartyTypeValidationForm || hasDuplicateErrorsOrWarnings()) return;
                } else {
                    if (selectClientContactValidationForm || selectClientEffectiveDateValidationForm || selectClientContactTPValidationForm || SelectClientEffectiveDateTPValidationForm || selectCRTypeValidationForm || attachFormsValidationForm || selectCarrierValidationForm || matchCarriersValidationForm || selectPlanValidationForm || checkAllContactsValidation() || selectCRHREmailValidationForm || thirdPartyNameValidationForm || selectMigrationValidationForm || selectMigrationPhaseValidationForm || selectMigrationStarDateValidationForm || selectPlannedFirstProductionDateValidationForm || selectTPMigrationValidationForm || selectTPMigrationPhaseValidationForm || selectTPMigrationStarDateValidationForm || selectTPPlannedFirstProductionDateValidationForm || thirdPartyPlansValidationForm || checkAllThirdPartiesContactsValidation() || thirdPartyAttachFormsValidationForm || selectThirdPartyTypeValidationForm) return;
                }
            } else {
                if (groupReq?.groupRequest?.IsPEO === 1) {
                    if (selectClientContactValidationForm || selectClientEffectiveDateValidationForm || selectClientContactTPValidationForm || SelectClientEffectiveDateTPValidationForm || selectCRTypeValidationForm || carrierCodeValidationForm || carrierNameValidationForm || selectCarrierValidationForm || thirdPartyNameValidationForm || selectMigrationValidationForm || selectMigrationPhaseValidationForm || selectMigrationStarDateValidationForm || selectPlannedFirstProductionDateValidationForm || selectTPMigrationValidationForm || selectTPMigrationPhaseValidationForm || selectTPMigrationStarDateValidationForm || selectTPPlannedFirstProductionDateValidationForm || selectThirdPartyTypeValidationForm || hasDuplicateErrorsOrWarnings()) return;
                } else {
                    if (selectClientContactValidationForm || selectClientEffectiveDateValidationForm || selectClientContactTPValidationForm || SelectClientEffectiveDateTPValidationForm || selectCRTypeValidationForm || selectCarrierValidationForm || matchCarriersValidationForm || thirdPartyNameValidationForm || selectMigrationValidationForm || selectMigrationPhaseValidationForm || selectMigrationStarDateValidationForm || selectPlannedFirstProductionDateValidationForm || selectTPMigrationValidationForm || selectTPMigrationPhaseValidationForm || selectTPMigrationStarDateValidationForm || selectTPPlannedFirstProductionDateValidationForm || selectThirdPartyTypeValidationForm || thirdPartyAttachFormsValidationForm) return;
                }
            }
            if (groupReq?.groupRequest?.IsPEO === 1) {
                openLoader();
                addConnectRequest = {
                    GroupRequestID: groupReq?.groupRequest?.GroupRequestID,
                    GroupRequestStatusID: groupReq?.groupRequest?.GroupRequestStatusID,
                    Carriers: selectedCarriers.map((selectedCarrier) => ({
                        CarrierID: selectedCarrier?.isOtherChecked ? null : (selectedCarrier?.isOtherChecked ? null : (groupReq?.groupRequest?.IsPEO === 1 ? selectedCarrier?.CarrierID : selectedCarrier.InternalCarrierID !== null ? selectedCarrier.InternalCarrierID : !(selectedCarrier.CarrierID) && (selectedCarrier.InternalCarrierID === null) ? null : selectedCarrier.CarrierID)),
                        ConnectivityRequestTypes: ['EDI'],
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        AssignedTo: selectedCarrier.AssignedTo,
                        // edit 
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        ClientContact: selectedCarrier?.ClientContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        IsolvedCarrier: {
                            Code: groupReq?.groupRequest?.IsPEO === 1 ? selectedCarrier?.Code : selectedCarrier?.partnerCode,
                            Name: groupReq?.groupRequest?.IsPEO === 1 ? selectedCarrier?.CarrierName : selectedCarrier?.carrierName,
                            Type: groupReq?.groupRequest?.IsPEO === 1 ? null : selectedCarrier?.carrierType,
                            isNew: selectedCarrier?.isOtherChecked ? true : (!selectedCarrier?.CarrierID && !selectedCarrier?.InternalCarrierID) ? true : false,
                            SanteonName: selectedCarrier?.isOtherChecked ? selectedCarrier?.CarrierInternalName : null,
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        MigrationGroupRequest: selectedCarrier.Migration,
                        MigrationStartDate: selectedCarrier.MigrationStartDate,
                        PlannedFirstProductionDate: selectedCarrier.PlannedFirstProductionDate,
                        MigrationPhaseID: selectedCarrier.MigrationPhase,
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })).concat(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: null,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        AssignedTo: selectedCarrier.AssignedTo,
                        // edit 
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        ClientContact: selectedCarrier?.ClientContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        IsolvedCarrier: {
                            Code: selectedCarrier?.Code,
                            Name: selectedCarrier?.Name,
                            Type: "",
                            isNew: true,
                            SanteonName: selectedCarrier?.isOtherChecked ? selectedCarrier?.CarrierInternalName : null,
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        MigrationGroupRequest: selectedCarrier.Migration,
                        MigrationStartDate: selectedCarrier.MigrationStartDate,
                        PlannedFirstProductionDate: selectedCarrier.PlannedFirstProductionDate,
                        MigrationPhaseID: selectedCarrier.MigrationPhase,
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []).concat(selectedThirdParties?.thirdPartiesFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: selectedThirdParties[0].InternalCarrierID ?? null,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        ClientContact: selectedCarrier?.ClientContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        PlanTypes: selectedCarrier?.SelectedTPPlans?.filter(p => p !== `Other`),
                        ThirdPartyName: selectedCarrier?.ThirdPartyName,
                        OtherPlanTypes: selectedCarrier.OtherTPPlanTypes?.length > 0 ? selectedCarrier.OtherTPPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedThirdParties[0].ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedThirdParties[0]?.partnerCode,
                            Name: selectedThirdParties[0]?.carrierName,
                            Type: selectedThirdParties[0]?.carrierName,
                            isNew: false
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        MigrationGroupRequest: selectedCarrier.Migration,
                        MigrationStartDate: selectedCarrier.MigrationStartDate,
                        PlannedFirstProductionDate: selectedCarrier.PlannedFirstProductionDate,
                        MigrationPhaseID: selectedCarrier.MigrationPhase,
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []),
                }
            } else {
                openLoader();
                addConnectRequest = {
                    GroupRequestID: groupReq?.groupRequest?.GroupRequestID,
                    GroupRequestStatusID: groupReq?.groupRequest?.GroupRequestStatusID,
                    Carriers: selectedCarriers.map((selectedCarrier) => ({
                        CarrierID: selectedCarrier?.isOtherChecked ? null : ((selectedCarrier.CarrierID !== null && selectedCarrier.CarrierID !== undefined) ? selectedCarrier.CarrierID : !(selectedCarrier.InternalCarrierID) && (selectedCarrier.CarrierID === null) ? null : selectedCarrier.InternalCarrierID),
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        PlanTypes: selectedCarrier?.SelectedPlans?.filter(p => p !== `Other`),
                        OtherPlanTypes: selectedCarrier.OtherPlanTypes?.length > 0 ? selectedCarrier.OtherPlanTypes?.split(/\r\n|\r|\n/) : [],
                        AssignedTo: selectedCarrier.AssignedTo,
                        // edit 
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        ClientContact: selectedCarrier?.ClientContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        ReceivedEncryptionRequirements: selectedCarrier.ReceivedEncryptionRequirements === true ? 1 : 0,
                        IsolvedCarrier: {
                            Code: selectedCarrier?.partnerCode,
                            Name: selectedCarrier?.carrierName,
                            Type: selectedCarrier?.carrierType,
                            isNew: selectedCarrier?.isOtherChecked ? true : (!selectedCarrier?.CarrierID && !selectedCarrier?.InternalCarrierID) ? true : false,
                            SanteonName: selectedCarrier?.isOtherChecked ? selectedCarrier?.CarrierInternalName : null,
                        },
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        MigrationGroupRequest: selectedCarrier.Migration,
                        MigrationStartDate: selectedCarrier.MigrationStartDate,
                        PlannedFirstProductionDate: selectedCarrier.PlannedFirstProductionDate,
                        MigrationPhaseID: selectedCarrier.MigrationPhase,
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })).concat(selectedThirdParties?.thirdPartiesFullArray?.map((selectedCarrier, indexSC) => ({
                        CarrierID: selectedThirdParties[0].InternalCarrierID ?? null,
                        ConnectivityRequestTypes: selectedCarrier.SelectedCRTypes[0] === "No Preference" ? ['EDI'] : selectedCarrier.SelectedCRTypes,
                        ConnectivityRequestEDISubTypeID: selectedCarrier.ConnectivityRequestEDISubTypeID,
                        ConnectivityRequestFormsSubTypeID: selectedCarrier.ConnectivityRequestFormsSubTypeID,
                        HREmail: selectedCarrier.HREmail,
                        ClientEffectiveDate: selectedCarrier.ClientEffectiveDate !== "Invalid date" ? selectedCarrier.ClientEffectiveDate : null,
                        ClientContact: selectedCarrier?.ClientContact,
                        CarrierGroupNumber: selectedCarrier?.CarrierGroupNumber ?? null,
                        PlanTypes: selectedCarrier?.SelectedTPPlans?.filter(p => p !== `Other`),
                        ThirdPartyName: selectedCarrier?.ThirdPartyName,
                        OtherPlanTypes: selectedCarrier.OtherTPPlanTypes?.length > 0 ? selectedCarrier.OtherTPPlanTypes?.split(/\r\n|\r|\n/) : [],
                        ReceivedEncryptionRequirements: selectedThirdParties[0].ReceivedEncryptionRequirements,
                        IsolvedCarrier: {
                            Code: selectedThirdParties[0]?.partnerCode,
                            Name: selectedThirdParties[0]?.carrierName,
                            Type: selectedThirdParties[0]?.carrierName,
                            isNew: false
                        },
                        MigrationGroupRequest: selectedCarrier.Migration,
                        MigrationStartDate: selectedCarrier.MigrationStartDate,
                        PlannedFirstProductionDate: selectedCarrier.PlannedFirstProductionDate,
                        MigrationPhaseID: selectedCarrier.MigrationPhase,
                        DigitalForms: selectedCarrier.FormsAttachments?.map((form) => ({
                            Name: form?.Name,
                            Type: "DigitalForm",
                            GUID: form?.GUID,
                            Extension: form?.Extension,
                            // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                            // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                        })),
                        NewAttachments: selectedCarrier.Attachments?.map((file) => ({
                            Type: file.Type,
                            Name: file.Name,
                            Extension: file.Extension,
                            GUID: file.GUID
                        })),
                        CarriersContacts: selectedCarrier.Contacts ?? []
                    })) ?? []),
                }
            }
        }

        APIs.createConnectivityRequest(addConnectRequest).then((r) => {
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            } else {
                routeHistory.push(`/GroupRequests/ViewGroupRequest/${groupReq?.groupRequest?.GroupRequestID}`)
            }
        }).catch((s) => FailureModal(dialog, {
            title: 'Failure',
            body: 'Error processing request',
        })).finally((t) => closeLoader());
    }

    return (
        <form className="row w-100" onSubmit={submitAddedConnectRequest}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Accordion className="connectivity-req-acc w-100" expanded={expanded === `connectivityRequest`}>
                    <AccordionSummary
                        onClick={() => expanded === `connectivityRequest` ? setExpanded("") : setExpanded("connectivityRequest")}
                        // className="pl-0 pr-0"
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="expand-connectivity-requests"
                    >
                        <span >Add Connectivities</span>
                    </AccordionSummary>

                    <Grid item xs={12} sm={12} md={6}>
                        <h6 className="form-title mt-2 ml-2">Select Carriers
                            <span className="text-danger mb-2 ml-2">*</span> </h6>

                        <input type="text" className="text-input w-100 ml-2" placeholder="Search carriers here..." value={carrierSearchValue} onChange={handleCarrierSearchValueChange} />
                    </Grid>

                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectCarrierValidationForm}</div>
                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{matchCarriersValidationForm}</div>
                    {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectPlanValidationForm}</div> */}


                    {groupReq?.groupRequest?.IsPEO === 1 ? (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center"></div>
                            <Grid item xs={12}>
                                <div className="mt-3 ml-2 mr-2">
                                    <SelectVerifiedCarriers onCarrierNotListedChecked={(s) => setCarrierNotListedChecked(s)} parentForceUpdate={forceUpdate} carriers={internalCarriers} onSelectedOtherCarriersChecked={(d) => setSelectedOtherCarriersChecked(d)} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} selectedOtherCarriers={selectedOtherCarriers} setSelectedOtherCarriers={setSelectedOtherCarriers} carrierSearchValue={carrierSearchValue} onIndexCallback={s => setIndexCallback(s)} onInternalCarrier={setInternalCarrier} />
                                </div>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center"></div>
                            <Grid item xs={12}>
                                <div className=" mt-3 ml-2 mr-2">
                                    <SelectCarriersAndPlans parentForceUpdate={forceUpdate} carriers={selectedGroupCarriers} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} selectedThirdParties={selectedThirdParties} setSelectedThirdParties={setSelectedThirdParties} carrierSearchValue={carrierSearchValue} onIndexCallback={s => setIndexCallback(s)} onInternalCarrier={setInternalCarrier} />
                                </div>
                            </Grid>
                        </>
                    )}
                </Accordion>
                {selectedCarriers.length > 0 && (
                    <>
                        <div className="col-xl-2 mt-2"></div>
                        <Grid item xs={12}>
                            <div className="mt-3">
                                <div className="counter-external-div alignvh-center">
                                    <div style={{ padding: "8px 8px 5px 8px" }}>
                                        {selectedCarriers?.map((s, si) => (
                                            <TruncatedChip
                                                style={{ marginBottom: "3px", marginRight: "1px" }}
                                                key={`selected-carrier-chip-${si}`}
                                                body={groupReq?.groupRequest?.IsPEO === 1 ? s.CarrierName : s.carrierName}
                                            />
                                        ))}
                                    </div>
                                    <div className="text-right ml-auto alignvh-center mr-2 pt-1 pb-1">
                                        <div style={{ whiteSpace: "nowrap" }} className="counter-div">{selectedCarriers.length > 1 ? `${selectedCarriers.length} Carriers Selected` : `${selectedCarriers.length} Carrier Selected`}</div>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        {(selectedCarriers.length > 0 && (groupReq?.groupRequest?.IsPEO !== 1)) && (
                            <>
                                <div className="col-xl-2 mt-3 alignvh-center"></div>
                                <Grid item xs={12}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary
                                            aria-controls="panel2-content"
                                            id="panel-header-cr-config"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                            style={{ pointerEvents: 'none' }}
                                        >
                                            <span>Mapped Carrier</span>
                                        </AccordionSummary>
                                        <div className=" matched-carriers-GR">
                                            <div className="row table-header">
                                                <div className="col-6 table-cell first-cell">isolved Carriers</div>
                                                <div className="col-6 table-cell">Standard Carriers</div>
                                            </div>
                                            {selectedCarriers?.map((s, si) => (

                                                <div key={`mapped-carriers-table-${si}`} className="row table-row">
                                                    <div className="col-6 table-cell first-cell">
                                                        {s?.carrierName}
                                                    </div>
                                                    <div className="col-6 table-cell">
                                                        {s?.CarrierInternalName ? s?.CarrierInternalName : s?.InternalCarrierName}
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </Accordion>
                                </Grid>
                            </>
                        )}
                    </>
                )}


                {groupReq?.groupRequest?.IsPEO === 1 && ((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <SelectCarrierDetails
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    parentForceUpdate={forceUpdate}
                                    requestSubmitted={requestSubmitted}
                                    requestSaved={requestSaved}
                                    setSelectedCarriers={setSelectedCarriers}
                                    selectedCarriers={selectedCarriers}
                                    carrierNotListedChecked={carrierNotListedChecked}
                                    carrierCodeValidationForm={carrierCodeValidationForm}
                                    carrierNameValidationForm={carrierNameValidationForm}
                                    duplicateCarriersList={duplicateCarriersList}
                                    setDuplicateCarriersList={setDuplicateCarriersList}
                                    similarCarriersList={similarCarriersList}
                                    setSimilarCarriersList={setSimilarCarriersList}
                                    duplicateValidation={duplicateValidation}
                                    setDuplicateValidation={setDuplicateValidation}
                                    continueWithWarning={continueWithWarning}
                                    setContinueWithWarning={setContinueWithWarning}
                                />
                            </div>
                        </Grid>
                    </>
                )}

                {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <SelectConnectivityType
                                    isPEOClicked={groupReq?.groupRequest?.IsPEO === 1}
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    selectCRTypeValidationForm={selectCRTypeValidationForm}
                                    requestSubmitted={requestSubmitted}
                                    parentForceUpdate={forceUpdate}
                                    carriers={selectedGroupCarriers}
                                    setSelectedCarriers={setSelectedCarriers}
                                    selectedCarriers={selectedCarriers}
                                    subTypeList={subTypeList}
                                    setSubTypeList={setSubTypeList}
                                    formsSubTypeList={formsSubTypeList}
                                    setFormsSubTypeList={setFormsSubTypeList}
                                    selectCRHREmailValidationForm={selectCRHREmailValidationForm}
                                    attachFormsValidationForm={attachFormsValidationForm}
                                />
                            </div>
                        </Grid>
                    </>
                )}

                {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <SelectConnectivityMigration
                                    groupReq={groupReq}
                                    isPEOClicked={groupReq?.groupRequest?.IsPEO === 1}
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    selectMigrationValidationForm={selectMigrationValidationForm}
                                    selectMigrationPhaseValidationForm={selectMigrationPhaseValidationForm}
                                    selectMigrationStarDateValidationForm={selectMigrationStarDateValidationForm}
                                    selectPlannedFirstProductionDateValidationForm={selectPlannedFirstProductionDateValidationForm}
                                    requestSubmitted={requestSubmitted}
                                    parentForceUpdate={forceUpdate}
                                    carriers={selectedGroupCarriers}
                                    setSelectedCarriers={setSelectedCarriers}
                                    selectedCarriers={selectedCarriers}
                                />
                            </div>
                        </Grid>
                    </>
                )}

                {/* Plans */}
                {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <SelectCarrierPlans isPEOClicked={groupReq?.groupRequest?.IsPEO === 1} selectedOtherCarriers={selectedOtherCarriers} groupReq={groupReq} selectPlanValidationForm={selectPlanValidationForm} requestSubmitted={requestSubmitted} parentForceUpdate={forceUpdate} carriers={selectedGroupCarriers} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} />
                            </div>
                        </Grid>
                    </>
                )}
                {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">

                                <SelectClientInformation
                                    groupReq={groupReq}
                                    isPEOClicked={groupReq?.groupRequest?.IsPEO === 1}
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    requestSubmitted={requestSubmitted}
                                    requestSaved={requestSaved}
                                    parentForceUpdate={forceUpdate}
                                    carriers={selectedGroupCarriers}
                                    setSelectedCarriers={setSelectedCarriers}
                                    selectedCarriers={selectedCarriers}
                                    groupsAndOEContactsList={groupsAndOEContactsList}
                                    onSelectClientContactValidationForm={s => setSelectClientContactValidationForm(s)}
                                    onSelectClientEffectiveDateValidationForm={s => setSelectClientEffectiveDateValidationForm(s)}
                                    selectClientContactValidationForm={selectClientContactValidationForm}
                                    selectClientEffectiveDateValidationForm={selectClientEffectiveDateValidationForm}
                                />
                            </div>
                        </Grid>
                    </>
                )}

                {/* Contacts */}
                {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <CarrierContacts
                                    onTabValueCallback={s => setTabValueCallback(s)}

                                    selectCRContactValidationForm={selectCRContactValidationForm}
                                    selectCRContactFirstNameValidationForm={selectCRContactFirstNameValidationForm}
                                    selectCRContactLastNameValidationForm={selectCRContactLastNameValidationForm}
                                    selectCRContactEmailValidationForm={selectCRContactEmailValidationForm}
                                    selectCRContactTypeValidationForm={selectCRContactTypeValidationForm}
                                    carrierContactTypes={carrierContactTypes}
                                    isPEOClicked={groupReq?.groupRequest?.IsPEO === 1}
                                    selectedOtherCarriers={selectedOtherCarriers}
                                    groupReq={groupReq}
                                    parentForceUpdate={forceUpdate}
                                    inputsSavedValidation={inputsSavedValidation}
                                    setInputsSavedValidation={setInputsSavedValidation}
                                    contactValidationCallback={contactValidationCallback}
                                    setContactValidationCallback={setContactValidationCallback}
                                    selectedCarriers={selectedCarriers}
                                    requestSubmitted={requestSubmitted} />
                            </div>
                        </Grid>
                    </>
                )}

                {/* Files Attachments */}
                {((selectedCarriers && selectedCarriers.length > 0) || (selectedOtherCarriersChecked)) && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <FilesAttachments isPEOClicked={groupReq?.groupRequest?.IsPEO === 1} selectedOtherCarriers={selectedOtherCarriers?.selectedOtherCarriersFullArray} selectedCarriers={selectedCarriers} />
                            </div>
                        </Grid>
                    </>
                )}

                {/* Third Party */}
                {selectedThirdParties && selectedThirdParties.length > 0 && (
                    <>
                        <div className="col-xl-2 mt-3 alignvh-center"></div>
                        <Grid item xs={12}>
                            <div className="mt-5">
                                <CreateThirdParty
                                    selectedThirdParties={selectedThirdParties}
                                    parentForceUpdate={forceUpdate}
                                    selectPlanValidationForm={selectPlanValidationForm}
                                    onSelectClientContactValidationForm={s => setSelectClientContactTPValidationForm(s)}
                                    onSelectClientEffectiveDateValidationForm={s => setSelectClientEffectiveDateTPValidationForm(s)}
                                    onThirdPartyNameValidationForm={s => setThirdPartyNameValidationForm(s)}
                                    onThirdPartyPlansValidationForm={s => setThirdPartyPlansValidationForm(s)}

                                    onThirdPartyContactsValidationForm={s => setThirdPartyContactsValidationForm(s)}
                                    onThirdPartyContactsFirstNameValidationForm={s => setThirdPartyContactsFirstNameValidationForm(s)}
                                    onThirdPartyContactsLastNameValidationForm={s => setThirdPartyContactsLastNameValidationForm(s)}
                                    onThirdPartyContactsEmailValidationForm={s => setThirdPartyContactsEmailValidationForm(s)}
                                    onThirdPartyContactsTypeValidationForm={s => setThirdPartyContactsTypeValidationForm(s)}

                                    onSelectTPMigrationValidationForm={s => setSelectTPMigrationValidationForm(s)}
                                    onSelectTPMigrationStarDateValidationForm={s => setSelectTPMigrationStarDateValidationForm(s)}
                                    onSelectTPMigrationPhaseValidationForm={s => setSelectTPMigrationPhaseValidationForm(s)}
                                    onSelectTPPlannedFirstProductionDateValidationForm={s => setSelectTPPlannedFirstProductionDateValidationForm(s)}
                                    requestSubmitted={requestSubmitted}
                                    requestSaved={requestSaved}
                                    groupReq={groupReq}
                                    groupsAndOEContactsList={groupsAndOEContactsList}
                                    migrationPhasesList={migrationPhasesList}
                                    carrierContactTypes={carrierContactTypes}
                                    subTypeList={subTypeList}
                                    setSubTypeList={setSubTypeList}
                                    formsSubTypeList={formsSubTypeList}
                                    setFormsSubTypeList={setFormsSubTypeList}
                                    onThirdPartyTypeValidationForm={s => setSelectThirdPartyTypeValidationForm(s)}
                                    OnThirdPartyAttachFormsValidationForm={s => setThirdPartyAttachFormsValidationForm(s)}
                                />
                            </div>
                        </Grid>
                    </>
                )}

                {/* Forms Attachments */}
                {/* {selectedCarriers && selectedCarriers.length > 0 && selectedCarriers.any(s => s.SelectedCRTypes?.filter(x => x === "Forms").length > 0) && (
                <>
                    <div className="col-xl-2 mt-3 alignvh-center"></div>
                    <div className="col-xl-10 pr-0 pl-0 mt-5">
                        <FormsAttachments
                            selectedCarriers={selectedCarriers}
                            requestSubmitted={requestSubmitted}
                            requestSaved={requestSaved}
                            // onSelectFormMainTypesValidationForm={(s) => setSelectFormMainTypesValidationForm(s)}
                            // onSelectFormSubTypesValidationForm={(s) => setSelectFormSubTypesValidationForm(s)}
                            attachFormsValidationForm={attachFormsValidationForm}
                            groupReq={groupReq}
                        />
                    </div>
                </>
            )} */}

                <div className="w-100 text-right mt-5">
                    <Button type="submit" variant="contained" className="blue-btn" onClick={() => { setRequestSubmitted(true); setRequestSaved(false); }}>Submit Request</Button>
                </div>
            </Grid>

        </form>
    )
}

export default CreateConnectivity;
