import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, FormControl } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { useAuthentication } from "../../../Data/Authentication";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


function HomeConnectivityRequestsFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onPageSizeAdv,
    onSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    onSelectedGroupsFilter,
    onSelectedPEOsFilter,
    onSelectedCarriersFilter,
    onSelectedStatusesFilter,
    onCloseClearReset,
    ...props }) {

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [grpName, setGrpName] = React.useState();
    const [groupsList, setGroupsList] = React.useState([]);
    const [groupSearchValue, setGroupSearchValue] = React.useState("");
    const [selectedGroups, setSelectedGroups] = React.useState([]);
    const [peoName, setPEOName] = React.useState();
    const [peosList, setPEOsList] = React.useState([]);
    const [peoSearchValue, setPEOSearchValue] = React.useState("");
    const [selectedPEOs, setSelectedPEOs] = React.useState([]);
    const [carName, setCarName] = React.useState();
    const [carriersList, setCarriersList] = React.useState([]);
    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [selectedCarriers, setSelectedCarriers] = React.useState([]);
    const [connectivityRequestsStatusesList, setConnectivityRequestsStatusesList] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        APIs.getGroupsListByName({ GroupName: grpName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsList([]) : setGroupsList(r?.data));
    }, [grpName])

    React.useEffect(() => {
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
    }, [peoName])

    React.useEffect(() => {
        APIs.getCarriersListByName({ CarrierName: carName ?? "", StatusTypeId: [1,2] }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [carName])

    React.useEffect(() => {
        APIs.getConnectivityRequestStatuses().then((r) => (!(r?.data?.length) ? setConnectivityRequestsStatusesList([]) : setConnectivityRequestsStatusesList(r?.data)));
    }, [])

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleGroupClick = (value) => (event) => {
        let selectedGroupsArray = selectedGroups;
        if (!value || value === "") selectedGroupsArray = [];
        else if (selectedGroupsArray.indexOf(value) >= 0) {
            selectedGroupsArray.splice(selectedGroupsArray.indexOf(value), 1);
        }
        else {
            selectedGroupsArray.push(value);
        }
        setSelectedGroups([...selectedGroupsArray]);
        onSelectedGroupsFilter([...selectedGroupsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handlePEOClick = (value) => (event) => {
        let selectedPEOsArray = selectedPEOs;
        if (!value || value === "") selectedPEOsArray = [];
        else if (selectedPEOsArray.indexOf(value) >= 0) {
            selectedPEOsArray.splice(selectedPEOsArray.indexOf(value), 1);
        }
        else {
            selectedPEOsArray.push(value);
        }
        setSelectedPEOs([...selectedPEOsArray]);
        onSelectedPEOsFilter([...selectedPEOsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarriers;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
        }
        else {
            selectedCarriersArray.push(value);
        }
        setSelectedCarriers([...selectedCarriersArray]);
        onSelectedCarriersFilter([...selectedCarriersArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleConnectivityRequestsStatusesClick = (value) => (event) => {
        let selectedConnectivityRequestsStatusesArray = selectedStatuses;
        if (!value || value === "") selectedConnectivityRequestsStatusesArray = [];
        else if (selectedConnectivityRequestsStatusesArray.indexOf(value) >= 0) {
            selectedConnectivityRequestsStatusesArray.splice(selectedConnectivityRequestsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedConnectivityRequestsStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedConnectivityRequestsStatusesArray]);
        onSelectedStatusesFilter([...selectedConnectivityRequestsStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleClearSearch = () => {
        setSelectedGroups([]);
        onSelectedGroupsFilter([]);
        setSelectedPEOs([]);
        onSelectedPEOsFilter([]);
        setSelectedCarriers([]);
        onSelectedCarriersFilter([]);
        setSelectedStatuses([]);
        onSelectedStatusesFilter([]);
        onSelectedPageAdv(1);
    }

    React.useEffect(() => {
        setSelectedGroups([]);
        onSelectedGroupsFilter([]);
        setSelectedPEOs([]);
        onSelectedPEOsFilter([]);
        setSelectedCarriers([]);
        onSelectedCarriersFilter([]);
        setSelectedStatuses([]);
        onSelectedStatusesFilter([]);
        onSelectedPageAdv(1);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }
    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
    }

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Connectivity Requests Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="filter-body-div">
                    <Box style={{ display: 'flex' }}>
                        <Tabs
                            style={{ maxHeight: "370px", overflow: "auto" }}
                            orientation="vertical"
                            // variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                        >
                            <Tab className="filter-tab-btn" label="Client Name" {...a11yProps(0)} />
                            <Tab className="filter-tab-btn" label="PEO Name" {...a11yProps(1)} />
                            <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(2)} />
                            <Tab className="filter-tab-btn" label="Status" {...a11yProps(3)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <input type="text" className="text-input w-100" placeholder="Search clients here..." value={groupSearchValue} onChange={(e) => setGroupSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>

                                {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`groups-select-all`}>
                                        <AccordionSummary
                                            aria-controls="groups-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`groups-select-option-all}`}
                                                            value="Select All"
                                                            // 
                                                            checked={selectedGroups && selectedGroups.length === groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedGroups, groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s), setSelectedGroups, onSelectedGroupsFilter)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }

                                {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`groups-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="groups-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-option-${ci}`}
                                                            value={c}
                                                            // 
                                                            checked={selectedGroups && selectedGroups.indexOf(c) >= 0}
                                                            onClick={handleGroupClick(c)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.GroupName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <input type="text" className="text-input w-100" placeholder="Search peos here..." value={peoSearchValue} onChange={(e) => setPEOSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>

                                {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`peos-select-all`}>
                                        <AccordionSummary
                                            aria-controls="peos-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`peos-select-option-all}`}
                                                            value="Select All"

                                                            checked={selectedPEOs && selectedPEOs.length === peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedPEOs, peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s), setSelectedPEOs, onSelectedPEOsFilter)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }

                                {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`peos-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="peos-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`peo-option-${ci}`}
                                                            value={c}

                                                            checked={selectedPEOs && selectedPEOs.indexOf(c) >= 0}
                                                            onClick={handlePEOClick(c)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.PeoName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`carriers-select-all`}>
                                        <AccordionSummary
                                            aria-controls="carriers-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`carriers-select-option-all}`}
                                                            value="Select All"

                                                            checked={selectedCarriers && selectedCarriers.length === carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedCarriers, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarriers, onSelectedCarriersFilter)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`carriers-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="carriers-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`carrier-option-${ci}`}
                                                            value={c}

                                                            checked={selectedCarriers && selectedCarriers.indexOf(c) >= 0}
                                                            onClick={handleCarrierClick(c)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.CarrierName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`connectivity-requests-status-all`}>
                                    <AccordionSummary
                                        aria-controls="connectivity-requests-status-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`connectivity-requests-status-option-all}`}
                                                        value="Select All"

                                                        checked={selectedStatuses && selectedStatuses.length === connectivityRequestsStatusesList.length}
                                                        onClick={handelSelectAll(selectedStatuses, connectivityRequestsStatusesList, setSelectedStatuses, onSelectedStatusesFilter)}

                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>



                                {connectivityRequestsStatusesList.map((f, fi) => (
                                    <Accordion key={`connectivity-requests-status-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-statuses-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-status-option-${fi}`}
                                                            value={f}

                                                            checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                            onClick={handleConnectivityRequestsStatusesClick(f)}

                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.ConnectivityRequestStatusName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                    </Box >
                </div >
            </DialogContent>
            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog >
    )
}

export default HomeConnectivityRequestsFilterModalBody;