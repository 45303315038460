import React from "react";
import { Paper, TextField, Button, } from "@material-ui/core";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import VerifiedIcon from '@mui/icons-material/Verified';
import TabsTitles from "../Layout/TabsTitles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@mui/icons-material/Search';
import verifyCarrierSVG from "../../Icons/shield-check.svg";
import searchIconSVG from "../../Icons/searchIcon.svg";

function SearchCarriers() {
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [carriersListFilter, setCarriersListFilter] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [carrierNameAutoValue, setCarrierNameAutoValue] = React.useState("");
    const [carrierNameFilter, setCarrierNameFilter] = React.useState("");

    React.useEffect(() => {
        getSearchCarrier();
    }, [carrierNameFilter])

    function getSearchCarrier() {
        let carrierBody = {
            CarrierName: carrierNameFilter ?? "",
            IncludeNonVerified: 1
        }
        if (carrierNameFilter === "") {
            APIs.getCarriersListByName(carrierBody).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
        } else {
            openLoader();
            APIs.getCarriersListByName(carrierBody).then((r) => !(r?.data?.length) ? setCarriersListFilter([]) : (setCarriersListFilter(r?.data),
                closeLoader())
            );
        }
        setCarrierNameAutoValue(carrierNameFilter);
    }
    const handleVerfiyCarrier = (carrier) => {
        ConfirmModal(dialog, () => verifyCarrier(carrier), {
            title: 'Please Confirm',
            question: `Are you sure you want to Verify this carrier?`,
        })
    }
    const verifyCarrier = (carrier) => {
        openLoader();
        APIs.approveCarrier({ CarrierID: carrier.CarrierID }).then((r) => {
            closeLoader();
            getSearchCarrier();
        });
    }

    return (
        <>
            <TabsTitles tabTitle={["Verify Carriers"]} />
            <Paper elevation={0} style={{ padding: "60px 100px" }}>
                <div className="mt-3">
                    <div className="row ">

                    </div>
                    <div className="row w-100 alignvh-center">
                        <div className="col-xl-2 alignvh-center mt-2 form-title">Enter Carrier Name:</div>
                        <div className="col-xl-10 ">
                            <div className="position-relative">
                                {/* <SearchIcon className="absolute-icon" /> */}
                                <svg width="17" height="17" viewBox="0 0 17 17" className="absolute-icon" style={{marginTop:"-6px"}} >
                                    <use xlinkHref={`${searchIconSVG}#searchIcon`}></use>
                                </svg> 
                                <Autocomplete
                                    freeSolo
                                    options={carriersList}
                                    autoHighlight
                                    className="search-dropdown-carrier w-100"
                                    inputValue={carrierNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarrierNameFilter(value?.CarrierName ?? "");
                                        setCarriersListFilter([]);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarrierNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>


                        </div>
                    </div>


                </div>
            </Paper>
            {carriersListFilter?.length > 0 && carrierNameFilter !== "" && <Paper elevation={0} className="mt-5" style={{ padding: "50px 100px" }}>


                <div id="connectivity-group-requests-table" >

                    <div className="col-xl-10 mx-auto">
                        <div className="row table-header">
                            <div className="col-4 table-cell first-cell" style={{ cursor: "pointer" }} >Carrier Id</div>
                            <div className="col-4 table-cell " style={{ cursor: "pointer" }} >Carrier Name</div>
                            <div className="col-4 table-cell last" style={{ cursor: "pointer" }} >Verify</div>
                        </div>
                        {carriersListFilter?.length > 0 && carriersListFilter?.map((c, ci) => (
                            <div key={`carriers-list-${ci}`} className="row table-row">
                                <div className="col-4 table-cell first-cell">
                                    <a href={`/Carriers/ViewCarrier/${c?.CarrierID}`}> {c?.CarrierID}</a>
                                </div>
                                <div className="col-4 table-cell">
                                    <a href={`/Carriers/ViewCarrier/${c?.CarrierID}`}>{c?.CarrierName}</a>
                                </div>
                                <div className="col-4 table-cell">
                                    {c?.IsVerified !== 1 ?
                                        <Button className="blue-btn mx-5" onClick={() => handleVerfiyCarrier(c)}>Verify </Button>
                                        :
                                        <span> Verified 
                                            <svg width="15" height="24" viewBox="0 0 24 24" className="ml-2 mt--2" >
                                                <use xlinkHref={`${verifyCarrierSVG}#shield-check`}></use>
                                            </svg> 
                                    </span>
                                    }

                                </div>
                            </div>))}


                    </div>

                </div>

                {carriersListFilter && carriersListFilter.length === 0 && (
                    <div className="no-data-alert">
                        <h6>No Results Found!</h6>
                        <h6>Please select correct search criteria.</h6>
                    </div>
                )}
            </Paper>}
        </>
    )
}
export default SearchCarriers;