import React from "react";
import FormsTable from "./FormsTable";
import { Paper } from "@material-ui/core";
import { Button, TextField, FormControl } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import Moment from 'moment';
import Autocomplete from "@material-ui/lab/Autocomplete";
import NativeSelect from '@material-ui/core/NativeSelect';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@mui/system';
import cancelIconSVG from "../../Icons/CancelIcon.svg";
import GenericDatePicker from "../Layout/GenericDatePicker";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});


function ViewFormsList() {

    const { openLoader, closeLoader } = useLoader();
    const [formsList, setFormsList] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [formIDFilter, setFormIDFilter] = React.useState("");
    const [formNameFilter, setFormNameFilter] = React.useState("");
    const [carrierNameFilter, setCarrierNameFilter] = React.useState("");
    const [carrierIDFilter, setCarrierIDFilter] = React.useState("");
    const [carrierNameAutoValue, setCarrierNameAutoValue] = React.useState("");
    const [createdDateFromFilter, setCreatedDateFromFilter] = React.useState(null);
    const [createdDateToFilter, setCreatedDateToFilter] = React.useState(null);
    const [createdByFilter, setCreatedByFilter] = React.useState("");
    const [createdByAutoValue, setCreatedByAutoValue] = React.useState("");
    const [modifiedDateFromFilter, setModifiedDateFromFilter] = React.useState(null);
    const [modifiedDateToFilter, setModifiedDateToFilter] = React.useState(null);
    const [modifiedByFilter, setModifiedByFilter] = React.useState("");
    const [lastModifiedByAutoValue, setLastModifiedByAutoValue] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [usersList, setUsersList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        APIs.getCarriersListByName({ CarrierName: carrierNameFilter ?? "" , StatusTypeId: [1,2]}).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
        setCarrierNameAutoValue(carrierNameFilter);
    }, [carrierNameFilter])

    React.useEffect(() => {
        setCreatedByAutoValue(`${createdByFilter?.FirstName ?? ""} ${createdByFilter?.LastName ?? ""}`);
    }, [createdByFilter])

    React.useEffect(() => {
        setLastModifiedByAutoValue(`${modifiedByFilter?.FirstName ?? ""} ${modifiedByFilter?.LastName ?? ""}`);
    }, [modifiedByFilter])


    const getData = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.CreatedDateFrom = createdDateFromFilter ? Moment(createdDateFromFilter).format('YYYY-MM-DD') : null;
        requestParameters.CreatedDateTo = createdDateToFilter ? Moment(createdDateToFilter).format('YYYY-MM-DD') : null;
        requestParameters.ModifiedDateFrom = modifiedDateFromFilter ? Moment(modifiedDateFromFilter).format('YYYY-MM-DD') : null;
        requestParameters.ModifiedDateTo = modifiedDateToFilter ? Moment(modifiedDateToFilter).format('YYYY-MM-DD') : null;
        if (selectedPage >= 1) {
            requestParameters.page = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.page = selectedPage;
        }
        if (formIDFilter !== "")
            requestParameters.DigitalFormID = formIDFilter;
        if (formNameFilter !== "")
            requestParameters.DigitalFormName = formNameFilter;
        if (carrierIDFilter !== "")
            requestParameters.CarrierIDsArray = [carrierIDFilter];
        if (createdByFilter !== "")
            requestParameters.CreatedByCognitoIDsArray = [createdByFilter.CognitoID];
        if (modifiedByFilter !== "")
            requestParameters.LastModifiedByCognitoIDsArray = [modifiedByFilter.CognitoID];
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        APIs.getAllForms(requestParameters).then((r) => (
            setFormsList(r?.data),
            closeLoader(),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }

    React.useEffect(() => {
        getData();
    }, [selectedPage, pageSize, formIDFilter, formNameFilter, carrierNameFilter, createdByFilter, modifiedByFilter, createdDateFromFilter, createdDateToFilter, modifiedDateFromFilter, modifiedDateToFilter, sortFieldFilter, sortOrderFilter])

    const handleFormNameFilter = (e) => {
        setFormNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleCarrierNameFilter = (e) => {
        setCarrierNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setCreatedDateFromFilter(date);
        }
        else {
            setCreatedDateToFilter(date);;
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleCreatedByFilter = (e, newValue) => {
        setCreatedByFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleModifiedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setModifiedDateFromFilter(date);
        }
        else {
            setModifiedDateToFilter(date);;
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleModifiedByFilter = (e, newValue) => {
        setModifiedByFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleClearSearch = () => {
        setFormIDFilter("");
        setFormNameFilter("");
        setCarrierNameFilter("");
        setCarrierIDFilter("");
        setCreatedByFilter("");
        setModifiedByFilter("");
        setCreatedDateFromFilter(null);
        setCreatedDateToFilter(null);
        setModifiedDateFromFilter(null);
        setModifiedDateToFilter(null);
        setSelectedPage(1);
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <div className="mt-3" id="forms-list">
            <div className="row w-100 alignvh-center">
                <h5 className="black1 fw-400 ml-3">Digital Forms</h5>
            </div>

            <Paper className="content-card-paper">
                <div className="search-container">
                    <div className="row alignvh-center">
                        <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Digital Form Name:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={formNameFilter} onChange={handleFormNameFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Carrier Name:</span>
                                <br />
                                <Autocomplete
                                    options={carriersList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={carrierNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarrierNameFilter(value?.CarrierName ?? "");
                                        setCarrierIDFilter(value?.CarrierID ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarrierNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Digitized By:</span>
                                <br />
                                <Autocomplete
                                    options={usersList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={createdByAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setCreatedByFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCreatedByAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Last Modified By:</span>
                                <br />
                                <Autocomplete
                                    options={usersList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={lastModifiedByAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setModifiedByFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setLastModifiedByAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Created Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={createdDateFromFilter}
                                    onChange={(date) => setCreatedDateFromFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={modifiedDateFromFilter}
                                    onChange={(date) => setCreatedDateToFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Last Modified Date:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={createdDateFromFilter}
                                    onChange={(date) => setModifiedDateFromFilter(date)}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={modifiedDateFromFilter}
                                    onChange={(date) => setModifiedDateToFilter(date)}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </div>
                            <div className="col-xl-12 mt-4" >
                                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                                    <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                    </svg> Clear Search
                                </Button>
                            </div>
                        </div>
                        <FormsTable
                            formsList={formsList}
                            setFormsList={setFormsList}
                            selectedPage={selectedPage}
                            pageSize={pageSize}
                            setSelectedPage={setSelectedPage}
                            setPageSize={setPageSize}
                            totalCount={totalCount}
                            onSortFieldFilter={(s) => setSortFieldFilter(s)}
                            onSortOrderFilter={(s) => setSortOrderFilter(s)}
                        />
                    </div>
                </div >
            </Paper >
        </div >
    )
}

export default ViewFormsList;