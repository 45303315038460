import React from "react";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import * as APIs from "../../../Data/APIs";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useHistory } from "react-router-dom";
import { AlertModal } from "../../Modals/AlertModal";
import { parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import dateTimeSVG from "../../../Images/dateTime.svg";
import GenericDatePicker from "../../Layout/GenericDatePicker";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

function CancelConnectivity({ cancelledConnectivities, groupReq, connectivities, openLoader, ...props }) {
    const dialog = useModalDialog(0);
    const [checked, setChecked] = React.useState(false);
    const [selectedConnectivities, setSelectedConnectivities] = React.useState([]);
    const [reasonStatuses, setReasonStatuses] = React.useState([]);
    const [dateCancelation, setdateCancelation] = React.useState([]);
    const [selectStatusReasonValidation, setSelectStatusReasonValidation] = React.useState([]);
    const [requestCancelClicked, setRequestCancelClicked] = React.useState(false);
    const [allValid, setAllValid] = React.useState(false);
    const handleChange = (e) => {
        setChecked(e.target.checked);
    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            case 3:
                return AlertModal(dialog, handleFunction, content);
            default:
                break;
        }
    }


    React.useEffect(() => {
        if (selectedConnectivities?.length <= 0) {
            setAllValid(false);
        } else {
            if (selectedConnectivities?.length > reasonStatuses?.length || selectedConnectivities?.length > dateCancelation.length) {
                setAllValid(false);
            }
            else {
                for (var ci = 0; ci < selectedConnectivities.length; ci++) {
                    if (!reasonStatuses[ci]?.replaceAll(" ", "").replaceAll("\n", "")
                        || reasonStatuses[ci]?.replaceAll(" ", "").replaceAll("\n", "") === undefined
                        || reasonStatuses[ci]?.replaceAll(" ", "").replaceAll("\n", "") === ""
                        || dateCancelation[ci] === undefined
                        || dateCancelation[ci] === "Invalid date"
                        || !dateCancelation[ci]
                        || (dateCancelation[ci] < Moment(todayDate).format('YYYY-MM-DD'))
                        || dateCancelation[ci] === "") {
                        setAllValid(false);
                        return;
                    }
                    else {
                        setAllValid(true);
                    }
                }

            }
        }
    }, [reasonStatuses, selectedConnectivities, dateCancelation]);


    const handleConnectivityClick = (value) => (event) => {
        let selectedConnectivitiesArray = selectedConnectivities;
        let selectedReasonArray = reasonStatuses;
        let selectedDateCancelationArray = dateCancelation;
        if (!value || value === "") selectedConnectivitiesArray = [];
        else if (selectedConnectivitiesArray.indexOf(value) >= 0) {
            selectedReasonArray.splice(selectedConnectivitiesArray.indexOf(value), 1);
            selectedDateCancelationArray.splice(selectedConnectivitiesArray.indexOf(value), 1);
            selectedConnectivitiesArray.splice(selectedConnectivitiesArray.indexOf(value), 1);

        }
        else {
            selectedConnectivitiesArray.push(value);
            selectedDateCancelationArray.push(Moment(todayDate).format('YYYY-MM-DD'));
        };
        setSelectedConnectivities([...selectedConnectivitiesArray]);
        setReasonStatuses([...selectedReasonArray]);
        setdateCancelation([...selectedDateCancelationArray]);
    }

    const handleCancelConnectivity = () => {
        setRequestCancelClicked(true);

        dialog.closeDialog();
        if (cancelledConnectivities.length + selectedConnectivities.length === connectivities.length)
            openModal(3, submitCancelConnectRequest, {
                title: 'Alert! No active connectivities left!',
                info: 'If all connectivities are cancelled, the current group request will be cancelled too.',
                question: `Are you sure you want to proceed?`,
            })
        else {
            openModal(0, submitCancelConnectRequest, {
                title: 'Please Confirm',
                question: `Are you sure you want to cancel?`,
            })
        }

    }

    // Cancel connectivity request
    const submitCancelConnectRequest = () => {
        openLoader();
        let ConnectivityRequestIDArrayBody = [];
        selectedConnectivities.map((c, ci) => {
            ConnectivityRequestIDArrayBody.push({
                Id: selectedConnectivities[ci].ConnectivityRequestID,
                StatusReason: reasonStatuses[ci],
                CancellationDate: dateCancelation[ci] !== "Invalid date" ? dateCancelation[ci] : null,
            })
        })
        const cancelConnectRequest = {
            ConnectivityRequestIDArray: ConnectivityRequestIDArrayBody,
            ConnectivityRequestGroupRequestID: groupReq?.groupRequest?.GroupRequestID,

        }

        APIs.cancelConnectivityRequest(cancelConnectRequest).then((r) => (
            window.location.reload()

        ));


    }

    return (
        <div id="cancel-connectivity">
            {/* <h5 className="black1 fw-700 ml-3">Connectivity Requests</h5>
            <Divider /> */}
            {connectivities?.map((c, ci) => (
                <div className="mt-3" key={`cancel-connectivity-${ci}`}>
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <CustomTooltip TransitionComponent={Zoom} title={(c.CancellationDate !== null || c.ConnectivityRequestStatusName === "Cancelled") ? "This connectivity is cancelled already" : ""}>
                                <span className="alignvh-center">
                                    <Checkbox
                                        disabled={c.CancellationDate !== null || c.ConnectivityRequestStatusName === "Cancelled"}
                                        id={`connectivity-option-${ci}`}
                                        value={c}
                                        checked={selectedConnectivities && selectedConnectivities[c]}
                                        onChange={handleChange}
                                        onClick={handleConnectivityClick(c)}
                                    />
                                </span>
                            </CustomTooltip>
                        }
                        label={c.IsolvedCarrierName === "Third Party" ? `${c.IsolvedCarrierName} (${c.ThirdPartyName})` : c.IsolvedCarrierName}
                    />
                    {c.PlanTypes?.map((p, pi) => (
                        <h6 key={`plan-types-${pi}`} className="ml-3 plan-type-modal">{p.PlanTypeName}</h6>
                    ))}

                    {(c.CancellationDate !== null && c.ConnectivityRequestStatusName !== "Cancelled") && <div className=" text-alert-yellow" >{`This connectivity request has a future cancellation date ${Moment(c.CancellationDate).format('MM/DD/YYYY')}`}</div>}


                    {selectedConnectivities.find((s) => s === c) &&
                        <div key={`cancel-${ci}`}>
                            <div className="col-xl-12 mt-3 alignvh-center mt-4">
                                <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>Reason For Cancellation
                                </h6>

                            </div>
                            <div className="col-xl-12 mt-2">
                                <textarea
                                    type="text"
                                    style={{ fontFamily: 'Manrope', fontSize: "12px" }}
                                    className="action-item-textarea w-100"
                                    value={reasonStatuses[selectedConnectivities.indexOf(c)]}
                                    onChange={(e) => {
                                        let reasonStatuse = [...reasonStatuses];
                                        reasonStatuse[selectedConnectivities.indexOf(c)] = e.target.value;
                                        setReasonStatuses(reasonStatuse);
                                    }}
                                    rows={4}
                                />
                            </div>
                            <div className="col-xl-12 mt-3 alignvh-center ">
                                <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>Cancellation Date
                                </h6>

                            </div>
                            <div className="col-xl-12 mt-2 mb-4">
                            <div className="mt-md-n2">
                            <GenericDatePicker
                                classNames="w-100"
                                id="cancelation-date"
                                value={
                                    !dateCancelation[selectedConnectivities.indexOf(c)] || dateCancelation[selectedConnectivities.indexOf(c)] === "Invalid date"
                                      ? null
                                      : parseISO(dateCancelation[selectedConnectivities.indexOf(c)])
                                  } 
                                onChange={(date) => {
                                    let dateCancelations = [...dateCancelation];
                                    dateCancelations[selectedConnectivities.indexOf(c)] = Moment(date).format('YYYY-MM-DD');
                                    setdateCancelation(dateCancelations);
                                }}
                                placeholder="Cancellation Date"
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </div>

                            </div>
                            {(dateCancelation[selectedConnectivities.indexOf(c)] < Moment(todayDate).format('YYYY-MM-DD')) && <div className=" text-danger fs-12" >Please note that the cancellation date should not be before today's date.</div>}
                            {(dateCancelation[selectedConnectivities.indexOf(c)] > Moment(todayDate).format('YYYY-MM-DD')) && <div className=" text-alert-yellow" >Please note that the connectivity request's current status<br /> will be the same until the cancellation date day.</div>}
                        </div>}
                </div>
            ))
            }


            <div className="w-100 text-center mt-5">
                <CustomTooltip TransitionComponent={Zoom} title={selectedConnectivities.length <= 0 ? "Please select at least one connectivity" : ""}>
                    <span>
                        <Button disabled={!allValid} onClick={handleCancelConnectivity} variant="contained" className="blue-outlined-btn">{selectedConnectivities.length > 1 ? 'Cancel Connectivities' : 'Cancel Connectivity'}</Button>
                    </span>
                </CustomTooltip>
            </div>
        </div >
    )
}

export default CancelConnectivity;