import React from "react";
import FormControl from '@material-ui/core/FormControl';
import { Select } from "@material-ui/core";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { parseISO } from 'date-fns';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateTimeSVG from "../../../Images/dateTime.svg";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@mui/material/Grid';
import GenericDatePicker from "../../Layout/GenericDatePicker";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SelectSingleClientInformation({
    carrier,
    index,
    groupsAndOEContactsList,
    parentForceUpdate,
    groupReq,
    onSelectClientContactValidationForm,
    onSelectClientEffectiveDateValidationForm,
    setSelectedCarriers,
    selectedCarriers,
    requestSubmitted,
    requestSaved,
    ...props
}) {

    // const [clientContactValidation, setClientContactValidation] = React.useState("");
    // const [clientEffectiveDateValidation, setClientEffectiveDateValidation] = React.useState("");

    const handleGroupEffDateChange = (date) => {
        carrier.ClientEffectiveDate = (date == null ? null : Moment(date).format('YYYY-MM-DD'));
        parentForceUpdate();
    };

    const handelClientContact = (e) => {
        carrier.ClientContact = e.target.value;
        parentForceUpdate();
    }
    const handleCarrierGroupNumberChange = (e) => {
        carrier.CarrierGroupNumber = e.target.value;
        parentForceUpdate();
    }

    React.useEffect(() => {
        if (selectedCarriers && selectedCarriers?.filter(s => !s.ClientContact).length > 0) {
            if (groupReq?.groupRequest?.IsPEO !== 1) {
                onSelectClientContactValidationForm("iSolved contact is missing, please select a iSolved contact from the list for each Carrier selected");
                // setClientContactValidation("iSolved contact is missing, please select a iSolved contact from the list for each Carrier selected");
            } else {
                onSelectClientContactValidationForm("PEO contact is missing, please select a PEO contact from the list for each Carrier selected");
                // setClientContactValidation("PEO contact is missing, please select a PEO contact from the list for each Carrier selected");
            }
        } else {
            onSelectClientContactValidationForm("");
            // setClientContactValidation("");
        }
    }, [JSON.stringify(selectedCarriers)])

    React.useEffect(() => {
        if (selectedCarriers && selectedCarriers?.filter(s => !s.ClientEffectiveDate).length > 0) {
            onSelectClientEffectiveDateValidationForm("Client Effective Date is missing, please select a Client Effective Date for each Carrier selected");
            // setClientEffectiveDateValidation("Client Effective Date is missing, please select a Client Effective Date for each Carrier selected");
        } else {
            onSelectClientEffectiveDateValidationForm("");
            // setClientEffectiveDateValidation("");
        }
    }, [JSON.stringify(selectedCarriers)])

    return (
        <TabPanel value={index} index={index}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={12} md={6}>

                    <h6 className="form-title">Client Effective Date<span className="text-danger mb-2 ml-2">*</span></h6>

                    <div className="mt-md-n2">
                        <GenericDatePicker
                            classNames="w-100 mt-0 mb-0"
                            id="frequency-date"
                            value={carrier.ClientEffectiveDate ? parseISO(carrier.ClientEffectiveDate) : null}
                            onChange={(date) => {
                                if (!date) {
                                    carrier.ClientEffectiveDate  = null; 
                                } else {
                                    const formattedDate = Moment(date).format("YYYY-MM-DD"); 
                                    carrier.ClientEffectiveDate = formattedDate;
                                    parentForceUpdate();
                                }
                            }}
                            placeholder="Plan Year Start Date"
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                        </div>
                </Grid>
                {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{clientEffectiveDateValidation}</div> */}

                {groupReq && (
                    <>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">{groupReq?.groupRequest?.IsPEO !== 1 ? `iSolved Contact` : `PEO Contact`}

                                <span className="text-danger">*</span>
                            </h6>

                            <FormControl label="Select iSolved contact" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={carrier.ClientContact ?? ""}
                                    onChange={handelClientContact}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{clientContactValidation}</div> */}
                    </>
                )}
                <Grid item xs={12} sm={12} md={6}>
                    <h6 className="form-title">Carrier Group Number</h6>

                    <input type="text" className="text-input w-100 mt-2"
                        // style={{ padding: "0 20px" }}
                        value={carrier?.CarrierGroupNumber ?? ""}
                        onChange={handleCarrierGroupNumberChange}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export default SelectSingleClientInformation;
