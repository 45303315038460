import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { FormControl, Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, TextField } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import GenericDatePicker from "../Layout/GenericDatePicker";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

function OERequestsModalBodyFilter({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onSetPageSizeAdv,
    onSetSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    setOERequestIDFilterAdvFilter,
    setUpdatedGSAdvFilter,
    setDraftAdvFilter,
    setSelectedPlanYearStartFromDateFilterAdvFilter,
    setSelectedPlanYearStartToDateFilterAdvFilter,
    setSelectedExpectedFromDateFilterAdvFilter,
    setSelectedExpectedToDateFilterAdvFilter,
    setSelectedCreatedFromDateFilterAdvFilter,
    setSelectedCreatedToDateFilterAdvFilter,
    setStatusFilterAdvFilter,
    setPhaseFilterAdvFilter,
    setOETypeFilterAdvFilter,
    setConnectivityRequestIDAdvFilter,
    setAssignedToAdvFilter,
    onSelectedAssignedToFilter,
    setISolvedContactAdvFilter,
    onSelectedISolvedContactFilter,
    selectedISolvedContactFilter,
    setSelectedStatusesFilter,
    setSelectedPhasesFilter,
    setSelectedOETypesFilter,
    onCloseClearReset,
    onSelectedClientsFilter,
    selectedClientsFilter,
    onSelectedPEOsFilter,
    selectedPEOsFilter,
    onSelectedCarriersFilter,
    selectedCarriersFilter,
    setSelectedOEFileSubmissionFromDateFilterAdvFilter,
    setSelectedOEFileSubmissionToDateFilterAdvFilter,
    setRequestTypeAdvFilter,
    ...props
}) {

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [oERequestID, setOERequestID] = React.useState("");
    const [connectivityRequestID, setConnectivityRequestID] = React.useState();
    const [selectedPlanYearStartFromDate, setSelectedPlanYearStartFromDate] = React.useState(null);
    const [selectedPlanYearStartToDate, setSelectedPlanYearStartToDate] = React.useState(null);
    const [selectedExpectedFromDate, setSelectedExpectedFromDate] = React.useState(null);
    const [selectedExpectedToDate, setSelectedExpectedToDate] = React.useState(null);
    const [selectedCreatedFromDate, setSelectedCreatedFromDate] = React.useState(null);
    const [selectedCreatedToDate, setSelectedCreatedToDate] = React.useState(null);
    const [status, setStatus] = React.useState();
    const [phase, setPhase] = React.useState();
    const [oeType, setOEType] = React.useState();
    const [assignedTo, setAssignedTo] = React.useState("");
    const [assignedToAutoValue, setAssignedToAutoValue] = React.useState("");
    const [assignedToSearchValue, setAssignedToSearchValue] = React.useState("");
    const [iSolvedContact, setISolvedContact] = React.useState("");
    const [iSolvedContactAutoValue, setISolvedContactAutoValue] = React.useState("");
    const [iSolvedContactSearchValue, setISolvedContactSearchValue] = React.useState("");
    const [selectedAssignees, setSelectedAssignees] = React.useState([]);
    const [selectedISolvedContacts, setSelectedISolvedContacts] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [selectedPhases, setSelectedPhases] = React.useState([]);
    const [selectedTypes, setSelectedTypes] = React.useState([]);
    const [updatedGS, setUpdatedGS] = React.useState("");
    const [draft, setDraft] = React.useState("");
    const [requestType, setRequestType] = React.useState("");
    const [clientsList, setClientsList] = React.useState([]);
    const [peosList, setPEOsList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [clientName, setClientName] = React.useState();
    const [clientNameAutoValue, setClientNameAutoValue] = React.useState("");
    const [peoName, setPEOName] = React.useState();
    const [peoNameAutoValue, setPEONameAutoValue] = React.useState("");
    const [carName, setCarName] = React.useState();
    const [carNameAutoValue, setCarNameAutoValue] = React.useState("");

    const [clientSearchValue, setClientSearchValue] = React.useState("");
    const [selectedClients, setSelectedClients] = React.useState([]);
    const [peoSearchValue, setPEOSearchValue] = React.useState("");
    const [selectedPEOs, setSelectedPEOs] = React.useState([]);
    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [selectedCarriers, setSelectedCarriers] = React.useState([]);
    const [selectedOEFileSubmissionFromDate, setSelectedOEFileSubmissionFromDate] = React.useState(null);
    const [selectedOEFileSubmissionToDate, setSelectedOEFileSubmissionToDate] = React.useState(null);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    // gated Data 
    const [statusesList, setStatusesList] = React.useState([]);
    const [phasesList, setPhasesList] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);
    const [requestTypes, setRequestTypes] = React.useState([]);

    React.useEffect(() => {
        openLoader();
        APIs.getGroupsListByName({ GroupName: clientName ?? "" }).then((r) => !(r?.data?.length) ? setClientsList([]) : setClientsList(r?.data));
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
        APIs.getCarriersListByName({ CarrierName: carName ?? "", StatusTypeId: [1,2] }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [])

    React.useEffect(() => {
        setClientNameAutoValue(clientName);
    }, [clientName])

    React.useEffect(() => {
        setPEONameAutoValue(peoName);
    }, [peoName])

    React.useEffect(() => {
        setCarNameAutoValue(carName);
    }, [carName])

    React.useEffect(() => {
        setAssignedToAutoValue(`${assignedTo?.FirstName ?? ""} ${assignedTo?.LastName ?? ""}`);
    }, [assignedTo])

    React.useEffect(() => {
        setISolvedContactAutoValue(`${iSolvedContact?.FirstName ?? ""} ${iSolvedContact?.LastName ?? ""}`);
    }, [iSolvedContact])
    // get Data 
    React.useEffect(() => {
        APIs.getOERequestStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getOERequestTypes().then((r) =>
            !r?.data?.length ? setRequestTypes([]) : setRequestTypes(r?.data)
        );
    }, []);

    React.useEffect(() => {
        APIs.getOERequestPhasesList().then(r => !(r?.data?.length) ? setPhasesList([]) : setPhasesList(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
        }
        APIs.getContactsList(contactsBody).then((r) => {
            setContactsList(r?.data ?? []);
        })
    }, [])

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };
    const handleOERequestIDFilter = (e) => {
        setOERequestID(e.target.value);
        setOERequestIDFilterAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleConnectivityRequestIDFilter = (e) => {
        setConnectivityRequestID(e.target.value);
        setConnectivityRequestIDAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleUpdatedGSFilter = (e, newValue) => {
        setUpdatedGS(e.target.value);
        setUpdatedGSAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleDraftFilter = (e) => {
        setDraft(e.target.value);
        setDraftAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleRequestTypeFilter = (e, newValue) => {
        setRequestType(e.target.value);
        setRequestTypeAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleOETypeFilter = (e) => {
        setOEType(e.target.value);
        setOETypeFilterAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }
    const handleStatusFilter = (e) => {
        setStatus(e.target.value);
        setStatusFilterAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }
    const handlePhaseFilter = (e) => {
        setPhase(e.target.value);
        setPhaseFilterAdvFilter(e.target.value);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }


    const handleSelectedPlanYearStartChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlanYearStartFromDate(date);
            setSelectedPlanYearStartFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedPlanYearStartToDate(date);
            setSelectedPlanYearStartToDateFilterAdvFilter(date);
        }
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    };

    const handleOEFileSubmissionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedOEFileSubmissionFromDate(date);
            setSelectedOEFileSubmissionFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedOEFileSubmissionToDate(date);
            setSelectedOEFileSubmissionToDateFilterAdvFilter(date);
        }
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    };

    const handleSelectedExpectedChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedExpectedFromDate(date);
            setSelectedExpectedFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedExpectedToDate(date);
            setSelectedExpectedToDateFilterAdvFilter(date);
        }
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    };



    const handleeSelectedCreatedChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCreatedFromDate(date);
            setSelectedCreatedFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedCreatedToDate(date);
            setSelectedCreatedToDateFilterAdvFilter(date);
        }
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    };

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleAssignedToClick = (value) => (event) => {
        let selectedAssignedToArray = selectedAssignees;
        if (!value || value === "") selectedAssignedToArray = [];
        else if (selectedAssignedToArray.indexOf(value) >= 0) {
            selectedAssignedToArray.splice(selectedAssignedToArray.indexOf(value), 1);
        }
        else {
            selectedAssignedToArray.push(value);
        }
        setSelectedAssignees([...selectedAssignedToArray]);
        onSelectedAssignedToFilter([...selectedAssignedToArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleISolvedContactClick = (value) => (event) => {
        let selectedISolvedContactArray = selectedISolvedContacts;
        if (!value || value === "") selectedISolvedContactArray = [];
        else if (selectedISolvedContactArray.indexOf(value) >= 0) {
            selectedISolvedContactArray.splice(selectedISolvedContactArray.indexOf(value), 1);
        }
        else {
            selectedISolvedContactArray.push(value);
        }
        setSelectedISolvedContacts([...selectedISolvedContactArray]);
        onSelectedISolvedContactFilter([...selectedISolvedContactArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleClientClick = (value) => (event) => {
        let selectedClientsArray = selectedClients;
        if (!value || value === "") selectedClientsArray = [];
        else if (selectedClientsArray.indexOf(value) >= 0) {
            selectedClientsArray.splice(selectedClientsArray.indexOf(value), 1);
        }
        else {
            selectedClientsArray.push(value);
        }
        setSelectedClients([...selectedClientsArray]);
        onSelectedClientsFilter([...selectedClientsArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handlePEOClick = (value) => (event) => {
        let selectedPEOsArray = selectedPEOs;
        if (!value || value === "") selectedPEOsArray = [];
        else if (selectedPEOsArray.indexOf(value) >= 0) {
            selectedPEOsArray.splice(selectedPEOsArray.indexOf(value), 1);
        }
        else {
            selectedPEOsArray.push(value);
        }
        setSelectedPEOs([...selectedPEOsArray]);
        onSelectedPEOsFilter([...selectedPEOsArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }


    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarriers;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
        }
        else {
            selectedCarriersArray.push(value);
        }
        setSelectedCarriers([...selectedCarriersArray]);
        onSelectedCarriersFilter([...selectedCarriersArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleOERequestStatusesClick = (value) => (event) => {
        let selectedOERequestStatusesArray = selectedStatuses;
        if (!value || value === "") selectedOERequestStatusesArray = [];
        else if (selectedOERequestStatusesArray.indexOf(value) >= 0) {
            selectedOERequestStatusesArray.splice(selectedOERequestStatusesArray.indexOf(value), 1);
        }
        else {
            selectedOERequestStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedOERequestStatusesArray]);
        setSelectedStatusesFilter([...selectedOERequestStatusesArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }

    const handleOERequestPhasesClick = (value) => (event) => {
        let selectedOERequestPhasesArray = selectedPhases;
        if (!value || value === "") selectedOERequestPhasesArray = [];
        else if (selectedOERequestPhasesArray.indexOf(value) >= 0) {
            selectedOERequestPhasesArray.splice(selectedOERequestPhasesArray.indexOf(value), 1);
        }
        else {
            selectedOERequestPhasesArray.push(value);
        }
        setSelectedPhases([...selectedOERequestPhasesArray]);
        setSelectedPhasesFilter([...selectedOERequestPhasesArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }


    const handleOETypesClick = (value) => (event) => {
        let selectedOETypesArray = selectedTypes;
        if (!value || value === "") selectedOETypesArray = [];
        else if (selectedOETypesArray.indexOf(value) >= 0) {
            selectedOETypesArray.splice(selectedOETypesArray.indexOf(value), 1);
        }
        else {
            selectedOETypesArray.push(value);
        }
        setSelectedTypes([...selectedOETypesArray]);
        setSelectedOETypesFilter([...selectedOETypesArray]);
        onSetSelectedPageAdv(1);
        onSetPageSizeAdv(30);
    }
    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handleClearSearch = () => {
        setOERequestID("");
        setOERequestIDFilterAdvFilter("");
        setConnectivityRequestID("");
        setConnectivityRequestIDAdvFilter("");
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setDraft("");
        setDraftAdvFilter("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setOEType("");
        setOETypeFilterAdvFilter("");
        setStatus("");
        setStatusFilterAdvFilter("");
        setPhase("");
        setPhaseFilterAdvFilter("");
        setSelectedPlanYearStartFromDate(null);
        setSelectedPlanYearStartFromDateFilterAdvFilter(null);
        setSelectedPlanYearStartToDate(null);
        setSelectedPlanYearStartToDateFilterAdvFilter(null);
        setSelectedOEFileSubmissionFromDate(null);
        setSelectedOEFileSubmissionFromDateFilterAdvFilter(null);

        setSelectedOEFileSubmissionToDate(null);
        setSelectedOEFileSubmissionToDateFilterAdvFilter(null);
        setSelectedExpectedFromDate(null);
        setSelectedExpectedFromDateFilterAdvFilter(null);
        setSelectedExpectedToDate(null);
        setSelectedExpectedToDateFilterAdvFilter(null);
        setSelectedCreatedFromDate(null);
        setSelectedCreatedFromDateFilterAdvFilter(null);
        setSelectedCreatedToDate(null);
        setSelectedCreatedToDateFilterAdvFilter(null);
        setAssignedTo("");
        setISolvedContact("");
        setSelectedAssignees([]);
        onSelectedAssignedToFilter([]);
        setAssignedToAdvFilter("");
        onSelectedISolvedContactFilter([]);
        setSelectedISolvedContacts([]);
        setISolvedContactAdvFilter("");
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPhases([]);
        setSelectedPhasesFilter([]);
        setSelectedTypes([]);
        setSelectedOETypesFilter([]);
        onSetSelectedPageAdv(1);
        setSelectedClients([]);
        onSelectedClientsFilter([]);
        setSelectedPEOs([]);
        onSelectedPEOsFilter([]);
        setSelectedCarriers([]);
        onSelectedCarriersFilter([]);
    }

    React.useEffect(() => {
        setOERequestID("");
        setOERequestIDFilterAdvFilter("");
        setConnectivityRequestID("");
        setConnectivityRequestIDAdvFilter("");
        setOEType("");
        setOETypeFilterAdvFilter("");
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setDraft("");
        setDraftAdvFilter("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setStatus("");
        setStatusFilterAdvFilter("");
        setPhase("");
        setPhaseFilterAdvFilter("");
        setSelectedPlanYearStartFromDate(null);
        setSelectedPlanYearStartFromDateFilterAdvFilter(null);
        setSelectedPlanYearStartToDate(null);
        setSelectedPlanYearStartToDateFilterAdvFilter(null);
        setSelectedOEFileSubmissionFromDate(null);
        setSelectedOEFileSubmissionFromDateFilterAdvFilter(null);

        setSelectedOEFileSubmissionToDate(null);
        setSelectedOEFileSubmissionToDateFilterAdvFilter(null);
        setSelectedExpectedFromDate(null);
        setSelectedExpectedFromDateFilterAdvFilter(null);
        setSelectedExpectedToDate(null);
        setSelectedExpectedToDateFilterAdvFilter(null);
        setSelectedCreatedFromDate(null);
        setSelectedCreatedFromDateFilterAdvFilter(null);
        setSelectedCreatedToDate(null);
        setSelectedCreatedToDateFilterAdvFilter(null);
        setAssignedTo("");
        setISolvedContact("");
        setSelectedAssignees([]);
        onSelectedAssignedToFilter([]);
        setAssignedToAdvFilter("");
        onSelectedISolvedContactFilter([]);
        setISolvedContactAdvFilter("");
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPhases([]);
        setSelectedPhasesFilter([]);
        setSelectedTypes([]);
        setSelectedOETypesFilter([]);
        onSetSelectedPageAdv(1);
        setSelectedClients([]);
        onSelectedClientsFilter([]);
        setSelectedPEOs([]);
        onSelectedPEOsFilter([]);
        setSelectedCarriers([]);
        setSelectedISolvedContacts([]);
        onSelectedCarriersFilter([]);
    }, [advancedFiltersClicked === false])

    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            onSetSelectedPageAdv(1);
            onSetPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            onSetSelectedPageAdv(1);
            onSetPageSizeAdv(30);
        }
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced OE Request Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>

                <div className="filter-body-div">
                    <Box style={{ display: 'flex' }}>
                        <Tabs
                            style={{ maxHeight: "370px", overflow: "auto" }}
                            orientation="vertical"
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                        >
                            <Tab className="filter-tab-btn" label="OE Request ID" {...a11yProps(0)} />
                            <Tab className="filter-tab-btn" label="Connectivity Request ID" {...a11yProps(1)} />
                            <Tab className="filter-tab-btn" label="Client Name" {...a11yProps(2)} />
                            <Tab className="filter-tab-btn" label="PEO Name" {...a11yProps(3)} />
                            <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(4)} />
                            <Tab className="filter-tab-btn" label="status" {...a11yProps(5)} />
                            <Tab className="filter-tab-btn" label="Phase" {...a11yProps(6)} />
                            <Tab className="filter-tab-btn" label="Updated Group Structure" {...a11yProps(7)} />
                            <Tab className="filter-tab-btn" label="Assigned To" {...a11yProps(8)} />
                            <Tab className="filter-tab-btn" style={{ textTransform: "none" }} label="isolved Contact" {...a11yProps(9)} />
                            <Tab className="filter-tab-btn" label="Draft" {...a11yProps(10)} />
                            <Tab className="filter-tab-btn" label="Plan Year Start Date" {...a11yProps(11)} />
                            <Tab className="filter-tab-btn" label="Client's Expected Data Ready Date" {...a11yProps(12)} />
                            <Tab className="filter-tab-btn" label="Created Date" {...a11yProps(13)} />
                            <Tab className="filter-tab-btn" label="OE File Transmission Date" {...a11yProps(14)} />
                            <Tab className="filter-tab-btn" label="Request Type" {...a11yProps(15)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <span>Please filter by only one OE Request ID:</span>
                            <br />
                            <input type="text" className="search-input w-100" placeholder="OE Request ID" value={oERequestID} onChange={handleOERequestIDFilter} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <span>Please filter by only one Connectivity Request ID:</span>
                            <br />
                            <input type="text" className="search-input w-100" placeholder="Connectivity Request ID" value={connectivityRequestID} onChange={handleConnectivityRequestIDFilter} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <input type="text" className="text-input w-100" placeholder="Search clients here..." value={clientSearchValue} onChange={(e) => setClientSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {clientsList?.filter(s => clientSearchValue ? s.GroupName?.toLowerCase().includes(clientSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`groups-select-all`}>
                                        <AccordionSummary
                                            aria-controls="groups-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`groups-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedClients && selectedClients.length === clientsList?.filter(s => clientSearchValue ? s.GroupName?.toLowerCase().includes(clientSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedClients, clientsList?.filter(s => clientSearchValue ? s.GroupName?.toLowerCase().includes(clientSearchValue.toLowerCase()) : s), setSelectedClients, onSelectedClientsFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                {clientsList?.filter(s => clientSearchValue ? s.GroupName?.toLowerCase().includes(clientSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`groups-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="groups-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedClients && selectedClients.indexOf(c) >= 0}
                                                            onClick={handleClientClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.GroupName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <input type="text" className="text-input w-100" placeholder="Search peos here..." value={peoSearchValue} onChange={(e) => setPEOSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`peos-select-all`}>
                                        <AccordionSummary
                                            aria-controls="peos-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`peos-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPEOs && selectedPEOs.length === peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedPEOs, peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s), setSelectedPEOs, onSelectedPEOsFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }

                                {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`peos-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="peos-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`peo-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPEOs && selectedPEOs.indexOf(c) >= 0}
                                                            onClick={handlePEOClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.PeoName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`carriers-select-all`}>
                                        <AccordionSummary
                                            aria-controls="carriers-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`carriers-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedCarriers && selectedCarriers.length === carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedCarriers, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarriers, onSelectedCarriersFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }
                                {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`carriers-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="carriers-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`carrier-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedCarriers && selectedCarriers.indexOf(c) >= 0}
                                                            onClick={handleCarrierClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.CarrierName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`oe-request-status-all`}>
                                    <AccordionSummary
                                        aria-controls="oe-request-status-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`oe-request-status-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedStatuses && selectedStatuses.length === statusesList.length}
                                                        onClick={handelSelectAll(selectedStatuses, statusesList, setSelectedStatuses, setSelectedStatusesFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>
                                {statusesList.map((f, fi) => (
                                    <Accordion key={`oe-request-status-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="oe-request-statuses-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`oe-request-status-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                            onClick={handleOERequestStatusesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`oe-request-phase-all`}>
                                    <AccordionSummary
                                        aria-controls="oe-request-phase-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`oe-request-phase-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedPhases && selectedPhases.length === phasesList.length}
                                                        onClick={handelSelectAll(selectedPhases, phasesList, setSelectedPhases, setSelectedPhasesFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>
                                {phasesList.map((f, fi) => (
                                    <Accordion key={`oe-request-phase-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="oe-request-phases-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`oe-request-phase-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                            onClick={handleOERequestPhasesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={7}>
                            <span>Please filter by only one option:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={updatedGS}
                                    onChange={handleUpdatedGSFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </NativeSelect>
                            </FormControl>
                        </TabPanel>
                        <TabPanel value={tabValue} index={8}>
                            <input type="text" className="text-input w-100" placeholder="Search users here..." value={assignedToSearchValue} onChange={(e) => setAssignedToSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {contactsList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`users-assignto-all`}>
                                        <AccordionSummary
                                            aria-controls="users-assignto-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-assignto-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedAssignees && selectedAssignees.length === contactsList.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedAssignees, contactsList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s), setSelectedAssignees, onSelectedAssignedToFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }

                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                {contactsList?.filter(s => s.IsEnabled === 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-assignto-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-assignto-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`user-assignto-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                            onClick={handleAssignedToClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                {contactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-assignto-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-assignto-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`user-assignto-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                            onClick={handleAssignedToClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={9}>
                            <input type="text" className="text-input w-100" placeholder="Search users here..." value={iSolvedContactSearchValue} onChange={(e) => setISolvedContactSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {groupsAndOEContactsList?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s).length > 0 &&
                                    <Accordion key={`users-iSolved-all`}>
                                        <AccordionSummary
                                            aria-controls="users-iSolved-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-iSolved-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedISolvedContacts && selectedISolvedContacts.length === groupsAndOEContactsList?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s).length}
                                                            onClick={handelSelectAll(selectedISolvedContacts, groupsAndOEContactsList?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s), setSelectedISolvedContacts, onSelectedISolvedContactFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                }

                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                {groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-iSolved-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-iSolved-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`user-iSolved-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedISolvedContacts && selectedISolvedContacts.indexOf(c) >= 0}
                                                            onClick={handleISolvedContactClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                {groupsAndOEContactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-iSolved-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-iSolved-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`user-iSolved-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedISolvedContacts && selectedISolvedContacts.indexOf(c) >= 0}
                                                            onClick={handleISolvedContactClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={10}>
                            <span>Please filter by only one option:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={draft}
                                    onChange={handleDraftFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </NativeSelect>
                            </FormControl>
                        </TabPanel>
                        <TabPanel value={tabValue} index={11}>
                            <span>From:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedPlanYearStartFromDate}
                                    onChange={(date) => {
                                        setSelectedPlanYearStartFromDateFilterAdvFilter(date);
                                        setSelectedPlanYearStartFromDate(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedPlanYearStartToDate}
                                    onChange={(date) => {
                                        setSelectedPlanYearStartToDateFilterAdvFilter(date);
                                        setSelectedPlanYearStartToDate(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                        </TabPanel>
                        <TabPanel value={tabValue} index={12}>
                            <span>From:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedExpectedFromDate}
                                    onChange={(date) => {
                                        setSelectedExpectedFromDateFilterAdvFilter(date);
                                        setSelectedExpectedFromDate(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedExpectedToDate}
                                    onChange={(date) => {
                                        setSelectedExpectedToDateFilterAdvFilter(date);
                                        setSelectedExpectedToDate(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                        </TabPanel>
                        <TabPanel value={tabValue} index={13}>
                            <span>From:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedFromDate}
                                    onChange={(date) => {
                                        setSelectedCreatedFromDateFilterAdvFilter(date);
                                        setSelectedCreatedFromDate(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedToDate}
                                    onChange={(date) => {
                                        setSelectedCreatedToDateFilterAdvFilter(date);
                                        setSelectedCreatedToDate(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                        </TabPanel>
                        <TabPanel value={tabValue} index={14}>
                            <span>From:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedOEFileSubmissionFromDate}
                                    onChange={(date) => {
                                        setSelectedOEFileSubmissionFromDateFilterAdvFilter(date);
                                        setSelectedOEFileSubmissionFromDate(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedOEFileSubmissionToDate}
                                    onChange={(date) => {
                                        setSelectedOEFileSubmissionToDateFilterAdvFilter(date);
                                        setSelectedOEFileSubmissionToDate(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                        </TabPanel>
                        <TabPanel value={tabValue} index={15}>
                            <span>Please filter by only one option:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={requestType}
                                    onChange={handleRequestTypeFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    {requestTypes?.map((p, pi) => (
                                        <option key={`oe-request-types-list-${pi}`} value={p.RequestTypeId}>{p.RequestTypeName}</option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                        </TabPanel>

                    </Box>
                </div>


            </DialogContent>
            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setAdvancedFiltersClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OERequestsModalBodyFilter
