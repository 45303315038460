import React from "react";
import { Paper, Zoom } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import Moment from 'moment';
import { useLoader } from "../../../Layout/Loader";
import { useAuthentication } from "../../../../Data/Authentication";
import Pagination from "../../../Layout/Pagination";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Truncate from "react-truncate";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { withCustomProps } from "../../../Transmissions/SingleFeedTransmissionNew";
import SingleEmployeeFormTransmissionNew from "../../../TransmissionsForms/SingleEmployeeFormTransmissionNew";
import GenericTable from "../../../Layout/GenericTable";
import { FailureModal } from "../../../Modals/FailureModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import FormsTransmissionDashboardFilter from "../../../TransmissionsForms/FormsTransmissionDashboardFilter";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

function AnalystSingleFeedFormsTransmission({ feedID, carrierID, feed, ...props }) {

    const { authUser } = useAuthentication();
    const dialog = useModalDialog(0);
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [singleFeedTransmissionsList, setSingleFeedTransmissionsList] = React.useState([]);
    const [feedsTransmissionsResponse, setFeedsTransmissionsResponse] = React.useState();

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [fromDateFilter, setFromDateFilter] = React.useState(todayDate);
    const [toDateFilter, setToDateFilter] = React.useState(todayDate);
    const [sentFromDateFilter, setSentFromDateFilter] = React.useState(null);
    const [sentToDateFilter, setSentToDateFilter] = React.useState(null);
    const [feedTypeFilter, setFeedTypeFilter] = React.useState("");
    const [feedStatusFilter, setFeedStatusFilter] = React.useState("");
    const [feedPhaseFilter, setFeedPhaseFilter] = React.useState("");
    const [executionStatusFilter, setExecutionStatusFilter] = React.useState("");
    const [executionIDFilter, setExecutionIDFilter] = React.useState("");
    const [processingStepFilter, setProcessingStepFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("DESC");
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    const [feedsFilterObject, setFeedsFilterObject] = React.useState();

    //Advanced Filters
    const [selectedFeedStatusAdvFilter, setSelectedFeedStatusAdvFilter] = React.useState([]);
    const [selectedFeedPhaseAdvFilter, setSelectedFeedPhaseAdvFilter] = React.useState([]);
    const [executionIDAdvFilter, setExecutionIDAdvFilter] = React.useState("");
    const [selectedExecutionStatusAdvFilter, setSelectedExecutionStatusAdvFilter] = React.useState([]);
    const [selectedProcessingStepAdvFilter, setSelectedProcessingStepAdvFilter] = React.useState([]);
    const [requestFromDateAdvFilter, setRequestFromDateAdvFilter] = React.useState(todayDate);
    const [requestToDateAdvFilter, setRequestToDateAdvFilter] = React.useState(todayDate);
    const [sentFromDateAdvFilter, setSentFromDateAdvFilter] = React.useState(null);
    const [sentToDateAdvFilter, setSentToDateAdvFilter] = React.useState(null);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("DESC");

    const [expandedRows, setExpandedRows] = React.useState({});


    const getDataQuickFilters = () => {
        openLoader();
        if (!feedID || feedID === undefined || !carrierID || carrierID === undefined) return;
        else {
            const singleFeedTransmissions = {};
            singleFeedTransmissions.feedID = [`${feedID}`];
            singleFeedTransmissions.carrierID = [`${carrierID}`];
            if (selectedPage >= 1) {
                singleFeedTransmissions.page = selectedPage;
                singleFeedTransmissions.limit = pageSize;
            }
            if (pageSize >= 10) {
                singleFeedTransmissions.limit = pageSize;
                singleFeedTransmissions.page = selectedPage;
            }
            if (fromDateFilter)
                singleFeedTransmissions.fromDate = Moment(fromDateFilter).format("YYYY-MM-DD");
            if (toDateFilter)
                singleFeedTransmissions.toDate = Moment(toDateFilter).format("YYYY-MM-DD");
            if (sentFromDateFilter)
                singleFeedTransmissions.sentFromDate = Moment(sentFromDateFilter).format("YYYY-MM-DD");
            if (sentToDateFilter)
                singleFeedTransmissions.sentToDate = Moment(sentToDateFilter).format("YYYY-MM-DD");
            if (executionStatusFilter !== "")
                singleFeedTransmissions.executionStatus = [`${executionStatusFilter}`];
            if (processingStepFilter !== "")
                singleFeedTransmissions.processingStep = [`${processingStepFilter}`];
            if (feedTypeFilter !== "")
                singleFeedTransmissions.feedTypeID = [`${feedTypeFilter}`];
            if (feedStatusFilter !== "")
                singleFeedTransmissions.feedStatusID = [`${feedStatusFilter}`];
            if (feedPhaseFilter !== "")
                singleFeedTransmissions.feedPhaseID = [`${feedPhaseFilter}`];
            if (executionIDFilter !== "")
                singleFeedTransmissions.executionID = executionIDFilter;
            if (sortFieldFilter !== "")
                singleFeedTransmissions.sortType = sortFieldFilter;
            if (sortOrderFilter !== "")
                singleFeedTransmissions.sortOrder = sortOrderFilter;

            setFeedsFilterObject(singleFeedTransmissions);

            if (fromDateFilter && toDateFilter && sortOrderFilter){

                APIs.getFeedsDigitalFormsTranmissions(singleFeedTransmissions).then((r) => {
                    setSingleFeedTransmissionsList(r?.data);
                    setRefreshClicked(false);
                    setTotalCount(r?.totalCount ?? 0);
                    setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
                    closeLoader();
                });
            }
        }
    };

    const getDataAdvancedFilters = () => {
        openLoader();
        if (!feedID || feedID === undefined || !carrierID || carrierID === undefined) return;
        else {
            const singleFeedTransmissions = {};
            singleFeedTransmissions.feedID = [`${feedID}`];
            singleFeedTransmissions.carrierID = [`${carrierID}`];
            if (selectedPageAdv >= 1) {
                singleFeedTransmissions.page = selectedPageAdv;
                singleFeedTransmissions.limit = pageSizeAdv;
            }
            if (pageSizeAdv >= 10) {
                singleFeedTransmissions.limit = pageSizeAdv;
                singleFeedTransmissions.page = selectedPageAdv;
            }
            if (requestFromDateAdvFilter)
                singleFeedTransmissions.fromDate = Moment(requestFromDateAdvFilter).format("YYYY-MM-DD");
            if (requestToDateAdvFilter)
                singleFeedTransmissions.toDate = Moment(requestToDateAdvFilter).format("YYYY-MM-DD");
            if (sentFromDateAdvFilter)
                singleFeedTransmissions.sentFromDate = Moment(sentFromDateAdvFilter).format("YYYY-MM-DD");
            if (sentToDateAdvFilter)
                singleFeedTransmissions.sentToDate = Moment(sentToDateAdvFilter).format("YYYY-MM-DD");
            if (selectedExecutionStatusAdvFilter.length > 0)
                singleFeedTransmissions.executionStatus = selectedExecutionStatusAdvFilter?.map(s => `${s?.Id}`);
            if (selectedProcessingStepAdvFilter.length > 0)
                singleFeedTransmissions.processingStep = selectedProcessingStepAdvFilter?.map(s => `${s?.Id}`);
            if (selectedFeedStatusAdvFilter.length > 0)
                singleFeedTransmissions.feedStatusID = selectedFeedStatusAdvFilter?.map(s => `${s?.FeedStatusID}`);
            if (selectedFeedPhaseAdvFilter.length > 0)
                singleFeedTransmissions.feedPhaseID = selectedFeedPhaseAdvFilter?.map(s => `${s?.FeedPhaseID}`);
            if (executionIDAdvFilter !== "")
                singleFeedTransmissions.executionID = executionIDAdvFilter;
            if (sortFieldAdvFilter !== "")
                singleFeedTransmissions.sortType = sortFieldAdvFilter;
            if (sortOrderAdvFilter !== "")
                singleFeedTransmissions.sortOrder = sortOrderAdvFilter;

            setFeedsFilterObject(singleFeedTransmissions);

            if (advancedFiltersClicked && fromDateFilter && toDateFilter && sortOrderFilter){
            APIs.getFeedsDigitalFormsTranmissions(singleFeedTransmissions).then((r) => {
                setSingleFeedTransmissionsList(r?.data);
                setRefreshClicked(false);
                setTotalCount(r?.totalCount ?? 0);
                setAdvancedFiltersAppliedClicked(false);
                closeLoader();
                setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
            });
        }
        }
    };

    React.useEffect(() => {
        if (quickFiltersClicked) getDataQuickFilters();
    }, [selectedPage, pageSize, fromDateFilter, toDateFilter, sentFromDateFilter, sentToDateFilter, executionStatusFilter, feedStatusFilter, feedPhaseFilter, feedTypeFilter, executionIDFilter, processingStepFilter, sortFieldFilter, sortOrderFilter, refreshClicked === true, feedID])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])

    // ---------------------------------------------------------------------NEW TABLE UI
    const handleSort = (column, sortDirection, type) => {
        if (type === "quick") {
            setSortFieldFilter(column?.sortingkey);
            setSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            setSortFieldAdvFilter(column?.sortingkey);
            setSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const handleRowExpandToggle = async (row) => {
        if (expandedRows[row.id]?.isExpanded) {
            setExpandedRows((prev) => ({ ...prev, [row.SK]: { ...prev[row.SK], isExpanded: false } }));
        } else {
            setExpandedRows((prev) => ({
                ...prev,
                [row.SK]: { isExpanded: !prev[row.SK]?.isExpanded }
            }));
        }
    };

    const expandableRowExpanded = (row) => row.isExpanded || false;

    const ExpandableRowsComponentWithProps = withCustomProps(SingleEmployeeFormTransmissionNew, {
        refreshClicked: refreshClicked,
        setRefreshClicked: setRefreshClicked,
    });
    

    const baseColumns = [
    
            {
                name: 'Feed Status',
                sortable: true,
                sortingkey: 'FeedStatus',
                id: 'FeedStatus',
                selector: feedTransmission => feedTransmission?.FeedStatus,
            },
            {
                name: 'Feed Phase',
                sortable: true,
                sortingkey: 'FeedPhase',
                id: 'FeedPhase',
                selector: feedTransmission => feedTransmission?.FeedPhase,
            },
            {
                name: 'Request Timestamp',
                sortable: true,
                sortingkey: 'RequestTimestamp',
                id: 'RequestTimestamp',
                selector: feedTransmission => Moment(feedTransmission?.RequestDate).format("MM/DD/YYYY HH:mm:ss"),
                tooltip: "Date and Time of when the feed started processing or reprocessing"
            },
            {
                name: 'Sent Timestamp',
                sortable: true,
                sortingkey: 'SentTimestamp',
                id: 'SentTimestamp',
                selector: feedTransmission => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom} title={feedTransmission?.SentDate === "N/A" ? "File is not transmitted yet" : ""}>
                    <span>
                        {`${feedTransmission?.SentDate === "N/A" ? "N/A" : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")}`}
                    </span>
                </CustomTooltip>,
                tooltip: "Date and Time of when the file feed was transmitted"
            },
            {
                name: 'Processing Step',
                sortable: true,
                width: '100px',
                sortingkey: 'ProcessingStep',
                id: 'ProcessingStep',
                selector: feedTransmission => feedTransmission?.ProcessingStep,
                tooltip: "The step in which the feed is currently processing"
            },
            {
                name: 'Processing Log',
                sortable: true,
                width: '200px',
                sortingkey: 'ProcessingLog',
                id: 'ProcessingLog',
                selector: feedTransmission =>
                    <Truncate
                        lines={3}
                        ellipsis={
                            <span>
                                <button
                                    className="btn-like-link fs-13"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        dialog.openDialog(
                                            "Processing Logs:",
                                            <div>
                                                
                                                <p style={{ wordBreak: "break-word" }}>
                                                    {feedTransmission?.ProcessingLog}
                                                </p>
                                            </div>,[]
                                        );
                                    }}
                                >
                                    Read More
                                </button>
                            </span>
                        }
                    >
                        {feedTransmission?.ProcessingLog}
                    </Truncate>,
                tooltip: "The status of the Processing Step"
            },
            {
                name: 'Transmission Status',
                sortable: true,
                sortingkey: 'TransmissionStatus',
                id: 'TransmissionStatus',
                selector: feedTransmission => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                    title={(feedTransmission?.ExecutionStatus)?.includes("Progress") ? "The feed is still processing" :
                        (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "Current processing step is completed" :
                            (feedTransmission?.ExecutionStatus)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                                (feedTransmission?.ExecutionStatus)?.includes("Tracking") ? "Failed to process the data" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Mapping") ? "There is an error in the output file" :
                                        (feedTransmission?.ExecutionStatus)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                                (feedTransmission?.ExecutionStatus)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Failed Split") ? "Failed splitting the data into separate employees" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                                (feedTransmission?.ExecutionStatus)?.includes("Approval Required") ? "Pending customer approval to send the form" :
                                                                    (feedTransmission?.ExecutionStatus)?.includes("Pulling") ? "Failed to extract a data file" :
                                                                        ""}>
                    <span>
                        <div style={(feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}} className={`${(feedTransmission?.ExecutionStatus)?.includes("Fail") ? "red background status-div" : (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "yellow-secondary-color background status-div" : ""}`} >
                            {feedTransmission?.ExecutionStatus}
                        </div>
                    </span>
                </CustomTooltip>,
                tooltip: "Identifies if the feed processing was completed, failed, stopped, or pending"
            }
        ];

    const pushToColumnsBasedOnUserAction = () => {

        const columnsToAdd = [
            {
                
                    name: 'Execution ID',
                    sortable: true,
                    sortingkey: 'ExecutionID',
                    id: 'ExecutionID',
                    selector: feedTransmission => feedTransmission?.SK,
                
            },

        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumns];

        if (userActions?.includes("43") || userActions?.includes("20")) {
            finalColumnsArray.unshift(...columnsToAdd);
        }

        return finalColumnsArray;
    };

    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(singleFeedTransmissionsList)) {
            return [];
        }

        return singleFeedTransmissionsList
            .filter((obj, index) =>
                singleFeedTransmissionsList.findIndex((item) => item.FeedID === obj.FeedID && item.SK === obj.SK) === index
            )
    }

    return (
        <div id="transmission-dashboard-table">
            <Paper className="content-card-paper">
                <FormsTransmissionDashboardFilter
                onFeedIDFilter={feedID}
                onCarrierIDFilter={carrierID}
                onExecutionStatusFilter={(d) => setExecutionStatusFilter(d)}
                onProcessingStepFilter={(d) => setProcessingStepFilter(d)}
                onFeedStatusFilter={(d) => setFeedStatusFilter(d)}
                onFeedPhaseFilter={(d) => setFeedPhaseFilter(d)}
                onExecutionIDFilter={(d) => setExecutionIDFilter(d)}
                onFromDateFilter={(d) => setFromDateFilter(d)}
                fromDateFilter={fromDateFilter}
                onToDateFilter={(d) => setToDateFilter(d)}
                toDateFilter={toDateFilter}
                onSentFromDateFilter={(d) => setSentFromDateFilter(d)}
                sentFromDateFilter={sentFromDateFilter}
                onSentToDateFilter={(d) => setSentToDateFilter(d)}
                sentToDateFilter={sentToDateFilter}
                setRefreshClicked={setRefreshClicked}
                selectedPage={selectedPage}
                onSelectedPage={(p) => setSelectedPage(p)}
                pageSize={pageSize}
                onPageSize={(p) => setPageSize(p)}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                selectedPageAdv={selectedPageAdv}
                onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                pageSizeAdv={pageSizeAdv}
                onPageSizeAdv={(p) => setPageSizeAdv(p)}
                quickFiltersClicked={quickFiltersClicked}
                setQuickFiltersClicked={setQuickFiltersClicked}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                feedIDAdvFilter={feedID}
                onFeedIDAdvFilter={feedID}
                selectedCarrierIDAdvFilter={carrierID}
                onSelectedCarrierIDAdvFilter={carrierID}
                selectedFeedStatusAdvFilter={selectedFeedStatusAdvFilter}
                onSelectedFeedStatusAdvFilter={s => setSelectedFeedStatusAdvFilter(s)}
                selectedFeedPhaseAdvFilter={selectedFeedPhaseAdvFilter}
                onSelectedFeedPhaseAdvFilter={s => setSelectedFeedPhaseAdvFilter(s)}
                executionIDAdvFilter={executionIDAdvFilter}
                onExecutionIDAdvFilter={s => setExecutionIDAdvFilter(s)}
                selectedExecutionStatusAdvFilter={selectedExecutionStatusAdvFilter}
                onSelectedExecutionStatusAdvFilter={s => setSelectedExecutionStatusAdvFilter(s)}
                selectedProcessingStepAdvFilter={selectedProcessingStepAdvFilter}
                onSelectedProcessingStepAdvFilter={s => setSelectedProcessingStepAdvFilter(s)}
                requestFromDateAdvFilter={requestFromDateAdvFilter}
                onRequestFromDateAdvFilter={s => setRequestFromDateAdvFilter(s)}
                requestToDateAdvFilter={requestToDateAdvFilter}
                onRequestToDateAdvFilter={s => setRequestToDateAdvFilter(s)}
                sentFromDateAdvFilter={sentFromDateAdvFilter}
                onSentFromDateAdvFilter={s => setSentFromDateAdvFilter(s)}
                sentToDateAdvFilter={sentToDateAdvFilter}
                onSentToDateAdvFilter={s => setSentToDateAdvFilter(s)}
                mode="singleFormFeed"
                />

                <GenericTable
                    columns={[...pushToColumnsBasedOnUserAction()]}
                    data={[...FilteredAndAddedIsExpand()]}
                    onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}

                    expandableRows
                    expandableRowsComponent={ExpandableRowsComponentWithProps}
                    expandedRows={expandedRows}
                    handleRowExpandToggle={handleRowExpandToggle}
                    expandableRowExpanded={expandableRowExpanded}
                />

                <div>
                    <Pagination
                        stateLimit={true}
                        pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                        page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                        onChange={(t, v) => {
                            if (quickFiltersClicked) {
                                if (t === 1) setPageSize(v);
                                else if (t === 2) setSelectedPage(v);
                            } else if (advancedFiltersClicked) {
                                if (t === 1) setPageSizeAdv(v);
                                else if (t === 2) setSelectedPageAdv(v);
                            }
                        }}
                        count={totalCount}
                    />
                </div>
            </Paper>
        </div >
    )
}

export default AnalystSingleFeedFormsTransmission;
