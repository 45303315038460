import { API } from "aws-amplify";
import { getJwtAccessToken } from "./Authentication";
import config from "../config.json";

let env = process.env.REACT_APP_ENV?.toLowerCase()?.trim();
if (!env) env = "dev";

// ------------- GENERIC LISTS ----------------

//Get all groups instances in iSolved System
export async function getGroupsInstances() {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getInstances`);
    } catch (error) {
        console.log("Getting Groups Instances Error: ", error);
        return false;
    }
}

//Get all groups instances auto complete in iSolved System
export async function getGroupsInstancesAutoComplete(instance) {
    try {
        return API.post("MainApiCall", `api/v1/groupRequests/getInstancesFromUrl`, { body: instance });
    } catch (error) {
        console.log("Getting Groups Instances From URL Error: ", error);
        return false;
    }
}

//Get all groups in iSolved System
export async function getGroups(instanceID) {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getGroups/${instanceID}`);
    } catch (error) {
        console.log("Getting Groups Error: ", error);
        return false;
    }
}

//Get all groups in iSolved System
export async function getISolvedGroups(instanceID) {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getISolvedGroups/${instanceID}`);
    } catch (error) {
        console.log("Getting iSolved Groups Error: ", error);
        return false;
    }
}

//Get single group details in iSolved System
export async function getSingleISolvedGroupDetails(groupDetails) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/getISolvedGroupDetails", { body: groupDetails });
    } catch (error) {
        console.log("Getting Single Group Details Error: ", error)
        return false;
    }
}

//Verify carriers 
export async function approveCarrier(carrier) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/approveCarrier", { body: carrier });
    } catch (error) {
        console.log("Approved Carrier Error: ", error)
        return false;
    }
}

//un-reject carriers 
export async function unrejectCarrier(carrier) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/unRejectCarrier", { body: carrier });
    } catch (error) {
        console.log("Unreject Carrier Error: ", error)
        return false;
    }
}

//Get all carriers in iSolved System
export async function getCarriers(carriers) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/getCarriers", { body: carriers });
    } catch (error) {
        console.log("Getting Carriers Error: ", error)
        return false;
    }
}
// reject carrier and replace it with another for its linked connectivity requests 
export async function rejectCarrier(carrier) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/rejectCarrier", { body: carrier });
    } catch (error) {
        console.log("Reject Carrier Error: ", error)
        return false;
    }
}
//Get all subgroups related to a group in iSolved System
export async function getSubgroups(groupID) {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getSubGroups/${groupID}`);
    } catch (error) {
        console.log("Getting Subgroups Error: ", error)
        return false;
    }
}

//Get all group requests available statuses
export function getGroupRequestStatuses() {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getGroupRequestStatus`)
    } catch (error) {
        console.log("Getting Group Request Statuses Error: ", error)
        return false;
    }
}

//Responsible for Creating&updating&editing Client Contact on Group Request
export function updateGroupRequestContacts(editGroupRequestContact) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/updateGroupContacts", { body: editGroupRequestContact });
    } catch (error) {
        console.log("Editing Group Request Contacts Error: ", error)
        return false;
    }
}


//Get all group requests available phases
export function getGroupRequestPhases() {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getGroupRequestPhases`)
    } catch (error) {
        console.log("Getting Group Request Phases Error: ", error)
        return false;
    }
}

//Get Enabled/Disabled partner state
export function getAllPartnersData() {
    try {
        return API.get("MainApiCall", `api/v1/partners/getPartners`)
    } catch (error) {
        console.log("Getting All Partners Error: ", error)
        return false;
    }
}

//Get Enabled/Disabled partner state
export function getPartnerStatus() {
    try {
        return API.get("MainApiCall", `api/v1/partners/getPartnerStatus`)
    } catch (error) {
        console.log("Getting Partners state Error: ", error)
        return false;
    }
}

//update partner state
export function updatePartnerStatus(body) {
    try {
        return API.post("MainApiCall", "api/v1/partners/disableCreationNetworkPartner", { body: body })
    } catch (error) {
        console.log("Update Partner Status Error: ", error)
        return false;
    }
}

//Get all connectivity requests available statuses
export function getConnectivityRequestStatuses() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityRequestStatus`)
    } catch (error) {
        console.log("Getting Connectivity Request Statuses Error: ", error)
        return false;
    }
}

//Get all feeds DF elements
export function getFeedDfElements() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getDfElements`)
    } catch (error) {
        console.log("Getting Feeds DF Elements Error: ", error)
        return false;
    }
}

//Get all feeds available statuses
export function getFeedStatuses() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeedsStatus`)
    } catch (error) {
        console.log("Getting Feeds Status Error: ", error)
        return false;
    }
}

//Get all connectivity requests available phases
export function getConnectivityRequestPhases() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityRequestPhases`)
    } catch (error) {
        console.log("Getting Connectivity Request Phases Error: ", error)
        return false;
    }
}

//Get all feeds available phases
export function getFeedPhases() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeedPhases`)
    } catch (error) {
        console.log("Getting Feed Phases Error: ", error)
        return false;
    }
}

//Get all feeds file types
export function getFeedFileTypes() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeedTypes`)
    } catch (error) {
        console.log("Getting Feed File Types Error: ", error)
        return false;
    }
}

//Get all feeds types
export function getFeedTypes() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeedMapTypes`)
    } catch (error) {
        console.log("Getting Feed Types Error: ", error)
        return false;
    }
}

//Get all feeds transmission types
export function getFeedTransmissionTypes() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getTransmissionTypes`)
    } catch (error) {
        console.log("Getting Feed Transmission Types Error: ", error)
        return false;
    }
}

//Get all feeds transmission types
export function getSSHKeys() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeedKeysType`)
    } catch (error) {
        console.log("Getting Feed SSH Keys Error: ", error)
        return false;
    }
}

//Get all feeds protocol types
export function getFeedProtocolTypes() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getProtocolTypes`)
    } catch (error) {
        console.log("Getting Feed Protocol Types Error: ", error)
        return false;
    }
}

//Get all Structure types
export function getStructureTypes() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getStructureTypes`)
    } catch (error) {
        console.log("Getting Structure Types Error: ", error)
        return false;
    }
}

//Get all Structure types for structures list
export function getAllStructureTypes() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getStructureFileTypes`)
    } catch (error) {
        console.log("Getting All Structure Types Error: ", error)
        return false;
    }
}

//Get all users
export function getAllUsers() {
    try {
        return API.get("MainApiCall", `api/v1/users/getUsers`)
    } catch (error) {
        console.log("Getting All Users Error: ", error)
        return false;
    }
}

//Enable disable users
export function enableDisableUsers(user) {
    try {
        return API.post("MainApiCall", "api/v1/users/enableDisableUser", { body: user })
    } catch (error) {
        console.log("Enable Disable Users Error: ", error)
        return false;
    }
}

//Reset MFA
export function resetUserMFA(user) {
    try {
        return API.post("MainApiCall", "api/v1/users/resetUserMFA", { body: user })
    } catch (error) {
        console.log("Reset MFA Error: ", error)
        return false;
    }
}

//Reset Password
export function resetUserPassword(user) {
    try {
        return API.post("MainApiCall", "api/v1/users/resetUserPassword", { body: user })
    } catch (error) {
        console.log("Reset User Password Error: ", error)
        return false;
    }
}

//Get all groups and OE contacts
export function getAllGroupsAndOEContacts() {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getGroupRequestsGroupAndOEContacts`)
    } catch (error) {
        console.log("Getting All Groups And OE Contacts Error: ", error)
        return false;
    }
}

//Get all Engine Components
export function getAllEngineComponents() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getEngineComponents`)
    } catch (error) {
        console.log("Getting All Engine Components Error: ", error)
        return false;
    }
}

//Get all Engine DF Components
export function getAllEngineDFComponents() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getDigitalFormsEngineComponents`)
    } catch (error) {
        console.log("Getting All Engine DF Components Error: ", error)
        return false;
    }
}

//Retry and Process To End Logic in Transmissions
export function retryProcessToEnd(logicBody) {
    try {
        return API.post("EngineApiCall", `api/v1/engine/retryEngineComponent`, { body: logicBody })
    } catch (error) {
        console.log("Retry and Process To End Logic Error: ", error)
        return false;
    }
}

// Mark Transmission As Finished
export function markAsFinished(logicBody) {
    try {
        return API.post("EngineApiCall", `api/v1/markTransmissionAsFinished`, { body: logicBody })
    } catch (error) {
        console.log("Mark Transmission as Finished Error: ", error)
        return false;
    }
}

// Approve Pending Network Partner CR by Authorized users (Generic for single or bulk CRs)
export function approvePendingBulckCRs(body) {
    try {
        return API.post("MainApiCall", `api/v1/connectivityRequests/approveConnectivityRequests`, { body: body })
    } catch (error) {
        console.log("Approve Pending CR Error: ", error)
        return false;
    }
}

//Show the Exact Output File Name Sent to the Carrier 
export function getExactFileName(transmissionID) {
    try {
        return API.get("EngineApiCall", `api/v1/getTransmittedOutputFileName/${transmissionID}`)
    } catch (error) {
        console.log("Get Exact name sent to the carrier error: ", error)
        return false;
    }
}
// get All plans for transmission
export function getActualPlans(transmissionID) {
    try {
        return API.get("EngineApiCall", `api/mapper/getmappedmessagebenefitssummary/${transmissionID}`)
    } catch (error) {
        console.log("Get Exact name sent to the carrier error: ", error)
        return false;
    }
}

//Download readable Excel from 834
export function getDownloadReadableExcel(processId) {
    try {
        return API.get("EngineApiCall", `api/mapper/get834excel/${processId}`)
    } catch (error) {
        console.log("Download Readable Excel Files Error: ", error)
        return false;
    }
}

//Download invalid output file
export function downloadInvalidFile(processId) {
    try {
        return API.get("EngineApiCall", `api/mapper/serializemappedmessage/${processId}`, { responseType: 'blob', response: 'true' }).then(r => { console.log(r.request.getResponseHeader('Content-Disposition')); return { data: r.data, fileName: r.request.getResponseHeader('Content-Disposition').split("\"")[1] }; })
    } catch (error) {
        console.log("Download Invalid Output File Error: ", error)
        return false;
    }
}

//Download output file POST
export function downloadOutputFile(logicBody) {
    try {
        return API.post("EngineApiCall", `api/mapper/serializemappedmessage`, { responseType: 'blob', response: 'true', body: logicBody }).then(r => { console.log(r.request.getResponseHeader('Content-Disposition')); return { data: r.data, fileName: r.request.getResponseHeader('Content-Disposition').split("\"")[1] }; })
    } catch (error) {
        console.log("Download Output File Error: ", error)
        return false;
    }
}



// Download unit measurement file
export function downloadUnitMeasurementFile(body) {
    try {
        return API.post("MainApiCall", `api/v1/connectivityRequests/getUnitMeasurementsReport`,{ body: body})
    } catch (error) {
        console.log("Getting Unit Measurement File Error: ", error)
        return false;
    }
}
//Get feeds list according to the auto complete
export function getFeedsListByName(feedName) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/getFeedsFromName", { body: feedName })
    } catch (error) {
        console.log("Getting Feeds List According To AutoComplete Error: ", error)
        return false;
    }
}

//Get all feeds connectivities list
export function getFeedsConnectivities(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeedConnectivities/${requestID}`)
    } catch (error) {
        console.log("Getting Feeds Connectivities List Error: ", error)
        return false;
    }
}

// Get carriers list according to the auto complete
// export function getCarriersListByName(carrierName) {
//     try {
//         return API.post("MainApiCall", "api/v1/carriers/getCarriersFromName", { body: carrierName })
//     } catch (error) {
//         console.log("Getting Carriers List According To AutoComplete Error: ", error)
//         return false;
//     }
// }

// Get carriers list for carriers dashboard
export function getCarriersListByName(body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/getCarriersForDashaboard", { body: body })
    } catch (error) {
        console.log("Getting Carriers List For Dashboard Error: ", error)
        return false;
    }
}

//Get Partner names
export function getPartnerNames() {
    try {
        return API.get("MainApiCall", "api/v1/partners/getPartnersFromName")
    } catch (error) {
        console.log("Get Partner Names Error: ", error)
        return false;
    }
}

export function getGroupsListByName(groupName) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/getGroupsFromName", { body: groupName })
    } catch (error) {
        console.log("Getting Groups List According To AutoComplete Error: ", error)
        return false;
    }
}

export function getPEOsListByName(groupName) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/getPeosFromName", { body: groupName })
    } catch (error) {
        console.log("Getting PEOs List According To AutoComplete Error: ", error)
        return false;
    }
}

//Get all Execution Statuses List
export function getExecutionStatusesList() {
    try {
        return API.get("EngineApiCall", `api/v1/getExecutionStatusesList/0`)
    } catch (error) {
        console.log("Getting All Execution Statuses Error: ", error)
        return false;
    }
}

//Get all Execution Statuses List
export function getProductionExecutionStatusesList() {
    try {
        return API.get("EngineApiCall", `api/v1/getExecutionStatusesList/1`)
    } catch (error) {
        console.log("Getting All Execution Statuses Error: ", error)
        return false;
    }
}

//Get all Execution Statuses List For DF
export function getDFExecutionStatusesList() {
    try {
        return API.get("EngineApiCall", `api/v1/getDigitalFormsExecutionStatusesListApi/0`)
    } catch (error) {
        console.log("Getting All Digital Forms Execution Statuses Error: ", error)
        return false;
    }
}

//Get all Execution Statuses List For DF
export function getDFEmployeesExecutionStatusesList() {
    try {
        return API.get("EngineApiCall", `api/v1/getDigitalFormsExecutionStatusesListApi/1`)
    } catch (error) {
        console.log("Getting All Digital Forms Employees Execution Statuses Error: ", error)
        return false;
    }
}

//Get all Processing Steps List
export function getProcessingStepsList() {
    try {
        return API.get("EngineApiCall", `api/v1/getProcessingStepsList`)
    } catch (error) {
        console.log("Getting All Processing Steps Error: ", error)
        return false;
    }
}

//Get all Processing Steps List For DF
export function getDFProcessingStepsList() {
    try {
        return API.get("EngineApiCall", `api/v1/getDigitalFormsProcessingSteps`)
    } catch (error) {
        console.log("Getting All Digital Forms Processing Steps Error: ", error)
        return false;
    }
}

// ------------- GR, CR, FD LISTS ----------------

//Get all group requests list and applying filters
export function getGroupRequests(getGroupRequests) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/getGroupRequests", { body: getGroupRequests })
    } catch (error) {
        console.log("Getting Group Requests List Error: ", error)
        return false;
    }
}

//Check about duplicated GRs
export function checkDuplicateGroupRequest(groupID) {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/checkIfGroupRequestIsAttachedToGroup/${groupID}`)
    } catch (error) {
        console.log("Checking Duplicate Group Requests Error: ", error)
        return false;
    }
}

//Check about duplicated GRs (NEW)
export function checkIfDuplicateGroupRequest(duplicateRequest) {
    try {
        return API.post("MainApiCall", `api/v1/groupRequests/checkIfGroupRequestAttachedToGroup`, { body: duplicateRequest })
    } catch (error) {
        console.log("Checking Duplicate Group Requests Error: ", error)
        return false;
    }
}

//Get all connectivity requests list and applying filters
export function getConnectivityRequests(getConnectivityRequests) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/getConnectivityRequests", { body: getConnectivityRequests })
    } catch (error) {
        console.log("Getting Connectivity Requests List Error: ", error)
        return false;
    }
}

//Download full report
export function downloadConnectivityRequestsFullReport() {
    try {
        return API.get("MainApiCall", "api/v1/connectivityRequests/getLastExportedConnectivitiesFile")
    } catch (error) {
        console.log("Getting Connectivity Requests Full Report Error: ", error)
        return false;
    }
}

// Download Network partner file
export function downloadNetworkPartnerFile() {
    try {
        return API.get("MainApiCall", `api/v1/partners/getLastExportedPartnersFile`)
    } catch (error) {
        console.log("Getting Network Partner File Error: ", error)
        return false;
    }
}

//Download OE full report
export function downloadOERequestsFullReport() {
    try {
        return API.get("MainApiCall", "api/v1/oeRequests/exportOERequestsReport")
    } catch (error) {
        console.log("Getting OE Requests Full Report Error: ", error)
        return false;
    }
}

//Edit OE Contact
export function editOEClientContact(editIsolvedContact) {
    try {
        return API.post("MainApiCall", "api/v1/oeRequests/editOEClientContact", { body: editIsolvedContact })
    } catch (error) {
        console.log("Editing OE iSolved Contact Error: ", error)
        return false;
    }
}

//Get all feeds list and applying filters
export function getFeeds(getFeeds) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/getFeeds", { body: getFeeds })
    } catch (error) {
        console.log("Getting Feeds List Error: ", error)
        return false;
    }
}

//Get maps list and applying filters
export function getAllMaps(getAllMaps) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/getAllMaps", { body: getAllMaps })
    } catch (error) {
        console.log("Getting All Maps List Error: ", error)
        return false;
    }
}

//Get forms list and applying filters
export function getAllForms(getAllForms) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/getAllDigitalForms", { body: getAllForms })
    } catch (error) {
        console.log("Getting All Digital Forms List Error: ", error)
        return false;
    }
}

//Get structures list and applying filters
export function getAllStructure(getStructures) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/getStructureFiles", { body: getStructures })
    } catch (error) {
        console.log("Getting All Structures List Error: ", error)
        return false;
    }
}

//Get feeds list linked with a single carrier
export function getCarrierFeeds(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getCarrierFeeds/${requestID}`)
    } catch (error) {
        console.log("Getting Carrier Feeds List Error: ", error)
        return false;
    }
}

//Get maps list linked with a single carrier
export function getCarrierMaps(getMaps) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/getMaps", { body: getMaps })
    } catch (error) {
        console.log("Getting Carrier Maps List Error: ", error)
        return false;
    }
}

//Get keys list linked with a single carrier
export function getCarrierKeys(carrierID) {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierEncryptionKeys/${carrierID}`)
    } catch (error) {
        console.log("Getting Carrier Keys List Error: ", error)
        return false;
    }
}

//Get feeds transmissions list "NEW"
export function getFeedsTranmissionsNEW(FeedsTransmissions) {
    try {
        return API.post("EngineApiCall", "api/v2/getTransmissions", { body: FeedsTransmissions })
    } catch (error) {
        console.log("Getting Feeds Tranmissions List Error: ", error)
        return false;
    }
}

//Get feeds digital forms transmissions list
export function getFeedsDigitalFormsTranmissions(FeedsFormsTransmissions) {
    try {
        return API.post("EngineApiCall", "api/v1/getDigitalFormsTransmissions", { body: FeedsFormsTransmissions })
    } catch (error) {
        console.log("Getting Feeds Digital Forms Tranmissions List Error: ", error)
        return false;
    }
}

//Test feed transmission connection
export function testFeedConnection(FeedConnection) {
    try {
        return API.post("EngineApiCall", "api/v1/testTransmitterConfig", { body: FeedConnection })
    } catch (error) {
        console.log("Testing Feed Connection Error: ", error)
        return false;
    }
}

export function getFeedLSFAvailableDates(bodyObject) {
    try {
        return API.post("EngineApiCall", "api/v1/engine/getAvailableDatesForFeedApi", { body: bodyObject })
    } catch (error) {
        console.log("Getting Feed Available Dates Error: ", error)
        return false;
    }
}

export function setFeedLSFAvailableDates(bodyObject) {
    try {
        return API.post("EngineApiCall", "api/v1/engine/setLastFileNameDataApi", { body: bodyObject })
    } catch (error) {
        console.log("Setting Feed Last Sent File Error: ", error)
        return false;
    }
}

export function fetchEmployeesForFeed(bodyObject) {
    try {
        return API.post("EngineApiCall", "api/v1/digitalForms/fetchGeneratedEmployees", { body: bodyObject })
    } catch (error) {
        console.log("fetch Generated Employees Error: ", error)
        return false;
    }
}

export function fetchDatasetsForEmployeeForFeed(bodyObject) {
    try {
        return API.post("EngineApiCall", "api/v1/digitalForms/fetchDatasets", { body: bodyObject })
    } catch (error) {
        console.log("fetch Dataset for Specfic Employee Error: ", error)
        return false;
    }
}

export function setLastSentFileForEmployees(bodyObject) {
    try {
        return API.post("EngineApiCall", "api/v1/digitalForms/setLastSentFileForEmployees", { body: bodyObject })
    } catch (error) {
        console.log("set Last Sent File For Employees for Specfic Employee Error: ", error)
        return false;
    }
}



export function clearLastSentFile(lsfBody) {
    try {
        return API.get("EngineApiCall", `api/v1/clearLastFileNameDate/${lsfBody}`)
    } catch (error) {
        console.log("Clearing Last Sent File Error: ", error)
        return false;
    }
}

// ------------- GR, CR STATISTICS ----------------

//Get the group requests dashboard statistics
export function getGroupRequestsDashboard() {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getGroupRequestsDashboard`)
    } catch (error) {
        console.log("Getting Group Requests Statistics Error: ", error)
        return false;
    }
}

//Get the connectivity requests dashboard statistics
export function getConnectivityRequestsDashboard() {
    try {
        return API.get("MainApiCall", "api/v1/connectivityRequests/getConnectivityDashboardStats")
    } catch (error) {
        console.log("Getting Connectivity Requests Statistics Error: ", error)
        return false;
    }
}

//Get the feed transmissions dashboard statistics for today only
export function getTransmissionsDashboard() {
    try {
        return API.get("EngineApiCall", "api/v1/getTransmissionsDashboard")
    } catch (error) {
        console.log("Getting Feed Transmissions Statistics For Today Error: ", error)
        return false;
    }
}

// ------------- GR, CR, FD SINGLE DETAILED DATA ----------------

//Get single group request detailed data
export function getGroupRequestData(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getGroupRequestData/${requestID}`)
    } catch (error) {
        console.log("Getting Single Group Request Data Error: ", error)
        return false;
    }
}

//Get single connectivity request detailed data
export function getConnectivityRequest(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityRequest/${requestID}`)
    } catch (error) {
        console.log("Getting Single Connectivity Request Data Error: ", error)
        return false;
    }
}

//Get single feed detailed data
export function getFeed(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getFeed/${requestID}`)
    } catch (error) {
        console.log("Getting Single Feed Data Error: ", error)
        return false;
    }
}

//Get single feed transmission logs
export function getSingleFeedTranmissionLogs(executionID) {
    try {
        return API.get("EngineApiCall", `api/v1/getSingleTransmissionLogs/${executionID}`)
    } catch (error) {
        console.log("Getting Single Feed Tranmission Logs Error: ", error)
        return false;
    }
}

//Get single feed digital form transmission logs
export function getSingleFeedDigitalFormTranmissionLogs(executionID) {
    try {
        return API.get("EngineApiCall", `api/v1/getSingleDigitalFormTransmissionLogs/${executionID}`)
    } catch (error) {
        console.log("Getting Single Feed Digital Form Tranmission Logs Error: ", error)
        return false;
    }
}

//Get employees from digital form transmission logs
export function getEmployeesFromDigitalFormTransmissionLogs(transmissionLog) {
    try {
        return API.post("EngineApiCall", `api/v1/getEmployeesFromDigitalFormTransmissionLogs`, { body: transmissionLog })
    } catch (error) {
        console.log("Getting Employees From Digital Form Tranmission Log Error: ", error)
        return false;
    }
}

//Get single feed transmissions list
export function getSingleFeedTranmissions(FeedTransmissions) {
    try {
        return API.post("EngineApiCall", "api/v1/getSingleFeedTransmissions", { body: FeedTransmissions })
    } catch (error) {
        console.log("Getting Single Feed Tranmissions List Error: ", error)
        return false;
    }
}

//Get single file attachment
export function getSingleFileAttachment(fileAttachment) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/getConnectivitySingleAttachment", { body: fileAttachment })
    } catch (error) {
        console.log("Getting Single File Attachment Error: ", error)
        return false;
    }
}

//Get single form attachment
export function getSingleFormAttachment(formAttachment) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/getConnectivityDigitalFormSingleAttachment", { body: formAttachment })
    } catch (error) {
        console.log("Getting Single Form Attachment Error: ", error)
        return false;
    }
}

//Get single carrier details
export function getSingleCarrierDetails(carrierID) {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierDetails/${carrierID}`)
    } catch (error) {
        console.log("Getting Single Carrier Error: ", error)
        return false;
    }
}

//update Carrier Legal Info page details
export function editCarrierLegalInfo(body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/editCarrierLegalInfo", { body: body })
    } catch (error) {
        console.log("Update Carrier Legal Info Tab details Error: ", error)
    }
}

//Get single carrier CRs
export function getSingleCarrierCRs(paginationObject) {
    try {
        return API.post("MainApiCall", `api/v1/carriers/getConnectivityRequestsForCarrier`, { body: paginationObject })
    } catch (error) {
        console.log("Getting Single Carrier CRs Error: ", error)
    }
}

//update Carrier Requirements page details
export function editCarrierRequirementsInfo(body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/editCarrierRequirementsDetails", { body: body })
    } catch (error) {
        console.log("Update Carrier Requirements Tab details Error: ", error)
    }
}

//update Carrier's OE page details
export function editCarrierOEDetails(body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/editCarrierOEDetails", { body: body })
    } catch (error) {
        console.log("Update Carrier's OE page details Error: ", error)
    }
}

//update Carrier's Technical Info page details
export function editCarrierTechnicalInfo(body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/editCarrierTechnicalInfo", { body: body })
    } catch (error) {
        console.log("Update Carrier's Technical Info page details Error: ", error)
        return false;
    }
}

//Upload Carrier Legal Info Attachment
export function uploadCarrierLegalDocumentAttachment(body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/uploadCarrierLegalDocumentAttachment", { body: body })
    } catch (error) {
        console.log("Upload Carrier Legal Info Document Error: ", error)
    }
}

//Get single carrier Change Logs
export function getSingleCarrieChangeLogs(paginationObject) {
    try {
        return API.post("MainApiCall", `api/v1/carriers/getChangeLogsForCarrier`, { body: paginationObject })
    } catch (error) {
        console.log("Getting Single Carrier Change Logs Error: ", error)
    }
}

// Get Carrier Allow for Dual Plan Years Options
export function getCarrierDualPlanOptions() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierDualPlanYear`)
    } catch (error) {
        console.log("Getting Carrier Dual Plan Year Options Error: ", error)
        return false;
    }
}

// Get Carrier Separate OE Files Options
export function getCarrierSeparateOEFilesOptions() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierSeparateOEFile`)
    } catch (error) {
        console.log("Getting Carrier Separate OE Files Options Error: ", error)
        return false;
    }
}

// Get Carrier Are New Plan Year Effective Dates Required Options
export function getCarrierNewPYEDRequired() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierNewPYEDRequired`)
    } catch (error) {
        console.log("Getting Carrier New Plan Year Required Options Error: ", error)
    }
}

// Get Carrier Statuses Options (Yes/No/Unknown)
export function getCarrierStatusesLookup() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getStatusLookup`)
    } catch (error) {
        console.log("Getting Carrier Statuses Options Error: ", error)
    }
}

//Get Carrier Legal Info Attachment
export function getCarrierSingleAttachment(fileAttachment) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/getCarrierSingleAttachment", { body: fileAttachment })
    } catch (error) {
        console.log("Getting Carrier Single File Attachment Error: ", error)
        return false;
    }
}

// Get Carrier Contact Categories
export function getCarrierContactCategories() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierContactCategories`)
    } catch (error) {
        console.log("Getting Carrier Contact Categories Error: ", error)
        return false;
    }
}

// Get Carrier Contact Types
export function getCarrierContactTypes() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierContactTypes`)
    } catch (error) {
        console.log("Getting Carrier Contact Types Error: ", error)
        return false;
    }
}
// Get Carrier Legal Document Types
export function getCarrierLegalDocumentTypes() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierLegalDocumentTypes`)
    } catch (error) {
        console.log("Getting Carrier Legal Document Types Error: ", error)
        return false;
    }
}

// Get Carrier Individuals Group Minimum
export function getCarrierIndividualsInGroupMinimum() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierIndividualsInGroupMinimum`)
    } catch (error) {
        console.log("Getting Carrier Individuals Group Minimum Error: ", error)
        return false;
    }
}
// Get Carrier Template Change Accepted 
export function getCarrierTemplateChangeAccepted() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierTemplateChangeAccepted`)
    } catch (error) {
        console.log("Getting Carrier Template Change Accepted  Error: ", error)
        return false;
    }
}
// Get Carrier Spreadsheet Provider
export function getCarrierSpreadsheetProvider() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierSpreadsheetProvider`)
    } catch (error) {
        console.log("Getting Carrier Spreadsheet Provider Error: ", error)
        return false;
    }
}
// Get Carrier Coverages On SingleFile
export function getCarrierCoveragesOnSingleFile() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierCoveragesOnSingleFile`)
    } catch (error) {
        console.log("Getting  Carrier Coverages On SingleFile Error: ", error)
        return false;
    }
}

// Get Carrier Terminate By Omission
export function getCarrierTerminateByOmission() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierTerminateByOmission`)
    } catch (error) {
        console.log("Getting Carrier Terminate By Omission Error: ", error)
        return false;
    }
}
// Get Carrier Gender Options
export function getCarrierGenderOptions() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierGenderOptions`);
    } catch (error) {
        console.log("Getting Carrier Gender Options Error: ", error);
        return false;
    }
}

// Get Carrier Generic FTP Types
export function getCarrierGenericFTPTypes() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierGenericFTPTypes`);
    } catch (error) {
        console.log("Getting Carrier Generic FTP Types Error: ", error);
        return false;
    }
}

// Get Carrier Generic FTP Protocols Types
export function getCarrierGenericFTPProtocolTypes() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierGenericFTPProtocolTypes`);
    } catch (error) {
        console.log("Getting Carrier Generic FTP Protocols Types Error: ", error);
        return false;
    }
}


// Get Carrier SSH Keys
export function getCarrierSSHKeys() {
    try {
        return API.get("MainApiCall", `api/v1/carriers/getCarrierSSHKeys`);
    } catch (error) {
        console.log("Getting Carrier SSH Keys Error: ", error);
        return false;
    }
}

export function updateCarrierGenericFTP(genericFTPListObject) {
    try {
        return API.post("MainApiCall", `api/v1/carriers/updateCarrierGenericFTP`,{ body: genericFTPListObject });
    } catch (error) {
        console.log("Updating Carrier Generic FTP List Error: ", error);
        return false;
    }
}

//Check if the carrier name are the same or similar
export function checkCarrierNameSameOrSimilar(carrierDetailsObject) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/checkIfCarrierExists", { body: carrierDetailsObject })
    } catch (error) {
        console.log("Checking if the carrier name is the same or similar Error: ", error)
        return false;
    }
}

// Add new carrier
export function addCarrier(carrierDetailsObject) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/addCarrier", { body: carrierDetailsObject })
    } catch (error) {
        console.log("Creating Carrier Error: ", error)
        return false;
    }
}

// Edit Carrier General Info details
export function editCarrierGeneralInfoDetails (body) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/editCarrierGeneralInfoDetails", { body: body })
    } catch (error) {
        console.log("Edit carrier general info details error: ", error)
        return false;
    }
}


// ------------- CREATE GR, CR, FD ----------------

//Responsible for creating new group request
export async function createGroupRequest(groupRequest) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/createGroupRequest", { body: groupRequest });
    } catch (error) {
        console.log("Creating Group Request Error: ", error)
        return false;
    }
}

//Responsible for creating new group request
export async function createGroupRequestISolved(groupRequest) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/createGroupRequestISolved", { body: groupRequest });
    } catch (error) {
        console.log("Creating Group Request ISolved Error: ", error)
        return false;
    }
}

//Responsible for creating new connectivity request to an existing group request
export function createConnectivityRequest(connectivityRequest) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/addConnectivityRequest", { body: connectivityRequest })
    } catch (error) {
        console.log("Creating Connectivity Request Error: ", error)
        return false;
    }
}

//Responsible for creating new feed
export function createFeed(feed) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/createFeed", { body: feed })
    } catch (error) {
        console.log("Creating Feed Error: ", error)
        return false;
    }
}

//Responsible for adding new encryption key
export function addEncryptionKey(key) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/addCarrierEncryptionKey", { body: key })
    } catch (error) {
        console.log("Adding Encryption Key Error: ", error)
        return false;
    }
}


//Responsible for adding new attachment
export function addAttachment(attachment) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/uploadConnectivityAttachment", { body: attachment })
    } catch (error) {
        console.log("Adding Attachment Error: ", error)
        return false;
    }
}

//Responsible for adding new attachment
export function addFormAttachment(attachment) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/uploadConnectivityDigitalFormAttachment", { body: attachment })
    } catch (error) {
        console.log("Adding Form Attachment Error: ", error)
        return false;
    }
}

// ------------- EDIT GR, CR, FD ----------------

//Responsible for updating the single group request status
export function editGroupRequest(updateRequest, requestID) {
    try {
        return API.post("MainApiCall", `api/v1/groupRequests/updateGroupRequest/${requestID}`, { body: updateRequest });
    } catch (error) {
        console.log("Editing Group Request Error: ", error)
        return false;
    }
}

//Responsible for updating the single group request iSolved
export function editGroupRequestISolved(updateRequest, requestID) {
    try {
        return API.post("MainApiCall", `api/v1/groupRequests/updateGroupRequestISolved/${requestID}`, { body: updateRequest });
    } catch (error) {
        console.log("Editing Group Request ISolved Error: ", error)
        return false;
    }
}

//Responsible for editing a single connectivity request (contacts edit, delete, add)
export function editConnectivityRequest(editConnectivityRequest) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/editConnectivityRequest", { body: editConnectivityRequest });
    } catch (error) {
        console.log("Editing Connectivity Request Error: ", error)
        return false;
    }
}

//Responsible for editing connectivity contacts
export function editConnectivityRequestContacts(editConnectivityRequestContact) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/updateConnectivityContacts", { body: editConnectivityRequestContact });
    } catch (error) {
        console.log("Editing Connectivity Request Contacts Error: ", error)
        return false;
    }
}

//Responsible for editing connectivity plans
export function editConnectivityRequestPlans(editConnectivityRequestPlans) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/manageConnectivityPlanTypes", { body: editConnectivityRequestPlans });
    } catch (error) {
        console.log("Editing Connectivity Request Plans Error: ", error)
        return false;
    }
}

//Responsible for editing a single feed
export function editFeed(editFeed) {
    try {
        return API.post("MainApiCall", "api/v1/feeds/editFeed", { body: editFeed });
    } catch (error) {
        console.log("Editing Feed Error: ", error)
        return false;
    }
}

//Responsible for cancelling one or more connectivity request(s) inside a single group request
export function cancelConnectivityRequest(cancelConnectivityRequest) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/cancelConnectivityRequest", { body: cancelConnectivityRequest })
    } catch (error) {
        console.log("Cancelling Connectivity Request Error: ", error)
        return false;
    }
}

//Responsible for uncancelling group request with uncancelling one or more connectivity request(s)
export function uncancelGroupRequest(uncancelGroupRequest) {
    try {
        return API.post("MainApiCall", "api/v1/groupRequests/uncancelGroupRequest", { body: uncancelGroupRequest })
    } catch (error) {
        console.log("Uncancelling Group Request Error: ", error)
        return false;
    }
}

//Responsible for editing a single connectivity request by Analyst
export function getEditConnectivityRequestForAnalyst(editConnectivityRequestsConfig) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/editConnectivityRequestForAnalyst", { body: editConnectivityRequestsConfig })
    } catch (error) {
        console.log("Editing Connectivity Request Config For Analyst Error: ", error)
        return false;
    }
}

//Responsible for deleting encryption key
export function deleteEncryptionKey(deleteEncryptionKey) {
    try {
        return API.post("MainApiCall", "api/v1/carriers/deleteCarrierEncryptionKey", { body: deleteEncryptionKey });
    } catch (error) {
        console.log("Deleting Encryption Key Error: ", error)
        return false;
    }
}

//Premapping Form

export function getPlanTypes() {
    try {
        return API.get("MainApiCall", `api/v1/preMapping/getPlanTypes`)
    } catch (error) {
        console.log("Getting Plan Types Error: ", error)
        return false;
    }
}

export function getGroupPlans(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/preMapping/getGroupPlans/${requestID}`)
    } catch (error) {
        console.log("Getting Plan Types Error: ", error)
        return false;
    }
}

export function getGroupRelationshipCodes(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/preMapping/getRelationshipCodes/${requestID}`)
    } catch (error) {
        console.log("Getting Group Relationship Codes Error: ", error)
        return false;
    }
}

export function getSectionsList() {
    try {
        return API.get("MainApiCall", `api/v1/preMapping/getPreMappingSections`)
    } catch (error) {
        console.log("Getting Sections List Error: ", error)
        return false;
    }
}

export function submitPreMappingFormAnalyst(createPreMappingForm) {
    try {
        return API.post("MainApiCall", "api/v1/preMapping/submitPreMappingForAnalyst", { body: createPreMappingForm });
    } catch (error) {
        console.log("Submit Pre-Mapping Form By Analyst Error: ", error)
        return false;
    }
}

export function submitPreMappingFormAdmin(createPreMappingForm) {
    try {
        return API.post("MainApiCall", "api/v1/preMapping/submitPreMappingForAdmin", { body: createPreMappingForm });
    } catch (error) {
        console.log("Submit Pre-Mapping Form By Admin Error: ", error)
        return false;
    }
}

export function getPreMapping(requestID) {
    try {
        return API.get("MainApiCall", `api/v1/preMapping/getPreMapObject/${requestID}`)
    } catch (error) {
        console.log("Getting Pre-Mapping Form Error: ", error)
        return false;
    }
}

export function getPreMappingComments(commentBody) {
    try {
        return API.post("MainApiCall", `api/v1/preMapping/getPreMappingPlansComments`, { body: commentBody })
    } catch (error) {
        console.log("Getting Pre-Mapping Comments Error: ", error)
        return false;
    }
}

export function getPreMappingAddComment(addCommentBody) {
    try {
        return API.post("MainApiCall", `api/v1/preMapping/addPreMappingPlansComment`, { body: addCommentBody })
    } catch (error) {
        console.log("Getting Pre-Mapping Add Comments Error: ", error)
        return false;
    }
}

//Get test file from feeds
export function getTestFile(request) {
    try {
        return API.post("EngineApiCall", "api/integration/gettestfile", { body: request })
    } catch (error) {
        console.log("Getting Test File Error: ", error)
        return false;
    }
}

//Get 401K test file from feeds
export function get401KTestFile(request) {
    try {
        return API.post("EngineApiCall", "api/integration/gettest401kfile", { body: request })
    } catch (error) {
        console.log("Getting 401K Test File Error: ", error)
        return false;
    }
}

//Add comment for Connectivity Request
export function addCRComment(commentBody) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/addConnectivityComment", { body: commentBody })
    } catch (error) {
        console.log("Adding Connectivity Request Comment Error: ", error)
        return false;
    }
}

//Delete comment for Connectivity Request
export function deleteCRComment(deleteCommentBody) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/deleteConnectivityComment", { body: deleteCommentBody })
    } catch (error) {
        console.log("Deleting Connectivity Request Comment Error: ", error)
        return false;
    }
}

//Edit comment for Connectivity Request
export function editCRComment(editCommentBody) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/editConnectivityComment", { body: editCommentBody })
    } catch (error) {
        console.log("Editing Connectivity Request Comment Error: ", error)
        return false;
    }
}

//get Connectivity Request comments
export function getCRComments(commentsBody) {
    try {
        return API.post("MainApiCall", "api/v1/connectivityRequests/getConnectivityComment", { body: commentsBody })
    } catch (error) {
        console.log("Getting Connectivity Request Comments Error: ", error)
        return false;
    }
}

//Action Items

export function getResponsiblePartiesList() {
    try {
        return API.get("MainApiCall", `api/v1/actionItems/getResponsibleParties`)
    } catch (error) {
        console.log("Getting Responsible Parties List Error: ", error)
        return false;
    }
}

// Download action items file
export function downloadActionItemsFile(body) {
    try {
        return API.post("MainApiCall", `api/v1/actionItems/getLastExportedActionItemsFile`,{ body: body})
    } catch (error) {
        console.log("Getting Action Items File Error: ", error)
        return false;
    }
}
export function getContactsList(contactBody) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/getActionItemsContactsList", { body: contactBody })
    } catch (error) {
        console.log("Getting Contacts List Error: ", error)
        return false;
    }
}

export function getActionItemStatusesList() {
    try {
        return API.get("MainApiCall", "api/v1/actionItems/getActionItemsStatusList")
    } catch (error) {
        console.log("Getting Action Item Statuses List Error: ", error)
        return false;
    }
}

export function createActionItem(actionItem) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/createActionItem", { body: actionItem })
    } catch (error) {
        console.log("Creating Action Item Error: ", error)
        return false;
    }
}

export function editActionItem(editActionItem) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/editActionItem", { body: editActionItem })
    } catch (error) {
        console.log("Editing Action Item Error: ", error)
        return false;
    }
}

export function getActionItemsList(actionItemsBody) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/getActionItemsList", { body: actionItemsBody })
    } catch (error) {
        console.log("Getting Action Items List Error: ", error)
        return false;
    }
}

export function getActionItemDetails(actionItemID) {
    try {
        return API.get("MainApiCall", `api/v1/actionItems/getActionItemDetails/${actionItemID}`)
    } catch (error) {
        console.log("Getting Action Items Details Error: ", error)
        return false;
    }
}

export function addActionItemComments(actionItemComment) {
    try {
        return API.post("MainApiCall", `api/v1/actionItems/addCommentToActionItem`, { body: actionItemComment })
    } catch (error) {
        console.log("Adding Action Item Comment Error: ", error)
        return false;
    }
}

export function deleteActionItemComments(actionItemComment) {
    try {
        return API.post("MainApiCall", `api/v1/actionItems/deleteActionItemComment`, { body: actionItemComment })
    } catch (error) {
        console.log("Deleting Action Item Comment Error: ", error)
        return false;
    }
}

export function editActionItemComments(actionItemComment) {
    try {
        return API.post("MainApiCall", `api/v1/actionItems/editActionItemComment`, { body: actionItemComment })
    } catch (error) {
        console.log("Editing Action Item Comment Error: ", error)
        return false;
    }
}

export function getAllRoles() {
    try {
        return API.get("MainApiCall", `api/v1/users/getRoles`)
    } catch (error) {
        console.log("Getting Users Roles Error: ", error)
        return false;
    }
}

export function getRoleEntities(roleID) {
    try {
        return API.get("MainApiCall", `api/v1/users/getRolesPossibleEntities/${roleID}`)
    } catch (error) {
        console.log("Getting Role Entities Error: ", error)
        return false;
    }
}

export function createUser(createBody) {
    try {
        return API.post("MainApiCall", `api/v1/users/createUser`, { body: createBody })
    } catch (error) {
        console.log("Creating New User Error: ", error)
        return false;
    }
}

export function editUser(editBody) {
    try {
        return API.post("MainApiCall", `api/v1/users/editUser`, { body: editBody })
    } catch (error) {
        console.log("Editing User Error: ", error)
        return false;
    }
}

export function getUsers(users) {
    try {
        return API.post("MainApiCall", `api/v1/users/getUsers`, { body: users })
    } catch (error) {
        console.log("Getting Users Error: ", error)
        return false;
    }
}

export function getUserID(user) {
    try {
        return API.post("MainApiCall", `api/v1/users/getUserCognitoIdFromEmail`, { body: user })
    } catch (error) {
        console.log("Getting User ID Error: ", error)
        return false;
    }
}

export function getUserDetails(userID) {
    try {
        return API.get("MainApiCall", `api/v1/users/getUserDetails/${userID}`)
    } catch (error) {
        console.log("Getting User Details Error: ", error)
        return false;
    }
}

export function getUserNotifications(userID) {
    try {
        return API.get("MainApiCall", `api/v1/users/getUserNotifications/${userID}`)
    } catch (error) {
        console.log("Getting User Notifications Error: ", error)
        return false;
    }
}

export function addIP(IP) {
    try {
        return API.post("MainApiCall", `api/v1/users/updateIPSet`, { body: IP })
    } catch (error) {
        console.log("Adding IP Error: ", error)
        return false;
    }
}

export function getDigitalFormsMainTypes() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityDigitalFormsMainTypes`)
    } catch (error) {
        console.log("Getting Digital Forms Main Types Error: ", error)
        return false;
    }
}

export function getDigitalFormsSubTypes() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityDigitalFormsSubTypes`)
    } catch (error) {
        console.log("Getting Digital Forms Sub Types Error: ", error)
        return false;
    }
}

export function getDigitalFormsList(id) {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getDigitalForms/${id}`)
    } catch (error) {
        console.log("Getting Digital Forms List Error: ", error)
        return false;
    }
}

// gets all forms attached to the Carrier including the plans
export function getCarrierDFPlansList(id) {
    try {
        return API.get("EngineApiCall", `api/v1/getDigitalFormPlans/${id}`)
    } catch (error) {
        console.log("Getting Digital Forms Plans List Error: ", error)
        return false;
    }
}

// gets all group plans for matching the forms
export function getGroupPlansDFList(id) {
    try {
        return API.get("EngineApiCall", `api/v1/getDigitalFormFeedPlans/${id}`)
    } catch (error) {
        console.log("Getting Group Plans List For Digital Forms Error: ", error)
        return false;
    }
}

// gets all matched plans info for digital forms
export function getDFMatchedPlans(id) {
    try {
        return API.get("EngineApiCall", `api/v1/getDigitalFormFeedPlanMatching/${id}`)
    } catch (error) {
        console.log("Getting Matched Plans in Digital Forms Error: ", error)
        return false;
    }
}

//matching group plans with forms plans
export function matchPlansGroupDigitalForms(matchedPlans) {
    try {
        return API.post("EngineApiCall", `api/v1/matchPlansForFeed`, { body: matchedPlans })
    } catch (error) {
        console.log("Matching Plans For Groups and Digital Forms Error: ", error)
        return false;
    }
}

//matching maps with forms
export function matchMapsForDigitalForms(matchedMaps) {
    try {
        return API.post("EngineApiCall", `api/v1/matchMapsForDigitalForms`, { body: matchedMaps })
    } catch (error) {
        console.log("Matching Maps For Digital Forms Error: ", error)
        return false;
    }
}

export function deleteDigitalForm(df) {
    try {
        return API.post("MainApiCall", `api/v1/feeds/deleteDigitalFormFeed`, { body: df })
    } catch (error) {
        console.log("Deleting Digital Form From Feed Error: ", error)
        return false;
    }
}


export function getGroupsAsync(onSuccess, onError, onFinally) {
    getGroups()
        .then((d) => {
            if (d === false && onError) onError();
            if (d && onSuccess) onSuccess(d);
        })
        .catch((err) => {
            console.log(err);
            if (onError) onError();
        })
        .finally(() => {
            if (onFinally) onFinally();
        });
}

// Send OE Data 
export function sendOEFile(oERequest) {
    try {
        return API.post("MainApiCall", "api/v1/oeRequests/sendOEFile", { body: oERequest })
    } catch (error) {
        console.log("Sending OE File Error: ", error)
        return false;
    }
}

//Download OE File
export function downloadOEFile(OERequestID) {
    try {
        return API.get("EngineApiCall", `api/mapper/getoefile/${OERequestID}`)
    } catch (error) {
        console.log("Download OE File Error: ", error)
        return false;
    }
}

// Create OE Request
export function createOERequest(oERequest) {
    try {
        // change when finish API 
        return API.post("MainApiCall", "api/v1/oeRequests/createOERequest", { body: oERequest })
    } catch (error) {
        console.log("OE Requests Error: ", error)
        return false;
    }
}
//confirm   Process To End Logic in Transmissions
export function confirmProcessTransmitter(logicBody) {
    try {
        return API.post("EngineApiCall", `api/v1/engine/updateTransmitterApi`, { body: logicBody })
    } catch (error) {
        console.log("Retry and Process To End Logic Error: ", error)
        return false;
    }
}



//Download Last Exported Migrations Summary File
export function downloadMigrationsSummaryFileFullReport() {
    try {
        return API.get("MainApiCall", "api/v1/connectivityRequests/getLastExportedMigrationsSummaryFile")
    } catch (error) {
        console.log("Getting  Migrations Summary File Full Report Error: ", error)
        return false;
    }
}
// get status of OE Request
export function getOERequestStatusesList() {
    try {
        return API.get("MainApiCall", "api/v1/oeRequests/getOERequestStatuses")
    } catch (error) {
        console.log("Getting Action Item Statuses List Error: ", error)
        return false;
    }
}

// get Phases of OE Request
export function getOERequestPhasesList() {
    try {
        return API.get("MainApiCall", "api/v1/oeRequests/getOERequestPhases")
    } catch (error) {
        console.log("Getting Action Item Statuses List Error: ", error)
        return false;
    }
}

// Get request types of OE requests
export function getOERequestTypes() {
    try {
        return API.get("MainApiCall", "api/v1/oeRequests/getOERequestTypes")
    } catch (error) {
        console.log("Getting OE Request Types List Error: ", error)
        return false;
    }
}


export function getOERequestsList(oERequestsBody) {
    try {
        return API.post("MainApiCall", "api/v1/oeRequests/getOERequestsList", { body: oERequestsBody })
    } catch (error) {
        console.log("Getting OE  Requests List Error: ", error)
        return false;
    }
}


// get OE Request Details 
export function getOERequestDetails(oERequestID) {
    try {
        return API.get("MainApiCall", `api/v1/oeRequests/getOERequestDetails/${oERequestID}`)
    } catch (error) {
        console.log("Getting  OE Request Details Error: ", error)
        return false;
    }
}

export function editOERequest(editOERequest) {
    try {
        return API.post("MainApiCall", "api/v1/oeRequests/editOERequest", { body: editOERequest })
    } catch (error) {
        console.log("Editing OE Request Error: ", error)
        return false;
    }
}


export function addOERequestComments(oERequestComment) {
    try {
        return API.post("MainApiCall", `api/v1/oeRequests/addCommentToOERequest`, { body: oERequestComment })
    } catch (error) {
        console.log("Adding oE Request Comment Error: ", error)
        return false;
    }
}


export function deleteOERequestComments(oERequestComment) {
    try {
        return API.post("MainApiCall", `api/v1/oeRequests/deleteOERequestComment`, { body: oERequestComment })
    } catch (error) {
        console.log("Deleting oE Request Comment  Error: ", error)
        return false;
    }
}


export function editOERequestComments(oERequestComment) {
    try {
        return API.post("MainApiCall", `api/v1/oeRequests/editOERequestComment`, { body: oERequestComment })
    } catch (error) {
        console.log("Editing oE Request  Comment Error: ", error)
        return false;
    }
}
//Get Migration Phases
export function getMigrationPhases() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getMigrationPhases`)
    } catch (error) {
        console.log("Getting  Migration Phases Error: ", error)
        return false;
    }
}

//Production Dashboard
export function getProductionDashboardStatusesList() {
    try {
        return API.get("EngineApiCall", `api/v1/getProducationDashboardTypes`)
    } catch (error) {
        console.log("Getting Production Dashboard Statuses Error: ", error)
        return false;
    }
}

//Get Production Dashboard list as per the type
export function getProductionDashboardList(data) {
    try {
        return API.post("EngineApiCall", `api/v1/getProducationDashboard`, { body: data })
    } catch (error) {
        console.log("Getting Production Dashboard List Error: ", error)
        return false;
    }
}

//Get all All Business Rule  Error
export function getAllBusinessRuleError() {
    try {
        return API.get("MainApiCall", `api/v1/feeds/getBusinessRulesErrors`)
    } catch (error) {
        console.log("Getting All Business Rule  Error: ", error)
        return false;
    }
}

//View Form From Forms Dashboard
export function generatePdfUrlForEmployees(data) {
    try {
        return API.post("EngineApiCall", `api/v1/generatePdfUrlForEmployees`, { body: data })
    } catch (error) {
        console.log("Generating PDF URL Error: ", error)
        return false;
    }
}

//View Past Form From Forms Dashboard
export function generateOldPdfUrlForEmployees(data) {
    try {
        return API.post("EngineApiCall", `api/v1/generateDownloadUrl`, { body: data })
    } catch (error) {
        console.log("Generating Old PDF URL Error: ", error)
        return false;
    }
}

//Reject Form From Forms Dashboard
export function rejectForm(form) {
    try {
        return API.post("EngineApiCall", `api/v1/rejectHrApprovalEmployees`, { body: form })
    } catch (error) {
        console.log("Rejecting Form Error: ", error)
        return false;
    }
}

//Transmit Form From Forms Dashboard
export function transmitForm(form) {
    try {
        return API.post("EngineApiCall", `api/v1/transmitHrApprovalEmployees`, { body: form })
    } catch (error) {
        console.log("Transmitting Form Error: ", error)
        return false;
    }
}



// Create create Multiple OE Request
export function createMultipleOERequests(oERequest) {
    try {
        // change when finish API 
        return API.post("MainApiCall", "api/v1/oeRequests/createMultipleOERequests", { body: oERequest })
    } catch (error) {
        console.log("OE Requests Error: ", error)
        return false;
    }
}

//Responsible for adding new add Action Item Attachment
export function addActionItemAttachment(attachment) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/uploadActionItemAttachment", { body: attachment })
    } catch (error) {
        console.log("Adding Attachment Error: ", error)
        return false;
    }
}


//Get Action Item Single Attachment
export function getActionItemSingleFileAttachment(fileAttachment) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/getActionItemSingleAttachment", { body: fileAttachment })
    } catch (error) {
        console.log("Getting Single File Attachment Error: ", error)
        return false;
    }
}


//delete Action Item Attachment
export function deleteActionItemAttachment(fileAttachment) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/deleteActionItemAttachment", { body: fileAttachment })
    } catch (error) {
        console.log("Delete Action Item Attachment Error: ", error)
        return false;
    }
}

//PEO

//Get PEOs
export function getAllPEOs(id) {
    try {
        return API.get("MainApiCall", `api/v1/groupRequests/getISolvedPEOs/${id}`)
    } catch (error) {
        console.log("Getting All PEOs  Error: ", error)
        return false;
    }
}

//Get CR- EDI Sub Types

export function getConnectivityRequestEDISubTypes() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityRequestEDISubTypes`)
    } catch (error) {
        console.log("Getting All EDI Sub Types  Error: ", error)
        return false;
    }
}

// Get CR - Forms Sub Types
export function getConnectivityRequestFormsSubTypes() {
    try {
        return API.get("MainApiCall", `api/v1/connectivityRequests/getConnectivityRequestFormsSubTypes`)
    } catch (error) {
        console.log("Getting All Forms Sub Types  Error: ", error)
        return false;
    }
}





//get OE Requests Dashboard Stats Error
export function getOERequestsDashboardStats(body) {
    try {
        return API.post("MainApiCall", `api/v1/oeRequests/getOERequestsDashboardStats`, { body: body })
    } catch (error) {
        console.log("get OE Requests Dashboard Stats Error: ", error)
        return false;
    }
}

//Feeds Transmissions Dashboard Stats
export function getTranmissionsDashboard(body) {
    try {
        return API.post("EngineApiCall", `api/v1/getTranmissionsDashboard`, { body: body })
    } catch (error) {
        console.log("Get Feeds Transmissions Dashboard Stats: ", error)
        return false;
    }
}


//Check about duplicated OE same Start Date 
export function checkDuplicateOERequest(body) {

    try {
        return API.post("MainApiCall", `api/v1/oeRequests/checkOeDuplicationWarning`, { body: body })
    } catch (error) {
        console.log("Checking Duplicate OE Requests Error: ", error)
        return false;
    }
}
//Reassign ActionItem
export async function reAssignActionItem(body) {
    try {
        return API.post("MainApiCall", "api/v1/actionItems/reAssignActionItem", { body: body });
    } catch (error) {
        console.log("Getting Carriers Error: ", error)
        return false;
    }
}

//Get Email from Code SSO
export function getEmailFromCode(body) {
    return API.post("PublicApiCall", `api/v1/getEmailFromCode`, { body: body })
}

// =============Empolyee Pages
export function getEmployeeForms(body) {
    try {
        return API.post("EngineApiCall", `api/v1/getDigitalFormsEmpoleyeeTransmissions`, { body })
    } catch (error) {
        console.log("error: ", error)
        return false;
    }
}

// Employee Can [Sign] Form
export function transmitEmpoleyeesPendingApproval(body) {
    try {
        return API.post("EngineApiCall", `api/v1/transmitEmpoleyeesPendingApproval`, { body })
    } catch (error) {
        console.log("error: ", error)
        return false;
    }
}

// Employee Can [reject] Form
export function rejectHrApprovalEmployees(body) {
    try {
        return API.post("EngineApiCall", `api/v1/rejectHrApprovalEmployees`, { body })
    } catch (error) {
        console.log("error: ", error)
        return false;
    }
}

////////////////////// DF ////////////////////////////
//Get form data
export async function getGeneratedEmployeeForms(feedId, employeeId) {
    try {
        return API.get("DFApiCall", `api/df/gen/employee/forms/${feedId}/${employeeId}`);
    } catch (error) {
        console.log("Getting Groups Instances Error: ", error);
        return false;
    }
}
export async function getGeneratedEmployeeForm(pk, sk) {
    try {
        return API.get("DFApiCall", `api/df/gen/employee/form/${pk}/${sk}`);
    } catch (error) {
        console.log("Getting Groups Instances Error: ", error);
        return false;
    }
}
export async function getForm(formId) {
    try {
        return API.get("DFApiCall", `api/df/gen/form/${formId}`);
    } catch (error) {
        console.log("Getting Groups Instances Error: ", error);
        return false;
    }
}
export async function signForm1(formId, pk, sk, signatures) {
    try {
        return API.post("DFApiCall", `api/df/gen/form/${formId}/sign/pdf/${pk}/${sk}`, { body: signatures });
    } catch (error) {
        console.log("Sign Form Error: ", error);
        return false;
    }
}
export async function saveSignedForm(pk, sk, formBlob, openLoader, closeLoader) {
    const formData = new FormData();
    formData.append("form", formBlob, "form.pdf");
    const token = await getJwtAccessToken();

    const xhr = new XMLHttpRequest();
    xhr.responseType = "json";
    return new Promise((resolve, reject) => {
        openLoader();
        xhr.upload.addEventListener("progress", (event) => {
            console.log("upload progress:", event.loaded, ", ", event.total);
            if (event.lengthComputable) {
                console.log("upload progress:", event.loaded / event.total);
                //uploadProgress.value = event.loaded / event.total;
            }
        });
        xhr.addEventListener("progress", (event) => {
            console.log("download progress:", event.loaded, ", ", event.total);
            if (event.lengthComputable) {
                console.log("download progress:", event.loaded / event.total);
                //downloadProgress.value = event.loaded / event.total;
            }
        });
        xhr.addEventListener("loadend", () => {
            closeLoader()

            if (xhr.readyState === 4 && xhr.status === 200) {
                resolve(xhr.response);
            }
        });
        xhr.addEventListener("error", () => {
            closeLoader();

            console.log("Generate PDF Error ", xhr.status, ": ", xhr.responseText);
            reject(`Error ${xhr.status}: ${xhr.responseText}`)
        });
        xhr.open("POST", `${config.api[env + "DFURL"]}api/df/gen/form/save/signed/pdf/${pk}/${sk}`, true);
        //xhr.setRequestHeader("Content-Type", "multipart/form-data");
        if (token)
            xhr.setRequestHeader("Authorization", `${token}`);
        xhr.send(formData);
    });

}
export async function getFormBlob(formId) {
    const token = await getJwtAccessToken();

    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    return new Promise((resolve, reject) => {
        xhr.addEventListener("error", () => {
            console.log("Get FormBlob Error ", xhr.status, ": ", xhr.responseText);
            reject(`Error ${xhr.status}: ${xhr.responseText}`)
        });
        xhr.addEventListener("loadend", () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                resolve(xhr.response);
            }
        });
        xhr.open("GET", `${config.api[env + "DFURL"]}api/df/gen/form/${formId}`, true);
        //xhr.setRequestHeader("Content-Type", "multipart/form-data");
        if (token)
            xhr.setRequestHeader("Authorization", `${token}`);
        xhr.send();
    });
}
export async function getFormOutputPDFBlob(pk, sk) {
    const token = await getJwtAccessToken();

    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    return new Promise((resolve, reject) => {
        xhr.addEventListener("error", () => {
            console.log("Get FormBlob Error ", xhr.status, ": ", xhr.responseText);
            reject(`Error ${xhr.status}: ${xhr.responseText}`)
        });
        xhr.addEventListener("loadend", () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                resolve(xhr.response);
            }
        });
        xhr.open("GET", `${config.api[env + "DFURL"]}api/df/gen/form/output/pdf/${pk}/${sk}`, true);
        //xhr.setRequestHeader("Content-Type", "multipart/form-data");
        if (token)
            xhr.setRequestHeader("Authorization", `${token}`);
        xhr.send();
    });
}
// =============Client Tab Forms
export function getClientormsFeed(body) {
    try {
        return API.post("EngineApiCall", `api/v1/getAvailableFeedsPendingHr`, { body })
    } catch (error) {
        console.log("error: ", error)
        return false;
    }
}

// Wet signature
export function uploadWetSintureEndpoint(attachment) {
    try {
        return API.post("EngineApiCall", "api/v1/digitalForms/uploadWetSignatureForm", { body: attachment })
    } catch (error) {
        console.log("uploadWetSignatureForm Error: ", error)
        return false;
    }
}
