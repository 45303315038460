import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, FormControl } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { useAuthentication } from "../../../../Data/Authentication";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import GenericDatePicker from "../../../Layout/GenericDatePicker";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


function FeedsFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    setPageSizeAdv,
    setSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    setFeedIDAdvFilter,
    setSelectedFeedsFilter,
    setSelectedCarriersFilter,
    setSelectedFeedTypesFilter,
    setSelectedFeedMapTypesFilter,
    setSelectedFileTypesFilter,
    setSelectedStructureTypesFilter,
    setCreatedFromDateAdvFilter,
    setCreatedToDateAdvFilter,
    // setLastModifiedFromDateAdvFilter,
    // setLastModifiedToDateAdvFilter,
    // setSelectedLastModifiedByFilter,
    setSelectedPhasesFilter,
    setSelectedStatusesFilter,
    onCloseClearReset,
    ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [feedID, setFeedID] = React.useState("");
    const [feedName, setFeedName] = React.useState();
    const [feedsList, setFeedsList] = React.useState([]);
    const [feedSearchValue, setFeedSearchValue] = React.useState("");
    const [selectedFeeds, setSelectedFeeds] = React.useState([]);
    const [carName, setCarName] = React.useState();
    const [carriersList, setCarriersList] = React.useState([]);
    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [selectedCarriers, setSelectedCarriers] = React.useState([]);
    const [fileTypeList, setFileTypeList] = React.useState([]);
    const [feedTypesList, setFeedTypesList] = React.useState([]);
    const [selectedFeedTypes, setSelectedFeedTypes] = React.useState([]);
    const [selectedFeedMapTypes, setSelectedFeedMapTypes] = React.useState([]);
    // const [fileTypesList, setFileTypesList] = React.useState([]);
    const [selectedFileTypes, setSelectedFileTypes] = React.useState("");
    const [structureTypesList, setStructureTypesList] = React.useState([]);
    const [selectedStructureTypes, setSelectedStructureTypes] = React.useState([]);
    const [selectedCreatedFromDate, setSelectedCreatedFromDate] = React.useState(null);
    const [selectedCreatedToDate, setSelectedCreatedToDate] = React.useState(null);
    // const [selectedLastModifiedFromDate, setSelectedLastModifiedFromDate] = React.useState(sixMonthDate);
    // const [selectedLastModifiedToDate, setSelectedLastModifiedToDate] = React.useState(todayDate);
    // const [lastmodifiedBy, setLastModifiedBy] = React.useState("");
    const [usersList, setUsersList] = React.useState([]);
    // const [lastModifiedBySearchValue, setLastModifiedBySearchValue] = React.useState("");
    // const [selectedModifiedBy, setSelectedModifiedBy] = React.useState([]);
    const [feedsStatusesList, setFeedsStatusesList] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [feedsPhasesList, setFeedsPhasesList] = React.useState([]);
    const [selectedPhases, setSelectedPhases] = React.useState([]);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }


    React.useEffect(() => {
        APIs.getCarriersListByName({ CarrierName: carName ?? "" , StatusTypeId: [1,2]}).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [carName])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
    }, [feedName])

    React.useEffect(() => {
        APIs.getAllUsers().then((r) => setUsersList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getFeedFileTypes().then((r) => (!(r?.data?.length) ? setFileTypeList([]) : setFileTypeList(r?.data)));
    }, [])
    React.useEffect(() => {
        APIs.getFeedTypes().then((r) => (!(r?.data?.length) ? setFeedTypesList([]) : setFeedTypesList(r?.data)));
    }, [])

    // React.useEffect(() => {
    //     setFileTypesList(["Full Files", "Changes Only Files"])
    // }, [])

    React.useEffect(() => {
        APIs.getStructureTypes().then((r) => (!(r?.data?.length) ? setStructureTypesList([]) : setStructureTypesList(r?.data)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedStatuses().then((r) => (!(r?.length) ? setFeedsStatusesList([]) : setFeedsStatusesList(r)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedPhases().then((r) => (!(r?.data?.length) ? setFeedsPhasesList([]) : setFeedsPhasesList(r?.data)));
    }, [])

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleFeedIDFilter = (e) => {
        setFeedID(e.target.value);
        setFeedIDAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleFeedClick = (value) => (event) => {
        let selectedFeedsArray = selectedFeeds;
        if (!value || value === "") selectedFeedsArray = [];
        else if (selectedFeedsArray.indexOf(value) >= 0) {
            selectedFeedsArray.splice(selectedFeedsArray.indexOf(value), 1);
        }
        else {
            selectedFeedsArray.push(value);
        }
        setSelectedFeeds([...selectedFeedsArray]);
        setSelectedFeedsFilter([...selectedFeedsArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarriers;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
        }
        else {
            selectedCarriersArray.push(value);
        }
        setSelectedCarriers([...selectedCarriersArray]);
        setSelectedCarriersFilter([...selectedCarriersArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleFeedTypeClick = (value) => (event) => {
        let selectedFeedTypesArray = selectedFeedTypes;
        if (!value || value === "") selectedFeedTypesArray = [];
        else if (selectedFeedTypesArray.indexOf(value) >= 0) {
            selectedFeedTypesArray.splice(selectedFeedTypesArray.indexOf(value), 1);
        }
        else {
            selectedFeedTypesArray.push(value);
        }
        setSelectedFeedTypes([...selectedFeedTypesArray]);
        setSelectedFeedTypesFilter([...selectedFeedTypesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }
    const handleFeedMapTypeFilter = (e) => {
        setSelectedFeedMapTypes(e.target.value);
        setSelectedFeedMapTypesFilter(e.target.value)
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    // const handleFileTypeClick = (value) => (event) => {
    //     let selectedFileTypesArray = selectedFileTypes;
    //     if (!value || value === "") selectedFileTypesArray = [];
    //     else if (selectedFileTypesArray.indexOf(value) >= 0) {
    //         selectedFileTypesArray.splice(selectedFileTypesArray.indexOf(value), 1);
    //     }
    //     else {
    //         selectedFileTypesArray.push(value);
    //     }
    //     setSelectedFileTypes([...selectedFileTypesArray]);
    //     setSelectedFileTypesFilter([...selectedFileTypesArray]);
    //     setSelectedPageAdv(1);
    //     setPageSizeAdv(30);
    // }

    const handleStructureTypeClick = (value) => (event) => {
        let selectedStructureTypesArray = selectedStructureTypes;
        if (!value || value === "") selectedStructureTypesArray = [];
        else if (selectedStructureTypesArray.indexOf(value) >= 0) {
            selectedStructureTypesArray.splice(selectedStructureTypesArray.indexOf(value), 1);
        }
        else {
            selectedStructureTypesArray.push(value);
        }
        setSelectedStructureTypes([...selectedStructureTypesArray]);
        setSelectedStructureTypesFilter([...selectedStructureTypesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCreatedFromDate(date);
            setCreatedFromDateAdvFilter(date);
        }
        else {
            setSelectedCreatedToDate(date);
            setCreatedToDateAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };

    // const handleLastModifiedDateChange = (controlName) => (date) => {
    //     if (date)
    //         date.setHours(0, 0, 0, 0);
    //     if (controlName == "fromDate") {
    //         setSelectedLastModifiedFromDate(date);
    //         setLastModifiedFromDateAdvFilter(date);
    //     }
    //     else {
    //         setSelectedLastModifiedToDate(date);
    //         setLastModifiedToDateAdvFilter(date);
    //     }
    //     setSelectedPageAdv(1);
    //     setPageSizeAdv(30);
    // };

    // const handleModifiedByClick = (value) => (event) => {
    //     let selectedModifiedByArray = selectedModifiedBy;
    //     if (!value || value === "") selectedModifiedByArray = [];
    //     else if (selectedModifiedByArray.indexOf(value) >= 0) {
    //         selectedModifiedByArray.splice(selectedModifiedByArray.indexOf(value), 1);
    //     }
    //     else {
    //         selectedModifiedByArray.push(value);
    //     }
    //     setSelectedModifiedBy([...selectedModifiedByArray]);
    //     setSelectedLastModifiedByFilter([...selectedModifiedByArray]);
    //     setSelectedPageAdv(1);
    //     setPageSizeAdv(30);
    // }

    const handleFeedsStatusesClick = (value) => (event) => {
        let selectedFeedsStatusesArray = selectedStatuses;
        if (!value || value === "") selectedFeedsStatusesArray = [];
        else if (selectedFeedsStatusesArray.indexOf(value) >= 0) {
            selectedFeedsStatusesArray.splice(selectedFeedsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedFeedsStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedFeedsStatusesArray]);
        setSelectedStatusesFilter([...selectedFeedsStatusesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleFeedsPhasesClick = (value) => (event) => {
        let selectedFeedsPhasesArray = selectedPhases;
        if (!value || value === "") selectedFeedsPhasesArray = [];
        else if (selectedFeedsPhasesArray.indexOf(value) >= 0) {
            selectedFeedsPhasesArray.splice(selectedFeedsPhasesArray.indexOf(value), 1);
        }
        else {
            selectedFeedsPhasesArray.push(value);
        }
        setSelectedPhases([...selectedFeedsPhasesArray]);
        setSelectedPhasesFilter([...selectedFeedsPhasesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleFileTypeNameFilter = (e) => {
        setSelectedFileTypes(e.target.value);
        setSelectedFileTypesFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleClearSearch = () => {
        setFeedIDAdvFilter("");
        setFeedID("");
        setSelectedFeedsFilter([]);
        setSelectedFeeds([]);
        setSelectedCarriersFilter([]);
        setSelectedCarriers([]);
        setSelectedFeedTypesFilter([]);
        setSelectedFeedTypes([]);
        setSelectedFileTypesFilter("");
        setSelectedFeedMapTypesFilter([]);
        setSelectedFeedMapTypes([]);
        setSelectedFileTypes("");
        setSelectedStructureTypesFilter([]);
        setSelectedStructureTypes([]);
        setSelectedCreatedFromDate(null);
        setCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDate(null);
        setCreatedToDateAdvFilter(null);
        // setSelectedLastModifiedFromDate(null);
        // setLastModifiedFromDateAdvFilter(null);
        // setSelectedLastModifiedToDate(null);
        // setLastModifiedToDateAdvFilter(null);
        // setSelectedModifiedBy([]);
        // setSelectedLastModifiedByFilter([]);
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPhases([]);
        setSelectedPhasesFilter([]);
        setSelectedPageAdv(1);
    }

    React.useEffect(() => {
        setFeedIDAdvFilter("");
        setFeedID("");
        setSelectedFeedsFilter([]);
        setSelectedFeeds([]);
        setSelectedCarriersFilter([]);
        setSelectedCarriers([]);
        setSelectedFeedTypesFilter([]);
        setSelectedFeedTypes([]);
        setSelectedFeedMapTypesFilter([]);
        setSelectedFeedMapTypes([]);
        setSelectedFileTypesFilter("");
        setSelectedFileTypes("");
        setSelectedStructureTypesFilter([]);
        setSelectedStructureTypes([]);
        setSelectedCreatedFromDate(null);
        setCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDate(null);
        setCreatedToDateAdvFilter(null);
        // setSelectedLastModifiedFromDate(sixMonthDate);
        // setLastModifiedFromDateAdvFilter(sixMonthDate);
        // setSelectedLastModifiedToDate(todayDate);
        // setLastModifiedToDateAdvFilter(todayDate);
        // setSelectedModifiedBy([]);
        // setSelectedLastModifiedByFilter([]);
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPhases([]);
        setSelectedPhasesFilter([]);
        setSelectedPageAdv(1);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            setSelectedPageAdv(1);
            setPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            setSelectedPageAdv(1);
            setPageSizeAdv(30);
        }
    }


    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Feeds Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            {(userActions?.includes("43") ||
                userActions?.includes("20")) ? (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Feed ID" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="Feed Name" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(2)} />
                                <Tab className="filter-tab-btn" label="Feed  Type" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="File Type" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Data Type" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Structure Type" {...a11yProps(6)} />
                                <Tab className="filter-tab-btn" label="Created Date" {...a11yProps(7)} />
                                {/* <Tab className="filter-tab-btn" label="Last Modified Date" {...a11yProps(7)} />
                                        <Tab className="filter-tab-btn" label="Last Modified By" {...a11yProps(8)} /> */}
                                <Tab className="filter-tab-btn" label="Feed Status" {...a11yProps(8)} />
                                <Tab className="filter-tab-btn" label="Feed Phase" {...a11yProps(9)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one feed ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Feed ID" value={feedID} onChange={handleFeedIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search feeds here..." value={feedSearchValue} onChange={(e) => setFeedSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`feeds-select-all`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeeds && selectedFeeds.length === feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedFeeds, feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s), setSelectedFeeds, setSelectedFeedsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }


                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`feeds-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeeds && selectedFeeds.indexOf(c) >= 0}
                                                                onClick={handleFeedClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.FeedName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`carriers-select-all`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length === selectedCarriers.length}
                                                                onClick={handelSelectAll(selectedCarriers, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarriers, setSelectedCarriersFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }



                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`carriers-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carrier-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && selectedCarriers.indexOf(c) >= 0}
                                                                onClick={handleCarrierClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.CarrierName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <span>Please filter by only one feed type</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedFeedMapTypes ?? ""}
                                        onChange={handleFeedMapTypeFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {feedTypesList?.map((item) => (
                                            <option aria-label={item.Name} value={item.Id}>{item.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>

                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`file-type-all`}>
                                        <AccordionSummary
                                            aria-controls="file-type-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feed-type-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedTypes && selectedFeedTypes.length === fileTypeList.length}
                                                            onClick={handelSelectAll(selectedFeedTypes, fileTypeList, setSelectedFeedTypes, setSelectedFeedTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {fileTypeList.map((f, fi) => (
                                        <Accordion key={`feed-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feed-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedTypes && selectedFeedTypes.indexOf(f) >= 0}
                                                                onClick={handleFeedTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedTypeName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <span>Please filter by only one data type:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedFileTypes ?? ""}
                                        onChange={handleFileTypeNameFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option aria-label="full" value="Full">Full File</option>
                                        <option aria-label="changes" value="Changes">Changes Only File</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`structure-types-all`}>
                                        <AccordionSummary
                                            aria-controls="structure-types-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`structure-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStructureTypes && selectedStructureTypes.length === structureTypesList.length}
                                                            onClick={handelSelectAll(selectedStructureTypes, structureTypesList, setSelectedStructureTypes, setSelectedStructureTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {structureTypesList.map((f, fi) => (
                                        <Accordion key={`structure-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="structure-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`structure-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStructureTypes && selectedStructureTypes.indexOf(f) >= 0}
                                                                onClick={handleStructureTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.StructureTypeName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={7}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedFromDate}
                                    onChange={(date) => {
                                        setSelectedCreatedFromDate(date);
                                        setCreatedFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedToDate}
                                    onChange={(date) => {
                                        setCreatedToDateAdvFilter(date);
                                        setSelectedCreatedToDate(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            {/* <TabPanel value={tabValue} index={7}>
                                        <span>From:</span>
                                        <br />
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="date-div alignvh-center">
                                                <svg viewBox="0 0 20 18" height="20">
                                                    <use xlinkHref={`${dateTimeSVG}#dateTimeSVG`}></use>
                                                </svg>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    className="ml-xl-1 col-12"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-inline"
                                                    value={selectedLastModifiedFromDate}
                                                    onChange={handleLastModifiedDateChange("fromDate")}
                                                     
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                    keyboardIcon={
                                                        <svg  width="14" height="16" viewBox="0 0 14 16">
                          <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                      </svg>
                                                    }
                                                />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                        <br />
                                        <br />
                                        <span>To:</span>
                                        <br />
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="date-div alignvh-center">
                                                <svg viewBox="0 0 20 18" height="20">
                                                    <use xlinkHref={`${dateTimeSVG}#dateTimeSVG`}></use>
                                                </svg>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    className="ml-xl-1 col-12"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-inline"
                                                    value={selectedLastModifiedToDate}
                                                    onChange={handleLastModifiedDateChange("toDate")}
                                                     
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                    keyboardIcon={
                                                        <svg  width="14" height="16" viewBox="0 0 14 16">
                          <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                      </svg>
                                                    }
                                                />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </TabPanel> */}
                            {/* <TabPanel value={tabValue} index={8}>
                                        <input type="text" className="text-input w-100" placeholder="Search users here..." value={lastModifiedBySearchValue} onChange={(e) => setLastModifiedBySearchValue(e.target.value)} />
                                        <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                            {usersList?.filter(s => lastModifiedBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(lastModifiedBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                                <Accordion key={`users-modified-${ci}`}>
                                                    <AccordionSummary
                                                        aria-controls="users-select"
                                                        className="accordion-checkboxes"
                                                    >
                                                        <FormControlLabel
                                                            aria-label="Acknowledge"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={
                                                                <>
                                                                    <Checkbox
                                                                        id={`user-modified-option-${ci}`}
                                                                        value={c}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checked={selectedModifiedBy && selectedModifiedBy.indexOf(c) >= 0}
                                                                        onClick={handleModifiedByClick(c)}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                        color="default"
                                                                    />
                                                                </>
                                                            }
                                                            label={`${c.FirstName} ${c.LastName}`}
                                                        />
                                                    </AccordionSummary>
                                                </Accordion>
                                            ))}
                                        </div>
                                    </TabPanel> */}
                            <TabPanel value={tabValue} index={8}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`feeds-statuse-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-statuse-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-statuse-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.length === feedsStatusesList.length}
                                                            onClick={handelSelectAll(selectedStatuses, feedsStatusesList, setSelectedStatuses, setSelectedStatusesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {feedsStatusesList.map((f, fi) => (
                                        <Accordion key={`feeds-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                                onClick={handleFeedsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={9}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`feeds-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-phases-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.length === feedsPhasesList.length}
                                                            onClick={handelSelectAll(selectedPhases, feedsPhasesList, setSelectedPhases, setSelectedPhasesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {feedsPhasesList.map((f, fi) => (
                                        <Accordion key={`feeds-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                                onClick={handleFeedsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedPhaseName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div >
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            ) : (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                {/* <Tab className="filter-tab-btn" label="Feed ID" {...a11yProps(0)} /> */}
                                <Tab className="filter-tab-btn" label="Feed Name" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Feed Type" {...a11yProps(2)} />
                                <Tab className="filter-tab-btn" label="File Type" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Data Type" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Structure Type" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Created Date" {...a11yProps(6)} />
                                {/* <Tab className="filter-tab-btn" label="Last Modified Date" {...a11yProps(7)} />
                                        <Tab className="filter-tab-btn" label="Last Modified By" {...a11yProps(8)} /> */}
                                <Tab className="filter-tab-btn" label="Feed Status" {...a11yProps(7)} />
                                <Tab className="filter-tab-btn" label="Feed Phase" {...a11yProps(8)} />
                            </Tabs>
                            {/* <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one feed ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Feed ID" value={feedID} onChange={handleFeedIDFilter} />
                            </TabPanel> */}
                            <TabPanel value={tabValue} index={0}>
                                <input type="text" className="text-input w-100" placeholder="Search feeds here..." value={feedSearchValue} onChange={(e) => setFeedSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`feeds-select-all`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeeds && selectedFeeds.length === feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedFeeds, feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s), setSelectedFeeds, setSelectedFeedsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }


                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`feeds-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeeds && selectedFeeds.indexOf(c) >= 0}
                                                                onClick={handleFeedClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.FeedName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`carriers-select-all`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length === selectedCarriers.length}
                                                                onClick={handelSelectAll(selectedCarriers, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarriers, setSelectedCarriersFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }


                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`carriers-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carrier-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && selectedCarriers.indexOf(c) >= 0}
                                                                onClick={handleCarrierClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.CarrierName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <span>Please filter by only one feed type</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedFeedMapTypes ?? ""}
                                        onChange={handleFeedMapTypeFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {feedTypesList?.map((item) => (
                                            <option aria-label={item.Name} value={item.Id}>{item.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>

                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`file-type-all`}>
                                        <AccordionSummary
                                            aria-controls="file-type-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feed-type-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedTypes && selectedFeedTypes.length === fileTypeList.length}
                                                            onClick={handelSelectAll(selectedFeedTypes, fileTypeList, setSelectedFeedTypes, setSelectedFeedTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {fileTypeList.map((f, fi) => (
                                        <Accordion key={`feed-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feed-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeedTypes && selectedFeedTypes.indexOf(f) >= 0}
                                                                onClick={handleFeedTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedTypeName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <span>Please filter by only one data type:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedFileTypes ?? ""}
                                        onChange={handleFileTypeNameFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option aria-label="full" value="Full">Full File</option>
                                        <option aria-label="changes" value="Changes">Changes Only File</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`structure-types-all`}>
                                        <AccordionSummary
                                            aria-controls="structure-types-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`structure-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStructureTypes && selectedStructureTypes.length === structureTypesList.length}
                                                            onClick={handelSelectAll(selectedStructureTypes, structureTypesList, setSelectedStructureTypes, setSelectedStructureTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {structureTypesList.map((f, fi) => (
                                        <Accordion key={`structure-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="structure-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`structure-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStructureTypes && selectedStructureTypes.indexOf(f) >= 0}
                                                                onClick={handleStructureTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.StructureTypeName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedFromDate}
                                    onChange={(date) => {
                                        setSelectedCreatedFromDate(date);
                                        setCreatedFromDateAdvFilter(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={selectedCreatedToDate}
                                    onChange={(date) => {
                                        setCreatedToDateAdvFilter(date);
                                        setSelectedCreatedToDate(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </TabPanel>
                            {/* <TabPanel value={tabValue} index={7}>
                                        <span>From:</span>
                                        <br />
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="date-div alignvh-center">
                                                <svg viewBox="0 0 20 18" height="20">
                                                    <use xlinkHref={`${dateTimeSVG}#dateTimeSVG`}></use>
                                                </svg>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    className="ml-xl-1 col-12"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-inline"
                                                    value={selectedLastModifiedFromDate}
                                                    onChange={handleLastModifiedDateChange("fromDate")}
                                                     
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                    keyboardIcon={
                                                        <svg  width="14" height="16" viewBox="0 0 14 16">
                          <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                      </svg>
                                                    }
                                                />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                        <br />
                                        <br />
                                        <span>To:</span>
                                        <br />
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="date-div alignvh-center">
                                                <svg viewBox="0 0 20 18" height="20">
                                                    <use xlinkHref={`${dateTimeSVG}#dateTimeSVG`}></use>
                                                </svg>
                                                <KeyboardDatePicker
                                                    autoOk={true}
                                                    className="ml-xl-1 col-12"
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    id="date-picker-inline"
                                                    value={selectedLastModifiedToDate}
                                                    onChange={handleLastModifiedDateChange("toDate")}
                                                     
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                    keyboardIcon={
                                                        <svg  width="14" height="16" viewBox="0 0 14 16">
                          <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                      </svg>
                                                    }
                                                />
                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </TabPanel> */}
                            {/* <TabPanel value={tabValue} index={8}>
                                        <input type="text" className="text-input w-100" placeholder="Search users here..." value={lastModifiedBySearchValue} onChange={(e) => setLastModifiedBySearchValue(e.target.value)} />
                                        <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                            {usersList?.filter(s => lastModifiedBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(lastModifiedBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                                <Accordion key={`users-modified-${ci}`}>
                                                    <AccordionSummary
                                                        aria-controls="users-select"
                                                        className="accordion-checkboxes"
                                                    >
                                                        <FormControlLabel
                                                            aria-label="Acknowledge"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={
                                                                <>
                                                                    <Checkbox
                                                                        id={`user-modified-option-${ci}`}
                                                                        value={c}
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checked={selectedModifiedBy && selectedModifiedBy.indexOf(c) >= 0}
                                                                        onClick={handleModifiedByClick(c)}
                                                                        checkedIcon={<DoneOutlineIcon />}
                                                                        color="default"
                                                                    />
                                                                </>
                                                            }
                                                            label={`${c.FirstName} ${c.LastName}`}
                                                        />
                                                    </AccordionSummary>
                                                </Accordion>
                                            ))}
                                        </div>
                                    </TabPanel> */}
                            <TabPanel value={tabValue} index={7}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`feeds-statuse-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-statuse-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-statuse-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.length === feedsStatusesList.length}
                                                            onClick={handelSelectAll(selectedStatuses, feedsStatusesList, setSelectedStatuses, setSelectedStatusesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {feedsStatusesList.map((f, fi) => (
                                        <Accordion key={`feeds-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                                onClick={handleFeedsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={8}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`feeds-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="feeds-phases-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feeds-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.length === feedsPhasesList.length}
                                                            onClick={handelSelectAll(selectedPhases, feedsPhasesList, setSelectedPhases, setSelectedPhasesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {feedsPhasesList.map((f, fi) => (
                                        <Accordion key={`feeds-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                                onClick={handleFeedsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.FeedPhaseName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div >
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            )}

            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )
}

export default FeedsFilterModalBody;