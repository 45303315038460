import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, FormControl } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { useAuthentication } from "../../../Data/Authentication";
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import GenericDatePicker from "../../Layout/GenericDatePicker"


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));


function ConnectivityRequestsFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onPageSizeAdv,
    onSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    onConnectivityRequestIDAdvFilter,
    onSelectedGroupsFilter,
    onSelectedPEOsFilter,
    onSelectedCarriersFilter,
    onSelectedPartnerFilter,
    onFeedIDAdvFilter,
    onIncludePMFAdvFilter,
    onSelectedFeedsFilter,
    onCreatedFromDateAdvFilter,
    onCreatedToDateAdvFilter,
    onSelectedAssignedToFilter,
    onSelectedPhasesFilter,
    onSelectedStatusesFilter,
    onCloseClearReset,
    onMigrationAdvFilter,
    onSelectedFeedDataTypesFilter,
    onSelectedFeedStructureTypesFilter,
    onMigrationStartFromDateAdvFilter,
    onMigrationStartToDateAdvFilter,
    onPlannedFirstProductionFromDateAdvFilter,
    onPlannedFirstProductionToDateAdvFilter,
    onSelectedMigrationPhasesAdvFilter,
    onSelectedConnectivityRequestTypesFilter,
    onSelectedConnectivityRequestSubTypesFilter,
    onSetResponsiblePartyAdvFilter,
    onSetResponsiblePartyFilter,
    onSetAgingConnectivityAdvFilter,
    onSetAgingConnectivityFilter,
    clientEffectiveDateFromAdvFilter,
    onSetClientEffectiveDateFromAdvFilter,
    clientEffectiveDateToAdvFilter,
    onSetClientEffectiveDateToAdvFilter,
    onSetIsPEOAdvFilter,
    isIsovedSuperAdminOrSuperAdmin,
    subTypeList,
    ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [connectivityRequestID, setConnectivityRequestID] = React.useState("");
    const [grpName, setGrpName] = React.useState();
    const [peoName, setPEOName] = React.useState();
    const [groupsList, setGroupsList] = React.useState([]);
    const [peosList, setPEOsList] = React.useState([]);
    const [groupSearchValue, setGroupSearchValue] = React.useState("");
    const [peoSearchValue, setPEOSearchValue] = React.useState("");
    const [selectedGroups, setSelectedGroups] = React.useState([]);
    const [selectedPEOs, setSelectedPEOs] = React.useState([]);
    const [carName, setCarName] = React.useState();
    const [carriersList, setCarriersList] = React.useState([]);
    const [carrierSearchValue, setCarrierSearchValue] = React.useState("");
    const [selectedCarriers, setSelectedCarriers] = React.useState([]);
    const [partner, setPartner] = React.useState([]);
    const [feedID, setFeedID] = React.useState("");
    const [feedName, setFeedName] = React.useState();
    const [feedsList, setFeedsList] = React.useState([]);
    const [feedSearchValue, setFeedSearchValue] = React.useState("");
    const [selectedFeeds, setSelectedFeeds] = React.useState([]);
    const [selectedCreatedFromDate, setSelectedCreatedFromDate] = React.useState(null);
    const [selectedCreatedToDate, setSelectedCreatedToDate] = React.useState(null);
    const [usersList, setUsersList] = React.useState([]);
    const [partnerNames, setPartnerNames] = React.useState([]);
    const [assignedToSearchValue, setAssignedToSearchValue] = React.useState("");
    const [selectedAssignees, setSelectedAssignees] = React.useState([]);
    const [connectivityRequestsStatusesList, setConnectivityRequestsStatusesList] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [connectivityRequestsPhasesList, setConnectivityRequestsPhasesList] = React.useState([]);
    const [selectedPhases, setSelectedPhases] = React.useState([]);
    const [includePMF, setIncludePMF] = React.useState(null);
    const [migrationList, setMigrationList] = React.useState(["No", "eBenefits Network", "Everything Benefits"]);
    const [selectedMigrations, setSelectedMigrations] = React.useState([]);
    const [dataTypesList, setDataTypesList] = React.useState([]);
    const [selectedDataTypes, setSelectedDataTypes] = React.useState("");
    const [structureTypesList, setStructureTypesList] = React.useState([]);
    const [selectedStructureTypes, setSelectedStructureTypes] = React.useState([]);
    const [selectedConnectivityRequestTypes, setSelectedConnectivityRequestTypes] = React.useState([]);
    const [selectedConnectivityRequestSubTypes, setSelectedConnectivityRequestSubTypes] = React.useState([]);
    const [selectedMigrationStartDateFromDate, setSelectedMigrationStartDateFromDate] = React.useState(null);
    const [selectedMigrationStartDateToDate, setSelectedMigrationStartDateToDate] = React.useState(null);
    const [responsibleParty, setResponsibleParty] = React.useState([]);
    const [responsiblePartiesList, setResponsiblePartiesList] = React.useState([]);
    const [selectedPlannedFirstProductionDateFromDate, setSelectedPlannedFirstProductionDateFromDate] = React.useState(null);
    const [selectedPlannedFirstProductionDateToDate, setSelectedPlannedFirstProductionDateToDate] = React.useState(null);
    const [selectedMigrationPhases, setSelectedMigrationPhases] = React.useState([]);
    const [selectedResponsibleParty, setSelectedResponsibleParty] = React.useState([]);
    const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);
    const [selectedAgingConnectivity, setSelectedAgingConnectivity] = React.useState("");
    const [clientEffectiveDateFrom, setClientEffectiveDateFrom] = React.useState(null);
    const [clientEffectiveDateTo, setClientEffectiveDateTo] = React.useState(null);
    const [isPEOFilter, setIsPEOFilter] = React.useState("");


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        APIs.getResponsiblePartiesList().then(r => !(r?.data?.length) ? setResponsiblePartiesList([]) : setResponsiblePartiesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getGroupsListByName({ GroupName: grpName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsList([]) : setGroupsList(r?.data));
    }, [grpName])

    React.useEffect(() => {
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
    }, [peoName])

    React.useEffect(() => {
        APIs.getCarriersListByName({ CarrierName: carName ?? "" , StatusTypeId: [1,2]}).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [carName])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
    }, [feedName])

    React.useEffect(() => {
        APIs.getAllUsers().then((r) => setUsersList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getPartnerNames().then((r) => setPartnerNames(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getConnectivityRequestStatuses().then((r) => (!(r?.data?.length) ? setConnectivityRequestsStatusesList([]) : setConnectivityRequestsStatusesList(r?.data)));
    }, [])

    React.useEffect(() => {
        APIs.getConnectivityRequestPhases().then((r) => (!(r?.data?.length) ? setConnectivityRequestsPhasesList([]) : setConnectivityRequestsPhasesList(r?.data)));
    }, [])

    React.useEffect(() => {
        setDataTypesList(["Full Files", "Changes Only Files"])
    }, [])

    React.useEffect(() => {
        APIs.getStructureTypes().then((r) => (!(r?.data?.length) ? setStructureTypesList([]) : setStructureTypesList(r?.data)));
    }, [])
    // get Migration phases List  apis 
    React.useEffect(() => {
        APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));

    }, [])



    const connectivityRequestTypesList = ["EDI", "Forms"];

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleConnectivityRequestIDFilter = (e) => {
        setConnectivityRequestID(e.target.value);
        onConnectivityRequestIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handelIsPEOFilter = (e, newValue) => {
        setIsPEOFilter(e.target.value);
        onSetIsPEOAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handleGroupClick = (value) => (event) => {
        let selectedGroupsArray = selectedGroups;
        if (!value || value === "") selectedGroupsArray = [];
        else if (selectedGroupsArray.indexOf(value) >= 0) {
            selectedGroupsArray.splice(selectedGroupsArray.indexOf(value), 1);
        }
        else {
            selectedGroupsArray.push(value);
        }
        setSelectedGroups([...selectedGroupsArray]);
        onSelectedGroupsFilter([...selectedGroupsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handlePEOClick = (value) => (event) => {
        let selectedPEOsArray = selectedPEOs;
        if (!value || value === "") selectedPEOsArray = [];
        else if (selectedPEOsArray.indexOf(value) >= 0) {
            selectedPEOsArray.splice(selectedPEOsArray.indexOf(value), 1);
        }
        else {
            selectedPEOsArray.push(value);
        }
        setSelectedPEOs([...selectedPEOsArray]);
        onSelectedPEOsFilter([...selectedPEOsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarriers;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
        }
        else {
            selectedCarriersArray.push(value);
        }
        setSelectedCarriers([...selectedCarriersArray]);
        onSelectedCarriersFilter([...selectedCarriersArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handlePartnerClick = (value) => (event) => {
        let selectedPartnersArray = partner;
        if (!value || value === "") selectedPartnersArray = [];
        else if (selectedPartnersArray.indexOf(value) >= 0) {
            selectedPartnersArray.splice(selectedPartnersArray.indexOf(value), 1);
        }
        else {
            selectedPartnersArray.push(value);
        }
        setPartner([...selectedPartnersArray]);
        onSelectedPartnerFilter([...selectedPartnersArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleConnectivityRequestTypeClick = (value) => (event) => {
        let selectedConnectivityRequestTypeArray = selectedConnectivityRequestTypes;
        if (!value || value === "") selectedConnectivityRequestTypeArray = [];
        else if (selectedConnectivityRequestTypeArray.indexOf(value) >= 0) {
            selectedConnectivityRequestTypeArray.splice(selectedConnectivityRequestTypeArray.indexOf(value), 1);
        }
        else {
            selectedConnectivityRequestTypeArray.push(value);
        }
        setSelectedConnectivityRequestTypes([...selectedConnectivityRequestTypeArray]);
        onSelectedConnectivityRequestTypesFilter([...selectedConnectivityRequestTypeArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleConnectivityRequestSubTypeClick = (value) => (event) => {
        let selectedConnectivityRequestSubTypeArray = selectedConnectivityRequestSubTypes;
        if (!value || value === "") selectedConnectivityRequestSubTypeArray = [];
        else if (selectedConnectivityRequestSubTypeArray.indexOf(value) >= 0) {
            selectedConnectivityRequestSubTypeArray.splice(selectedConnectivityRequestSubTypeArray.indexOf(value), 1);
        }
        else {
            selectedConnectivityRequestSubTypeArray.push(value);
        }
        setSelectedConnectivityRequestSubTypes([...selectedConnectivityRequestSubTypeArray]);
        onSelectedConnectivityRequestSubTypesFilter([...selectedConnectivityRequestSubTypeArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }


    const handlePartnerFilter = (e) => {
        setPartner(e.target.value);
        onSelectedPartnerFilter([e.target.value]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleFeedIDFilter = (e) => {
        setFeedID(e.target.value);
        onFeedIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handleMigrationPhasesClick = (value) => (event) => {
        let selectedMigrationPhasesArray = selectedMigrationPhases;
        if (!value || value === "") selectedMigrationPhasesArray = [];
        else if (selectedMigrationPhasesArray.indexOf(value) >= 0) {
            selectedMigrationPhasesArray.splice(selectedMigrationPhasesArray.indexOf(value), 1);
        }
        else {
            selectedMigrationPhasesArray.push(value);
        }
        setSelectedMigrationPhases([...selectedMigrationPhasesArray]);
        onSelectedMigrationPhasesAdvFilter([...selectedMigrationPhasesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handleResponsiblePartyClick = (value) => (event) => {
        let selectedResponsiblePartyArray = selectedResponsibleParty;
        if (!value || value === "") selectedResponsiblePartyArray = [];
        else if (selectedResponsiblePartyArray.indexOf(value) >= 0) {
            selectedResponsiblePartyArray.splice(selectedResponsiblePartyArray.indexOf(value), 1);
        }
        else {
            selectedResponsiblePartyArray.push(value);
        }
        setSelectedResponsibleParty([...selectedResponsiblePartyArray]);
        onSetResponsiblePartyAdvFilter([...selectedResponsiblePartyArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleMigrationStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedMigrationStartDateFromDate(date);
            onMigrationStartFromDateAdvFilter(date);
        }
        else {
            setSelectedMigrationStartDateToDate(date);
            onMigrationStartToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };



    const handlePlannedFirstProductionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlannedFirstProductionDateFromDate(date);
            onPlannedFirstProductionFromDateAdvFilter(date);
        }
        else {
            setSelectedPlannedFirstProductionDateToDate(date);
            onPlannedFirstProductionToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };
    const handleFeedClick = (value) => (event) => {
        let selectedFeedsArray = selectedFeeds;
        if (!value || value === "") selectedFeedsArray = [];
        else if (selectedFeedsArray.indexOf(value) >= 0) {
            selectedFeedsArray.splice(selectedFeedsArray.indexOf(value), 1);
        }
        else {
            selectedFeedsArray.push(value);
        }
        setSelectedFeeds([...selectedFeedsArray]);
        onSelectedFeedsFilter([...selectedFeedsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCreatedFromDate(date);
            onCreatedFromDateAdvFilter(date);
        }
        else {
            setSelectedCreatedToDate(date);
            onCreatedToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };
    const handleClientEffectiveDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setClientEffectiveDateFrom(date);
            onSetClientEffectiveDateFromAdvFilter(date);
        }
        else {
            setClientEffectiveDateTo(date);
            onSetClientEffectiveDateToAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };
    const handleAssignedToClick = (value) => (event) => {
        let selectedAssignedToArray = selectedAssignees;
        if (!value || value === "") selectedAssignedToArray = [];
        else if (selectedAssignedToArray.indexOf(value) >= 0) {
            selectedAssignedToArray.splice(selectedAssignedToArray.indexOf(value), 1);
        }
        else {
            selectedAssignedToArray.push(value);
        }
        setSelectedAssignees([...selectedAssignedToArray]);
        onSelectedAssignedToFilter([...selectedAssignedToArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleStructureTypeClick = (value) => (event) => {
        let selectedStructureTypesArray = selectedStructureTypes;
        if (!value || value === "") selectedStructureTypesArray = [];
        else if (selectedStructureTypesArray.indexOf(value) >= 0) {
            selectedStructureTypesArray.splice(selectedStructureTypesArray.indexOf(value), 1);
        }
        else {
            selectedStructureTypesArray.push(value);
        }
        setSelectedStructureTypes([...selectedStructureTypesArray]);
        onSelectedFeedStructureTypesFilter([...selectedStructureTypesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleConnectivityRequestsStatusesClick = (value) => (event) => {
        let selectedConnectivityRequestsStatusesArray = selectedStatuses;
        if (!value || value === "") selectedConnectivityRequestsStatusesArray = [];
        else if (selectedConnectivityRequestsStatusesArray.indexOf(value) >= 0) {
            selectedConnectivityRequestsStatusesArray.splice(selectedConnectivityRequestsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedConnectivityRequestsStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedConnectivityRequestsStatusesArray]);
        onSelectedStatusesFilter([...selectedConnectivityRequestsStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleConnectivityRequestsPhasesClick = (value) => (event) => {
        let selectedConnectivityRequestsPhasesArray = selectedPhases;
        if (!value || value === "") selectedConnectivityRequestsPhasesArray = [];
        else if (selectedConnectivityRequestsPhasesArray.indexOf(value) >= 0) {
            selectedConnectivityRequestsPhasesArray.splice(selectedConnectivityRequestsPhasesArray.indexOf(value), 1);
        }
        else {
            selectedConnectivityRequestsPhasesArray.push(value);
        }
        setSelectedPhases([...selectedConnectivityRequestsPhasesArray]);
        onSelectedPhasesFilter([...selectedConnectivityRequestsPhasesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleIncludePMFFilter = (e, newValue) => {
        setIncludePMF(e.target.value);
        onIncludePMFAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleMigrationClick = (value) => (event) => {
        let selectedMigrationArray = selectedMigrations;
        if (!value || value === "") selectedMigrationArray = [];
        else if (selectedMigrationArray.indexOf(value) >= 0) {
            selectedMigrationArray.splice(selectedMigrationArray.indexOf(value), 1);
        }
        else {
            selectedMigrationArray.push(value);
        }
        setSelectedMigrations([...selectedMigrationArray]);
        onMigrationAdvFilter([...selectedMigrationArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleDataTypeNameFilter = (e) => {
        setSelectedDataTypes(e.target.value);
        onSelectedFeedDataTypesFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handleAgingConnectivitiesFilter = (e) => {
        setSelectedAgingConnectivity(e.target.value);
        onSetAgingConnectivityAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleClearSearch = () => {
        setConnectivityRequestID("");
        onConnectivityRequestIDAdvFilter("");
        setSelectedGroups([]);
        onSelectedGroupsFilter([]);
        setSelectedPEOs([]);
        onSelectedPEOsFilter([]);
        setSelectedCarriers([]);
        onSelectedCarriersFilter([]);
        setPartner("");
        onSelectedPartnerFilter("");
        setFeedID("");
        onFeedIDAdvFilter("");
        setIncludePMF(null);
        onIncludePMFAdvFilter(null);
        setSelectedFeeds([]);
        onSelectedFeedsFilter([]);
        setSelectedCreatedFromDate(null);
        onCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDate(null);
        onCreatedToDateAdvFilter(null);
        onSetClientEffectiveDateFromAdvFilter(null);
        setClientEffectiveDateFrom(null);
        setClientEffectiveDateTo(null);
        onSetClientEffectiveDateToAdvFilter(null);
        setSelectedAssignees([]);
        onSelectedAssignedToFilter([]);
        onSetIsPEOAdvFilter("");
        setIsPEOFilter("");
        setSelectedStatuses([]);
        onSelectedStatusesFilter([]);
        setSelectedPhases([]);
        onSelectedPhasesFilter([]);
        onSelectedPageAdv(1);
        onMigrationAdvFilter([]);
        setSelectedMigrations([]);
        onSelectedFeedDataTypesFilter("");
        onSetResponsiblePartyFilter("");
        setSelectedDataTypes("");
        onSelectedFeedStructureTypesFilter([]);
        setSelectedStructureTypes([]);
        setSelectedMigrationStartDateFromDate(null);
        onMigrationStartFromDateAdvFilter(null);
        setSelectedMigrationStartDateToDate(null);
        onMigrationStartToDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateFromDate(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateToDate(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        setSelectedMigrationPhases([]);
        onMigrationAdvFilter([]);
        onSelectedMigrationPhasesAdvFilter([]);
        setSelectedMigrationPhases([]);
        setSelectedConnectivityRequestTypes([]);
        setSelectedConnectivityRequestSubTypes([]);
        onSelectedConnectivityRequestTypesFilter([]);
        onSelectedConnectivityRequestSubTypesFilter([]);
        onSetResponsiblePartyAdvFilter([]);
        setSelectedResponsibleParty([]);
        onSetAgingConnectivityFilter("");
        onSetAgingConnectivityAdvFilter("");
        setSelectedAgingConnectivity("");
    }

    React.useEffect(() => {
        setConnectivityRequestID("");
        onConnectivityRequestIDAdvFilter("");
        setSelectedGroups([]);
        onSelectedGroupsFilter([]);
        setSelectedPEOs([]);
        onSelectedPEOsFilter([]);
        setSelectedCarriers([]);
        onSelectedCarriersFilter([]);
        setPartner([]);
        onSelectedPartnerFilter([]);
        setFeedID("");
        onFeedIDAdvFilter("");
        setIncludePMF(null);
        onIncludePMFAdvFilter(null);
        setSelectedFeeds([]);
        onSelectedFeedsFilter([]);
        setSelectedCreatedFromDate(null);
        onCreatedFromDateAdvFilter(null);
        setSelectedCreatedToDate(null);
        onCreatedToDateAdvFilter(null);
        onSetClientEffectiveDateFromAdvFilter(null);
        setClientEffectiveDateFrom(null);
        setClientEffectiveDateTo(null);
        onSetClientEffectiveDateToAdvFilter(null);
        setSelectedAssignees([]);
        onSelectedAssignedToFilter([]);
        onSetIsPEOAdvFilter("");
        setIsPEOFilter("");
        setSelectedStatuses([]);
        onSelectedStatusesFilter([]);
        setSelectedPhases([]);
        onSetResponsiblePartyFilter("");
        onSetAgingConnectivityFilter("");
        onSetAgingConnectivityAdvFilter("");
        setSelectedAgingConnectivity("");
        onSelectedPhasesFilter([]);
        onSelectedPageAdv(1);
        setSelectedMigrations([]);
        onMigrationAdvFilter([]);
        onSelectedFeedDataTypesFilter("");
        setSelectedDataTypes("");
        onSelectedFeedStructureTypesFilter([]);
        setSelectedStructureTypes([]);
        setSelectedMigrationStartDateFromDate(null);
        onMigrationStartFromDateAdvFilter(null);
        setSelectedMigrationStartDateToDate(null);
        onMigrationStartToDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateFromDate(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateToDate(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        setSelectedMigrationPhases([]);
        onMigrationAdvFilter([]);
        onSelectedMigrationPhasesAdvFilter([]);
        setSelectedMigrationPhases([]);
        setSelectedConnectivityRequestTypes([]);
        setSelectedConnectivityRequestSubTypes([]);
        onSelectedConnectivityRequestTypesFilter([]);
        onSelectedConnectivityRequestSubTypesFilter([]);
        onSetResponsiblePartyAdvFilter([]);
        setSelectedResponsibleParty([]);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }
    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
    }
    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);


    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Connectivity Requests Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            {(userActions?.includes("43") ||
                userActions?.includes("20")) ? (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                // variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Connectivity Request ID" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="Client Name" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="PEO Name" {...a11yProps(2)} />
                                <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Feed ID" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Feed Name" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Feed Structure Type" {...a11yProps(6)} />
                                <Tab className="filter-tab-btn" label="Feed Data Type" {...a11yProps(7)} />
                                <Tab className="filter-tab-btn" label="Created Date" {...a11yProps(8)} />
                                <Tab className="filter-tab-btn" label="Assigned To" {...a11yProps(9)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Status" {...a11yProps(10)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Phase" {...a11yProps(11)} />
                                <Tab className="filter-tab-btn" label="Migration" {...a11yProps(12)} />
                                <Tab className="filter-tab-btn" label="Include Pre-Mapping Form" {...a11yProps(13)} />
                                <Tab className="filter-tab-btn" label="Migration Start Date" {...a11yProps(14)} />
                                <Tab className="filter-tab-btn" label="Planned First Production Date" {...a11yProps(15)} />
                                <Tab className="filter-tab-btn" label="Migration Phase" {...a11yProps(16)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Type" {...a11yProps(17)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Sub Type" {...a11yProps(18)} />
                                <Tab className="filter-tab-btn" label="Responsible Party" {...a11yProps(19)} />
                                <Tab className="filter-tab-btn" label="Include Aging" {...a11yProps(20)} />
                                <Tab className="filter-tab-btn" label="Client Effective Date" {...a11yProps(21)} />
                                <Tab className="filter-tab-btn" label="Include PEO" {...a11yProps(22)} />
                                {isIsovedSuperAdminOrSuperAdmin() && <Tab className="filter-tab-btn" label="Partner" {...a11yProps(23)} />}


                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one connectivity request ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Connectivity Request ID" value={connectivityRequestID} onChange={handleConnectivityRequestIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search clients here..." value={groupSearchValue} onChange={(e) => setGroupSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`groups-select-all`}>
                                            <AccordionSummary
                                                aria-controls="groups-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`groups-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedGroups && selectedGroups.length === groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedGroups, groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s), setSelectedGroups, onSelectedGroupsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }
                                    {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`groups-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="groups-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`group-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedGroups && selectedGroups.indexOf(c) >= 0}
                                                                onClick={handleGroupClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.GroupName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <input type="text" className="text-input w-100" placeholder="Search peos here..." value={peoSearchValue} onChange={(e) => setPEOSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`peos-select-all`}>
                                            <AccordionSummary
                                                aria-controls="peos-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`peos-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPEOs && selectedPEOs.length === peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedPEOs, peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s), setSelectedPEOs, onSelectedPEOsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }

                                    {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`peos-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="peos-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`peo-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPEOs && selectedPEOs.indexOf(c) >= 0}
                                                                onClick={handlePEOClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.PeoName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`carriers-select-all`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && selectedCarriers.length === carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedCarriers, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarriers, onSelectedCarriersFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`carriers-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carrier-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && selectedCarriers.indexOf(c) >= 0}
                                                                onClick={handleCarrierClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.CarrierName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>

                            <TabPanel value={tabValue} index={4}>
                                <span>Please filter by only one feed ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Feed ID" value={feedID} onChange={handleFeedIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <input type="text" className="text-input w-100" placeholder="Search feeds here..." value={feedSearchValue} onChange={(e) => setFeedSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`feeds-select-all`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feeds-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeeds && selectedFeeds.length === feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedFeeds, feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s), setSelectedFeeds, onSelectedFeedsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }
                                    {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`feeds-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="feeds-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`feed-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedFeeds && selectedFeeds.indexOf(c) >= 0}
                                                                onClick={handleFeedClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.FeedName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`structure-types-all`}>
                                        <AccordionSummary
                                            aria-controls="structure-types-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`structure-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStructureTypes && selectedStructureTypes.length === structureTypesList.length}
                                                            onClick={handelSelectAll(selectedStructureTypes, structureTypesList, setSelectedStructureTypes, onSelectedFeedStructureTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {structureTypesList.map((f, fi) => (
                                        <Accordion key={`structure-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="structure-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`structure-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStructureTypes && selectedStructureTypes.indexOf(f) >= 0}
                                                                onClick={handleStructureTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.StructureTypeName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={7}>
                                <span>Please filter by only one data type:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedDataTypes ?? ""}
                                        onChange={handleDataTypeNameFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option aria-label="full" value="Full">Full File</option>
                                        <option aria-label="changes" value="Changes">Changes Only File</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={8}>
                                <span>From:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedCreatedFromDate}
                                        onChange={(date) => {setSelectedCreatedFromDate(date);
                                            onCreatedFromDateAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedCreatedToDate}
                                        onChange={(date) => {setSelectedCreatedToDate(date);
                                            onCreatedToDateAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={9}>
                                <input type="text" className="text-input w-100" placeholder="Search users here..." value={assignedToSearchValue} onChange={(e) => setAssignedToSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {usersList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`users-select-all`}>
                                            <AccordionSummary
                                                aria-controls="users-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`users-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedAssignees && selectedAssignees.length === usersList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedAssignees, usersList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s), setSelectedAssignees, onSelectedAssignedToFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                                onClick={handleAssignedToClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                                onClick={handleAssignedToClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={10}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`connectivity-requests-status-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-status-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-status-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.length === connectivityRequestsStatusesList.length}
                                                            onClick={handelSelectAll(selectedStatuses, connectivityRequestsStatusesList, setSelectedStatuses, onSelectedStatusesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>



                                    {connectivityRequestsStatusesList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                                onClick={handleConnectivityRequestsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.ConnectivityRequestStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={11}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`connectivity-requests-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-phases-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.length === connectivityRequestsPhasesList.length}
                                                            onClick={handelSelectAll(selectedPhases, connectivityRequestsPhasesList, setSelectedPhases, onSelectedPhasesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>



                                    {connectivityRequestsPhasesList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                                onClick={handleConnectivityRequestsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.ConnectivityRequestPhasesName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div >
                            </TabPanel>
                            <TabPanel value={tabValue} index={12}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`group-requests-migration-all`}>
                                        <AccordionSummary
                                            aria-controls="group-requests-migration-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-requests-migration-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedMigrations && selectedMigrations.length === migrationList.length}
                                                            onClick={handelSelectAll(selectedMigrations, migrationList, setSelectedMigrations, onMigrationAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {migrationList.map((f, fi) => (
                                        <Accordion key={`migration-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="migration-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`migration-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedMigrations && selectedMigrations.indexOf(f) >= 0}
                                                                onClick={handleMigrationClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div >
                            </TabPanel>
                            <TabPanel value={tabValue} index={13}>
                                <span>Please filter by only one option:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={includePMF}
                                        onChange={handleIncludePMFFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="null" />
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={14}>
                                <span>From:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedMigrationStartDateFromDate}
                                        onChange={(date) => {setSelectedMigrationStartDateFromDate(date);
                                            onMigrationStartFromDateAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedMigrationStartDateToDate}
                                        onChange={(date) => {setSelectedMigrationStartDateToDate(date);
                                            onMigrationStartToDateAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={15}>
                                <span>From:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedPlannedFirstProductionDateFromDate}
                                        onChange={(date) => {setSelectedPlannedFirstProductionDateFromDate(date);
                                            onPlannedFirstProductionFromDateAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedPlannedFirstProductionDateToDate}
                                        onChange={(date) => {setSelectedPlannedFirstProductionDateToDate(date);
                                            onPlannedFirstProductionToDateAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={16}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`migration-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="migration-phases-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`migration-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedMigrationPhases && selectedMigrationPhases.length === migrationPhasesList.length}
                                                            onClick={handelSelectAll(selectedMigrationPhases, migrationPhasesList, setSelectedMigrationPhases, onSelectedMigrationPhasesAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {migrationPhasesList?.map((f, fi) => (
                                        <Accordion key={`migration-phase${fi}`}>
                                            <AccordionSummary
                                                aria-controls="migration-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`migration-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedMigrationPhases && selectedMigrationPhases.indexOf(f) >= 0}
                                                                onClick={handleMigrationPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={17}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`connectivity-requests-types-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-types-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedConnectivityRequestTypes && selectedConnectivityRequestTypes.length === connectivityRequestTypesList.length}
                                                            onClick={handelSelectAll(selectedConnectivityRequestTypes, connectivityRequestTypesList, setSelectedConnectivityRequestTypes, onSelectedConnectivityRequestTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {connectivityRequestTypesList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedConnectivityRequestTypes && selectedConnectivityRequestTypes.indexOf(f) >= 0}
                                                                onClick={handleConnectivityRequestTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={18}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`connectivity-requests-sub-types-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-sub-types-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-sub-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedConnectivityRequestSubTypes && selectedConnectivityRequestSubTypes.length === subTypeList.length}
                                                            onClick={handelSelectAll(selectedConnectivityRequestSubTypes, [...subTypeList.map(s => s.Id)], setSelectedConnectivityRequestSubTypes, onSelectedConnectivityRequestSubTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {subTypeList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-sub-type-option-${fi}`}
                                                                value={f.Id}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedConnectivityRequestSubTypes && selectedConnectivityRequestSubTypes.indexOf(f.Id) >= 0}
                                                                onClick={handleConnectivityRequestSubTypeClick(f.Id)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={19}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`responsible-parties-all`}>
                                        <AccordionSummary
                                            aria-controls="responsible-parties-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`responsible-parties-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedResponsibleParty && selectedResponsibleParty.length === responsiblePartiesList.length}
                                                            onClick={handelSelectAll(selectedResponsibleParty, responsiblePartiesList, setSelectedResponsibleParty, onSetResponsiblePartyAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {responsiblePartiesList?.map((f, fi) => (
                                        <Accordion key={`responsible-parties-list-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="responsible-parties-list-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`responsible-parties-list-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedResponsibleParty && selectedResponsibleParty.indexOf(f) >= 0}
                                                                onClick={handleResponsiblePartyClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary >
                                        </Accordion >
                                    ))
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={20}>
                                <span>Include Aging:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedAgingConnectivity ?? ""}
                                        onChange={handleAgingConnectivitiesFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="null" />
                                        <option value="1">Yes</option>

                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={21}>
                                <span>From:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={clientEffectiveDateFrom}
                                        onChange={(date) => {setClientEffectiveDateFrom(date);
                                            onSetClientEffectiveDateFromAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={clientEffectiveDateTo}
                                        onChange={(date) => {setClientEffectiveDateTo(date);
                                            onSetClientEffectiveDateToAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={22}>
                                <span>Include PEO:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={isPEOFilter ?? ""}
                                        onChange={handelIsPEOFilter}
                                        input={<BootstrapInput />}
                                        multiple={false}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </NativeSelect >
                                </FormControl >
                            </TabPanel>
                            {isIsovedSuperAdminOrSuperAdmin() &&
                                <TabPanel value={tabValue} index={23}>
                                    <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                        <Accordion key={`partners-select-all`}>
                                            <AccordionSummary
                                                aria-controls="partners-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={partner && partner.length === partnerNames.length}
                                                                onClick={handelSelectAll(partner, [...partnerNames.map(s => s.PartnerName)], setPartner, onSelectedPartnerFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>



                                        {partnerNames?.map((c, ci) => (
                                            <Accordion key={`partners-${ci}`}>
                                                <AccordionSummary
                                                    aria-controls="partners-select"
                                                    className="accordion-checkboxes"
                                                >
                                                    <FormControlLabel
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <>
                                                                <Checkbox
                                                                    id={`carrier-option-${ci}`}
                                                                    value={c?.PartnerName}
                                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                                    checked={partner && partner.indexOf(c?.PartnerName) >= 0}
                                                                    onClick={handlePartnerClick(c?.PartnerName)}
                                                                    checkedIcon={<DoneOutlineIcon />}
                                                                    color="default"
                                                                />
                                                            </>
                                                        }
                                                        label={c?.PartnerName}
                                                    />
                                                </AccordionSummary>
                                            </Accordion>
                                        ))}
                                    </div>
                                </TabPanel>
                            }

                        </Box>
                    </div>
                </DialogContent >
            ) : (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                // variant="scrollable"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Client Name" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="PEO Name" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Carrier Name" {...a11yProps(2)} />
                                {/* <Tab className="filter-tab-btn" label="Feed Structure Type" {...a11yProps(3)} /> */}
                                <Tab className="filter-tab-btn" label="Feed Data Type" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Created Date" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Assigned To" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Status" {...a11yProps(6)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Phase" {...a11yProps(7)} />
                                <Tab className="filter-tab-btn" label="Migration" {...a11yProps(8)} />
                                <Tab className="filter-tab-btn" label="Include Pre-Mapping Form" {...a11yProps(9)} />
                                <Tab className="filter-tab-btn" label="Migration Start Date" {...a11yProps(10)} />
                                <Tab className="filter-tab-btn" label="Planned First Production Date" {...a11yProps(11)} />
                                <Tab className="filter-tab-btn" label="Migration Phase" {...a11yProps(12)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Type" {...a11yProps(13)} />
                                <Tab className="filter-tab-btn" label="Connectivity Request Sub Type" {...a11yProps(14)} />
                                <Tab className="filter-tab-btn" label="Responsible Party" {...a11yProps(15)} />
                                <Tab className="filter-tab-btn" label="Include Aging" {...a11yProps(16)} />
                                <Tab className="filter-tab-btn" label="Client Effective Date" {...a11yProps(17)} />
                                <Tab className="filter-tab-btn" label="Include PEO" {...a11yProps(18)} />
                                {(isIsovedSuperAdminOrSuperAdmin() || userRoles.includes("4")) && <Tab className="filter-tab-btn" label="Partner" {...a11yProps(19)} />}


                            </Tabs >
                            <TabPanel value={tabValue} index={0}>
                                <input type="text" className="text-input w-100" placeholder="Search clients here..." value={groupSearchValue} onChange={(e) => setGroupSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`groups-select-all`}>
                                            <AccordionSummary
                                                aria-controls="groups-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`groups-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedGroups && selectedGroups.length === groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedGroups, groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s), setSelectedGroups, onSelectedGroupsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }



                                    {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`groups-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="groups-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`group-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedGroups && selectedGroups.indexOf(c) >= 0}
                                                                onClick={handleGroupClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.GroupName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search peos here..." value={peoSearchValue} onChange={(e) => setPEOSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`peos-select-all`}>
                                            <AccordionSummary
                                                aria-controls="peos-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`peos-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPEOs && selectedPEOs.length === peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedPEOs, peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s), setSelectedPEOs, onSelectedPEOsFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }

                                    {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`peos-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="peos-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`peo-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPEOs && selectedPEOs.indexOf(c) >= 0}
                                                                onClick={handlePEOClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.PeoName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <input type="text" className="text-input w-100" placeholder="Search carriers here..." value={carrierSearchValue} onChange={(e) => setCarrierSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`carriers-select-all`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && selectedCarriers.length === carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedCarriers, carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s), setSelectedCarriers, onSelectedCarriersFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }



                                    {carriersList?.filter(s => carrierSearchValue ? s.CarrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`carriers-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="carriers-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carrier-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedCarriers && selectedCarriers.indexOf(c) >= 0}
                                                                onClick={handleCarrierClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={c?.CarrierName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            {/* <TabPanel value={tabValue} index={3}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`structure-types-all`}>
                                        <AccordionSummary
                                            aria-controls="structure-types-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`structure-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStructureTypes && selectedStructureTypes.length === structureTypesList.length}
                                                            onClick={handelSelectAll(selectedStructureTypes, structureTypesList, setSelectedStructureTypes, onSelectedFeedStructureTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {structureTypesList.map((f, fi) => (
                                        <Accordion key={`structure-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="structure-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`structure-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStructureTypes && selectedStructureTypes.indexOf(f) >= 0}
                                                                onClick={handleStructureTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.StructureTypeName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel> */}

                            <TabPanel value={tabValue} index={3}>
                                <span>Please filter by only one data type:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedDataTypes ?? ""}
                                        onChange={handleDataTypeNameFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option aria-label="full" value="Full">Full File</option>
                                        <option aria-label="changes" value="Changes">Changes Only File</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <span>From:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedCreatedFromDate}
                                        onChange={(date) => {setSelectedCreatedFromDate(date);
                                            onCreatedFromDateAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedCreatedToDate}
                                        onChange={(date) => {setSelectedCreatedToDate(date);
                                            onCreatedToDateAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <input type="text" className="text-input w-100" placeholder="Search users here..." value={assignedToSearchValue} onChange={(e) => setAssignedToSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    {usersList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).length > 0 &&
                                        <Accordion key={`users-select-all`}>
                                            <AccordionSummary
                                                aria-controls="users-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`users-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedAssignees && selectedAssignees.length === usersList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).length}
                                                                onClick={handelSelectAll(selectedAssignees, usersList?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s), setSelectedAssignees, onSelectedAssignedToFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    }

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                                onClick={handleAssignedToClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                                onClick={handleAssignedToClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`connectivity-requests-status-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-status-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-status-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.length === connectivityRequestsStatusesList.length}
                                                            onClick={handelSelectAll(selectedStatuses, connectivityRequestsStatusesList, setSelectedStatuses, onSelectedStatusesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>


                                    {connectivityRequestsStatusesList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                                onClick={handleConnectivityRequestsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.ConnectivityRequestStatusName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={7}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`connectivity-requests-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-phases-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.length === connectivityRequestsPhasesList.length}
                                                            onClick={handelSelectAll(selectedPhases, connectivityRequestsPhasesList, setSelectedPhases, onSelectedPhasesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    {connectivityRequestsPhasesList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-phase-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                                onClick={handleConnectivityRequestsPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.ConnectivityRequestPhasesName}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div >
                            </TabPanel>
                            <TabPanel value={tabValue} index={8}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`group-requests-migration-all`}>
                                        <AccordionSummary
                                            aria-controls="group-requests-migration-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-requests-migration-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedMigrations && selectedMigrations.length === migrationList.length}
                                                            onClick={handelSelectAll(selectedMigrations, migrationList, setSelectedMigrations, onMigrationAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {migrationList.map((f, fi) => (
                                        <Accordion key={`migration-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="migration-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`migration-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedMigrations && selectedMigrations.indexOf(f) >= 0}
                                                                onClick={handleMigrationClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div >
                            </TabPanel>
                            <TabPanel value={tabValue} index={9}>
                                <span>Please filter by only one option:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={includePMF}
                                        onChange={handleIncludePMFFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="null" />
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={10}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedMigrationStartDateFromDate}
                                        onChange={(date) => {setSelectedMigrationStartDateFromDate(date);
                                            onMigrationStartFromDateAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedMigrationStartDateToDate}
                                        onChange={(date) => {setSelectedMigrationStartDateToDate(date);
                                            onMigrationStartToDateAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={11}>
                                <span>From:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedPlannedFirstProductionDateFromDate}
                                        onChange={(date) => {setSelectedPlannedFirstProductionDateFromDate(date);
                                            onPlannedFirstProductionFromDateAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={selectedPlannedFirstProductionDateToDate}
                                        onChange={(date) => {setSelectedPlannedFirstProductionDateToDate(date);
                                            onPlannedFirstProductionToDateAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={12}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`migration-phases-all`}>
                                        <AccordionSummary
                                            aria-controls="migration-phases-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`migration-phases-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedMigrationPhases && selectedMigrationPhases.length === migrationPhasesList.length}
                                                            onClick={handelSelectAll(selectedMigrationPhases, migrationPhasesList, setSelectedMigrationPhases, onSelectedMigrationPhasesAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {migrationPhasesList?.map((f, fi) => (
                                        <Accordion key={`migration-phase${fi}`}>
                                            <AccordionSummary
                                                aria-controls="migration-phases-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`migration-phase-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedMigrationPhases && selectedMigrationPhases.indexOf(f) >= 0}
                                                                onClick={handleMigrationPhasesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={13}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`connectivity-requests-types-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-types-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedConnectivityRequestTypes && selectedConnectivityRequestTypes.length === connectivityRequestTypesList.length}
                                                            onClick={handelSelectAll(selectedConnectivityRequestTypes, connectivityRequestTypesList, setSelectedConnectivityRequestTypes, onSelectedConnectivityRequestTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {connectivityRequestTypesList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-type-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedConnectivityRequestTypes && selectedConnectivityRequestTypes.indexOf(f) >= 0}
                                                                onClick={handleConnectivityRequestTypeClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={14}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`connectivity-requests-sub-types-all`}>
                                        <AccordionSummary
                                            aria-controls="connectivity-requests-sub-types-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`connectivity-requests-sub-types-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedConnectivityRequestSubTypes && selectedConnectivityRequestSubTypes.length === subTypeList.length}
                                                            onClick={handelSelectAll(selectedConnectivityRequestSubTypes, [].push(...subTypeList.map(s => s.Id)), setSelectedConnectivityRequestSubTypes, onSelectedConnectivityRequestSubTypesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {subTypeList.map((f, fi) => (
                                        <Accordion key={`connectivity-requests-type-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="connectivity-requests-types-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`connectivity-requests-sub-type-option-${fi}`}
                                                                value={f.Id}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedConnectivityRequestSubTypes && selectedConnectivityRequestSubTypes.indexOf(f.Id) >= 0}
                                                                onClick={handleConnectivityRequestSubTypeClick(f.Id)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={15}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`responsible-parties-all`}>
                                        <AccordionSummary
                                            aria-controls="responsible-parties-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`responsible-parties-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedResponsibleParty && selectedResponsibleParty?.length === responsiblePartiesList?.length}
                                                            onClick={handelSelectAll(selectedResponsibleParty, responsiblePartiesList, setSelectedResponsibleParty, onSetResponsiblePartyAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {responsiblePartiesList?.map((f, fi) => (
                                        <Accordion key={`responsible-parties-list-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="responsible-parties-list-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`responsible-parties-list-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedResponsibleParty && selectedResponsibleParty.indexOf(f) >= 0}
                                                                onClick={handleResponsiblePartyClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary >
                                        </Accordion >
                                    ))
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={16}>
                                <span>Include Aging:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedAgingConnectivity ?? ""}
                                        onChange={handleAgingConnectivitiesFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="null" />
                                        <option value="1">Yes</option>

                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={17}>
                                <span>From:</span>
                                <br />
                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={clientEffectiveDateFrom}
                                        onChange={(date) => {setClientEffectiveDateFrom(date);
                                            onSetClientEffectiveDateFromAdvFilter(date);}}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                                <br />
                                <br />
                                <span>To:</span>
                                <br />

                                <GenericDatePicker
                                        classNames="ml-xl-1 col-12"
                                        id="date-picker-inline"
                                        value={clientEffectiveDateTo}
                                        onChange={(date) => {setClientEffectiveDateTo(date);
                                            onSetClientEffectiveDateToAdvFilter(date);}}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />

                            </TabPanel>
                            <TabPanel value={tabValue} index={18}>
                                <span>Include PEO:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={isPEOFilter ?? ""}
                                        onChange={handelIsPEOFilter}
                                        input={<BootstrapInput />}
                                        multiple={false}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </NativeSelect >
                                </FormControl >
                            </TabPanel>
                            {(isIsovedSuperAdminOrSuperAdmin() || userRoles.includes("4")) &&
                                <TabPanel value={tabValue} index={19}>
                                    <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                        <Accordion key={`partners-select-all`}>
                                            <AccordionSummary
                                                aria-controls="partners-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`carriers-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={partner && partner.length === partnerNames.length}
                                                                onClick={handelSelectAll(partner, [...partnerNames.map(s => s.PartnerName)], setPartner, onSelectedPartnerFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>



                                        {partnerNames?.map((c, ci) => (
                                            <Accordion key={`partners-${ci}`}>
                                                <AccordionSummary
                                                    aria-controls="partners-select"
                                                    className="accordion-checkboxes"
                                                >
                                                    <FormControlLabel
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <>
                                                                <Checkbox
                                                                    id={`carrier-option-${ci}`}
                                                                    value={c?.PartnerName}
                                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                                    checked={partner && partner.indexOf(c?.PartnerName) >= 0}
                                                                    onClick={handlePartnerClick(c?.PartnerName)}
                                                                    checkedIcon={<DoneOutlineIcon />}
                                                                    color="default"
                                                                />
                                                            </>
                                                        }
                                                        label={c?.PartnerName}
                                                    />
                                                </AccordionSummary>
                                            </Accordion>
                                        ))}
                                    </div>
                                </TabPanel>
                            }

                        </Box >
                    </div >
                </DialogContent >
            )
            }
            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog >
    )
}

export default ConnectivityRequestsFilterModalBody;
