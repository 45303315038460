import React, { useCallback } from "react";
import { Paper, Button, FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import * as APIs from "../../../Data/APIs";
import CreateThirdPartyContactRow from "./CreateThirdPartyContactRow";
import { DropzoneArea } from "material-ui-dropzone";
import TPFileRow from "./TPFileRow";
import { parseISO } from 'date-fns';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateTimeSVG from "../../../Images/dateTime.svg";

import { useLoader } from "../../Layout/Loader";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { debounce } from "lodash";
import { AlertModal } from "../../Modals/AlertModal";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function SelectCarrierDetails({
    selectedOtherCarriers,
    parentForceUpdate,
    requestSubmitted,
    requestSaved,
    groupReq,
    setSelectedCarriers,
    selectedCarriers,
    carrierNotListedChecked,
    carrierCodeValidationForm,
    carrierNameValidationForm,
    duplicateCarriersList,
    setDuplicateCarriersList,
    similarCarriersList,
    setSimilarCarriersList,
    duplicateValidation, 
    setDuplicateValidation,
    continueWithWarning,
    setContinueWithWarning,
    ...props
}) {

    const classes = useStyles();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);

    const [tabValue, setTabValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState("ThirdParty");
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")
    const otherCarriersNames = selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(c => c?.Name);


    const openAlertModal = (carrierIndex) => {
            AlertModal(
            dialog,
            // on confirm handler
            () => {
                setContinueWithWarning(prev => ({
                    ...prev,
                    [carrierIndex]: true  
                }))
            },
            // content
            {
                title: 'Alert!',
                info: (
            <div>
                {duplicateValidation[carrierIndex]?.duplicateType === "error" ? (
                    <>
                        This carrier name already exists, please make sure you are adding a new carrier.
                        <div className="text-left fs-12">
                        {duplicateValidation[carrierIndex]?.duplicateCarriersList.map((dc) => (
                            <a className='warningLink' key={`duplicatedCarrier-${dc?.CarrierID}`} href={`/Carriers/ViewCarrier/${dc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{dc?.CarrierName}</a>
                        ))}
                        </div>
                    </>) : (
                    <>
                        This carrier name is similar to the following carrier(s), are you sure you want to save a new one?
                        <div className="text-left fs-12">
                            {duplicateValidation[carrierIndex]?.similarCarriersList.map((sc) =>
                            (<a className='warningLink' key={`similarCarrier-${sc?.CarrierID}`} href={`/Carriers/ViewCarrier/${sc?.CarrierID}`} target="_blank" rel="noopener noreferrer">{sc?.CarrierName}</a>))}
                        </div>
                    </>
                    )}
                </div >
                ),
                question: ``,
            },
            duplicateValidation[carrierIndex]?.duplicateType === "error" ?  { yes: "Ok", no: "Cancel" } : { yes: 'Yes', no: 'No' }
            )
        }
      // the core function of carrier name validation
      const validateCarrierNameCore = async (carrierNameValue, carrierIndex) => {
        openLoader();
        // Reset warning ONLY for the specific carrier
            setContinueWithWarning(prev => ({
                ...prev,
                [carrierIndex]: false,  // Only reset for the current carrier
            }));
    
        APIs.checkCarrierNameSameOrSimilar({ CarrierName: carrierNameValue })
            .then((res) => {
                setDuplicateValidation(prev => ({
                    ...prev,
                    [carrierIndex]: {
                        duplicateType: res?.duplicateType?.toLowerCase() || '',
                        duplicateCarriersList: res?.duplicateCarriers || [],
                        similarCarriersList: res?.similarCarriers || []
                    }
                }));
            })
            .catch((error) => console.log('Error while checking carrier name:', error))
            .finally(() => closeLoader());
    };
      
    
         const debouncedAsyncValidationForCarrierName = useCallback(
                debounce(validateCarrierNameCore, 2000),
                []
            );

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        if (!carrierNotListedChecked) return;
        else {
            selectedOtherCarriers.selectedOtherCarriersFullArray = [
                {
                    CarrierID: null,
                    IsolvedCarrier: {
                        Code: null,
                        Name: "",
                        isNew: false
                    },
                    ReceivedEncryptionRequirements: 0,
                    SelectedCRTypes: ["EDI"],
                    ConnectivityRequestEDISubTypeID: 1,
                }
            ]
            parentForceUpdate();
        }
    }, [selectedOtherCarriers])

    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }

    const checkUniqueCarrierNameHandler =(e)=>{
        let duplicateValidationCopy = {...duplicateValidation}

        if (!duplicateValidationCopy[tabValue]) {
            duplicateValidationCopy[tabValue] = {};
        }
        duplicateValidationCopy[tabValue]['duplicateType'] = otherCarriersNames.includes(e.target.value) ? 'uniqueCarrierNameValidation' :'';
        setDuplicateValidation(duplicateValidationCopy);
    }

    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers?.selectedOtherCarriersFullArray])
    return (
        <div id="third-party-section">
            <Paper elevation={0}>
                <Accordion expanded={expanded === `ThirdParty`}>
                    <AccordionSummary
                        onClick={() => expanded === `ThirdParty` ? setExpanded("") : setExpanded("ThirdParty")}
                        // className="pl-0 pr-0"
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="expand-carrier-contacts"
                    >
                        <span>Carriers Details</span>
                    </AccordionSummary>
                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{carrierCodeValidationForm}</div>
                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                    <div className="col-xl-10 mt-2 mb-3 text-danger" hidden={!requestSubmitted && !requestSaved}>{carrierNameValidationForm}</div>
                    <AccordionDetails>
                        <div className="w-100">

                            {selectedOtherCarriers?.selectedOtherCarriersFullArray?.length > 0 && (
                                <div style={{ textAlign: "end" }} className="mt-3">
                                    <Button variant="contained" className="blue-btn" onClick={() => {
                                        selectedOtherCarriers.selectedOtherCarriersFullArray = ([...selectedOtherCarriers.selectedOtherCarriersFullArray, {
                                            CarrierID: "",
                                            IsolvedCarrier: {
                                                Code: "",
                                                Name: "",
                                                isNew: false
                                            },
                                            ReceivedEncryptionRequirements: 0,
                                            SelectedCRTypes: ["EDI"],
                                            ConnectivityRequestEDISubTypeID: 1,
                                        }]);
                                        parentForceUpdate();
                                    }
                                    }>
                                        <label style={{ cursor: "pointer" }} className="mb-0">+ Other Carrier</label>
                                    </Button>
                                </div>
                            )}

                            {/* {(selectedCarriers && selectedCarriers?.length > 0) || (selectedOtherCarriers?.selectedOtherCarriersFullArray && selectedOtherCarriers.selectedOtherCarriersFullArray?.length > 0) && ( */}
                            <div id="third-parties" className={classes.root}>
                                <div className="border-bottom">
                                    <TabsTitlesWithMoreDD
                                        tabTitle={[
                                            ...(selectedCarriers?.map(sc => sc?.CarrierName) || []),
                                            ...(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(s => s?.Name ?? tabDefaultValue) || [])
                                        ]}
                                        value={handleTabValue()}
                                        onChange={handleTabChange}
                                        setValue={setTabValue}
                                    />
                                </div>
                                {/* <AppBar position="static" color="default">
                                    <div className="row">
                                        <div className="col-10 pl-0">
                                            <Tabs
                                                className="tab-style"
                                                value={handleTabValue()}
                                                onChange={handleTabChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs"
                                            >
                                                {selectedCarriers?.map((s, si) => (
                                                    <Tab
                                                        key={`carrier-tab-${si}`}
                                                        label={s?.CarrierName}
                                                    />
                                                ))}
                                                {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                                                    <Tab
                                                        key={`tp-tab-${si}`}
                                                        label={s?.Name ?? tabDefaultValue}
                                                    />
                                                ))}
                                            </Tabs>
                                        </div>
                                    </div>
                                </AppBar> */}
                                {selectedCarriers?.map((s, si) => (
                                    si === tabValue && (
                                        <div key={`tp-record-${si}`} >
                                            <TabPanel value={tabValue} index={tabValue} >
                                                <div className="row w-100">
                                                    <div className="col-xl-2 mt-3 alignvh-center">
                                                        <h6 className="form-title">Carrier Code</h6>
                                                        <span className="text-danger mb-2 ml-2">*</span>
                                                    </div>
                                                    <div className="col-xl-10 mt-3">
                                                        <input type="text" className="text-input w-100" value={s.Code ?? ""} onChange={e => { s.Code = e.target.value; parentForceUpdate(); }} />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    )
                                ))}
                                {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                                    si + selectedCarriers.length === tabValue && (
                                        <div key={`tp-record-${si}`} >
                                            <TabPanel value={tabValue} index={tabValue} >
                                                {selectedOtherCarriers?.selectedOtherCarriersFullArray?.length >= 2 && (
                                                    <div className="text-right">
                                                        <Button variant="contained" className="blue-outlined-btn ml-3" onClick={() => {
                                                            selectedOtherCarriers?.selectedOtherCarriersFullArray?.splice(selectedOtherCarriers?.selectedOtherCarriersFullArray.indexOf(s), 1);
                                                            if (tabValue === 0) setTabValue(0);
                                                            else setTabValue(tabValue - 1);
                                                            parentForceUpdate();
                                                        }}>
                                                            <label style={{ cursor: "pointer" }} className="mb-0">Delete Other Carrier</label>
                                                        </Button>
                                                    </div>
                                                )}
                                                <div className="row w-100">
                                                    <div className="col-xl-2 mt-3 alignvh-center">
                                                        <h6 className="form-title">Carrier Name</h6>
                                                        <span className="text-danger mb-2 ml-2">*</span>
                                                    </div>
                                                    <div className="col-xl-10 mt-3">
                                                    <input 
                                                        type="text" 
                                                        className="text-input w-100" 
                                                        value={s.Name ?? ""} 
                                                        onChange={e => {
                                                            const newName = e.target.value;
                                                            s.Name = newName;
                                                            parentForceUpdate();
                                                            if (!otherCarriersNames.includes(e.target.value))
                                                               debouncedAsyncValidationForCarrierName(newName, si + selectedCarriers.length);
                                                            checkUniqueCarrierNameHandler(e);
                                                        }} 
                                                    />
                                                         {duplicateValidation[tabValue]?.duplicateType === "uniqueCarrierNameValidation" && (
                                                            <div className="text-danger">
                                                                This carrier name is already selected
                                                            </div>
                                                        )}
                                                        {/* Error and Warning Messages */}
                                                        {duplicateValidation[tabValue]?.duplicateType === "error" && (
                                                            <div className="text-danger">
                                                                This carrier name is duplicate as the following{" "}
                                                                <span
                                                                    style={{ color: '#007bff', textDecoration: 'underline' }}
                                                                    role="button"
                                                                    onClick={() => openAlertModal(tabValue)}
                                                                >
                                                                    carrier
                                                                </span>
                                                            </div>
                                                        )}

                                                        {duplicateValidation[tabValue]?.duplicateType === "warning" && !continueWithWarning[tabValue] && (
                                                            <div className="text-alert-yellow">
                                                                This carrier name is similar to the following{" "}
                                                                <span
                                                                    style={{ color: '#007bff', textDecoration: 'underline' }}
                                                                    role="button"
                                                                    onClick={() => openAlertModal(tabValue)}
                                                                >
                                                                    carrier(s)
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-xl-2 mt-3 alignvh-center">
                                                        <h6 className="form-title">Carrier Code</h6>
                                                        <span className="text-danger mb-2 ml-2">*</span>
                                                    </div>
                                                    <div className="col-xl-10 mt-3">
                                                        <input type="text" className="text-input w-100" value={s.Code ?? ""} onChange={e => { s.Code = e.target.value; parentForceUpdate(); }} />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    )
                                ))}
                            </div>
                            {/* ) */}
                            {/* } */}
                        </div>
                    </AccordionDetails >
                </Accordion >
            </Paper >
        </div >
    )
}

export default SelectCarrierDetails;
