import React from "react";
import { Paper, Button } from "@material-ui/core";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import TabsTitles from "../Layout/TabsTitles";
import { SuccessModal } from "../Modals/SuccessModal";

function AddIP() {
    const [IPAddress, setIPAddress] = React.useState("");
    const [error, setError] = React.useState(""); // State to handle validation errors
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();

    const handleIPChange = (e) => {
        setIPAddress(e.target.value);
        setError(""); // Clear error when user types
    };

    // Function to validate IP format
    const isValidIP = (ip) => {
        const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipRegex.test(ip);
    };

    const handleAddIP = (IP) => {
        // Validation 1: Check if IP is empty or contains only whitespace
        if (!IP.trim()) {
            setError("IP address is required.");
            return;
        }

        // Validation 2: Check if IP is in a valid format
        if (!isValidIP(IP)) {
            setError("Please enter a valid IP address.");
            return;
        }

        // If validations pass, proceed with confirmation modal
        ConfirmModal(dialog, () => AddIPAddress(IP), {
            title: "Please Confirm",
            question: `Are you sure you want to add this IP?`,
        });
    };

    const AddIPAddress = (IP) => {
        openLoader();
        APIs.addIP({ IP: IP })
            .then((response) => {
                closeLoader();
                // Handle API response based on the "msg" field
                if (response.msg === "IP Updated Successfully.") {
                    // Show success toast
                    SuccessModal(dialog, {
                        title: "Success",
                        body: response.msg, // "IP Updated Successfully."
                    });
                    setIPAddress(""); // Clear the input field after successful submission
                } else {
                    // Show error toast for other messages
                    SuccessModal(dialog, {
                        title: "Error",
                        body: response.msg, // e.g., "IP address already exists."
                        type: "alert", // Use "alert" type for error messages
                    });
                }
            })
            .catch((error) => {
                closeLoader();
                // Show error toast if the API call fails (e.g., network error)
                SuccessModal(dialog, {
                    title: "Error",
                    body: "Failed to add IP. Please try again.",
                    type: "alert", // Use "alert" type for error messages
                });
            });
    };

    return (
        <>
            <TabsTitles tabTitle={["Add IP"]} />
            <Paper elevation={0} style={{ padding: "60px 100px" }}>
                <div className="mt-3 d-flex flex-column align-items-center">
                    <h6 className="form-title">
                        Enter Your IP:
                        <span className="text-danger ml-2">*</span>
                    </h6>
                    <input
                        type="text"
                        className="text-input w-25 mt-2"
                        value={IPAddress}
                        placeholder="e.g., 192.168.1.1"
                        onChange={handleIPChange}
                    />
                    {error && <p className="text-danger mt-2">{error}</p>} {/* Display validation error message */}
                    <Button
                        type="submit"
                        variant="contained"
                        className="blue-btn mt-3"
                        onClick={() => handleAddIP(IPAddress)}
                    >
                        ADD
                    </Button>
                </div>
            </Paper>
        </>
    );
}

export default AddIP;
