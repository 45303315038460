import React from "react";
import { Divider, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Button } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import Moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import MomentTZ from 'moment-timezone';
import GenericDatePicker from "../../../Layout/GenericDatePicker";


export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}

function SetLastSentFileModal({ feed, ...props }) {

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [availableDatesList, setAvailableDatesList] = React.useState([]);
    const [dateFrom, setDateFrom] = React.useState(null);
    const [dateTo, setDateTo] = React.useState(null);
    const [lastSentFileDate, setLastSentFileDate] = React.useState("");
    const [filteredLSFObject, setFilteredLSFObject] = React.useState();

    const handleLastSentFileChange = (e) => {
        setLastSentFileDate(e.target.value);
    };

    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setDateFrom(date);
        }
        else {
            setDateTo(date);
        }
    };

    const getFeedAvailableDates = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.feedID = feed?.FeedID;
        requestParameters.sortOrder = "DESC";
        if (dateFrom !== null)
            requestParameters.fromDate = dateFrom ? Moment(dateFrom).format('YYYY-MM-DD') : null;
        if (dateTo !== null)
            requestParameters.toDate = dateTo ? Moment(dateTo).format('YYYY-MM-DD') : null;

        APIs.getFeedLSFAvailableDates(requestParameters).then((r) => {
            setAvailableDatesList(r?.data);
            closeLoader();
        });
    }

    React.useEffect(() => {
        getFeedAvailableDates();
    }, [dateFrom, dateTo])

    React.useEffect(() => {
        setFilteredLSFObject(...availableDatesList.filter(d => d.date === lastSentFileDate));
    }, [lastSentFileDate])

    const updateLastSentFile = () => {
        dialog.closeDialog();
        openLoader();
        const lastSentFileBody = {
            date: filteredLSFObject.date,
            feedID: feed?.FeedID,
            fileName: filteredLSFObject.fileName
        }
        APIs.setFeedLSFAvailableDates(lastSentFileBody).then(r => window.location.reload());
    }

    return (
        <div id="last-sent-file-modal" style={{ minWidth: "600px" }}>
            <Paper elevation={0} className="m-3 p-4 search-container">
                <h6 className="text-nowrap fw-500">Filter by:</h6>
                <div className="row" style={{ whiteSpace: "nowrap" }}>
                    <div className="col-xl-6 mt-3">
                        <span>Available Last Sent File Date:</span>
                        <br />
                        <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={dateFrom}
                                    onChange={(date) => {
                                        setDateFrom(date);
                                      }}
                                    placeholder="From Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                    </div>
                    <div className="col-xl-6 mt-3">
                        <br />
                        <GenericDatePicker
                                    classNames="ml-xl-1 col-12"
                                    id="date-picker-inline"
                                    value={dateTo}
                                    onChange={(date) => {
                                        setDateTo(date);
                                      }}
                                    placeholder="To Date"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                    </div>
                </div>
            </Paper>
            <Paper elevation={0} className="pr-5 pl-5">
                {availableDatesList?.length > 0 && availableDatesList?.map((a, ai) => (
                    <FormControl key={`last-sent-file-${ai}`} component="fieldset">
                        <RadioGroup aria-label="date" name="dates" value={lastSentFileDate} onChange={handleLastSentFileChange}>
                            <FormControlLabel value={a?.date} control={<Radio />} label={toESTTime(Moment(a?.date).format('MM/DD/YYYY HH:mm:ss'))} />
                        </RadioGroup>
                    </FormControl>
                ))}
            </Paper>
            <div className="row w-100 mt-5" style={{ justifyContent: "flex-end" }}>
                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>Cancel</Button>
                <Button variant="contained" className="blue-btn" disabled={!lastSentFileDate} onClick={updateLastSentFile}>Confirm</Button>
            </div>
        </div>
    )
}

export default SetLastSentFileModal;