import React from "react";
import PartnersTable from "./PartnersTable";
import { Paper, Switch, FormControlLabel } from "@material-ui/core";
import { Button } from "@material-ui/core";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@mui/system';
import { SuccessModal } from "../Modals/SuccessModal";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { FailureModal } from "../Modals/FailureModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { useAuthentication } from "../../Data/Authentication";
import UnitMeasurementModal from "./UnitMeasurementModal";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function ViewPartnersList() {

    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader();
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [partnersList, setPartnersList] = React.useState([]);
    const [partnerStatus, setPartnerStatus] = React.useState("");
    const dialog = useModalDialog(0);


    React.useEffect(() => {
        APIs.getPartnerStatus().then((r) => setPartnerStatus(r));
    }, [])

    React.useEffect(() => {
        APIs.getAllPartnersData().then((r) => {
            setPartnersList(r?.data);
            setTotalCount(r?.count ?? 0);
        }
        );
    }, [])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    const handlePartnerStatus = () => {
        const body = {
            PartnersDisabled: partnerStatus === true ? 1 : 2 // 1 -> Disable Partners, 2 -> Enable Partners
        }
        openModal(0, () => handlePatnerStatusAPI(body), {
            title: "Please Confirm",
            question: partnerStatus == 1 ? `Are you sure you want to disable all Network Partners from submitting new connectivity requests?`
                : `Are you sure you want to enable all Network Partners to submit new connectivity requests?`,
        });
    }

    const handlePatnerStatusAPI = (body) => {
        openLoader()
        APIs.updatePartnerStatus(body).then((r) => {
            // setRefreshClicked(true)
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            }
            else {
                closeLoader();
                window.location.reload();
            }
        });
    }

    return (
        <div className="mt-3" id="maps-list">
            <div className="row w-100 alignvh-center">
                <h5 className="black1 fw-400">Partners</h5>
                {/* Download unit measurement file only for ISolved super admin and super admin  */}
                {(userRoles?.includes("2") || userRoles?.includes("7")) && <Button className="blue-btn" style={{ marginLeft: "auto" }} onClick={() => { dialog.openDialog("Unit Measurements Report", <UnitMeasurementModal />, []) }}>Unit Measurements Report</Button>}
            </div>

            <Paper className="content-card-paper">
                <div className="search-container">
                    <div className="row alignvh-center">
                        {/* <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6> */}
                        {(userRoles?.includes("11") || userRoles?.includes("7") || userRoles?.includes("2")) && ( // Authorized user or superAdmin or isolved super Admin

                            <div className="ml-auto">
                                <h6 className="form-title">Disable partner requests
                                    <FormControlLabel
                                        className="ml-2 mt-1"
                                        control={
                                            <Switch
                                                checked={!partnerStatus}
                                                onChange={(e) => handlePartnerStatus()}
                                            />
                                        }
                                    />
                                </h6>
                            </div>
                        )}
                        <br />
                        {/* <div className="row w-100">
                            <div className="col-xl-3 mt-3">
                                <span>Partner URL:</span>
                                <br />
                                <input type="text" className="search-dropdown w-100" value={mapNameFilter} onChange={handleMapNameFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Partner Name:</span>
                                <br />
                                <Autocomplete
                                    options={carriersList}
                                    autoHighlight
                                    className=" w-100"
                                    inputValue={carrierNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.CarrierName}
                                    onChange={(e, value) => {
                                        setCarrierNameFilter(value?.CarrierName ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.CarrierName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setCarrierNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            

                            <div className="col-xl-12 mt-4" >
                                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                                    <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                    </svg> Clear Search
                                </Button>
                            </div>


                        </div> */}

                        <div className="max-w-100 w-100 mt-3">
                            <PartnersTable
                                partnersList={partnersList}
                                partnerStatus={partnerStatus}
                                selectedPage={selectedPage}
                                pageSize={pageSize}
                                setSelectedPage={setSelectedPage}
                                setPageSize={setPageSize}
                                totalCount={totalCount}
                            />
                        </div>
                    </div>
                </div >
            </Paper >
        </div >
    )
}

export default ViewPartnersList;