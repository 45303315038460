import React from "react";
import { Select } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@mui/material/Grid';

function AnalystFeedTransmissionProtocol({
    feed,
    transmissionProtocolObject,
    feedsTransmissionTypes,
    feedsProtocolTypes,
    feedSSHKeys,
    onEdit,
    onTransmissionTypeChange,
    onProtocolTypeChange,
    onSSHKeyChange,
    onSFTPSiteChange,
    onSFTPPortChange,
    onSFTPUsernameChange,
    onSFTPPasswordChange,
    onSFTPDirectoryChange,
    transmissionTypeValidationForm,
    sftpProtocolTypeValidationForm,
    sftpSiteValidationForm,
    sftpPortValidationForm,
    sftpUsernameValidationForm,
    sftpPasswordValidationForm,
    sftpDirectoryValidationForm,
    requestSubmitted,
    sftpSSHKeyValidationForm,
    toEmailTransmission,
    setToEmailTransmission,
    ccEmailTransmission,
    setCcEmailTransmission,
    bccEmailTransmission,
    setBccEmailTransmission,
    emailSubjectTransmission,
    setEmailSubjectTransmission,
    carrierEmailSubjectValidationForm,
    emailBodyTransmission,
    setEmailBodyTransmission,
    carrierEmailBodyValidationForm,
    carrierEmailToEmailValidationForm,
    transmissionEmailObject,
    ...props
}) {

    const [transmissionType, setTransmissionType] = React.useState();
    const [protocolType, setProtocolType] = React.useState();
    const [sshKey, setSSHKey] = React.useState();
    const [sftpSite, setSFTPSite] = React.useState();
    const [sftpPort, setSFTPPort] = React.useState();
    const [sftpUsername, setSFTPUsername] = React.useState();
    const [sftpPassword, setSFTPPassword] = React.useState();
    const [sftpDirectory, setSFTPDirectory] = React.useState();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleTransmissionTypeChange = (e) => {
        setTransmissionType(e.target.value);
        onTransmissionTypeChange(parseInt(e.target.value));
    };

    const handleProtocolTypeChange = (e) => {
        setProtocolType(e.target.value);
        onProtocolTypeChange(e.target.value);
    };

    const handleSSHKeyChange = (e) => {
        setSSHKey(e.target.value);
        onSSHKeyChange(e.target.value);
    };

    const handleSFTPSiteChange = (e) => {
        setSFTPSite(e.target.value);
        onSFTPSiteChange(e.target.value);
    }

    const handleSFTPPortChange = (e) => {
        setSFTPPort(e.target.value);
        onSFTPPortChange(e.target.value);
    }

    const handleSFTPUsernameChange = (e) => {
        setSFTPUsername(e.target.value);
        onSFTPUsernameChange(e.target.value);
    }

    const handleSFTPPasswordChange = (e) => {
        setSFTPPassword(e.target.value);
        onSFTPPasswordChange(e.target.value);
    }

    const handleSFTPDirectoryChange = (e) => {
        setSFTPDirectory(e.target.value);
        onSFTPDirectoryChange(e.target.value);
    }


    React.useEffect(() => {
        setTransmissionType(parseInt(feed?.TransmissionTypeID));
        setProtocolType(transmissionProtocolObject?.Type);
        setSSHKey(transmissionProtocolObject?.FeedKeyId);
        setSFTPSite(transmissionProtocolObject?.Site);
        setSFTPPort(transmissionProtocolObject?.Port);
        setSFTPUsername(transmissionProtocolObject?.UserName);
        setSFTPPassword(transmissionProtocolObject?.Password);
        setSFTPDirectory(transmissionProtocolObject?.RemoteDirectory);
    }, [onEdit === true])

    return (
        <div className="w-100">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-3">
                <Grid item xs={12}>
                    <div style={{ display: "ruby" }}>
                        <h6 className="form-title">Transmission Type</h6>
                        {onEdit && (
                            <span className="text-danger mb-2 ml-2">*</span>
                        )}
                    </div>
                    {onEdit ?
                        (
                            <FormControl label="Select transmission" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={transmissionType ?? ""}
                                    onChange={handleTransmissionTypeChange}
                                >
                                    {feedsTransmissionTypes?.length > 0 && feedsTransmissionTypes.map((t, ti) => (
                                        <MenuItem key={`transmission-types-${ti}`} value={t.TransmissionTypeID}>{t.TransmissionTypeName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <div className="textfield-div">
                                {feed?.TransmissionTypeName}
                            </div>
                        )}
                </Grid>
            </Grid>
            {((!onEdit && feed?.TransmissionTypeID == 2) || (onEdit && transmissionType == 2)) && (
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Protocol Type</h6>
                            {onEdit && (
                                <span className="text-danger mb-2 ml-2">*</span>
                            )}
                        </div>
                        {onEdit ? (
                            <>
                                <FormControl label="Select transmission" focused={false} variant="outlined" className="w-100">
                                    <Select
                                        className="text-dropdown"
                                        value={protocolType ?? ""}
                                        onChange={handleProtocolTypeChange}
                                    >
                                        {feedsProtocolTypes?.length > 0 && feedsProtocolTypes.map((p, pi) => (
                                            <MenuItem key={`protocol-types-${pi}`} value={p.ProtocolTypeName}>{p.ProtocolTypeName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpProtocolTypeValidationForm}</div>
                            </>

                        ) : (
                            <div className="textfield-div">
                                {transmissionProtocolObject?.Type}
                            </div>
                        )}
                    </Grid>
                    {!onEdit && transmissionProtocolObject?.Type === "SCP SFTP (SSH)" && (
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">SSH Key</h6>
                            <div className="textfield-div">
                                {feedSSHKeys?.filter(s => s.Id == transmissionProtocolObject?.FeedKeyId)[0]?.FeedKeyName}
                            </div>
                        </Grid>
                    )}
                    {(onEdit && protocolType === "SCP SFTP (SSH)") && (
                        <Grid item xs={12} sm={12} md={6}>
                            <div style={{ display: "ruby" }}>
                                <h6 className="form-title">SSH Key</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <FormControl label="Select transmission" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={sshKey ?? ""}
                                    onChange={handleSSHKeyChange}
                                >
                                    {feedSSHKeys?.length > 0 && feedSSHKeys.map((p, pi) => (
                                        <MenuItem key={`ssh-key-${pi}`} value={p.Id}>{p.FeedKeyName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpSSHKeyValidationForm}</div>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Site</h6>
                            {onEdit && (
                                <span className="text-danger mb-2 ml-2">*</span>
                            )}
                        </div>
                        {onEdit ? (
                            <>
                                <input type="text" className="text-input w-100" value={sftpSite ?? ""} onChange={handleSFTPSiteChange} />
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpSiteValidationForm}</div>
                            </>
                        ) : (
                            <div className="textfield-div">
                                {transmissionProtocolObject?.Site}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Port</h6>
                            {onEdit && (
                                <span className="text-danger mb-2 ml-2">*</span>
                            )}
                        </div>
                        {onEdit ? (
                            <>
                                <input type="number" className="text-input w-100" value={sftpPort ?? ""} onChange={handleSFTPPortChange} />
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpPortValidationForm}</div>
                            </>
                        ) : (
                            <div className="textfield-div">
                                {transmissionProtocolObject?.Port}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Username</h6>
                            {onEdit && (
                                <span className="text-danger mb-2 ml-2">*</span>
                            )}
                        </div>
                        {onEdit ? (
                            <>
                                <input type="text" className="text-input w-100" value={sftpUsername ?? ""} onChange={handleSFTPUsernameChange} />
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpUsernameValidationForm}</div>
                            </>
                        ) : (
                            <div className="textfield-div">
                                {transmissionProtocolObject?.UserName}
                            </div>
                        )}
                    </Grid>
                    {!onEdit && transmissionProtocolObject?.Type !== "SCP SFTP (SSH)" && (
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Password</h6>
                            <div className="textfield-div position-relative">
                                {showPassword ? transmissionProtocolObject?.Password : `********`}
                                <span style={{ right: "12px", position: "absolute", cursor: "pointer", top: "5px" }}>
            
                                    {showPassword ? <Visibility className="fs-18" onClick={() => setShowPassword(false)} /> : <VisibilityOff className="fs-18" onClick={() => setShowPassword(true)} />}
                                </span>
                            </div>
                        </Grid>
                    )}
                    {(onEdit && protocolType !== "SCP SFTP (SSH)") && (
                        <Grid item xs={12} sm={12} md={6}>
                            <div style={{ display: "ruby" }}>
                                <h6 className="form-title">Password</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <div className="position-relative">
                                <input  autoComplete="new-password"  type={showPassword ? "text" : "password"} className="text-input w-100" value={sftpPassword ?? ""} onChange={handleSFTPPasswordChange} />
                                <span style={{ right: "12px", position: "absolute", cursor: "pointer", top: "5px" }}>
                                    {showPassword ? <Visibility className="fs-18" onClick={() => setShowPassword(false)} /> : <VisibilityOff className="fs-18" onClick={() => setShowPassword(true)} />}
                                </span>
                            </div>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpPasswordValidationForm}</div>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Remote Directory</h6>
                            {onEdit && (
                                <span className="text-danger mb-2 ml-2">*</span>
                            )}
                        </div>
                        {onEdit ? (
                            <>
                                <input type="text" className="text-input w-100" value={sftpDirectory ?? ""} onChange={handleSFTPDirectoryChange} />
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpDirectoryValidationForm}</div>
                            </>
                        ) : (
                            <div className="textfield-div">
                                {transmissionProtocolObject?.RemoteDirectory}
                            </div>
                        )}
                    </Grid>
                </Grid>
            )}
            {((!onEdit && feed?.TransmissionTypeID == 1) || (onEdit && transmissionType == 1)) && (
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-3">
                    <Grid item xs={12}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Confirmation Email</h6>
                            {onEdit && <span className="text-danger mb-2 ml-2">*</span>}
                        </div>
                        {onEdit ? (
                            <div className="components-div pb-3">
                                <div className="mt-3 mb-2">
                                    <span>You can enter multiple emails here separated by commas</span>
                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 alignvh-center">
                                        <h6 className="form-title">To:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={toEmailTransmission} onChange={(e) => { setToEmailTransmission(e.target.value) }} />
                                    </div>
                                    <div className="col-xl-1 mt-1 alignvh-center"></div>
                                    <div className="col-xl-11 mt-1">
                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{carrierEmailToEmailValidationForm}</div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 alignvh-center">
                                        <h6 className="form-title">Cc:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={ccEmailTransmission} onChange={(e) => { setCcEmailTransmission(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 alignvh-center">
                                        <h6 className="form-title">Bcc:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={bccEmailTransmission} onChange={(e) => { setBccEmailTransmission(e.target.value) }} />
                                    </div>
                                </div>
                                {(toEmailTransmission !== "" || ccEmailTransmission !== "" || bccEmailTransmission !== "") && (
                                    <>
                                        <div className="row">
                                            <div className="col-xl-1 mt-3 alignvh-center">
                                                <h6 className="form-title">Subject:</h6>
                                            </div>
                                            <div className="col-xl-11 mt-3">
                                                <input autoComplete="new-password" className="text-input w-100" value={emailSubjectTransmission} onChange={(e) => { setEmailSubjectTransmission(e.target.value) }} />
                                            </div>
                                            <div className="col-xl-1 mt-1 alignvh-center"></div>
                                            <div className="col-xl-11 mt-1">
                                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{carrierEmailSubjectValidationForm}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-1 mt-3 alignvh-center">
                                                <h6 className="form-title">Body:</h6>
                                            </div>
                                            <div className="col-xl-11 mt-3">
                                                <textarea type="text" className="action-item-textarea w-100" value={emailBodyTransmission.replaceAll("<br/>", "\n")} onChange={(e) => { setEmailBodyTransmission(e.target.value) }} rows={4} />
                                            </div>
                                            <div className="col-xl-1 mt-1 alignvh-center"></div>
                                            <div className="col-xl-11 mt-1">
                                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{carrierEmailBodyValidationForm}</div>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        ) : (
                            <div className="textfield-div" style={{ display: "block" }}>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 alignvh-center">
                                        <h6 className="form-title">To:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        {transmissionEmailObject?.EmailObject?.To?.length > 0 && transmissionEmailObject?.EmailObject?.To?.map(s => s).join(", ")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 alignvh-center">
                                        <h6 className="form-title">Cc:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        {transmissionEmailObject?.EmailObject?.Cc?.length > 0 && transmissionEmailObject?.EmailObject?.Cc?.map(s => s).join(", ")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 alignvh-center">
                                        <h6 className="form-title">Bcc:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        {transmissionEmailObject?.EmailObject?.Bcc?.length > 0 && transmissionEmailObject?.EmailObject?.Bcc?.map(s => s).join(", ")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3">
                                        <h6 className="form-title">Subject:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3">
                                        {transmissionEmailObject?.EmailObject?.EmailSubject}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-1 mt-3 mb-3">
                                        <h6 className="form-title">Body:</h6>
                                    </div>
                                    <div className="col-xl-11 mt-3 mb-3">
                                        <span style={{ whiteSpace: "break-spaces" }}>{transmissionEmailObject?.EmailObject?.EmailBody?.replaceAll("<br/>", "\n")}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export default AnalystFeedTransmissionProtocol;